import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { InputField, InputFieldProps } from '../../core';

const StyledInputWrapper = styled.div<{ hide_background: boolean }>`
    // select the InputField container div
    > div {
        background-color: ${(props): string =>
            props.hide_background ? 'inherit' : props.theme.colors.white};
    }
`;

/**
 * InputField component with auth specific styling
 */
export function AuthInput(props: InputFieldProps): ReactElement {
    const { hide_background } = props;

    return (
        <StyledInputWrapper hide_background={!!hide_background}>
            <InputField {...props} />
        </StyledInputWrapper>
    );
}
