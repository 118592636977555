import styled from 'styled-components';

export const TooltipContainer = styled.div<{ max_width?: string }>`
    padding: 8px 16px;
    min-width: 70px;
    border-radius: 4px;
    gap: 4px;
    background-color: ${(props): string => props.theme.colors.grey800};
    align-items: flex-start;
    color: ${(props): string => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    max-width: ${(props): string => props.max_width || 'none'};
`;

export const LightTooltipContainer = styled(TooltipContainer)`
    background: ${(props): string => props.theme.colors.white};
    color: ${(props): string => props.theme.colors.grey900};
    box-shadow: ${(props): string => props.theme.elevations.z4};
`;
