import React, { ReactElement } from 'react';
import { StyledDivider } from './Divider.styles';

export enum DividerVariant {
    REGULAR = 'regular',
    STRONG = 'strong',
}
export interface DividerProps {
    variant?: DividerVariant;
    className?: string;
}

export default function Divider(props: DividerProps): ReactElement {
    return (
        <StyledDivider
            variant={props.variant || DividerVariant.REGULAR}
            className={props.className}
        />
    );
}
