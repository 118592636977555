import styled from 'styled-components';

const PAYSTONE_LOGIN_STONES_IMAGE_URL = 'https://cdn.nicejob.co/assets/login-stones.svg';
const PAYSTONE_BANNER_ON_DARK_IMAGE_URL = 'https://cdn.nicejob.co/assets/paystone-logo-on-dark.svg';
const PAYSTONE_BANNER_ON_LIGHT_IMAGE_URL =
    'https://cdn.nicejob.co/assets/paystone-logo-on-white.svg';

export const AuthSideCar = styled.div`
    display: none;

    @media ${(props): string => props.theme.responsive_media_queries.desktop} {
        display: flex;
        flex: 1;
        height: 100%;
        background-color: ${(props): string => props.theme.colors.paystoneBlueBackground};
        background-image: url(${PAYSTONE_LOGIN_STONES_IMAGE_URL});
        background-repeat: no-repeat;
        background-position: right -120px bottom -100px;
        background-size: 300px;
    }
`;

export const SideCarContent = styled.div`
    position: relative;
    width: 276px;
    margin: 140px 0 0 40px;
`;

export const SideCarMessage = styled.div`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.1px;
    line-height: 24px;
    color: ${(props): string => props.theme.colors.white};
    text-align: left;
    font-weight: 400;
`;

export const PaystoneDarkBanner = styled.div`
    width: 210px;
    height: 44px;
    background-image: url(${PAYSTONE_BANNER_ON_DARK_IMAGE_URL});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    margin: 0 0 32px;
`;

export const PaystoneLightBanner = styled.div`
    display: flex;
    width: 210px;
    height: 44px;
    margin: 0 auto 16px;
    background-image: url(${PAYSTONE_BANNER_ON_LIGHT_IMAGE_URL});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    @media ${(props): string => props.theme.responsive_media_queries.desktop} {
        display: none;
    }
`;

export const PaymentsAuthCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const LanguageSwitcher = styled.div`
    display: flex;
    flex-direction: row;
    margin: 16px;
    font-size: 16px;
    font-weight: 700;
    justify-content: flex-end;
    color: ${(props): string => props.theme.colors.paystoneBlue};
    cursor: pointer;
`;

export const PaymentsAuthContent = styled.div<{ is_loading?: boolean }>`
    display: ${(props): string => (props.is_loading ? 'none' : 'flex')};

    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;

    @media ${(props): string => props.theme.responsive_media_queries.desktop} {
        width: 762px;
        height: 496px;
        border-radius: 6px;
        box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.16);
    }
`;

export const PaymentsAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const PaymentsAuthCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    height: 100%;
    justify-content: center;
    padding: 24px;
`;

export const PaymentsAuthCard = styled.div`
    flex: 1;
    height: 100%;
`;

export const AuthErrorContainer = styled.div<{ is_loading: boolean }>`
    display: ${(props): string => (props.is_loading ? 'none' : 'block')};
    width: 100%;

    @media ${(props): string => props.theme.responsive_media_queries.desktop} {
        width: 762px;
    }
`;

export const AuthErrorLink = styled.span`
    color: ${(props): string => props.theme.colors.paystoneBlue};
    text-decoration: underline;
    cursor: pointer;
`;
