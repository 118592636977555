import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { ICheckboxProps, ICheckboxCheckProps, ICheckboxCheckPropsWithTheme } from './Checkbox';

export const CheckboxContainer = styled.div<Pick<ICheckboxProps, 'disabled'>>`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    padding: 2px;
    :focus {
        outline: none;
    }
    ${(props): FlattenSimpleInterpolation | undefined => {
        if (props.disabled) {
            return css`
                pointer-events: none;
                opacity: 0.4;
            `;
        }
    }};
`;

export const CheckboxInputHidden = styled.input.attrs({ type: 'Checkbox' })`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    margin: -1px;
    width: 1px;
    outline: none;
`;

export const CheckboxLabel = styled.label`
    margin: 0;
    margin-left: 8px;
    color: inherit;
    display: block;
    font-size: 14px;
`;

export const CheckboxActiveIndicator = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props): string => props.theme.colors.blue500};
    color: ${(props): string => props.theme.colors.white};
    visibility: inherit;
`;

export const CheckboxCheck = styled.div<ICheckboxCheckProps>`
    display: flex;
    box-sizing: border-box;
    background-color: ${(props): string => getCheckboxBackgroundStyles(props)};
    border: 1px solid ${(props): string => getCheckboxBorderStyles(props)};
    border-radius: 2px;
    height: 16px;
    width: 16px;
    overflow: hidden;
    cursor: pointer;
    justify-content: center;
    flex-shrink: 0;

    align-items: center;
    ${CheckboxInputHidden}:checked + & {
        border: 2px solid ${(props): string => props.theme.colors.blue500};
    }

    ${CheckboxActiveIndicator} {
        visibility: ${(props): string => (props.selected ? 'inherit' : 'hidden')};
    }
`;

export const CrownBlockedWrapper = styled.div`
    margin-left: auto;
`;

// Helpers
function getCheckboxBorderStyles(props: ICheckboxCheckPropsWithTheme): string {
    if (props.selected || props.hovered) {
        return props.theme.colors.blue500;
    } else {
        return props.theme.colors.grey400;
    }
}

function getCheckboxBackgroundStyles(props: ICheckboxCheckPropsWithTheme): string {
    if (props.selected) {
        return props.theme.colors.blue500;
    } else {
        return props.theme.colors.grey50;
    }
}
