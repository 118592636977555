import styled, { DefaultTheme } from 'styled-components';
import { ITextFieldProps } from './TextField';
import { Typography } from '../../../typography/Typography';

export const StyledTextField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`;

export const StyledLabel = styled(Typography.P)<ITextFieldProps>`
    color: ${(props): string => labelColor(props)};
`;

export const StyledDescription = styled(Typography.Cite)<ITextFieldProps>`
    display: flex;
    justify-content: space-between;
    color: ${(props): string => descriptionColor(props)};
`;

interface ITextFieldPropsWithTheme extends ITextFieldProps {
    theme: DefaultTheme;
}

// Helpers
function descriptionColor(props: ITextFieldPropsWithTheme): string {
    return getColorByState(props) || props.theme.colors.grey600;
}

function labelColor(props: ITextFieldPropsWithTheme): string {
    return getColorByState(props) || props.theme.colors.grey900;
}

function getColorByState(props: ITextFieldPropsWithTheme): string | null {
    const { disabled, valid, invalid, color, theme } = props;

    if (disabled) {
        return theme.colors.grey600;
    }
    if (color) {
        return color;
    }
    if (valid) {
        return theme.colors.green500;
    }
    if (invalid) {
        return theme.colors.red600;
    }

    return null;
}
