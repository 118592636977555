import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import {
    BlueCylinder,
    GraphicsContainer,
    GraphicsImage,
    GreenArrow,
    RedCircle,
    StarsContainer,
} from './BackgroundVisuals.styles';

/** Types */
interface IBackgroundVisualsProps {
    show_manual_address: boolean;
}

/** Globals */
const STOCK_IMAGE_WOMAN_TEXTING_URL =
    'https://storage.googleapis.com/cdn.nicejob.co/assets/stock-image-woman-texting.png';
const ARROW_CURVED_GREEN_URL =
    'https://storage.googleapis.com/cdn.nicejob.co/assets/arrow-green-curved.png';

export function BackgroundVisuals(props: IBackgroundVisualsProps): ReactElement {
    const { show_manual_address } = props;

    const theme = useTheme();

    return (
        <GraphicsContainer show_manual_address={show_manual_address}>
            <StarsContainer>
                <FontAwesomeIcon icon={faStar} color={theme.colors.gold500} fontSize='20px' />
                <FontAwesomeIcon icon={faStar} color={theme.colors.gold500} fontSize='20px' />
                <FontAwesomeIcon icon={faStar} color={theme.colors.gold500} fontSize='20px' />
                <FontAwesomeIcon icon={faStar} color={theme.colors.gold500} fontSize='20px' />
                <FontAwesomeIcon icon={faStar} color={theme.colors.gold500} fontSize='20px' />
            </StarsContainer>
            <GreenArrow src={ARROW_CURVED_GREEN_URL} />
            <BlueCylinder />
            <RedCircle />
            <GraphicsImage src={STOCK_IMAGE_WOMAN_TEXTING_URL} />
        </GraphicsContainer>
    );
}
