import { useWindowSize } from '@nicejob-library/react-hooks';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';
import {
    StyledAnchorLink,
    StyledCite,
    StyledDivLink,
    StyledH1,
    StyledH2,
    StyledH3,
    StyledH4,
    StyledH5,
    StyledP,
} from './Typography.styles';

export interface HeaderProps {
    children: ReactNode;
    muted?: boolean;
    className?: string;
    onClick?: (event?: React.MouseEvent) => any;
    ref?: React.MutableRefObject<HTMLHeadingElement>;
    'data-testid'?: string;
}

export interface StyledH1Props {
    window_width: number;
    muted?: boolean;
    onClick?: () => any;
}

export interface PProps {
    children: ReactNode;
    strong?: boolean;
    muted?: boolean;
    size?: 'small';
    className?: string;
    display?: CSSProperties['display'];
    onClick?: () => any;
    'data-testid'?: string;
}

export interface CiteProps {
    children: ReactNode;
    strong?: boolean;
    muted?: boolean;
    className?: string;
    onClick?: () => any;
}

export interface LinkProps {
    children: ReactNode;
    href?: string;
    rel?: string;
    onClick?: () => void;
    target?: string;
    undecorated?: boolean;
    strong?: boolean;
    muted?: boolean;
    size?: TLinkSize;
    color?: string;
    className?: string;
}

export interface ThemeProp {
    theme: DefaultTheme;
}

export type TLinkSize = 'small' | 'medium';

export function Typography(): null {
    return null;
}

// Headers
function H1(props: HeaderProps): ReactElement {
    const window_width = useWindowSize().width;
    const { onClick, children, muted, className, ref } = props;

    return (
        <StyledH1
            window_width={window_width}
            muted={muted}
            className={className}
            onClick={onClick}
            ref={ref}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledH1>
    );
}

function H2(props: HeaderProps): ReactElement {
    const { children, muted, className, onClick, ref } = props;

    return (
        <StyledH2
            muted={muted}
            className={className}
            onClick={onClick}
            ref={ref}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledH2>
    );
}

function H3(props: HeaderProps): ReactElement {
    const { children, muted, className, onClick, ref } = props;

    return (
        <StyledH3
            muted={muted}
            className={className}
            onClick={onClick}
            ref={ref}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledH3>
    );
}

function H4(props: HeaderProps): ReactElement {
    const { children, muted, className, onClick, ref } = props;

    return (
        <StyledH4
            muted={muted}
            className={className}
            onClick={onClick}
            ref={ref}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledH4>
    );
}

function H5(props: HeaderProps): ReactElement {
    const { children, muted, className, onClick, ref } = props;

    return (
        <StyledH5
            muted={muted}
            className={className}
            onClick={onClick}
            ref={ref}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledH5>
    );
}

// Paragraph
function P(props: PProps): ReactElement {
    const { strong, size, muted, onClick, className, children, display = 'block' } = props;

    return (
        <StyledP
            display={display}
            strong={strong}
            size={size}
            muted={muted}
            className={className}
            onClick={onClick}
            data-testid={props['data-testid']}
        >
            {children}
        </StyledP>
    );
}

// Citation
function Cite(props: CiteProps): ReactElement {
    const { strong, muted, className, children } = props;

    return (
        <StyledCite strong={strong} muted={muted} className={className}>
            {children}
        </StyledCite>
    );
}

// Link
function Link(props: LinkProps): ReactElement {
    const { target, href, children, onClick: inputOnClick } = props;
    const history = useHistory();
    // If there is a target, we likely want to open the link in a new tab using an anchor tag
    if (target) {
        return <StyledAnchorLink {...props}>{children}</StyledAnchorLink>;
    }

    // If there is no target, we want to avoid a browser refresh and therefore use a div and the useHistory hook instead
    const onClick = (): void => {
        if (inputOnClick) {
            inputOnClick();
        }

        if (href) {
            history.push(href);
        }
    };

    return (
        <StyledDivLink {...props} onClick={onClick}>
            {children}
        </StyledDivLink>
    );
}

// Typography static properties (akin to exports)
Typography.H1 = H1;
Typography.H2 = H2;
Typography.H3 = H3;
Typography.H4 = H4;
Typography.H5 = H5;
Typography.P = P;
Typography.Cite = Cite;
Typography.Link = Link;
