import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Typography } from '../typography/Typography';

/** Types */
export interface RichHeaderProps {
    title: string;
    description: string;
    icon: ReactElement;
    className?: string;
}

/** Styles */
const RichHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const IconWrapper = styled.div`
    height: 48px;
    width: 48px;
    margin-right: 24px;
    flex-shrink: 0;

    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        margin-right: 16px;
    }
`;

const TitleDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

/** Main component */
export function RichHeader(props: RichHeaderProps): ReactElement {
    const { title, description, icon, className } = props;

    return (
        <RichHeaderWrapper className={className}>
            <IconWrapper>{icon}</IconWrapper>

            <TitleDescriptionWrapper>
                <Typography.H1>{title}</Typography.H1>

                {props.description && <Typography.P muted={true}>{description}</Typography.P>}
            </TitleDescriptionWrapper>
        </RichHeaderWrapper>
    );
}
