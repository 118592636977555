import React, { ReactElement } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { FaCrown } from 'react-icons/fa';

import { Typography } from '../typography/Typography';

interface ILabel {
    text: string;
    color?: string;
    icon?: boolean;
}

interface IStyledLabel {
    text_color: string;
    background_color: string;
}

export enum LabelColor {
    blue = 'blue',
    red = 'red',
    grey = 'grey',
    yellow = 'yellow',
    purple = 'purple',
    green = 'green',
}

interface IColorPreset {
    background: string;
    text: string;
}

interface IGetColorPresetInput {
    color: LabelColor;
    theme: DefaultTheme;
}

function getColorPreset({ color, theme }: IGetColorPresetInput): IColorPreset {
    const color_presets = {
        [LabelColor.blue]: {
            background: theme.colors.blue50,
            text: theme.colors.blue500,
        },
        [LabelColor.green]: {
            background: theme.colors.green50,
            text: theme.colors.green500,
        },
        [LabelColor.red]: {
            background: theme.colors.red50,
            text: theme.colors.red500,
        },
        [LabelColor.purple]: {
            background: theme.colors.purple50,
            text: theme.colors.purple500,
        },
        [LabelColor.yellow]: {
            background: theme.colors.gold50,
            text: theme.colors.gold700,
        },
        [LabelColor.grey]: {
            background: theme.colors.grey100,
            text: theme.colors.grey700,
        },
    };

    return color_presets[color];
}

const StyledLabel = styled.div<IStyledLabel>`
    padding: 4px 8px;
    margin: 0 8px;
    border-radius: 16px;
    background-color: ${(props): string => props.background_color};
    color: ${(props): string => props.text_color};
    display: flex;
    align-items: center;
`;

const StyleLabelText = styled(Typography.Cite)`
    color: inherit;
`;

const StyledCrown = styled(FaCrown)`
    color: inherit;
    margin-right: 4px;
`;

export function Label(props: ILabel): ReactElement {
    const theme = useTheme();

    const { color = LabelColor.blue, icon, text } = props;

    const color_preset = getColorPreset({ color, theme } as IGetColorPresetInput);

    return (
        <StyledLabel text_color={color_preset.text} background_color={color_preset.background}>
            {icon ? <StyledCrown size={12} /> : null}
            <StyleLabelText>{text}</StyleLabelText>
        </StyledLabel>
    );
}
