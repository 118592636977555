import styled, { DefaultTheme } from 'styled-components';
import { StyledH1Props, HeaderProps, PProps, CiteProps, LinkProps, ThemeProp } from './Typography';

// STYLED COMPONENTS
// --> Headers
function sharedHeaderStyles(
    props: (StyledH1Props & ThemeProp) | (HeaderProps & ThemeProp)
): string {
    return `
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    color: ${props.muted ? props.theme.colors.grey600 : props.theme.colors.black};
`;
}

export const StyledH1 = styled.h1<StyledH1Props>`
    ${(props): string => sharedHeaderStyles(props)}
    font-size: ${(props): string =>
        styledH1FontSize({ window_width: props.window_width, theme: props.theme })};
    line-height: ${(props): string =>
        styledH1LineHeight({ window_width: props.window_width, theme: props.theme })};
`;

export const StyledH2 = styled.h2<HeaderProps>`
    ${(props): string => sharedHeaderStyles(props)}
    font-size: 16px;
    line-height: 24px;
`;

export const StyledH3 = styled.h3<HeaderProps>`
    ${(props): string => sharedHeaderStyles(props)}
    font-size: 14px;
    line-height: 20px;
`;

export const StyledH4 = styled.h4<HeaderProps>`
    ${(props): string => sharedHeaderStyles(props)}
    font-size: 12px;
    line-height: 16px;
`;

export const StyledH5 = styled.h5<HeaderProps>`
    ${(props): string => sharedHeaderStyles(props)}
    font-size: 12px;
    line-height: 16px;
    color: ${(props): string => props.theme.colors.grey600};
`;

// --> Paragraph

export const StyledP = styled.p<PProps>`
    display: ${(props): string => props.display || 'block'};
    font-family: Inter;
    font-style: normal;
    font-size: ${(props): string => styledPFontSize(props)};
    line-height: ${(props): string => styledPLineHeight(props)};
    color: ${(props): string => styledPColor(props)};
    font-weight: ${(props): string => styledPFontWeight(props)};
    margin: 0px;
`;

// --> Citation

export const StyledCite = styled.cite<CiteProps>`
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    font-weight: ${(props): string => styledCiteFontWeight(props)};
    color: ${(props): string => styledCiteColor(props)};
`;

// --> Link

export const StyledAnchorLink = styled.a<LinkProps>`
    font-family: Inter;
    font-style: normal;
    font-weight: ${(props): string =>
        styledLinkFontWeight({
            strong: props.strong,
            undecorated: props.undecorated,
        })};
    font-size: ${(props): string => styledLinkFontSize(props)};
    line-height: ${(props): string => styledLinkLineHeight(props)};
    text-decoration-line: ${(props): string =>
        styledLinkUnderline({
            strong: props.strong,
            undecorated: props.undecorated,
        })};
    color: ${(props): string => styledLinkColor(props)};
    &:hover {
        color: ${(props): string => props.theme.colors.blue500};
        text-decoration-line: ${(props): string =>
            styledLinkUnderline({
                strong: props.strong,
                undecorated: props.undecorated,
            })};
        cursor: pointer;
    }
`;

export const StyledDivLink = styled(StyledAnchorLink).attrs({
    as: 'div',
})``;

// HELPERS
// --> Headers
function styledH1FontSize({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width > theme.legacyMediaWidth.mobile_horizontal) {
        return '24px';
    }

    return '20px';
}

function styledH1LineHeight({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width > theme.legacyMediaWidth.mobile_horizontal) {
        return '32px';
    }

    return '24px';
}

// --> Paragraph
function styledPColor(props: PProps & ThemeProp): string {
    if (props.muted) {
        return props.theme.colors.grey600;
    }

    return props.theme.colors.black;
}

function styledPFontSize(props: PProps & ThemeProp): string {
    if (props.size === 'small') {
        return '14px';
    }

    return '16px';
}

function styledPLineHeight(props: PProps & ThemeProp): string {
    if (props.size === 'small') {
        return '20px';
    }

    return '24px';
}

function styledPFontWeight(props: PProps & ThemeProp): string {
    if (props.strong) {
        return 'bold';
    }

    return 'normal';
}

// --> Citation
function styledCiteColor(props: PProps & ThemeProp): string {
    if (props.muted) {
        return props.theme.colors.grey600;
    }

    return props.theme.colors.black;
}

function styledCiteFontWeight(props: CiteProps & ThemeProp): string {
    if (props.strong) {
        return 'bold';
    }

    return 'normal';
}

// --> Link
function styledLinkFontWeight({
    strong,
    undecorated,
}: Pick<LinkProps, 'strong' | 'undecorated'>): string {
    if (strong && undecorated) {
        return 'bold';
    }

    return 'normal';
}

function styledLinkFontSize(props: LinkProps): string {
    if (props.size === 'small') {
        return '12px';
    }

    if (props.size === 'medium') {
        return '14px';
    }

    return '16px';
}

function styledLinkLineHeight(props: LinkProps): string {
    if (props.size === 'small') {
        return '16px';
    }

    if (props.size === 'medium') {
        return '20px';
    }

    return '24px';
}

function styledLinkUnderline({
    strong,
    undecorated,
}: Pick<LinkProps, 'strong' | 'undecorated'>): string {
    if (undecorated || strong) {
        return 'none';
    }

    return 'underline';
}

function styledLinkColor(props: LinkProps & ThemeProp): string {
    const { strong, undecorated, muted, color, theme } = props;
    if (muted) {
        return theme.colors.grey600;
    }

    if (strong && !undecorated) {
        return theme.colors.blue500;
    }

    return color || theme.colors.grey900;
}
