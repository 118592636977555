import React, { ReactElement } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';

// Interfaces
export interface DraggableSurfaceProps {
    drag_handle_props: DraggableProvidedDragHandleProps;
    lifted?: boolean;
}

// Styled components
const StyledDraggableSurface = styled.div<
    DraggableProvidedDragHandleProps & Pick<DraggableSurfaceProps, 'lifted'>
>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 100%;
    border-radius: 8px;
    height: 100%;
    padding-right: 12px;
    opacity: ${({ lifted }): string => (lifted ? '1' : '0')};
`;

const StyledEllipse = styled.img`
    width: 4px;
    height: 4px;
    border-radius: 100%;
`;

const StyledEllipseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
`;

// GLOBALS
const BLUE_ELLIPSE = 'https://cdn.nicejob.co/assets/blue-ellipse.svg';
const GREY_ELLIPSE = 'https://cdn.nicejob.co/assets/ellipse.svg';

// Component
export default function DraggableSurface(props: DraggableSurfaceProps): ReactElement {
    const { lifted, drag_handle_props } = props;

    return (
        <StyledDraggableSurface
            {...drag_handle_props}
            lifted={lifted}
            className='draggable-surface'
        >
            <StyledEllipseWrapper>
                <StyledEllipse src={props.lifted ? BLUE_ELLIPSE : GREY_ELLIPSE} />
                <StyledEllipse src={props.lifted ? BLUE_ELLIPSE : GREY_ELLIPSE} />
                <StyledEllipse src={props.lifted ? BLUE_ELLIPSE : GREY_ELLIPSE} />
            </StyledEllipseWrapper>
        </StyledDraggableSurface>
    );
}
