import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

type IAuthContentProps = PropsWithChildren<{
    is_loading?: boolean;
}>;

/**
 * Container for page content that is hidden when loading
 * @param props.is_loading - set `display: none` when loading since unmounting will prevent default form submission behaviour
 */
export const AuthContent = styled.div`
    display: ${(props: IAuthContentProps): string => (props.is_loading ? 'none' : 'flex')};

    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;

    width: 100%;

    :last-child {
        padding: 20px 0 40px 0;
    }
`;
