/**
 * Return the API domain
 */
import { Online, PAYSTONE_DOMAIN } from '../../../common';

/**  Types  */
interface APIDomainOptions {
    protocol?: boolean;
    websocket?: boolean;
    domain?: string;
    is_online?: boolean;
}

/**  Globals  */
const { REACT_APP_ONLINE_FROM_LOCALHOST } = process.env;
const DOMAIN_EXTRACTION_PATTERN = /^\w+\.(.+)/;

/**  Exports  */
export function APIDomain({
    protocol = true,
    websocket = false,
    is_online = !!(Online || REACT_APP_ONLINE_FROM_LOCALHOST),
    domain = document.location.hostname,
}: APIDomainOptions = {}): string {
    const extracted_domain = DOMAIN_EXTRACTION_PATTERN.exec(domain);
    const api_domain_protocol =
        websocket && is_online ? 'wss' : websocket ? 'ws' : is_online ? 'https' : 'http';

    const api_domain =
        is_online && extracted_domain
            ? extracted_domain[1].includes(PAYSTONE_DOMAIN)
                ? `${api_domain_protocol}://api.hub.${extracted_domain[1]}`
                : `${api_domain_protocol}://api.${extracted_domain[1]}`
            : `${api_domain_protocol}://localhost:8084`;

    return protocol ? api_domain : api_domain.replace('https://', '').replace('http://', '');
}

export const API_QUERY_PATH = `/graphql/q`;
export const API_SUBSCRIPTION_PATH = `/graphql/subscriptions`;
