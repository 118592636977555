import styled from 'styled-components';

export const Input = styled.input<{ is_highlighted: boolean }>`
    width: 270px;
    height: 40px;

    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: ${({ is_highlighted, theme }): string =>
        is_highlighted ? `${theme.colors.blue950}` : `${theme.colors.blue300}`};

    background-color: ${({ is_highlighted, theme }): string =>
        is_highlighted ? `${theme.colors.blue50}` : `${theme.colors.blue800}`};

    border-radius: 35px;

    :hover {
        cursor: ${({ is_highlighted }): string => (is_highlighted ? 'text' : 'pointer')};
    }

    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
        margin-bottom 0.3s ease-in-out;
`;
