import React from 'react';
import styled from 'styled-components';

/**
 * Container for footer content below the auth card
 */
export const AuthFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
`;
