import { useMutation } from '@apollo/client';
import { Online } from '@nicejob/nicejob-toolbox';
import { NetworkName } from '@nicejob-library/data-platform-implementation/entities/Network/enums';
import { ConnectGBP as ConnectGBPModal } from '@nicejob-library/design-system/onboarding/ConnectGBP';
import { makePathSubstitutions, Paths } from '@nicejob-library/paths';
import { trackClick } from '@nicejob-library/tracking';
import React, { ReactElement, useState } from 'react';
import { useOnboardingState } from '../context/OnboardingStateContext';
import ONBOARDING_STATE_QUERIES from '../queries/OnboardingState';

const CREATE_GBP_URL = 'https://www.google.com/business/';
/**
 * ConnectGBP component to be rendered inside the login-onboarding page
 * @returns a ConnectGBP Modal component from the design-system with adjusted props
 */
export function ConnectGBP(): ReactElement {
    const [connect_gbp_loading, setConnectGBPLoading] = useState(false);
    const { onboarding_state, onOnboardingStateChange } = useOnboardingState();
    const { onboarding_state_id, form_data } = onboarding_state || {};
    const { user_name } = form_data || {};

    const [updateOnboardingState] = useMutation(
        ONBOARDING_STATE_QUERIES.Mutation.updateOnboardingState
    );

    /**
     * When the Connect GBP button is clicked
     * - Set the button loading
     * - Track the action
     * - Redirect to the request onboarding path
     */
    function onGBPClick(): void {
        setConnectGBPLoading(true);

        trackClick({
            id: 'onboarding_google_connect',
            type: 'action',
            is_anonymous: true,
        });

        const path = makePathSubstitutions(Paths.OAUTH_REQUEST_ONBOARDING, {
            network: NetworkName.GOOGLE,
        });

        window.location.href = Online ? path : `http://localhost:8091${path}`;
    }

    /**
     * When the create GBP link is clicked
     * - Open the CREATE GBP page on the new tab
     * - Track the action
     */
    function onGBPCreateClick(): void {
        window.open(CREATE_GBP_URL, '_blank');

        trackClick({
            id: 'onboarding_google_create',
            type: 'action',
            is_anonymous: true,
        });
    }

    /**
     * When close the modal or clicking the `connect later`
     * - Track the action
     * - Update the onboarding state
     */
    async function onAbortClick(): Promise<void> {
        trackClick({
            id: 'onboarding_google_skip',
            type: 'action',
            is_anonymous: true,
        });

        updateOnboardingState({
            variables: {
                input: {
                    onboarding_state_id,
                    has_aborted_google_connect: true,
                },
            },
        });

        onboarding_state &&
            onOnboardingStateChange({ ...onboarding_state, has_aborted_google_connect: true });
    }

    return (
        <ConnectGBPModal
            connect_gbp_loading={connect_gbp_loading}
            as_modal={false}
            show_welcome_text={true}
            user_name={user_name}
            onAbortClick={onAbortClick}
            onGBPClick={onGBPClick}
            onGBPCreateClick={onGBPCreateClick}
        />
    );
}
