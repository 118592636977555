import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { COUNTRIES_WITH_PROVINCES } from '@nicejob-library/design-system';
import { IValidationError } from '@nicejob-library/react-form-validation';

export function isAdditionalInfoComplete({
    current_company_name,
    current_company_address,
    validation_errors,
}: {
    current_company_name?: string | null;
    current_company_address?: Partial<IAddress> | null;
    validation_errors: Partial<IValidationError> | null;
}): boolean {
    return (
        // no outstanding validation errors
        !!validation_errors &&
        Object.keys(validation_errors).length === 0 &&
        // company name has been completed
        !!current_company_name &&
        current_company_name?.length > 1 &&
        // all required company address fields have been completed
        !!current_company_address?.address &&
        !!current_company_address.country &&
        (!COUNTRIES_WITH_PROVINCES.includes(current_company_address.country) ||
            !!current_company_address.state) &&
        !!current_company_address.zip
    );
}
