/*
 * Constants file that exports data-testid to frontend-route and to cypress test files
 */

export const TEST_ID = {
    nav: {
        stories: 'stories_nav',
        people: 'people_nav',
        campaigns: 'campaigns_nav',
        campaigns_v2: 'campaigns_v2_nav',
        insights: 'insights_nav',
        convert: 'convert_nav',
        goals: 'goals_nav',
        payments: 'payments_nav',
        all_insights: 'all_insights_nav',
        all_payments: 'all_payments_nav',
        gifts: 'gifts_nav',
        settings: 'settings_nav',
        get_repeats: 'get_repeats_nav',
    },
    settings_menu: 'settings_menu',
    login: {
        submit_button: 'login-submit',
        password_input: 'password',
        username_input: 'username',
        mask_on: 'mask_hide',
        mask_off: 'mask_show',
        invalid_invitation: 'invalid-invitation',
        auth_submit_button: 'login-with-auth-link',
    },
    page_title: {
        stories: 'stories_page_title',
    },
};
