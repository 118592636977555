import styled from 'styled-components';

export const OAuthLoginContainer = styled.div`
    * {
        box-sizing: border-box;
    }
`;

export const LoginWithAuthLink = styled.a`
    text-decoration: none;
    display: block;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    &:hover {
        box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    }
    margin-bottom: 8px;
`;

export const OAuthLoginButtonContainer = styled.div`
    border: none;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
`;

export const OAuthLoginButtonIconContainer = styled.div`
    border: 1.5px solid ${(props): string => props.theme.colors.googleBlue};
    border-radius: 4px 0 0 4px;
    width: 40px;
    height: 40px;
`;

export const OAuthLoginButtonIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    object {
        width: 65%;
        height: 65%;
    }
`;

export const OAuthLoginButton = styled.button`
    background-color: ${(props): string => props.theme.colors.googleBlue};
    color: ${(props): string => props.theme.colors.white};
    width: 100%;
    height: 100%;
    font-family: Roboto;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    border-style: none;
    &:active {
        background-color: ${(props): string => props.theme.colors.googleBlueActive};
    }
`;

export const SeparatorText = styled.p`
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 0px;
    padding: 0px;
    color: ${(props): string => props.theme.colors.grey400};
    &:before,
    &:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px dashed ${(props): string => props.theme.colors.grey400};
        margin: auto;
    }
    &:before {
        margin-right: 10px;
    }
    &:after {
        margin-left: 10px;
    }
    > span {
        font-size: 16px;
        color: ${(props): string => props.theme.colors.grey600};
        font-weight: lighter;
    }
`;
