import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { COUNTRIES_WITH_PROVINCES } from '@nicejob-library/design-system';
import { CountrySelect } from '@nicejob-library/design-system/billing/forms/ChildComponents/CountrySelect';
import { StateSelect } from '@nicejob-library/design-system/billing/forms/ChildComponents/StateSelect';
import { IValueProps, TextField } from '@nicejob-library/design-system/core';
import { IProvinceDataWithShort, getProvinceOptions } from '@nicejob-library/gps/regions';
import { translate } from '@nicejob-library/internationalization';
import { IValidationError, Validation } from '@nicejob-library/react-form-validation';
import React, { ReactElement, useEffect, useState } from 'react';
import { VALIDATION_FIELD } from '../../../shared/types';
import {
    ITwoColumnLayoutContainerProps,
    InputFieldAndLabelContainer,
    InputLabel,
    TwoColumnLayoutContainer,
} from '../../Onboarding.styles';

interface IManualAddressProps {
    company_address?: Partial<IAddress> | null;
    validation: Validation;
    validation_errors: Partial<IValidationError>;
    clearValidationError: (field: VALIDATION_FIELD) => void;
    onChange: (new_address: Partial<IAddress>) => void;
    variant: ITwoColumnLayoutContainerProps['variant'];
}

export function ManualAddress(props: IManualAddressProps): ReactElement {
    const {
        company_address,
        onChange,
        validation,
        validation_errors,
        clearValidationError,
    } = props;

    const [selected_country_code, setSelectedCountryCode] = useState<string | null>(() => {
        return company_address?.country || null;
    });
    const [states_for_selected_country, setStatesForSelectedCountry] = useState<
        Array<IProvinceDataWithShort>
    >([]);

    function onAddressChange({
        key,
        value,
        validation_key,
    }: {
        key: keyof IAddress;
        value: string | null;
        validation_key?: VALIDATION_FIELD;
    }): void {
        if (validation_key) {
            clearValidationError(validation_key);
        }

        const new_address: Partial<IAddress> = {
            ...(company_address || {}),
            [key]: value,
        };

        if (key === 'country') {
            new_address.state = '';
        }

        onChange(new_address);
    }

    function onAddressSelectChange({
        key,
        selected,
        validation_key,
    }: {
        key: keyof IAddress;
        selected: IValueProps<string> | null;
        validation_key?: VALIDATION_FIELD;
    }): void {
        if (!selected) {
            return;
        }

        onAddressChange({ key, value: selected.value, validation_key });
    }

    function onCountryChange(selected_country: IValueProps<string> | null): void {
        if (!selected_country) {
            return;
        }

        onAddressSelectChange({
            key: 'country',
            selected: selected_country,
            validation_key: VALIDATION_FIELD.country,
        });

        setSelectedCountryCode(selected_country.value);
    }

    useEffect(() => {
        if (!selected_country_code) {
            return;
        }

        const states = getProvinceOptions({
            country_code: selected_country_code,
            countries_with_provinces: COUNTRIES_WITH_PROVINCES,
        });

        setStatesForSelectedCountry(states);
    }, [selected_country_code]);

    return (
        <TwoColumnLayoutContainer variant={props.variant}>
            <InputFieldAndLabelContainer>
                <TextField
                    label={translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.manual_address.street',
                    })}
                    name='street_address'
                    variant='stretch'
                    initial_value={company_address?.address || ''}
                    onChange={(value): void =>
                        onAddressChange({
                            key: 'address',
                            value,
                            validation_key: VALIDATION_FIELD.street_address,
                        })
                    }
                    error={validation_errors?.street_address?.message}
                    onBlur={validation.input}
                    invalid={!!validation_errors.street_address}
                />
            </InputFieldAndLabelContainer>
            <InputFieldAndLabelContainer>
                <TextField
                    label={translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.manual_address.unit',
                    })}
                    name='unit'
                    variant='stretch'
                    initial_value={company_address?.unit || ''}
                    onChange={(value): void =>
                        onAddressChange({
                            key: 'unit',
                            value,
                        })
                    }
                />
            </InputFieldAndLabelContainer>
            <InputFieldAndLabelContainer>
                <InputLabel>{translate({ namespace: 'common', key: 'text.country' })}</InputLabel>
                <CountrySelect
                    default_label={translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.manual_address.select',
                    })}
                    initial_value={company_address?.country}
                    onChange={onCountryChange}
                />
            </InputFieldAndLabelContainer>
            <InputFieldAndLabelContainer>
                <InputLabel>
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.manual_address.state_or_province',
                    })}
                </InputLabel>
                <StateSelect
                    default_label={translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.manual_address.select',
                    })}
                    initial_value={company_address?.state}
                    country_code={company_address?.country}
                    states={states_for_selected_country}
                    onChange={(selected): void => onAddressSelectChange({ key: 'state', selected })}
                />
            </InputFieldAndLabelContainer>
            <InputFieldAndLabelContainer>
                <TextField
                    label={translate({ namespace: 'common', key: 'text.city' })}
                    name='city'
                    variant='stretch'
                    initial_value={company_address?.city || ''}
                    onChange={(value): void =>
                        onAddressChange({
                            key: 'city',
                            value,
                            validation_key: VALIDATION_FIELD.city,
                        })
                    }
                />
            </InputFieldAndLabelContainer>
            <InputFieldAndLabelContainer>
                <TextField
                    label={translate({
                        namespace: 'login',
                        key: 'common.textfield.postal_code',
                    })}
                    name='zip'
                    variant='stretch'
                    initial_value={company_address?.zip || ''}
                    onChange={(value): void =>
                        onAddressChange({
                            key: 'zip',
                            value,
                            validation_key: VALIDATION_FIELD.zip,
                        })
                    }
                    error={validation_errors?.zip?.message}
                    onBlur={validation.input}
                    invalid={!!validation_errors.zip}
                />
            </InputFieldAndLabelContainer>
        </TwoColumnLayoutContainer>
    );
}
