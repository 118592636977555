/**
 * Utility types
 */
export type Require<T, K extends keyof T> = Omit<Partial<T>, K> & Readonly<Pick<T, K>>;
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

//  Extract 'T' from Promise<T>
export type Await<T> = T extends {
    then(onfulfilled?: (value: infer U) => unknown): unknown;
}
    ? U
    : T;

//  Extract keys of type K from T
export type ExtractKeysOfValueType<T, K> = { [I in keyof T]: T[I] extends K ? I : never }[keyof T];

/**
 * Common types
 */
export interface IEntityKey<
    K extends string = string,
    NS extends string = string,
    ID extends string = string
> {
    namespace: NS;
    kind: K;
    id: ID;
}

export interface ParamsDictionary {
    [key: string]: string;
}

export enum NiceJobProjectId {
    production = 'nicejob-production',
    exp = 'nicejob-g-my-business',
    testing = 'nicejob-testing',
    bi = 'business-intelligence-300822',
    ops = 'nicejob-ops-id',
}

// .constants.json
export interface IProjectConfig {
    vpc_access_connector: string;
}

export interface IGlobalConstants {
    INTERCOM_APP_ID: Record<string, string>;
    ports: Record<string, number>;
    projects: Record<string, string>;
    project_config: Record<string, IProjectConfig>;
    PROTECTED_SLUGS: Array<string>;
    ROLE_HIERARCHY: Array<string>;
    SCOPES: Array<string>;
    tax_rates: Record<string, Record<string, number>>;
    topics: Record<string, Record<'created' | 'updated' | 'deleted' | 'service', string>>;
}
