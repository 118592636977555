import { getLanguage, ILanguage, translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import { IValidationError } from '@nicejob-library/react-form-validation';
import { trackClick } from '@nicejob-library/tracking';
import { AxiosError } from 'axios';
import React, { KeyboardEvent, MouseEvent, ReactElement, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_LANGUAGE_PREFERENCE } from '../../../../../../../common';
import { MANUAL_REGISTRATION_TRACKING_DATA } from '../../../shared/constants';
import { VALIDATION_FIELD } from '../../../shared/types';
import { useRegisterPageParams } from '../../hooks/useRegisterPageParams';
import { ClientRegister } from './ClientRegister';
import { PartnerRegister } from './PartnerRegister';
import { IRegisterBaseProps } from './types';

export function Register(): ReactElement {
    const { pathname } = useLocation();
    const query_params = useRegisterPageParams();
    const [language] = useState<ILanguage>(() => {
        return getLanguage() || DEFAULT_LANGUAGE_PREFERENCE;
    });

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [is_valid, setIsValid] = useState<boolean>(false);
    const [validation_errors, setValidationErrors] = useState<Partial<IValidationError>>({});
    const [alert_message, setAlertMessage] = useState<string>('');
    const [duplicate_email_validation_errors, setDuplicateEmailValidationErrors] = useState<
        Partial<IValidationError>
    >({});

    function onValidation(errors: Partial<IValidationError>): void {
        setValidationErrors({ ...errors });
    }

    function clearValidationError(field: VALIDATION_FIELD): void {
        const new_validation_errors = { ...validation_errors };
        delete new_validation_errors[field];

        setValidationErrors(new_validation_errors);
    }

    function onSubmitError(error: AxiosError): void {
        setIsLoading(false);

        if (error?.response?.data?.field && error?.response?.data?.message) {
            const { field, message } = error.response.data;

            setValidationErrors({
                [field]: {
                    message: message as string,
                    fields: [field as string],
                    refs: [],
                },
            });

            setAlertMessage('');
        } else {
            const msg_support_text = translate({
                namespace: 'login',
                key: 'onboarding.register.contact_support',
            });
            // parse error message - on Server error, replace with stock message
            const friendly_alert_message = error?.response?.data?.message
                ? error.response.data.message === 'Server error'
                    ? translate({
                          namespace: 'login',
                          key: 'onboarding.register.error.general',
                      })
                    : error.response.data.message + '. ' + msg_support_text
                : error.message + '. ' + msg_support_text;

            setAlertMessage(friendly_alert_message);
        }
    }

    const onSubmit = useCallback(
        async (
            e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
            submit: () => Promise<void>
        ): Promise<void> => {
            e.preventDefault();

            if (is_valid && !is_loading) {
                setIsLoading(true);

                try {
                    // Track manual registration with tracking
                    await trackClick(MANUAL_REGISTRATION_TRACKING_DATA);

                    await submit();
                } catch (error) {
                    onSubmitError(error);
                }
            }
        },
        [query_params, is_valid, is_loading]
    );

    const props: IRegisterBaseProps = {
        // context
        language,
        query_params,

        // state
        alert_message,
        duplicate_email_validation_errors,
        setDuplicateEmailValidationErrors,
        is_valid,
        setIsValid,
        is_loading,
        setIsLoading,
        validation_errors,
        setValidationErrors,
        clearValidationError,

        // handlers
        onValidation,
        onSubmit,
        onSubmitError,
    };

    return pathname === Paths.ONBOARDING_PARTNER ? (
        <PartnerRegister {...props} />
    ) : (
        <ClientRegister {...props} />
    );
}
