import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StaticCardProps, DefaultCardProps, DraggableCardProps } from './Card';

// Styled components

export const StaticCard = styled.div<Pick<StaticCardProps, 'width' | 'height'>>`
    ${(props): FlattenSimpleInterpolation => {
        const { width, height, theme } = props;

        return css`
            width: ${width || 'auto'};
            height: ${height || 'auto'};
            background-color: ${theme.colors.white};
            border-radius: 8px;
            border: 1px solid ${theme.colors.grey200};
        `;
    }}
`;

export const DefaultCard = styled.div<DefaultCardProps>`
    ${(props): FlattenSimpleInterpolation => {
        const { width, height } = props;

        return css`
            display: flex;
            flex-direction: column;
            width: ${width || 'auto'};
            height: ${height || 'auto'};
            border-radius: 8px;
            box-shadow: ${defaultCardBoxShadow(props)};
            ${defaultCardBoxShadowOnHover(props)};
            background-color: ${defaultCardBackgroundColor(props)};
            border: ${defaultCardBorderColor(props)};
        `;
    }}
`;

export const DraggableCard = styled.div<
    Omit<DraggableCardProps, 'has_drag_handle' | 'draggable_props' | 'drag_handle_props'>
>`
    ${(props): FlattenSimpleInterpolation => {
        const { width, height } = props;

        return css`
            display: flex;
            flex-direction: row;
            width: ${width || 'auto'};
            height: ${height || 'auto'};
            border-radius: 8px;
            box-shadow: ${defaultCardBoxShadow(props)};
            ${defaultCardBoxShadowOnHover(props)};
            background-color: ${defaultCardBackgroundColor(props)};
            border: ${defaultCardBorderColor(props)};
            &:hover .draggable-surface {
                opacity: 1;
            }
        `;
    }}
`;

// Helpers
function defaultCardBackgroundColor(props: DefaultCardProps | DraggableCardProps): string {
    const { disabled, theme } = props;
    if (disabled) {
        return theme.colors.grey50;
    }

    return theme.colors.white;
}

function defaultCardBorderColor(props: DefaultCardProps | DraggableCardProps): string {
    const { editing, theme } = props;
    if (editing) {
        return `1px solid ${theme.colors.blue500}`;
    }

    return 'none';
}

function defaultCardBoxShadow(props: DefaultCardProps | DraggableCardProps): string {
    const { lifted, editing, theme } = props;
    if (editing) {
        return 'none';
    }

    if (lifted) {
        return theme.elevations.z8;
    }

    return theme.elevations.z1;
}

function defaultCardBoxShadowOnHover(
    props: DefaultCardProps | DraggableCardProps
): FlattenSimpleInterpolation {
    const { interactive, lifted, editing, theme } = props;

    if (interactive && !lifted && !editing) {
        return css`
            &:hover {
                box-shadow: ${theme.elevations.z4};
            }
        `;
    }

    return css``;
}
