import React, { ReactElement, Ref, forwardRef } from 'react';
import { Input } from './PhoneNumberVerification.styles';

export interface IPhoneNumberVerificationProps {
    value: string;
    name: string;
    type: string;
    placeholder: string;

    is_highlighted?: boolean;

    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PhoneNumberVerification = forwardRef(function(
    props: IPhoneNumberVerificationProps,
    ref: Ref<HTMLInputElement>
): ReactElement {
    const {
        value,
        name,
        type,
        placeholder,
        is_highlighted = false,
        onChange,
        onBlur,
        onKeyDown,
    } = props;

    return (
        <Input
            ref={ref}
            value={value}
            name={name}
            type={type}
            placeholder={placeholder}
            is_highlighted={is_highlighted}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        />
    );
});
