import styled from 'styled-components';

/** Types */
interface IStyledProgress {
    percent: number;
}

/** Styled Components */

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 140px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin-top: 112px;
    }
`;

export const StarBackgroundContainer = styled.div`
    z-index: 1;
`;

export const StarIconContainer = styled.div`
    font-size: 25px;
    color: ${({ theme }): string => theme.colors.blue950};
`;

export const BlueCircle = styled.div`
    z-index: -1;

    position: relative;
    width: 20px;
    height: 20px;
    left: -5px;
    top: 19px;

    background: ${({ theme }): string => theme.colors.blue200};
    border-radius: 20px;
`;

export const MainText = styled.div`
    width: 261px;

    margin-top: 13px;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    color: ${({ theme }): string => theme.colors.blue950};
`;

export const TestimonialContainer = styled.div`
    margin-top: 43px;
`;

export const ProgressBarContainer = styled.div`
    width: 240px;
    height: 4px;

    margin-top: 56px;

    background: ${({ theme }): string => theme.colors.blue100};
    border-radius: 4px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin-top: 32px;
    }
`;

export const ProgressBar = styled.div<IStyledProgress>`
    height: 100%;
    width: ${({ percent }): string => `${percent}%`};

    background: ${({ theme }): string => theme.colors.blue500};
    border-radius: 4px;
`;

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
`;

export const ProgressText = styled.div`
    margin-bottom: 104px;

    width: 199px;
    height: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    color: ${({ theme }): string => theme.colors.typography};
`;
