import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

// forward exports
export * from './censorPhoneNumber';
export * from './removePhoneFormat';
export * from './getCountriesInfo';

/**  Types  */
interface IParsePhoneNumberInput {
    phone: string;
    country?: string | null;
}

interface IParsePhoneNumberResponse {
    is_valid: boolean;
    international: string | null;
    friendly: string | null;
    country_code: string | null;
    is_mobile_phone: boolean;
}

/**  Lazy init  */
let instance: PhoneNumberUtil;

/**
 * Parse a phone number into international / friendly formats
 */
export function parsePhoneNumber({
    phone: input_phone,
    country: input_country,
}: IParsePhoneNumberInput): IParsePhoneNumberResponse {
    //  Lazy init
    instance = instance || PhoneNumberUtil.getInstance();

    let input_country_code = input_country;
    /**
     * if phone start with '+' and contain at least 2 characters
     * and country is not provided, try to get country code from phone
     */
    if (!input_country && input_phone?.length > 1 && input_phone[0] === '+') {
        const parsed_phone = instance.parse(input_phone);
        input_country_code = instance.getRegionCodeForNumber(parsed_phone);
    }

    /**
     * Try US and CA by default, after `input_country`
     */
    const countries = Array.from(new Set([input_country_code as string, 'US', 'CA'])).filter(
        x => x
    );

    //  Phone must be string!
    const phone = (input_phone && input_phone.toString()) || '';

    if (!phone) {
        return {
            is_valid: false,
            international: null,
            friendly: null,
            country_code: null,
            is_mobile_phone: false,
        };
    }

    /**
     * Try each country from the set
     */
    for (const country of countries) {
        try {
            //  Parse & validate phone number
            const number = instance.parse(phone, country);
            const is_valid = instance.isValidNumberForRegion(number, country);

            //  If valid, return
            if (is_valid) {
                //  International format
                const international = instance.format(number, PhoneNumberFormat.E164);

                //  Friendly format
                const friendly = instance.format(number, PhoneNumberFormat.NATIONAL);

                //  Country code
                const country_code = `+${number.getCountryCode()}`;

                // Mobile phone check
                const is_mobile_phone =
                    instance.getNumberType(number) === 1 || instance.getNumberType(number) === 2;

                return {
                    is_valid,
                    international,
                    friendly,
                    country_code,
                    is_mobile_phone,
                };
            }
        } catch (error) {
            //  Throw more explicit 'invalid country' error
            if (error.message === `Invalid country calling code`) {
                throw new Error(`Invalid country value "${country}"`);
            }

            throw error;
        }
    }

    return {
        is_valid: false,
        international: null,
        friendly: null,
        country_code: null,
        is_mobile_phone: false,
    };
}

/**
 *  Validate phone number input field
 *    --> accepts digits, +, and -
 */
export function phoneNumberInputValidator(input: string): boolean {
    return /^(\s*|[0-9+-]+)$/.test(input);
}
