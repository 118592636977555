import { ICountryData } from '@nicejob-library/gps/regions';
import { IValueProps, ValueType } from '../../../../core';
import { IPrioritizedCountryList } from './types';

export const ORDERED_PRIORITY_COUNTRIES = ['US', 'CA', 'GB', 'NZ', 'AU'];

interface IPrioritizedCountryMap {
    prioritized: Record<ICountryData['alpha2'], IValueProps<ValueType>>;
    general: Array<IValueProps<ValueType>>;
}

export function generateCountrySelectOptions(
    countries: Array<ICountryData>
): IPrioritizedCountryList {
    const { prioritized, general }: IPrioritizedCountryMap = splitCountriesByPriority(countries);

    const prioritized_options: Array<IValueProps<ValueType>> = ORDERED_PRIORITY_COUNTRIES.map(
        prioritized_country_code => prioritized[prioritized_country_code]
    ).filter(x => x);

    const general_options: Array<IValueProps<ValueType>> = general.sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    return {
        prioritized: prioritized_options,
        general: general_options,
    };
}

function splitCountriesByPriority(countries: Array<ICountryData>): IPrioritizedCountryMap {
    return countries.reduce(
        (acc, country) => {
            if (ORDERED_PRIORITY_COUNTRIES.includes(country.alpha2)) {
                return {
                    ...acc,
                    prioritized: {
                        ...acc.prioritized,
                        [country.alpha2]: convertCountryToSelectOption(country),
                    },
                };
            }

            return { ...acc, general: [...acc.general, convertCountryToSelectOption(country)] };
        },
        {
            prioritized: {},
            general: [],
        } as IPrioritizedCountryMap
    );
}

function convertCountryToSelectOption(country: ICountryData): IValueProps<ValueType> {
    return {
        value: country.alpha2,
        label: country.name,
    };
}
