import React, { useState, useRef, ReactElement, useEffect, ReactNode } from 'react';
import { usePopper, PopperChildrenProps } from 'react-popper';
import { useDetectOutsideClick } from '@nicejob-library/react-hooks';

// Get styles
import { OverlayContainer, OverlayInner } from './styles';
import { useTheme } from 'styled-components';
export interface IOverlayProps {
    trigger_ref: React.MutableRefObject<any>;
    children: ReactNode;
    visible: boolean;
    offset?: [number, number];
    overlay_position?: PopperChildrenProps['placement'];
    trigger?: 'click' | 'hover' | null;
    is_barebones?: boolean;
    avoid_outside_click?: boolean;
    onOutsideClick?: () => void;
}

/** Main Overlay Component */
export function Overlay({
    trigger_ref,
    children,
    offset,
    overlay_position,
    visible,
    trigger,
    is_barebones,
    avoid_outside_click,
    onOutsideClick,
}: IOverlayProps): ReactElement {
    const popper_ref = useRef(null);

    const theme = useTheme();

    // Init with `false`! – See useEffect/setPopperVisibility below for note
    const [popper_visibility, setPopperVisibility] = useState(false);

    const popper_offset: [number, number] = offset ? offset : [0, 0];

    /**
     * Define our Popper instance
     **/
    const { styles, attributes, update } = usePopper(trigger_ref.current, popper_ref.current, {
        placement: overlay_position || 'bottom',
        modifiers: [
            {
                name: 'offset',
                enabled: true,
                options: {
                    offset: popper_offset,
                },
            },
        ],
    });

    /**
     * Click trigger handler
     * Toggle the popper visibility value
     **/
    function handleClick(): void {
        setPopperVisibility(v => !v);
    }

    /**
     * Hover in trigger handler
     * Show the popper
     */
    function handleHoverIn(): void {
        update && update();
        setPopperVisibility(true);
    }

    /**
     * Hover out trigger handler
     * Hide the popper
     */
    function handleHoverOut(): void {
        setPopperVisibility(false);
    }

    /**
     * Register event listeners based on trigger type (click or hover)
     **/
    useEffect(() => {
        if (trigger === 'click') {
            trigger_ref?.current?.addEventListener('click', handleClick);

            return (): void => {
                // Clean up
                trigger_ref?.current?.removeEventListener('click', handleClick);
            };
        } else if (trigger === 'hover') {
            const refs = [trigger_ref, popper_ref];
            // Bind Event
            refs.forEach(ref => {
                ref?.current?.addEventListener('mouseenter', handleHoverIn);
                ref?.current?.addEventListener('mouseleave', handleHoverOut);
            });

            return (): void => {
                // Clean up
                refs.forEach(ref => {
                    ref?.current?.removeEventListener('mouseenter', handleHoverIn);
                    ref?.current?.removeEventListener('mouseleave', handleHoverOut);
                });
            };
        }
        // Bind Event
    }, [update]);

    /**
     * Effect to toggle overlay visibility
     **/

    // Popper gives strange behaviour when initing with visibility: true
    // - Therefore we init with false above, and wait for the mount before we
    //   set visibility to its proper state
    useEffect(() => {
        setPopperVisibility(visible);
    }, []);

    //  Update Popper visibility based on incoming `visible` prop
    useEffect(() => {
        update && update();
        setPopperVisibility(visible);
    }, [visible]);

    /**
     * Hooks to hide the overlay on outside element click
     */
    useDetectOutsideClick({
        refs: [popper_ref, trigger_ref],
        callback: function() {
            setPopperVisibility(false);
            onOutsideClick && onOutsideClick();
        },
        disable_hook: avoid_outside_click,
    });

    return (
        <OverlayContainer
            ref={popper_ref}
            style={{ zIndex: theme.zIndex.z2, ...styles.popper }}
            {...attributes.popper}
            visible={popper_visibility}
            data-testid='nicejob-library-overlay'
        >
            {is_barebones ? (
                children
            ) : (
                <OverlayInner style={styles.offset}>{children}</OverlayInner>
            )}
        </OverlayContainer>
    );
}
