import { EntityMetadata, SDKBaseElementProperties } from '../entities.types';
import { IClientIdentify } from '../session.types';
import { ISDKEventMetadata } from '.';

//  ====

/**
 * SDK events: Client emission
 */
export enum ISDKEvent {
    view = 'view',
    click = 'click',
    webform = 'webform',
    pageview = 'pageview',
}

export interface ISDKSharedPayloadProperties<
    M extends EntityMetadata = EntityMetadata,
    D = undefined
> extends Partial<SDKBaseElementProperties<M>> {
    element?: HTMLElement;
    data?: D;
}

export type ISDKEventPayload = ISDKSharedPayloadProperties<ISDKEventMetadata>;

/**
 * Event send payload
 */
export interface ISDKSendEventPayload<
    Payload extends SDKBaseElementProperties = SDKBaseElementProperties
> {
    k: string; // public key
    c: string; // company_id
    r: boolean; // reviewly
    s: string; // session_id
    pv: string; // pageview_id
    i: IClientIdentify;
    e: ISDKEvent;
    pl: Payload;
    csrf: string; // CSRF token
}

export interface ISDKParsedEventPayload<
    Payload extends SDKBaseElementProperties = SDKBaseElementProperties
> {
    c: string; // company_id
    r: boolean; // reviewly
    s: string; // session_id
    pv: string; // pageview_id
    i: IClientIdentify;
    e: ISDKEvent;
    pl: Payload;
    csrf: string; // CSRF token
}

export interface ISDKSendEventResponse {
    success: boolean;
    code: number;
    error?: string;
}
