export const SCREEN_WIDTH_SIZES = {
    mobile: '425',
    tablet: '768',
    desktop: '2560',
};

export const MEDIA_QUERIES = {
    mobile: `(min-width: ${SCREEN_WIDTH_SIZES.mobile}px)`,
    tablet: `(min-width: ${SCREEN_WIDTH_SIZES.tablet}px)`,
    desktop: `(min-width: ${SCREEN_WIDTH_SIZES.desktop}px)`,
};
