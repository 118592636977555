import React, { ReactElement } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { Typography } from '../typography/Typography';

/**
 * Interfaces
 */
interface ILearnMore {
    href: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
}

/**
 * Styled components
 */
const QuestionMarkIcon = styled(BsQuestionCircle)`
    color: ${(props): string => props.theme.colors.grey600};
    &:hover {
        color: ${(props): string => props.theme.colors.grey900};
    }
`;

/**
 * Main component
 */
export function LearnMore(props: ILearnMore): ReactElement {
    const { href, target } = props;

    return (
        <Typography.Link href={href} target={target}>
            <QuestionMarkIcon size='14px' />
        </Typography.Link>
    );
}
