import styled, { DefaultTheme } from 'styled-components';
import { DividerProps, DividerVariant } from './Divider';

// Styled components

export const StyledDivider = styled.div<DividerProps>`
    background-color: ${(props): string =>
        dividerColor({ variant: props.variant, theme: props.theme })};
    border-bottom: 1px solid
        ${(props): string => dividerColor({ variant: props.variant, theme: props.theme })};
    width: 100%;
    margin: 0px 0px;
`;

// Helpers
function dividerColor({
    variant,
    theme,
}: {
    variant?: DividerVariant;
    theme: DefaultTheme;
}): string {
    if (variant === DividerVariant.STRONG) {
        return theme.colors.grey300;
    }

    // regular
    return theme.colors.grey200;
}
