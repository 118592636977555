import styled from 'styled-components';

export const PhoneNumberInputDescription = styled.p<{ is_highlighted: boolean }>`
    visibility: ${({ is_highlighted }): string => (is_highlighted ? 'visible' : 'hidden')};

    width: 270px;
    height: 18px;

    margin-top: 8px;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #a6acb6;
`;

export const PhoneVerificationResendCountdown = styled.div`
    margin-top: 10px;

    text-align: center;
    font-size: 14px;

    color: ${({ theme }): string => theme.colors.grey500};
`;

export const PhoneNumberInputActionLink = styled.a`
    display: block;
    text-align: center;

    margin-top: 10px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;

    color: ${({ theme }): string => theme.colors.grey500};
`;

export const PhoneNumberInputError = styled.p<{ show: boolean }>`
    display: ${({ show }): string => (show ? 'block' : 'none')};

    margin-top: 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    letter-spacing: 0em;
    text-align: center;

    color: ${({ theme }): string => theme.colors.red500};
`;
