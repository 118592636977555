// fields to be validated on the screen
export enum VALIDATION_FIELD {
    company_name = 'company_name',
    company_address = 'company_address',
    street_address = 'street_address',
    city = 'city',
    zip = 'zip',
    country = 'country',
    state = 'state',
    password = 'password',
    user_name = 'user_name',
    phone = 'phone',
    email = 'email',
}
