import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { MenuItem } from '../menu/Menu';
import { IValueProps, ValueType } from './Select';

export interface ISelectOptionProps {
    value: string | number;
    label: string;
    formatLabel?: (args: { label: string }) => ReactNode;
    selectItem?: ({ value, label }: IValueProps<ValueType>) => void;
    onClick?: (e: MouseEvent) => void;
    children?: never;
    width?: string;
    display?: string;
    active?: boolean;
    disabled?: boolean;

    // Used to draw a divider underneath this option which can be used to creation
    // "sections" in the select list.  For example, we want to prioritize certain countries
    // in the country select using specific ordering and then list the rest in
    // alphabetical order.
    bottomOfSection?: boolean;
}

interface IStyledSelectOption {
    active: boolean;
    bottomOfSection: boolean;
}

const StyledSelectOption = styled.div<IStyledSelectOption>`
    background-color: ${(props): string =>
        props.active ? props.theme.colors.grey100 : 'transparent'};
    border-bottom: ${(props): string =>
        props.bottomOfSection ? `1px ${props.theme.colors.grey100} solid` : 'none'};
`;

export function SelectOption({
    value,
    label,
    formatLabel,
    selectItem,
    onClick,
    width,
    display,
    active,
    bottomOfSection,
    disabled,
}: ISelectOptionProps): ReactElement {
    function handleClick(e: MouseEvent): void {
        selectItem && selectItem({ value, label, display });
        onClick && onClick(e);
    }

    return (
        // active used for highlighting when item is selected by keyboard
        <StyledSelectOption active={!!active} bottomOfSection={!!bottomOfSection}>
            <MenuItem
                variant={'default'}
                text={formatLabel ? formatLabel({ label }) : label}
                onClick={handleClick}
                width={width}
                disabled={disabled}
            />
        </StyledSelectOption>
    );
}
