import styled from 'styled-components';

export const TestimonialContainer = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    width: 325px;
    max-height: 400px;
    padding: 32px 32px;

    background: ${(props): string => props.theme.colors.white};
    box-shadow: 0px 0px 14px rgba(4, 23, 51, 0.15);
    border-radius: 0px 16px 16px 16px;
    cursor: pointer;
`;

export const ClientPhoto = styled.img`
    width: 120px;

    border-radius: 120px;
`;

export const StarContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;

    margin-top: 12px;
`;

export const NetworkIcon = styled.img`
    width: 13px;
    height: 13px;

    margin-left: 4px;
`;

export const TextContainer = styled.div<{ show_text_snippet: boolean }>`
    display: flex;
    overflow: auto;

    padding-right: ${(props): string => (props.show_text_snippet ? 'none' : '24px')};

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;

    color: ${({ theme }): string => theme.colors.typography};
`;

export const FooterContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const NameAndLocationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NameContainer = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;

    color: ${({ theme }): string => theme.colors.typography};
    opacity: 0.8;
`;

export const LocationContainer = styled.div`
    display: flex;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: ${({ theme }): string => theme.colors.typography};
    opacity: 0.8;
`;
