import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from '../../button/Button';
import { Overlay } from '../../overlay/Overlay';
const UpgradeOverlayContainer = styled.div`
    width: 160px;
    padding: 20px 16px;
    border-radius: 8px;
    box-shadow: ${(props): string => props.theme.elevations.z8};
    background: ${(props): string => props.theme.colors.white};
`;

const MainActionText = styled.div`
    font-size: 16px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

interface IUpgradeOverlayProps {
    trigger_ref: React.MutableRefObject<any>;
    visible: boolean;
}

export default function UpgradeOverlay({
    trigger_ref,
    visible,
}: IUpgradeOverlayProps): ReactElement {
    return (
        <Overlay
            trigger_ref={trigger_ref}
            visible={visible}
            overlay_position='bottom-start'
            is_barebones
        >
            <UpgradeOverlayContainer>
                <MainActionText>Upgrade to Standard plan to continue</MainActionText>
                <ButtonContainer>
                    <Button
                        variant='action'
                        onClick={(): void => {
                            return;
                        }}
                    >
                        Upgrade
                    </Button>
                </ButtonContainer>
            </UpgradeOverlayContainer>
        </Overlay>
    );
}
