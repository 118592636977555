/**
 * Tag queries
 */
import gql from 'graphql-tag';

// eslint-disable-next-line @nicejob/snake-case
export const AddressAutoCompleteFields = `
    predictions {
        google_place_id
        description
    }
`;

// eslint-disable-next-line @nicejob/snake-case
export const AddressFromGooglePlaceIdFields = `
    address {
        address
        unit
        city
        state
        zip
        country
        googlePlaceId
        timezone
        geo {
            latitude
            longitude
        }
    }
`;

// ignoring snake-case to be consistent with existing implementations of GraphQL
// eslint-disable-next-line @nicejob/snake-case
export const GraphQL = {
    /** Queries */
    Query: {
        addressAutoComplete: gql`
             query addressAutoComplete($input: AddressAutoCompleteInput!) {
                 addressAutoComplete(input: $input) {
                     ${AddressAutoCompleteFields}
                 }
             }
         `,
        addressFromGooglePlaceId: gql`
             query addressFromGooglePlaceId($input: AddressFromGooglePlaceIdInput!) {
                addressFromGooglePlaceId(input: $input) {
                     ${AddressFromGooglePlaceIdFields}
                 }
             }
         `,
    },
};
