import { PropsWithChildren } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
    IStyledContent,
    IStyledFooter,
    IWindowWidth,
    ModalStylesBackdropProps,
    ModalStylesProps,
} from './Modal';

export const StyledBackground = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${(props): number => props.theme.zIndex.z100};
`;

export const StyledDimmedBackground = styled(StyledBackground)<{
    styles?: ModalStylesBackdropProps;
}>`
    background: ${(props): string | number => props?.styles?.background || 'rgba(0, 0, 0, 0.4)'};
    align-items: ${(props): string => props?.styles?.alignItems || 'center'};
    justify-content: ${(props): string => props?.styles?.justifyContent || 'center'};
`;

export const StyledWhiteContainer = styled.div<
    IWindowWidth &
        Pick<
            ModalStylesProps,
            'width' | 'maxWidth' | 'elevation' | 'top' | 'bottom' | 'left' | 'right'
        >
>`
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: white;
    min-width: ${(props): string =>
        whiteContainerMinWidth({ window_width: props.window_width, theme: props.theme })};
    max-width: ${(props): string =>
        props.maxWidth ||
        whiteContainerMaxWidth({ window_width: props.window_width, theme: props.theme })};
    max-height: ${(props): string =>
        whiteContainerMaxHeight({ window_width: props.window_width, theme: props.theme })};
    border-radius: 8px;
    box-shadow: ${(props): string => props.theme.elevations[props.elevation || 'z24']};
    > * {
        margin-bottom: ${(props): string =>
            whiteContainerChildrenBottomMargins({
                window_width: props.window_width,
                theme: props.theme,
            })};
    }
    ${(props): string => (props.width ? `width: ${props.width};` : '')}
    ${(props): string => (props?.top ? `top: ${props.top};` : '')}
    ${(props): string => (props?.bottom ? `bottom: ${props.bottom};` : '')}
    ${(props): string => (props?.left ? `left: ${props.left};` : '')}
    ${(props): string => (props?.right ? `right: ${props.right};` : '')}
`;

export const StyledClose = styled.img.attrs(() => ({
    src: 'https://cdn.nicejob.co/assets/grey-close.svg',
}))`
    margin-left: auto;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledHeader = styled.div<
    IWindowWidth & { marginBottom?: string; showBottomBorder: boolean }
>`
    padding: ${(props): string =>
        headerPadding({ window_width: props.window_width, theme: props.theme })};
    border-bottom: ${(props): string =>
        props.showBottomBorder ? `1px solid ${props.theme.colors.grey200}` : 'none'};
    margin: ${(props): string =>
        `0px ${modalViewportSpacing({ window_width: props.window_width, theme: props.theme })}`};
    margin-top: 4px;
    margin-bottom: ${(props): string =>
        props.marginBottom ||
        whiteContainerChildrenBottomMargins({
            window_width: props.window_width,
            theme: props.theme,
        })};
`;

export const StyledHeaderMainSection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledHeaderMainAction = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    top: 24px;
    right: 24px;
    margin-left: 10px;
`;

export const StyledContent = styled.div<PropsWithChildren<IStyledContent>>`
    display: flex;
    flex: 1;
    min-height: 0;
    padding: ${(props): string =>
        props.padding ||
        (props.full_width_content
            ? '0px'
            : `0px ${modalViewportSpacing({
                  window_width: props.window_width,
                  theme: props.theme,
              })}`)};

    ${(props: IStyledContent): string => (props.no_header ? `margin-top: 16px;` : '')}

    ${(props: IStyledContent): string =>
        props.marginBottom
            ? `margin-bottom: ${props.marginBottom};`
            : props.no_footer
            ? `margin-bottom: 0px;`
            : ''}
`;

export const StyledDismissModalButton = styled.button`
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 16px;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledFooter = styled.div<IStyledFooter>`
    display: flex;
    justify-content: flex-end;

    padding: ${(props): string =>
        `0px ${modalViewportSpacing({
            window_width: props.window_width,
            theme: props.theme,
        })}`};
`;

// Helpers
function whiteContainerMinWidth({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.mobile_horizontal) {
        return '360px';
    }

    return 'calc(100% - 32px)';
}

function whiteContainerMaxWidth({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.desktop) {
        return '860px';
    }

    if (window_width >= theme.legacyMediaWidth.mobile_horizontal) {
        return 'calc(100% - 64px)';
    }

    return 'calc(100% - 32px)';
}

function whiteContainerMaxHeight({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.desktop) {
        return 'calc(100% - 112px)';
    }

    if (window_width >= theme.legacyMediaWidth.mobile_horizontal) {
        return 'calc(100% - 80px)';
    }

    return 'calc(100% - 48px)';
}

export function modalViewportSpacing({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.tablet) {
        return '24px';
    }

    return '16px';
}

function whiteContainerChildrenBottomMargins({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.tablet) {
        return '24px';
    }

    return '16px';
}

function headerPadding({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width >= theme.legacyMediaWidth.mobile_horizontal) {
        return '16px 0px';
    }

    return '8px 0px';
}
