import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import React, {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react';

export interface IOnboardingStateContext {
    onboarding_state: IClientSafeOnboardingState | null;
    onOnboardingStateChange: (updated_onboarding_state: IClientSafeOnboardingState | null) => void;
    onboarding_state_error: boolean;
    setOnboardingStateError: Dispatch<boolean>;
    onboarding_update_is_loading: boolean;
    setOnboardingUpdateIsLoading: Dispatch<boolean>;
}

export interface IOnboardingStateContextProps {
    onboarding_state: IClientSafeOnboardingState | null;
    children: ReactNode;
}

const default_context = {
    onboarding_state: null,
    onOnboardingStateChange: (): void => {},
    onboarding_state_error: false,
    setOnboardingStateError: (): void => {},
    onboarding_update_is_loading: false,
    setOnboardingUpdateIsLoading: (): void => {},
};

export const OnboardingStateContext = createContext<IOnboardingStateContext>(default_context);

export function OnboardingStateContextProvider(props: IOnboardingStateContextProps): ReactElement {
    const [onboarding_state, setOnboardingState] = useState(props.onboarding_state);
    const [onboarding_state_error, setOnboardingStateError] = useState<boolean>(false);
    const [onboarding_update_is_loading, setOnboardingUpdateIsLoading] = useState<boolean>(false);

    function onOnboardingStateChange(
        updated_onboarding_state: IClientSafeOnboardingState | null
    ): void {
        setOnboardingState(updated_onboarding_state);
    }

    return (
        <OnboardingStateContext.Provider
            value={{
                onboarding_state,
                onOnboardingStateChange,
                onboarding_state_error,
                setOnboardingStateError,
                onboarding_update_is_loading,
                setOnboardingUpdateIsLoading,
            }}
        >
            {props.children}
        </OnboardingStateContext.Provider>
    );
}

export function useOnboardingState(): IOnboardingStateContext {
    return useContext<IOnboardingStateContext>(OnboardingStateContext);
}
