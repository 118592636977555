export const NICEJOB_DOMAIN = 'nicejob.com';
export const PAYSTONE_DOMAIN = 'paystone.com';
export const REVIEWLY_DOMAIN = 'reviewly.io';
export const ATOM_DOMAIN = 'atomhq.com';

export const APP_SUB_DOMAIN = 'app';
export const API_SUB_DOMAIN = 'api';
export const ENG_SUB_DOMAIN = 'eng';
export const HUB_SUB_DOMAIN = 'hub';
export const ATOM_MICROSITE_SUB_DOMAIN = 'ms';

export const PROJECT_SUB_DOMAINS: Record<string, string> = {
    ['nicejob-g-my-business']: 'exp',
};
