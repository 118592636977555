/**
 * REGEX
 */
// --> Numbers regex
export const FLOAT_TWO_DECIMALS_REGEX = /^\d*?($|\.\d{1,2}|\.)$/; // matches floats with up to two decimals --> 1.23, 3, 6.7 --> careful, also matches `6.`
export const FLOAT_REGEX = /^\d*?($|\.\d+|\.)$/; // matches floats --> 1.23465 --> careful, also matches `1.`
export const DIGITS_REGEX = /^\d*?$/; // matches digits --> 0, 1, 2, 3, 45, 500 --> but careful, this also matches 001234
export const INTEGER_REGEX = /^((?!^0+)\d*?|0)$/; // matches zero and positive integers --> 0, 1, 2, 3, 45, 500 --> like DIGITS_REGEX, but no leading zeroes allowed
export const LEADING_ZERO_REGEX = /(?!^0$)^0+/; // matches a number with leading 0's --> ex: 00123 --> Excludes the actual number 0 itself.

/**
 * Mobile Breakpoints
 */
export const MOBILE_BREAKPOINT_VERTICAL = 380;
export const MOBILE_BREAKPOINT_HORIZONTAL = 568;
export const TABLET_BREAKPOINT = 834;

/**
 * UX Options
 */
export const COUNTRIES_WITH_PROVINCES = ['US', 'CA'];
