import React from 'react';
import styled from 'styled-components';
import { Typography } from '../../core';

/**
 * Styled card for containing forms such as login and password reset
 */
export const AuthCard = styled.div`
    max-width: 432px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px -1px gray, 0 0 5px -2px grey;
    border-radius: 2px;
`;

/**
 * Styled `<p>` tag for text on auth cards
 */
export const AuthCardText = styled(Typography.P)`
    font-size: 17px;
    line-height: 1.7;
    text-align: left;
    color: ${(props): string => props.theme.colors.grey800};
    padding: 10px 20px;
`;
