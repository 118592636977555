import React, { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';

export { default as ListItem } from './atom/ListItem/ListItem';

export interface IListProps {
    show_dividers?: boolean;
}

const StyledListContainer = styled.div<IListProps>`
    display: flex;
    width: 100%;
    flex-direction: column;

    ${(props): string | null =>
        props.show_dividers
            ? `& .list-divider-section {
                    border-bottom: 1px solid ${props.theme.colors.grey200};
                }

                & > :first-child .list-divider-section {
                    border-top: 1px solid ${props.theme.colors.grey200};
                }`
            : null}
`;

export function List(props: PropsWithChildren<IListProps>): ReactElement {
    const { show_dividers } = props;

    return (
        <StyledListContainer show_dividers={show_dividers}>{props.children}</StyledListContainer>
    );
}
