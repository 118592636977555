import { getCountries } from './globals';

export function getCountryNameFromCode({ code }: { code: string }): string {
    const country = getCountries().find(c => c.alpha2 === code);

    if (!country) {
        return '';
    }

    return country.name;
}
