import { useEffect } from 'react';

/**
 * Detect a click outside a specified element
 * @param {*} ref --- Reference for the specified element
 * @param {*} callback --- Action when an outside element is clicked
 */
interface Props {
    refs: React.MutableRefObject<any>[];
    callback: () => void;
    disable_hook?: boolean;
}

export function useDetectOutsideClick(props: Props): void {
    const { refs, callback, disable_hook } = props;

    function handleOutsideClick(e: MouseEvent): void {
        const clicked_inside_allowed_elements = refs.find(
            ref => ref.current && ref.current.contains(e.target)
        );
        if (!clicked_inside_allowed_elements) {
            if (typeof callback == 'function') {
                callback();
            }
        }
    }

    useEffect(() => {
        if (disable_hook) {
            return;
        }
        // Bind event
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up
        return (): void => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    });
}
