import { getCountryNameFromCode, getCountryOptions } from '@nicejob-library/gps/regions';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { IValueProps, Select, SelectOption, ValueType } from '../../../../core/select';
import { generateCountrySelectOptions } from './generateCountrySelectOptions';
import { IPrioritizedCountryList } from './types';

export interface ICountrySelectProps {
    default_label: string;
    initial_value?: string | null;
    onChange: (selected: IValueProps<string> | null) => void;
}

export function CountrySelect(props: ICountrySelectProps): ReactElement {
    const [country_options, setCountryOptions] = useState<IPrioritizedCountryList | null>(null);

    const { initial_value, default_label, onChange } = props;

    function onSelectChange(selected: IValueProps<ValueType> | null): void {
        if (!selected) {
            return;
        }

        onChange(selected as IValueProps<string>);
    }

    const default_value = useMemo(() => {
        return initial_value
            ? {
                  label: getCountryNameFromCode({ code: initial_value }),
                  value: initial_value,
              }
            : {
                  label: default_label,
                  value: '',
              };
    }, [initial_value]);

    // on mount, get countries from gps lib and convert to select options
    useEffect(() => {
        const countries = getCountryOptions();

        setCountryOptions(generateCountrySelectOptions(countries));
    }, []);

    return (
        <Select
            stretch
            max_menu_height={350}
            onChange={onSelectChange}
            default_value={default_value}
        >
            {country_options &&
                country_options.prioritized.map(({ label, value }, i) => {
                    return (
                        <SelectOption
                            key={value as string}
                            label={label}
                            value={value as string}
                            bottomOfSection={i === country_options.prioritized.length - 1}
                        />
                    );
                })}
            {country_options &&
                country_options.general.map(({ label, value }) => {
                    return (
                        <SelectOption key={value as string} label={label} value={value as string} />
                    );
                })}
        </Select>
    );
}
