import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TEST_ID } from '../../../../../common/testing/constants';
import { InputFieldProps, InputVariantType } from '../../../core';
import {
    StyledDescription,
    StyledLabel,
    StyledTextField,
} from '../../../core/input/molecule/text-field/TextField.styles';
import { ReactComponent as FaCheck } from '../../../icons/font-awesome-pro/regular/check.svg';
import { ReactComponent as FaEyeSlash } from '../../../icons/font-awesome-pro/regular/eye-slash.svg';
import { ReactComponent as FaEye } from '../../../icons/font-awesome-pro/regular/eye.svg';
import { AuthInput } from '../../common/AuthInput';

export interface PasswordFieldProps extends InputFieldProps {
    variant: InputVariantType;
    name?: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    description?: string;
    error?: string;
    color?: string;
    hide_background?: boolean;
    onChange?: (value: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const ValidIconWrapper = styled.div`
    width: 10px;
    height: 10px;
    margin-left: auto;
`;

const Icon = styled.div`
    cursor: pointer;
    fill: #1e2024;
`;

export function PasswordField(props: PasswordFieldProps): ReactElement {
    const {
        name,
        placeholder,
        invalid,
        error,
        description,
        disabled,
        variant,
        valid,
        label,
        color,
    } = props;

    const display_invalid_description = invalid && error ? error : '';

    const display_valid_description = valid ? description : '';

    const [hide_password, setHidePassword] = useState(true);

    const theme = useTheme();

    return (
        <StyledTextField>
            {label && (
                <StyledLabel
                    valid={valid}
                    invalid={invalid}
                    disabled={disabled}
                    variant={variant}
                    size='small'
                    color={color}
                >
                    {label}
                </StyledLabel>
            )}
            <AuthInput
                {...props}
                name={name || 'password'}
                type={hide_password ? 'password' : 'text'}
                placeholder={placeholder}
                icon={{
                    value: (): ReactElement => (
                        <Icon onClick={(): void => setHidePassword(!hide_password)}>
                            {hide_password ? (
                                <FaEye data-testid={TEST_ID.login.mask_off} />
                            ) : (
                                <FaEyeSlash data-testid={TEST_ID.login.mask_on} />
                            )}
                        </Icon>
                    ),
                    position_right: true,
                }}
            />
            {display_valid_description && (
                <StyledDescription
                    valid={valid}
                    disabled={disabled}
                    variant={variant}
                    color={color}
                >
                    {display_valid_description}
                    <ValidIconWrapper>
                        <FaCheck
                            color={theme.colors.green500}
                            stroke={theme.colors.green500}
                            fill={theme.colors.green500}
                        />
                    </ValidIconWrapper>
                </StyledDescription>
            )}
            {display_invalid_description && (
                <StyledDescription
                    invalid={invalid}
                    disabled={disabled}
                    variant={variant}
                    color={color}
                >
                    {display_invalid_description}
                </StyledDescription>
            )}
        </StyledTextField>
    );
}
