import { useEffect, useRef } from 'react';

/** This is a very simple hook to check if an effect call is running on
 *  component Mount.
 *
 *  It is useful in cases when you want an effect to run only after the component
 *  has already been Mounted.
 *
 * @returns true - If effect is running on Mount
 * @returns false - If effect is running on subsequent component renders.
 */
export function useIsMount(): boolean {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
}
