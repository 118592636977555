import React, { PropsWithChildren, ReactElement, ReactNode, useState } from 'react';
import { FaAngleDoubleDown, FaAngleRight } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import CrownBlocked from '../common/blocked/CrownBlocked';
import { IconProps } from '../common/types/iconProps';
import { Typography } from '../typography/Typography';
import {
    CrownBlockedWrapper,
    IconWrapper,
    LoadingAnimation,
    NoMargingH5,
    StyledMenuContainer,
    StyledMenuIconRow,
    StyledMenuIconWrapper,
    StyledMenuItem,
    StyledMenuItemIconWrapper,
    StyledMenuItemText,
    StyledMenuItemWrapper,
    StyledMenuScrollContainer,
} from './styles';

export type TMenuItemVariant = 'default' | 'subtle' | 'link';
export type TMenuSize = 'large' | 'medium' | 'small';

export interface IMenuItem {
    variant: TMenuItemVariant;
    disabled?: boolean;
    selected?: boolean;
    icon?: IconProps;
    size?: TMenuSize;
    shrink?: boolean;
    width?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface LoadingAnimationProps {
    src: string;
    size?: TMenuSize;
}
export interface IMenuProps {
    max_height?: number;
    width?: string;
}

export interface IMenuSectionProps {
    title: string;
    icon?: { value: ReactElement };
    display_limit?: number;
}

interface IMenuItemProps extends IMenuItem {
    text: ReactNode;
    link?: string;
    loading?: boolean;
    shrink?: boolean;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export interface IMenuItemBlockedProps extends Omit<IMenuItemProps, 'icon'> {
    blocked?: boolean;
}

export type TMenuItemProps = IMenuItemBlockedProps & IMenuItemProps;

export function Menu({ children, width, max_height }: PropsWithChildren<IMenuProps>): ReactElement {
    // We use 2 wrappers to allow sub menus to be visible outside the scroll container
    // https://css-tricks.com/popping-hidden-overflow/
    return (
        <StyledMenuContainer>
            <StyledMenuScrollContainer max_height={max_height} width={width}>
                {children}
            </StyledMenuScrollContainer>
        </StyledMenuContainer>
    );
}

export function MenuSection({
    children,
    icon,
    title,
    display_limit,
}: PropsWithChildren<IMenuSectionProps>): ReactElement {
    const [show_more, setShowMore] = useState(false);
    const theme = useTheme();

    function renderChildren(): ReactNode {
        return React.Children.map(children, (child, i) => {
            if (!show_more && display_limit && i >= display_limit) {
                return null;
            }

            return child;
        });
    }

    return (
        <div className={'menu-section'}>
            <StyledMenuIconRow>
                {icon ? <StyledMenuIconWrapper>{icon.value}</StyledMenuIconWrapper> : null}
                <NoMargingH5 muted>{title}</NoMargingH5>
            </StyledMenuIconRow>

            {renderChildren()}

            {!show_more ? (
                <StyledMenuIconRow onClick={(): void => setShowMore(true)}>
                    <StyledMenuIconWrapper>
                        <FaAngleDoubleDown size={12} color={theme.colors.grey600} />
                    </StyledMenuIconWrapper>

                    <Typography.P size={'small'} muted>
                        ...more
                    </Typography.P>
                </StyledMenuIconRow>
            ) : null}
        </div>
    );
}

export function MenuItem({
    children,
    text,
    link,
    variant,
    disabled,
    selected,
    icon,
    loading,
    size,
    blocked,
    shrink,
    width,
    style,
    onClick,
}: PropsWithChildren<TMenuItemProps>): ReactElement {
    return (
        <StyledMenuItemWrapper disabled={!!disabled} className={'menu-item-wrapper'}>
            <StyledMenuItem
                data-testid={'nicejob-library-menu-item'}
                disabled={disabled}
                selected={selected}
                icon={icon}
                tabIndex={0}
                text={text}
                blocked={blocked}
                shrink={shrink}
                width={width}
                variant={variant}
                style={style}
                onClick={onClick}
            >
                {loading ? (
                    <LoadingAnimation
                        src={
                            variant === 'link'
                                ? 'https://cdn.nicejob.co/assets/spinnerBlue.gif'
                                : 'https://cdn.nicejob.co/assets/spinner.gif'
                        }
                        size={size}
                    />
                ) : (
                    <>
                        <StyledMenuItemText>
                            {!blocked && icon && icon.value && !shrink && (
                                <IconWrapper position_right={icon.position_right}>
                                    <StyledMenuItemIconWrapper>
                                        {icon.value}
                                    </StyledMenuItemIconWrapper>
                                </IconWrapper>
                            )}

                            {variant === 'link' ? <a href={link ? link : '#'}>{text}</a> : text}
                        </StyledMenuItemText>

                        {children ? <FaAngleRight /> : null}
                    </>
                )}

                {blocked && (
                    <CrownBlockedWrapper>
                        <CrownBlocked />
                    </CrownBlockedWrapper>
                )}
            </StyledMenuItem>

            {children && !blocked ? <div className='sub-menu'>{children}</div> : null}
        </StyledMenuItemWrapper>
    );
}
