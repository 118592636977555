import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthCardText,
    AuthContainer,
    AuthContent,
} from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { IInvalidInvitationPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function InvalidInvitationPage(props: IInvalidInvitationPageProps): ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <AuthContainer>
                <HeaderLogo branding={props.branding} />

                <AuthContent>
                    <AuthCard>
                        <AuthCardText data-testid='invalid-invitation'>
                            {props.error_message ||
                                translate({
                                    namespace: 'login',
                                    key: 'invitation.invalid',
                                    language: props.language,
                                })}
                        </AuthCardText>
                    </AuthCard>
                </AuthContent>

                <LoginLink branding={props.branding} language={props.language} />
            </AuthContainer>
        </ThemeProvider>
    );
}
