import { AuthLinkButton } from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import React, { ReactElement } from 'react';

export function GetStartedLink(): ReactElement {
    return (
        <AuthLinkButton href={Paths.ONBOARDING_BASE}>
            {translate({ namespace: 'login', key: 'common.link.get_started' })}
        </AuthLinkButton>
    );
}
