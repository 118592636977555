import { useLazyQuery } from '@apollo/client';
import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import {
    DropdownElement,
    IValueProps,
    Select,
    SelectOption,
    TextField,
    ValueType,
} from '@nicejob-library/design-system/core';
import { translate } from '@nicejob-library/internationalization';
import { Validation } from '@nicejob-library/react-form-validation';
import { IValidationError } from '@nicejob-library/react-form-validation/src/Validation';
import _debounce from 'lodash/debounce';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { IAddressAutoCompletePrediction } from '../../../../../../../../../types/gql.types';
import { VALIDATION_FIELD } from '../../../../../shared/types';
import { InputFieldAndLabelContainer } from '../../../../Onboarding.styles';
import { GraphQL as GPSQueries } from '../../../../queries/GPS';
import { InputContainer } from '../../AdditionalInfo.styles';

const DEBOUNCE_TIMEOUT = 650;

interface IAutoCompleteAddressProps {
    onAddressChosen: (chosen_address: IAddress) => void;
    initial_address_prompt: string | null;
    validation: Validation;
    validation_errors: Partial<IValidationError>;
    clearValidationError: (field: VALIDATION_FIELD) => void;
    should_disable_validation: boolean;
    onFocus: () => void;
}

export function AutoCompleteAddress(props: IAutoCompleteAddressProps): ReactElement {
    const {
        onAddressChosen,
        initial_address_prompt,
        validation_errors,
        validation,
        clearValidationError,
        should_disable_validation,
        onFocus,
    } = props;

    const [address_prompt, setAddressPrompt] = useState<string>(() => initial_address_prompt || '');
    const [suggested_addresses, setSuggestedAddresses] = useState<
        Array<IAddressAutoCompletePrediction>
    >([]);
    const [chosen_address_prediction, setChosenAddressPrediction] = useState<
        IAddressAutoCompletePrediction
    >();

    const select_ref = useRef<DropdownElement>(null);

    const [addressAutoComplete, { data: address_autocomplete_response }] = useLazyQuery(
        GPSQueries.Query.addressAutoComplete,
        {
            variables: {
                input: {
                    address_partial: address_prompt,
                },
            },
        }
    );

    const [
        addressFromGooglePlaceId,
        { data: address_from_google_place_id_response },
    ] = useLazyQuery(GPSQueries.Query.addressFromGooglePlaceId, {
        variables: {
            input: {
                google_place_id: chosen_address_prediction?.google_place_id,
            },
        },
    });

    function onAddressPromptChange(value: string): void {
        clearValidationError(VALIDATION_FIELD.company_address);
        setAddressPrompt(value);
    }

    function onAddressOptionChosen(selected: IValueProps<ValueType> | null): void {
        const { label, value } = selected || {};

        if (!value || !label) {
            return;
        }

        const description = label.toString();
        const google_place_id = value.toString();

        setChosenAddressPrediction({
            google_place_id,
            description,
        });
    }

    const debouncedAddressAutoComplete = _debounce(() => {
        addressAutoComplete();
    }, DEBOUNCE_TIMEOUT);

    // hide address suggestion panel when there are no suggestions
    useEffect(() => {
        if (suggested_addresses && suggested_addresses.length) {
            select_ref?.current?.open();
        } else {
            select_ref?.current?.close();
        }
    }, [JSON.stringify(suggested_addresses)]);

    // fetch autocomplete predictions when address prompt:
    // a) changes
    // b) is not empty
    // c) is of a meaningful length
    // d) is not the initial value
    useEffect(() => {
        if (
            address_prompt &&
            address_prompt.length > 3 &&
            address_prompt !== initial_address_prompt
        ) {
            debouncedAddressAutoComplete();
        }
    }, [address_prompt]);

    // set address suggestions once received from autocomplete query
    // do not set the suggestions if prompt change is a result of
    // an address choice
    useEffect(() => {
        if (address_prompt === chosen_address_prediction?.description) {
            return;
        }

        setSuggestedAddresses(address_autocomplete_response?.addressAutoComplete.predictions);
    }, [address_autocomplete_response]);

    // when an address prediction is chosen
    //  a) populate the prompt field and clear suggestions
    //  b) get the full address entity from Google
    useEffect(() => {
        if (!chosen_address_prediction?.google_place_id) {
            return;
        }

        setAddressPrompt(chosen_address_prediction?.description);
        addressFromGooglePlaceId();
    }, [chosen_address_prediction]);

    // when we receive a full address from addressFromGooglePlaceId
    // set the chosen address
    useEffect(() => {
        if (!address_from_google_place_id_response) {
            return;
        }

        const address_response =
            address_from_google_place_id_response.addressFromGooglePlaceId.address;

        onAddressChosen(address_response);
    }, [address_from_google_place_id_response]);

    function renderCustomTrigger(): ReactElement {
        return (
            <InputContainer>
                <TextField
                    label={translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.company_address',
                    })}
                    variant='stretch'
                    name='company_address'
                    initial_value={address_prompt}
                    onChange={onAddressPromptChange}
                    error={validation_errors?.company_address?.message}
                    onBlur={(e): void => {
                        !should_disable_validation && validation.input(e);
                    }}
                    onFocus={onFocus}
                    invalid={!!validation_errors.company_address}
                />
            </InputContainer>
        );
    }

    return (
        <InputFieldAndLabelContainer>
            <Select
                ref={select_ref}
                onChange={onAddressOptionChosen}
                default_value={null}
                custom_trigger={renderCustomTrigger}
                onOutsideClick={(): void => setSuggestedAddresses([])}
            >
                {suggested_addresses?.map(({ google_place_id, description }) => {
                    return (
                        <SelectOption
                            key={google_place_id}
                            label={description}
                            value={google_place_id}
                        />
                    );
                })}
            </Select>
        </InputFieldAndLabelContainer>
    );
}
