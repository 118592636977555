import { useEffect } from 'react';

export function useDocumentKeydownListener(
    condition: boolean,
    keydownHandler: (event: KeyboardEvent) => void
): void {
    useEffect(() => {
        // Add event listeners
        if (condition) {
            window.addEventListener('keydown', keydownHandler);
        }

        // Remove event listeners on cleanup
        return (): void => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [condition, keydownHandler]);
}
