import styled from 'styled-components';

export const Row = styled.div`
    padding: 10px 20px;
`;

export const RowSubmit = styled(Row)`
    margin-top: 10px;
    text-align: right;
`;

export const FormDescription = styled.p`
    margin: 0px;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 1.7;
    text-align: left;
`;

export const FormError = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $smoke;
    color: crimson;
    font-size: 15px;
    text-align: center;
    display: inline;
    vertical-align: middle;
`;

export const InputWrapper = styled.div`
    /* select the div wrapper of the input */
    > div {
        background-color: #ffffff;
    }
`;

export const HiddenInput = styled.input`
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: -1;
    visibility: hidden;
    overflow: hidden;
`;

export const SubmitInput = styled.input`
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: none;
    outline: none;
    text-align: center;
    font: 400 13.3333px Arial;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    transition: background-color 0.3s ease;
    will-change: background-color;

    color: white;
    background-color: #1874fd;

    &:hover {
        background-color: #0084d4;
    }
`;

export const PasswordValidation = styled.div`
    font-size: 12px;
    line-height: 16px;
    text-align: left;
`;
