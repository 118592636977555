import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {
    ILanguage,
    INamespaceTranslationKeyMap,
    NAMESPACES,
    RESOURCES,
    TranslationContext,
} from '../data/locales';
import { IInternationalizationNamespace } from '../data/namespaces';
import { IKeyParams } from '../data/params';
import { determineDefaultValue } from './determineDefaultValue';
import { TCase, transformCase } from './transformCase';

/** Expose the list of all languages from the top level of the library */
export { LANGUAGES } from '../data/locales';

/** Default internationalization language */
export const DEFAULT_LANGUAGE = 'en';

/**
 * @interface ITranslateArgument
 * @description Conditional `params` argument for `translate` function
 */
export type ITranslateArgument<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
> = {
    context?: TranslationContext;
    namespace: Namespace;
    key: Key;
    params?: IKeyParams<Namespace, Key>;
    text_case?: TCase;
    language?: ILanguage;
};

/**
 * Initialize i18next
 */
i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        /**
         * given user's browser languages are ['fr-CA', 'en'] in order of preference
         * it will use 'fr' which is broader language instead of en the exact match to our supported langs.
         */
        load: 'languageOnly',
        fallbackLng: DEFAULT_LANGUAGE,
        detection: {
            // use current browser language and ignore cached one in localstorage
            order: ['navigator'],
        },
        defaultNS: false,
        ns: NAMESPACES,
        resources: RESOURCES,
        supportedLngs: Object.keys(RESOURCES),
    });

/**
 * @function translate
 * @description Translate a given translation key based on the configured language
 * @param text_case - Change case of the translated text by respecting the language rules
 */
export function translate<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
>(...args: [ITranslateArgument<Namespace, Key>]): string {
    const { context, namespace, key, params, text_case, language } = args[0];

    /**
     * Translate value
     */

    //  Type-casting necessary since i18next.t() will return type `object` if `returnObjects: true`
    const translated_value = i18next.t(key, {
        context,
        defaultValue: determineDefaultValue({ namespace, key }),
        returnObjects: true,
        ns: namespace,
        //  Interpolation parameters are spread over options
        ...params,
        // Override global language - useful when we can't use changeLanguage, for example on server-side translation.
        lng: language,
    }) as string | Record<string, any>;

    /**
     * Handle response & return
     */

    //  If the translation key is not found, `translated_value` will be the key itself; we don't want this
    const value = translated_value === key ? null : translated_value;

    //  Need to manually throw, since i18next.t() will not throw if an object is returned, even with `returnObjects: false`
    if (typeof value !== 'string') {
        throw new Error(
            `Invalid translation key "${key}" in namespace "${namespace}" – corresponding value not found`
        );
    }

    return text_case ? transformCase(value, text_case) : value;
}
/**
 * @function changeLanguage
 * @description Change the active language
 */
export async function changeLanguage(language: string): Promise<void> {
    if (language && getLanguage() !== language) {
        await i18next.changeLanguage(language);
    }
}

/**
 * Returns the active language
 * @function getLanguage
 * @returns The current language
 */
export function getLanguage(): ILanguage {
    return i18next.resolvedLanguage as ILanguage;
}

/**
 * @function translationKey
 * @description Build a type-safe translation key
 */
export function translationKey<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
>(input: ITranslateArgument<Namespace, Key>): ITranslateArgument<Namespace, Key> {
    return input;
}
