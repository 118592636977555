import React, {
    Children,
    cloneElement,
    isValidElement,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';

/** Types */
export interface ITabRowProps {
    current_index: number;
    onChange: (new_index: number) => void;
    className?: string;
}

interface IShuttlePosition {
    left: number;
    width: number;
}

/** Styled components */
const TabRowWrapper = styled.div`
    position: relative;
    padding: 8px 0;
    border-bottom: solid 1px ${(props): string => props.theme.colors.grey200};
`;

const TabsWrapper = styled.div`
    position: relative;
    display: flex;
`;

const Shuttle = styled.div<{ shuttle_position: IShuttlePosition }>`
    position: absolute;
    background-color: ${(props): string => props.theme.colors.blue600};
    height: 2px;
    width: ${(props): string => `${props.shuttle_position.width}px`};
    bottom: -1px;
    left: ${(props): string => `${props.shuttle_position.left}px`};
    z-index: 999;

    transition-timing-function: ease;
    transition: left 0.2s, width 0.2s;
`;

/** Helper functions */
function renderChildren(
    current_index: number,
    children: ReactNode,
    onChange: ITabRowProps['onChange']
): ReactNode {
    const children_with_props = Children.map(
        children,
        (child, i): ReactNode => {
            if (isValidElement(child)) {
                return cloneElement(child, {
                    onClick: (e: React.ChangeEvent<HTMLDivElement>) => {
                        child.props.onClick && child.props.onClick(e);

                        // prevent disabled tabs from being selected
                        if (!child.props.disabled) {
                            onChange(i);
                        }
                    },
                    active: i === current_index,
                });
            }

            return child;
        }
    );

    return children_with_props;
}

/** Main component */
export function TabRow(props: PropsWithChildren<ITabRowProps>): ReactElement {
    const { current_index, children, onChange, className } = props;

    const tabs_wrapper_ref = useRef<HTMLDivElement>(null);

    const [shuttle_position, setShuttlePosition] = useState<IShuttlePosition | null>(null);

    // set shuttle position based on active tab
    useEffect(() => {
        if (tabs_wrapper_ref.current === null) {
            return;
        }

        const active_tab = tabs_wrapper_ref.current.children[current_index] as HTMLDivElement;
        const text_elements = active_tab.getElementsByTagName('p');
        const text_element = text_elements[0];

        if (!text_element) {
            setShuttlePosition({
                width: active_tab.offsetWidth,
                left: active_tab.offsetLeft,
            });
        } else {
            setShuttlePosition({
                width: text_element.offsetWidth,
                left: text_element.offsetLeft,
            });
        }
    }, [current_index]);

    return (
        <TabRowWrapper className={className}>
            <TabsWrapper ref={tabs_wrapper_ref}>
                {renderChildren(current_index, children, onChange)}
            </TabsWrapper>

            {shuttle_position ? <Shuttle shuttle_position={shuttle_position} /> : null}
        </TabRowWrapper>
    );
}
