/** Styled Components */
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100vh;

    overflow-x: hidden;
    scrollbar-gutter: stable;

    background-color: ${(props): string => props.theme.colors.grey100};
`;

export const FormContainer = styled.div`
    position: relative;
    top: 180px;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
`;

export const NicejobLogo = styled.img`
    position: absolute;
    top: 0;
    left: 0;

    width: 93px;
    margin: 8px;

    @media screen and (${(props): string => props.theme.responsive_media_queries.tablet}) {
        width: 150px;
        margin: 12px;
    }

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;
