import styled, { DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { renderAdditionalStyles } from '../../utils/styled-helpers';
import { IStyledSelectTriggerProps, ISelectLabelProps, ICaretWrapperProps } from './Select';

export const StyledSelectTrigger = styled.button<IStyledSelectTriggerProps>`
    border: none;
    border-radius: ${(props): string => getSelectBorderRadius(props.rounded)};
    padding: ${(props): string => (props.icon ? '5px 2.5px' : '6px 12px')};
    width: ${(props): string => (props.stretch ? '100%' : 'auto')};
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    pointer-events: ${(props): string => (props.disabled || props.is_loading ? 'none' : 'auto')};
    background-color: ${(props): string => getSelectBackgroundColor(props)};
    opacity: ${(props): string => (props.disabled ? '0.5' : '1')};
    color: ${(props): string => getSelectColor(props)};
    &:hover {
        background-color: ${(props): string => getHoverStateBackgroundColor(props)};
    }
    &:active {
        background-color: ${(props): string => getActiveStateBackgroundColor(props)};
    }
    &:focus {
        outline: none;
    }
    ${(props): FlattenSimpleInterpolation => {
        return renderAdditionalStyles(props.additional_styles || '');
    }}
`;

export const SelectLabelWrapper = styled.span<ISelectLabelProps>`
    display: block;
    font-weight: ${(props): string => (props.is_operator ? '800' : 'inherit')};
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;

export const LoadingAnimation = styled.img`
    width: 14px;
    height: 14px;
`;

export const ClearSelectWrapper = styled.span`
    height: auto;
    width: 8px;
    color: inherit;
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-left: 20px;
    &:focus {
        outline: none;
    }
`;

export const MenuWrapper = styled.div`
    min-width: 50px;
    border-radius: 8px;
    box-shadow: ${(props): string => props.theme.elevations.z8};
    background: ${(props): string => props.theme.colors.white};
    overflow: hidden;
`;

export const CrownBlockedWrapper = styled.div`
    position: absolute;
    top: -10px;
    right: -20px;
`;

export const CaretWrapper = styled.div<ICaretWrapperProps>`
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
    position: relative;
    transition: transform 0.25s ease-in;
    transform: ${(props): string => (props.menu_open ? `rotate(0deg)` : `rotate(-90deg)`)};
`;

export const StyledIconWrapper = styled.span`
    display: block;
`;

export const StyledRulesComposerGroupOperatorWrapper = styled.div`
    margin-left: 4px;
    margin-right: 4px;
`;

export const StyledRulesComposerCollectionOperatorWrapper = styled.div`
    margin-left: 2px;
    margin-right: 2px;
`;

export const StyledSelectFooter = styled.div`
    border-top: 1px solid ${(props): string => props.theme.colors.grey200};
`;

// Helpers
function getSelectBorderRadius(rounded: string | undefined): string {
    switch (rounded) {
        case 'semi':
            return '0px 8px 8px 0px';
        case 'none':
            return '0px';
        default:
            return '8px';
    }
}

function getSelectBackgroundColor(
    props: IStyledSelectTriggerProps & { theme: DefaultTheme }
): string {
    return props.has_error
        ? props.theme.colors.red50
        : props.is_operator
        ? 'transparent'
        : props.theme.colors.grey100;
}

function getSelectColor(props: IStyledSelectTriggerProps & { theme: DefaultTheme }): string {
    return props.has_error ? props.theme.colors.red600 : props.theme.colors.grey900;
}

function getHoverStateBackgroundColor(
    props: IStyledSelectTriggerProps & { theme: DefaultTheme }
): string {
    return props.has_error
        ? props.theme.colors.red100
        : props.is_operator
        ? props.theme.colors.grey100
        : props.theme.colors.grey200;
}

function getActiveStateBackgroundColor(
    props: IStyledSelectTriggerProps & { theme: DefaultTheme }
): string {
    return props.has_error
        ? props.theme.colors.red200
        : props.is_operator
        ? props.theme.colors.grey200
        : props.theme.colors.grey300;
}
