import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface CardFooterProps {
    label?: ReactElement;
    footer_stats?: CardFooterStatProps[];
    footer_action?: ReactElement;
    loading?: boolean;
}

export interface CardFooterStatProps {
    category: string;
    value: string | number;
    muted?: boolean;
}

// Styled components
const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    > div:not(:last-child) {
        margin-right: 12px;
    }
    align-items: center;
`;

const StyledFooterLoading = styled(StyledFooter)`
    justify-content: center;
`;

const StyledLabelWrapper = styled.div`
    padding-right: 8px;
`;

const StyledFooterStat = styled.div<CardFooterStatProps>`
    display: flex;
    flex-direction: row;
    color: ${(props): string =>
        props.muted ? props.theme.colors.grey600 : props.theme.colors.grey900};
    font-size: 12px;
    line-height: 16px;
    > div {
        margin-right: 2px;
    }
`;

const StyledFooterAction = styled.div`
    margin-left: auto;
`;

export default function CardFooter(props: CardFooterProps): ReactElement {
    const { label, footer_stats, footer_action, loading } = props;

    if (loading) {
        return (
            <StyledFooterLoading>
                <img
                    src={'https://cdn.nicejob.co/assets/spinner.gif'}
                    alt='Loading...'
                    width='20px'
                    height='20px'
                />
            </StyledFooterLoading>
        );
    }

    return (
        <StyledFooter>
            {label ? <StyledLabelWrapper>{label}</StyledLabelWrapper> : null}
            {footer_stats
                ? footer_stats.map(stat => (
                      <FooterStat
                          key={stat.category}
                          category={stat.category}
                          value={stat.value}
                          muted={stat.muted}
                      />
                  ))
                : null}
            {footer_action ? <StyledFooterAction>{footer_action}</StyledFooterAction> : null}
        </StyledFooter>
    );
}

function FooterStat(props: CardFooterStatProps): ReactElement {
    const { category, value, muted } = props;

    return (
        <StyledFooterStat category={category} value={value} muted={muted}>
            <div>{category}</div>
            <div>{value}</div>
        </StyledFooterStat>
    );
}
