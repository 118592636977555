import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';

const WaveAnimation = keyframes`
    0%, 60%, 100% {
        transform: initial;
    }

    30% {
        transform: translateY(-15px);
    }
`;

const Dot = styled.span`
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    background: ${({ theme }): string => theme.colors.grey500};
    animation: ${WaveAnimation} 1.3s linear infinite;

    &:nth-child(2) {
        animation-delay: -1.1s;
    }

    &:nth-child(3) {
        animation-delay: -0.9s;
    }
`;

const Wave = styled.div`
    text-align: center;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
`;

export function JumpingDots(): ReactElement {
    return (
        <Wave>
            <Dot />
            <Dot />
            <Dot />
        </Wave>
    );
}
