import { Paths } from './globals';

export function makePathSubstitutions(path: Paths, substitutions: Record<string, string>): string {
    let substituted_path: string = path;

    for (const [from_string, to_string] of Object.entries(substitutions)) {
        const from_regex = new RegExp(`:${from_string}`, 'g');

        if (substituted_path.search(from_regex) === -1) {
            throw new Error('failed to make substitutions');
        }

        substituted_path = substituted_path.replace(from_regex, to_string);
    }

    return substituted_path;
}
