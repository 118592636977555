import React, { ReactElement } from 'react';
import styled from 'styled-components';
import CrownBlocked from '../../common/blocked/CrownBlocked';

interface IButtonCrownBlocked {
    onClick: () => void;
}

const CrownBlockedWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    width: 20px;
    top: -12px;
    left: 22px;
`;
export default function ButtonCrownBlocked(props: IButtonCrownBlocked): ReactElement {
    return (
        <CrownBlockedWrapper onClick={props.onClick}>
            <CrownBlocked />
        </CrownBlockedWrapper>
    );
}
