import React, { ReactElement } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { Typography } from '../typography/Typography';

export type IChipSize = 'medium' | 'large';
export type IChipVariant =
    | 'blue'
    | 'vibrant_blue'
    | 'red'
    | 'green'
    | 'dark_green'
    | 'gold'
    | 'purple'
    | 'default';

export interface IChipProps {
    text?: string | number;
    icon?: ReactElement;
    onRemove?: () => void;
    variant?: IChipVariant;
    stretch?: boolean;
    chip_size?: IChipSize;
    className?: string;
    test_id?: string;
}

function chipPadding(props: {
    icon_only: boolean;
    chip_size?: IChipSize;
    icon?: ReactElement;
}): string {
    return props.icon_only
        ? ''
        : `
            padding: ${props.chip_size === 'large' ? '6px 10px' : '4px 8px'};
            padding-left: ${props.icon ? '2px' : '8px'};
        `;
}

export const StyledChip = styled.span<{
    icon_only: boolean;
    chip_size?: IChipSize;
    icon?: ReactElement;
    variant: IChipVariant | 'default';
}>`
    display: flex;
    align-items: center;
    ${(props): string => chipPadding(props)}
    border-radius: ${(props): string => (props.chip_size === 'large' ? '26px' : '16px')};
    background-color: ${(props): string =>
        props.variant
            ? props.theme.chip[props.variant].background
            : props.theme.chip.default.background};
    &:hover {
        background-color: ${(props): string =>
            props.variant !== 'default'
                ? props.theme.chip[props.variant].background
                : props.theme.chip.default.onHover};
    }
`;

const ChipText = styled(Typography.Cite)<{
    chip_size?: IChipSize;
    variant: IChipVariant | 'default';
}>`
    margin-right: ${(props): string => (props.chip_size === 'large' ? '10px' : '0')};
    color: ${(props): string =>
        props.variant ? props.theme.chip[props.variant].text : props.theme.chip.default.text};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ChipIconWrapper = styled.span<{
    variant: IChipVariant | 'default';
    chip_size?: IChipSize;
    icon_only?: boolean;
}>`
    line-height: 0;
    color: ${(props): string =>
        props.variant ? props.theme.chip[props.variant].text : props.theme.chip.default.text};

    ${(props): string =>
        props.icon_only
            ? `
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;

                & > * {
                    width: 10px; /* icon size */
                    height: 16px;
                }
            `
            : `
            margin-right: ${props.chip_size === 'large' ? '10px' : '4px'};
            `}
`;

const ChipRemoveButtonWrapper = styled.span<{
    stretch: boolean;
    chip_size?: IChipSize;
    variant: IChipVariant | 'default';
}>`
    display: flex;
    margin: 0px 4px;
    margin-left: ${(props): string => (props.stretch ? '30px' : '4px')};
    margin-right: ${(props): string => (props.chip_size === 'large' ? '10px' : '4px')};
    width: 7px;
    font-size: 10px;
    line-height: 16px;
    color: ${(props): string =>
        props.variant ? props.theme.chip[props.variant].text : props.theme.chip.default.text};

    &:hover {
        cursor: pointer;
    }
`;

export function Chip({
    text,
    icon,
    onRemove,
    variant: input_variant,
    stretch,
    chip_size,
    className,
    test_id,
}: IChipProps): ReactElement {
    const variant = input_variant || 'default';

    const icon_only = Boolean(!text && icon);

    return (
        <StyledChip
            icon_only={icon_only}
            variant={variant}
            icon={icon}
            chip_size={chip_size}
            className={className}
            data-testid={test_id}
        >
            {icon ? (
                <ChipIconWrapper variant={variant} icon_only={icon_only} chip_size={chip_size}>
                    {icon}
                </ChipIconWrapper>
            ) : null}
            {/* if text empty it means icon only */}
            {text && (
                <ChipText variant={variant} chip_size={chip_size}>
                    {text}
                </ChipText>
            )}

            {onRemove ? (
                <ChipRemoveButtonWrapper
                    variant={variant}
                    onClick={onRemove}
                    stretch={!!stretch}
                    chip_size={chip_size}
                >
                    <FaTimes />
                </ChipRemoveButtonWrapper>
            ) : null}
        </StyledChip>
    );
}
