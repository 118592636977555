import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { HorizontallyCenteredLoadingBall } from '../../core';

const AuthLoadingContainer = styled.div`
    padding-top: 80px;
`;

/**
 * Horizontally centered loading ball with top padding from the page header
 */
export function AuthLoading(): ReactElement {
    return (
        <AuthLoadingContainer>
            <HorizontallyCenteredLoadingBall />
        </AuthLoadingContainer>
    );
}
