/**
 * validatePassword
 *
 * - WARNING: Do not log unhashed password!
 * - Abiding by Quickbooks password policy:
 *   https://developer.intuit.com/app/developer/qbo/docs/legal-agreements/password-policy-for-intuit-developer-services
 */

import { ILanguage, translate } from '@nicejob-library/internationalization';
import { DEFAULT_LANGUAGE_PREFERENCE } from '../../common';
import { COMMON_PASSWORDS } from './commonPassword';

/**
 * frontend input validation doesn't support providing multiple values
 * check for email contained in password in the backend
 */
interface ValidatePasswordInput {
    password: string;
    email?: string;
    language?: ILanguage;
}

/**  Globals  */
const SUBSTITUTIONS = [
    { from: 'e', to: '(e|3)' },
    { from: 'i', to: '(i|1)' },
    { from: 'l', to: '(l|1|7)' },
    { from: 'o', to: '(o|0)' },
];
const NICEJOB_STRING = 'nicejob';
const COLOR = {
    red: '#FF281F',
    yellow: '#997A00',
    green: '#2CE080',
};

export function validatePassword(
    input: ValidatePasswordInput,
    options?: { ignore_empty_password?: boolean }
): {
    valid: boolean;
    message: string;
    color: string;
} {
    let { password, email, language } = input;
    if (!language) {
        language = DEFAULT_LANGUAGE_PREFERENCE;
    }

    /**
     * Password is not null
     */
    if (!password) {
        if (options?.ignore_empty_password) {
            return {
                valid: false,
                message: '',
                color: '',
            };
        }

        return {
            valid: false,
            message: translate({ namespace: 'common', key: 'validation.password.empty', language }),
            color: COLOR.red,
        };
    }

    /**
     * Password doesn't contain spaces
     */
    if (/\s/.test(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.contains_space',
                language,
            }),
            color: COLOR.red,
        };
    }

    /**
     * Password doesn't have 4 or more repeated characters
     */
    if (/(.)\1\1\1/.test(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.repeated',
                language,
            }),
            color: COLOR.red,
        };
    }

    /**
     * Password doesn't have 4 or more sequential characters
     */
    if (containsSequence(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.sequential',
                language,
            }),
            color: COLOR.red,
        };
    }

    /**
     * Password doesn't contain email username
     */
    if (email) {
        const user_name = email
            .trim()
            .toLowerCase()
            .substr(0, email.indexOf('@'));

        if (containsSubstringVariation(password, user_name)) {
            return {
                valid: false,
                message: translate({
                    namespace: 'common',
                    key: 'validation.password.email_username',
                    params: { user_name: user_name },
                    language,
                }),
                color: COLOR.red,
            };
        }
    }

    /**
     * Password doesn't contain a variation of "NiceJob"
     */
    if (containsSubstringVariation(password, NICEJOB_STRING)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.contains_nicejob',
                language,
            }),
            color: COLOR.red,
        };
    }

    /**
     * Password is at least 8 characters long
     */
    if (password.length < 8) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.almost_8_chars',
                language,
            }),
            color: COLOR.yellow,
        };
    }

    /**
     * Password contains at least 1 letter
     */
    if (!/[a-zA-Z]/.test(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.try_letter',
                language,
            }),
            color: COLOR.yellow,
        };
    }

    /**
     * Password contains at least 1 number
     */
    if (!/\d/.test(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.try_number',
                language,
            }),
            color: COLOR.yellow,
        };
    }

    /**
     * Password not found in list of 1000 most common passwords
     * - the most common passwords that pass the previous validation rules
     */
    if (COMMON_PASSWORDS.includes(password)) {
        return {
            valid: false,
            message: translate({
                namespace: 'common',
                key: 'validation.password.try_complex',
                language,
            }),
            color: COLOR.red,
        };
    }

    return {
        valid: true,
        message: translate({ namespace: 'common', key: 'validation.password.thanks', language }),
        color: COLOR.green,
    };
}

/**
 * Determines whether the given password has 4 or more sequential characters
 */
function containsSequence(password: string): boolean {
    let sequential = 1;
    for (let i = 0; i < password.length - 1; ++i) {
        if (password.charCodeAt(i) === password.charCodeAt(i + 1) - 1) {
            if (++sequential === 4) {
                return true;
            }
        } else {
            sequential = 1;
        }
    }

    return false;
}

/**
 * Determines whether the given password contains a substring or it's variations
 */
function containsSubstringVariation(password: string, substring: string): boolean {
    try {
        // escape all special regex characters
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
        let variations_string = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

        // create regex to check for common variations
        SUBSTITUTIONS.forEach(({ from, to }) => {
            variations_string = variations_string.replace(new RegExp(from, 'ig'), to);
        });

        const variations_regex = new RegExp(variations_string, 'i');

        // check if password contains substring or any variations
        return variations_regex.test(password);
    } catch (error) {
        // unexpected errors do not block
        return false;
    }
}
