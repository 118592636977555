import React, { ReactElement, forwardRef, Ref, ReactNode } from 'react';
import { FaTimes, FaCaretDown } from 'react-icons/fa';
import CrownBlocked from '../common/blocked/CrownBlocked';
import {
    StyledSelectTrigger,
    ClearSelectWrapper,
    SelectLabelWrapper,
    LoadingAnimation,
    CrownBlockedWrapper,
    CaretWrapper,
    StyledIconWrapper,
} from './Select.styles';

export interface ISelectTriggerProps {
    label: ReactNode;
    additional_styles?: string;
    allow_clear?: boolean;
    blocked?: boolean;
    custom_trigger?: ReactElement;
    disabled?: boolean;
    has_error?: boolean;
    icon?: ReactElement;
    is_operator?: boolean;
    loading?: boolean;
    menu_open?: boolean;
    onClear?: () => void;
    onClick?: () => void;
    rounded?: 'semi' | 'none' | 'round';
    stretch?: boolean;
}

export default forwardRef(function SelectTrigger(
    props: ISelectTriggerProps,
    ref: Ref<HTMLButtonElement>
) {
    const {
        additional_styles,
        allow_clear,
        blocked,
        disabled,
        has_error,
        icon,
        is_operator,
        label,
        loading,
        menu_open = false,
        onClear,
        onClick,
        rounded,
        stretch,
    } = props;

    return (
        <StyledSelectTrigger
            ref={ref}
            onClick={onClick}
            has_error={has_error}
            is_operator={is_operator}
            disabled={disabled}
            is_loading={loading}
            stretch={stretch}
            rounded={rounded || 'round'}
            icon={icon}
            additional_styles={additional_styles}
        >
            {loading ? (
                <LoadingAnimation src={'https://cdn.nicejob.co/assets/spinner.gif'} />
            ) : (
                <>
                    <SelectLabelWrapper is_operator={is_operator}>
                        {icon ? <StyledIconWrapper>{icon}</StyledIconWrapper> : label}
                    </SelectLabelWrapper>
                    {blocked && (
                        <CrownBlockedWrapper>
                            <CrownBlocked />
                        </CrownBlockedWrapper>
                    )}
                </>
            )}
            <>
                {allow_clear && (
                    <ClearSelectWrapper onClick={onClear}>
                        <FaTimes />
                    </ClearSelectWrapper>
                )}
            </>
            <>
                {stretch && (
                    <CaretWrapper menu_open={menu_open}>
                        <FaCaretDown size={10} />
                    </CaretWrapper>
                )}
            </>
        </StyledSelectTrigger>
    );
});
