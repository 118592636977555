import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Typography } from '../typography/Typography';

export const PromptContainer = styled.div`
    padding: 16px;
    width: 100%;
    border-style: none;
    border-radius: 16px;
    background-color: ${({ theme }): string => theme.colors.blue50};
    display: flex;
`;

export const ContentContainer = styled.div`
    width: 100%;
    background-color: inherit;
    flex-grow: 1;
`;

export const IconContainerLeft = styled.div`
    margin-right: 16px;
    background-color: inherit;
    flex-shrink: 0;
`;

export const IconContainerRight = styled.div`
    margin-left: 16px;
    background-color: inherit;
    flex-shrink: 0;
`;

export const StyledDismissButton = styled.button`
    width: 12px;
    height: 16px;
    cursor: pointer;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Message = styled(Typography.P)`
    color: var(--neutron-blue-900, #061e42);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const CallToAction = styled.span`
    color: ${(props): string => props.theme.colors.blue600};
    margin-top: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: block;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const StyledSparklesIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.colors.blue500};
`;

export const StyledDismissIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.colors.grey600};
`;
