import styled from 'styled-components';

export const SetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }): string => theme.colors.white};
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 121px;
`;

export const AtomLogo = styled.img`
    height: 24px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 315px;
`;

export const Greeting = styled.h1`
    margin-bottom: 10px;
    font-family: Bogart;
    font-size: 24px;
    color: ${({ theme }): string => theme.colors.grey900};
    line-height: 20px;
`;

export const CTA = styled.h3`
    margin-bottom: 20px;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }): string => theme.colors.grey700};
    line-height: 20px;
    text-align: center;
`;

export const SubmitInput = styled.input`
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: none;
    outline: none;
    text-align: center;
    font: 400 13.3333px Inter;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;

    transition: background-color 0.3s ease;
    will-change: background-color;

    color: white;
    background-color: ${({ theme }): string => theme.button.action.default.background};

    &:hover {
        background-color: ${({ theme }): string => theme.button.action.hover.background};
    }
`;
