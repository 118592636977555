import { useIsMount, usePrevious } from '@nicejob-library/react-hooks';
import React, {
    CSSProperties,
    Dispatch,
    forwardRef,
    ReactElement,
    ReactNode,
    Ref,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import UpgradeOverlay from '../common/blocked/UpgradeOverlay';
import { Overlay } from '../overlay/Overlay';

export type DropdownElement = {
    open: () => void;
    close: () => void;
};

export interface IDropdown {
    children: ReactNode;
    renderTrigger: (
        menu_open: boolean,
        setMenuOpen: Dispatch<SetStateAction<boolean>>
    ) => ReactElement;
    blocked?: boolean;
    default_open?: boolean;
    onOutsideClick?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
    additional_styles?: Partial<CSSProperties>;
    className?: string;
}

const ContentContainer = styled.div`
    margin-top: 8px;
`;

export const Dropdown = forwardRef(function(
    {
        children,
        default_open = false,
        renderTrigger,
        onOutsideClick,
        onOpen,
        onClose,
        blocked,
        className,
        additional_styles = {},
    }: IDropdown,
    ref: Ref<DropdownElement>
): ReactElement {
    const [menu_open, setMenuOpen] = useState(!!default_open);
    const prev_is_menu_open = usePrevious(menu_open);

    const select_button_ref = useRef(null);

    const is_mount = useIsMount();

    useEffect(() => {
        if (is_mount) {
            return;
        }

        if (menu_open !== prev_is_menu_open) {
            if (menu_open) {
                onOpen && onOpen();
            } else {
                onClose && onClose();
            }
        }
    }, [menu_open, prev_is_menu_open, onOpen, onClose]);

    useImperativeHandle(ref, () => ({
        open: (): void => {
            setMenuOpen(true);
        },
        close: (): void => {
            setMenuOpen(false);
        },
    }));

    // Change Open state if default_open changes...
    useEffect(() => {
        setMenuOpen(!!default_open);
    }, [!!default_open]);

    return (
        <>
            <div
                data-testid={'nicejob-library-dropdown'}
                ref={select_button_ref}
                className={`dropdown-trigger--wrap ${className || ''}`}
                style={additional_styles}
            >
                {renderTrigger(!!menu_open, setMenuOpen)}
            </div>
            {menu_open && !blocked && (
                <Overlay
                    trigger_ref={select_button_ref}
                    visible={menu_open}
                    overlay_position='bottom-start'
                    is_barebones
                    onOutsideClick={(): void => {
                        if (onOutsideClick) {
                            onOutsideClick();
                        } else {
                            setMenuOpen(false);
                        }
                    }}
                >
                    <ContentContainer>{children}</ContentContainer>
                </Overlay>
            )}

            {/*  Upgrade overlay */}
            {blocked ? (
                <UpgradeOverlay trigger_ref={select_button_ref} visible={menu_open} />
            ) : null}
        </>
    );
});
