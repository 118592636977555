import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface ILoadingBall {
    className?: string;
}

const Loading = styled.img`
    width: 50px;
    height: 50px;
`;

const HorizontallyCentredDiv = styled.div`
    text-align: center;
`;

const AbsoluteCentredDiv = styled.div`
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
`;

export function LoadingBall(props: ILoadingBall): ReactElement {
    const { className } = props;

    return <Loading src='https://cdn.nicejob.co/assets/loading-ball.gif' className={className} />;
}

export const StyledCenteredLoadingBall = styled(LoadingBall)`
    margin: auto;
`;

export function HorizontallyCenteredLoadingBall(): ReactElement {
    return (
        <HorizontallyCentredDiv>
            <StyledCenteredLoadingBall />
        </HorizontallyCentredDiv>
    );
}

export function AbsoluteCenteredLoadingBall(): ReactElement {
    return (
        <AbsoluteCentredDiv>
            <StyledCenteredLoadingBall />
        </AbsoluteCentredDiv>
    );
}
