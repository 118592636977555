import { useMutation } from '@apollo/client';
import { Button, Typography } from '@nicejob-library/design-system/core';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { useOnboardingState } from '../context/OnboardingStateContext';
import ONBOARDING_STATE_QUERIES from '../queries/OnboardingState';
import {
    BottomLineText,
    ContentBlock,
    ErrorImage,
    MainContainer,
    TextBlock,
} from './ErrorScreen.styles';

/**
 * Renders the error screen component.
 * This component is responsible for displaying an error message and providing a button to try again.
 */
export function ErrorScreen(): ReactElement {
    const { onboarding_state, onOnboardingStateChange } = useOnboardingState();
    const { onboarding_state_id } = onboarding_state || {};
    const [deleteOnboardingState, { loading }] = useMutation(
        ONBOARDING_STATE_QUERIES.Mutation.deleteOnboardingState
    );

    function cleanupOnboardingState(): void {
        deleteOnboardingState({
            variables: {
                input: {
                    onboarding_state_id,
                },
            },
        });

        onOnboardingStateChange(null);
    }

    return (
        <MainContainer>
            <ContentBlock>
                <TextBlock>
                    <Typography.P>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.error.text.error_message',
                        })}
                    </Typography.P>
                    <Typography.H2>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.error.text.action',
                        })}
                    </Typography.H2>
                    <BottomLineText>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.error.text.thank_you',
                        })}
                    </BottomLineText>
                    <Button variant='action' loading={loading} onClick={cleanupOnboardingState}>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.error.button.try_again',
                        })}
                    </Button>
                </TextBlock>
                <ErrorImage src='https://cdn.nicejob.co/assets/404-image.svg' alt='' />
            </ContentBlock>
        </MainContainer>
    );
}
