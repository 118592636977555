import styled from 'styled-components';

export const DropZoneContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${(props): string => props.theme.colors.grey400};
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;

    &:hover {
        border-color: ${(props): string => props.theme.colors.blue400};
    }

    &:focus {
        border-color: ${(props): string => props.theme.colors.blue400};
    }

    &:active {
        border-color: ${(props): string => props.theme.colors.blue400};
    }
`;

export const DropZoneContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props): string => props.theme.colors.grey600};
    transition: color 0.2s ease-in-out;
    gap: 2px;

    &:hover {
        color: ${(props): string => props.theme.colors.blue400};
    }

    &:focus {
        color: ${(props): string => props.theme.colors.blue400};
    }

    &:active {
        color: ${(props): string => props.theme.colors.blue400};
    }
`;
