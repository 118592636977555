/**
 * login-route globals
 */
import path from 'path';
import { Online } from '@nicejob/nicejob-toolbox';
import { CompanyProductPlan } from '@nicejob-library/data-platform-implementation/entities/Company/ProductStatus/types';
import { CompanyProductId } from '@nicejob-library/data-platform-implementation/entities/Company/types';
import { domain } from '@nicejob-library/domain';
import { SelectedProduct } from '../../../../../../types/entities.types';

//  Domains
export const NICEJOB_DOMAIN = domain({ key: 'APP' });
export const REVIEWLY_DOMAIN = domain({ key: 'APP', reviewly: true });
export const LOGIN_DOMAIN = Online ? NICEJOB_DOMAIN : 'http://localhost:8088';

// URL Paths
export const TEST_REGISTRATION_PATH = '/u/register-test';
export const LOGIN_PATHS = ['/login', '/u/login'];

// Google OAuth Paths
export const GOOGLE_OAUTH_LOGIN_PATHS = {
    request: '/u/auth/request/google',
    callback: '/u/login/google/callback',
};
export const GOOGLE_OAUTH_STRATEGY = 'google-login';

// Xero OAuth Paths
export const XERO_OAUTH_LOGIN_PATHS = {
    request: '/u/auth/request/xero',
    callback: '/u/login/xero/callback',
};
export const XERO_OAUTH_STRATEGY = 'xero-login';

// File Paths
export const INDEX_FILE_PATH = path.join(
    process.cwd(),
    Online ? '/pages/index.html' : '/dist/pages/index.html'
);

export const DEFAULT_PRODUCTS: Array<SelectedProduct> = [
    {
        product: CompanyProductId.reviews,
        plan: CompanyProductPlan.PRO,
    },
];
export const REVIEWS_FREE_PLAN: CompanyProductPlan = CompanyProductPlan.FREE;

export const ATOM_PRODUCTS: Array<SelectedProduct> = [
    {
        product: CompanyProductId.reviews,
        plan: CompanyProductPlan.PARTNER,
    },
];

export interface IConditionalCoupons {
    url_pattern: RegExp;
    coupon: string;
    start_date: Date;
    end_date: Date;
}

export const CONDITIONAL_COUPONS: Array<IConditionalCoupons> = [
    //  HSSS
    {
        url_pattern: /\/home-service-super-summit/,
        coupon: `hsssgift`,
        start_date: new Date('2020-07-25'),
        end_date: new Date('2020-08-01'),
    },

    //  Black Friday 2021
    {
        url_pattern: /.*/, // any page
        coupon: `UAP8TFcj`,
        start_date: new Date(`2021-11-26T05:00:00.000Z`), // Midnight Eastern time, Nov. 26th
        end_date: new Date(`2021-12-04T07:59:00.000Z`), // 11:59pm Pacific time, Dec. 3
    },
];
