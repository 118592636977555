import React, { forwardRef, ReactElement, Ref, RefObject } from 'react';
import { InputField, InputFieldProps } from '../../input-field/InputField';
import { StyledDescription, StyledLabel, StyledTextField } from './TextField.styles';

export interface ITextFieldProps extends InputFieldProps {
    label?: string;
    description?: string;
    error?: string;
    name?: string;
    color?: string;
    ref?: RefObject<HTMLInputElement>;
}

export const TextField = forwardRef(function(
    props: ITextFieldProps,
    ref: Ref<HTMLInputElement>
): ReactElement {
    const {
        label,
        description,
        error,
        valid,
        invalid,
        disabled,
        variant,
        onBlur: parentOnBlur,
        name = '',
    } = props;

    const displayed_description = invalid && error ? error : description;

    /**
     * onBlur
     * combine the select value and text field value and sent it as event
     * currently to fit validation needs
     */
    function onBlur(e: React.FocusEvent<HTMLInputElement>): void {
        e.target.name = name;
        parentOnBlur && parentOnBlur(e);
    }

    return (
        <StyledTextField>
            {label && (
                <StyledLabel
                    valid={valid}
                    invalid={invalid}
                    disabled={disabled}
                    variant={variant}
                    size='small'
                >
                    {label}
                </StyledLabel>
            )}
            <InputField {...props} onBlur={onBlur} ref={ref} />
            {displayed_description && (
                <StyledDescription
                    valid={valid}
                    invalid={invalid}
                    disabled={disabled}
                    variant={variant}
                >
                    {displayed_description}
                </StyledDescription>
            )}
        </StyledTextField>
    );
});
