import { useIsMount } from '@nicejob-library/react-hooks';
import React, {
    Children,
    cloneElement,
    forwardRef,
    isValidElement,
    ReactElement,
    ReactNode,
    Ref,
    useEffect,
    useReducer,
} from 'react';
import styled from 'styled-components';
import {
    Checkbox,
    checkboxValuesReducer,
    ICheckboxProps,
    TCheckboxActionType,
} from '../checkbox/Checkbox';
import { Dropdown, DropdownElement } from '../dropdown/Dropdown';
import { Menu } from '../menu/Menu';
import { SelectFooter } from './Select';
import { MenuWrapper } from './Select.styles';
import SelectTrigger from './SelectTrigger';

interface ICheckboxSelectSelect {
    label: ReactNode;
    has_error?: boolean;
    is_operator?: boolean;
    disabled?: boolean;
    loading?: boolean;
    blocked?: boolean;
    stretch?: boolean;
    rounded?: 'semi' | 'none' | 'round';
    max_menu_height?: number;
    footer_content?: ReactElement;
    icon?: ReactElement;
    children?: ReactNode;
    default_selected_checkboxes?: string[];
    onChange?: (selected_checkboxes: string[]) => void;
    onOutsideClick?: () => void;
    additional_styles?: string;
}

interface ICheckboxSelectSelectOption
    extends Omit<ICheckboxProps, 'onChange' | 'selected_checkboxes'> {
    onChange?: ICheckboxProps['onChange'];
    selected_checkboxes?: ICheckboxProps['selected_checkboxes'];
}

export const StyledCheckboxSelectOption = styled(Checkbox)`
    padding: 10px 16px;
`;

export function CheckboxSelectOption(props: ICheckboxSelectSelectOption): ReactElement {
    const {
        selected_checkboxes = [],
        label,
        value,
        onChange = (): void => {},
        blocked,
        disabled,
        className,
    } = props;

    return (
        <StyledCheckboxSelectOption
            selected_checkboxes={selected_checkboxes}
            label={label}
            value={value}
            onChange={onChange}
            blocked={blocked}
            disabled={disabled}
            className={className}
        />
    );
}

export const CheckboxSelect = forwardRef(function(
    props: ICheckboxSelectSelect,
    ref: Ref<DropdownElement>
): ReactElement {
    const {
        label,
        children,
        has_error,
        is_operator,
        disabled,
        loading,
        blocked,
        stretch,
        rounded,
        max_menu_height,
        footer_content,
        icon,
        default_selected_checkboxes = [],
        onChange,
        onOutsideClick,
        additional_styles,
    } = props;
    const is_mount = useIsMount();

    const [selected_checkboxes, setSelectedCheckbox] = useReducer<typeof checkboxValuesReducer>(
        checkboxValuesReducer,
        default_selected_checkboxes
    );

    function handleChange(value: string, type: TCheckboxActionType): void {
        setSelectedCheckbox({ type, payload: value });
    }

    function RenderChildren(): ReactElement {
        const childrenWithProps = Children.map(children, child => {
            if (isValidElement(child)) {
                return cloneElement(child, {
                    onChange: handleChange,
                    selected_checkboxes,
                });
            }

            return child;
        });

        return <>{childrenWithProps}</>;
    }

    useEffect(() => {
        if (is_mount) {
            return;
        }

        onChange && onChange(selected_checkboxes);
    }, [selected_checkboxes.length]);

    return (
        <Dropdown
            ref={ref}
            blocked={blocked}
            onOutsideClick={onOutsideClick}
            renderTrigger={(menu_open, setMenuOpen): ReactElement => {
                return (
                    <SelectTrigger
                        has_error={has_error}
                        is_operator={is_operator}
                        disabled={disabled}
                        loading={loading}
                        blocked={blocked}
                        stretch={stretch}
                        rounded={rounded}
                        icon={icon}
                        label={label}
                        menu_open={menu_open}
                        onClick={(): void => {
                            setMenuOpen(!menu_open);
                        }}
                        additional_styles={additional_styles}
                    />
                );
            }}
        >
            <MenuWrapper>
                <Menu max_height={max_menu_height}>{RenderChildren()}</Menu>
                {footer_content ? <SelectFooter>{footer_content}</SelectFooter> : null}
            </MenuWrapper>
        </Dropdown>
    );
});
