import { capitalizeString } from '../../../common/capitalizeString';
import { titleCase } from '../../../common/titleCase';
import { getLanguage } from './index';

export type TCase = 'sentence' | 'title' | 'lower' | 'upper';

/**
 * French have a different way of capitalizing words in titles, they don't capitalize all words.
 * The function transform the case of a string by follow the rules of the language.
 * @param text - String
 * @param case_kind - case kind
 * @returns - String
 */
export function transformCase(text: string, case_kind: TCase): string {
    switch (case_kind) {
        case 'sentence':
            return capitalizeString(text);
        case 'title':
            return getTitleCase(text);
        case 'lower':
            return text.toLowerCase();
        case 'upper':
            return text.toUpperCase();
        default:
            return text;
    }
}

/**
 * Get the title case of a string.
 * @param text - String
 * @returns - String
 */
function getTitleCase(text: string): string {
    if (getLanguage() === 'fr') {
        return capitalizeString(text);
    }

    return titleCase(text);
}
