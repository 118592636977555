import { AuthLinkButton } from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';

/**
 * link to the forgot password page
 */
export function ForgotPasswordLink(): ReactElement {
    return (
        <AuthLinkButton href='/u/forgot_password'>
            {translate({ namespace: 'login', key: 'login.link.forgot_password' })}
        </AuthLinkButton>
    );
}
