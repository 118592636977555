import { Countdown } from '@nicejob-library/design-system/core/countdown/Countdown';
import { PhoneNumberVerification } from '@nicejob-library/design-system/onboarding/input/PhoneNumberVerification';
import { translate } from '@nicejob-library/internationalization';
import React, {
    ChangeEvent,
    Dispatch,
    FocusEvent,
    KeyboardEvent,
    ReactElement,
    ReactNode,
    useRef,
} from 'react';
import {
    PhoneNumberInputActionLink,
    PhoneNumberInputDescription,
    PhoneNumberInputError,
    PhoneVerificationResendCountdown,
} from './PhoneNumberInputWithVerification.styles';

const PHONE_VERIFICATION_RESEND_TIMEOUT_IN_SECONDS = 60;

export interface IPhoneNumberInputWithVerificationProps {
    with_verification: boolean;
    children: ReactNode;

    // state
    is_highlighted: boolean;
    verification_in_progress: boolean;
    setVerificationInProgress: Dispatch<boolean>;
    placeholder: string;
    verification_code: string;
    error: string;
    onError: Dispatch<boolean>;
    countdown_loading_message: string;

    // handlers
    onBlur: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSendingVerificationCode: () => void;
}

export function PhoneNumberInputWithVerification(
    props: IPhoneNumberInputWithVerificationProps
): ReactElement {
    const {
        with_verification,
        children,

        verification_in_progress,
        setVerificationInProgress,
        is_highlighted,
        placeholder,
        verification_code,
        countdown_loading_message,
        error,

        onBlur,
        onKeyDown,
        onChange,
        onSendingVerificationCode,
    } = props;

    const phone_verification_code_input_ref = useRef<HTMLInputElement>(null);

    // Phone Verification Code Input
    if (verification_in_progress) {
        return (
            <>
                <PhoneNumberVerification
                    data-testid='phone-verification-code-input'
                    ref={phone_verification_code_input_ref}
                    value={verification_code}
                    is_highlighted={is_highlighted}
                    name='phone_verification_code'
                    type='otp'
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => {
                        if (e.key === 'Enter' || e.key === 'Tab') {
                            onKeyDown(e);
                        }
                    }}
                    onChange={onChange}
                />
                <PhoneNumberInputError show={error?.length > 0}>{error}</PhoneNumberInputError>
                <PhoneVerificationResendCountdown>
                    <Countdown
                        time_span_in_seconds={PHONE_VERIFICATION_RESEND_TIMEOUT_IN_SECONDS}
                        loading_message={countdown_loading_message}
                    >
                        {/* Resend */}
                        <PhoneNumberInputActionLink onClick={onSendingVerificationCode}>
                            {translate({
                                namespace: 'login',
                                key: 'onboarding.register.resend_code',
                            })}
                        </PhoneNumberInputActionLink>
                    </Countdown>
                </PhoneVerificationResendCountdown>
                {/* Edit Phone Number */}
                <PhoneNumberInputActionLink
                    onClick={(): void => {
                        setVerificationInProgress(false);
                    }}
                >
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register.edit_phone_number',
                    })}
                </PhoneNumberInputActionLink>
            </>
        );
    }

    // Phone Number Input
    return (
        <>
            {children}
            <PhoneNumberInputError show={error?.length > 0}>{error}</PhoneNumberInputError>
            {with_verification && (
                <PhoneNumberInputDescription is_highlighted={is_highlighted}>
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register.phone_verification_description',
                    })}
                </PhoneNumberInputDescription>
            )}
        </>
    );
}
