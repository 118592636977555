import {
    CSS_PROPERTIES,
    CSS_PROPERTY_NAMES_MAP,
    IBoxBreakPoints,
    PROPERTIES_WITH_UNIT_VALUES,
} from '.';
import { theme } from '../..';

// helper function that generates a chunk of css for a given property and value
export function generateCSSDeclaration({
    property,
    value,
}: {
    property: CSS_PROPERTIES;
    value: number | string | IBoxBreakPoints<number | string>;
}): string {
    // if we were passed a primitive value we can create a simple css declaration
    if (typeof value === 'string' || typeof value === 'number') {
        return formatCSSDeclaration({ property, value });
    }
    // if we have reached the else we were passed an IBoxBreakPoints object and we need to generate n css declaration with media queries
    else {
        // iterate through each break point
        return Object.entries(value).reduce((acc, entry) => {
            const [breakpoint, breakpoint_value] = entry as [
                keyof IBoxBreakPoints<number>,
                number | string
            ];

            // for mobile we don't need a media query
            if (breakpoint === 'mobile') {
                return `${acc}${formatCSSDeclaration({ property, value: breakpoint_value })}`;
            }

            // wrap css declaration in the appropriate media query
            const css_declaration = `@media screen and ${
                theme.responsive_media_queries[breakpoint]
            } {${formatCSSDeclaration({ property, value: breakpoint_value })}}`;

            return `${acc} ${css_declaration}`;
        }, '');
    }
}

// helper function to generate a css declaration with valid punctuation, css property names and values
export function formatCSSDeclaration({
    property,
    value,
}: {
    property: CSS_PROPERTIES;
    value: string | number;
}): string {
    return CSS_PROPERTY_NAMES_MAP[property]
        .map(css_property => `${css_property}: ${getCSSValue({ property, value })};`)
        .join('');
}

// helper function to generate valid css value for a given property
export function getCSSValue({
    property,
    value,
}: {
    property: CSS_PROPERTIES;
    value: string | number;
}): string {
    if (PROPERTIES_WITH_UNIT_VALUES.has(property)) {
        return typeof value === 'number' ? `${value}px` : value;
    } else {
        return String(value);
    }
}
