import { NavLink } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';
import { Typography } from '../typography/Typography';
import { AlertLinkProps, AlertProps, VariantType } from './Alert';

// Styled components

export const Wrapper = styled.div<Pick<AlertProps, 'width' | 'variant'>>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props): string => backgroundColor(props)};
    width: ${(props): string => props.width || '100%'};
    padding: 7px 16px;
`;

export const Text = styled.div<Pick<AlertProps, 'variant'>>`
    font-size: 14px;
    line-height: 22px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: ${(props): string => textColor(props)};
    padding: 0px 8px 0px 12px;
`;

export const Link = styled.a<Pick<AlertLinkProps, 'href'> & Pick<AlertProps, 'variant'>>`
    font-size: 12px;
    line-height: 16px;
    color: ${(props): string => textColor(props)};
`;

export const Close = styled.span`
    margin-left: auto;
    &:hover {
        cursor: pointer;
    }
`;

export const StyledInlineLink = styled(Typography.Link)`
    display: inline;
`;

export const StyledIconContainer = styled.div`
    min-width: 14px;
`;

export const StyledNav = styled(NavLink)`
    text-decoration: underline;
`;

// Helpers
function backgroundColor({
    variant,
    theme,
}: {
    variant?: VariantType;
    theme: DefaultTheme;
}): string {
    switch (variant) {
        case 'neutral':
            return `${theme.alert.neutral.background}`;
        case 'unhappy':
            return `${theme.alert.unhappy.background}`;
        case 'serious':
            return `${theme.alert.serious.background}`;
        case 'confirmation':
            return `${theme.alert.confirmation.background}`;
        default:
            return `${theme.alert.neutral.background}`;
    }
}

export function textColor({
    variant,
    theme,
}: {
    variant?: VariantType;
    theme: DefaultTheme;
}): string {
    switch (variant) {
        case 'neutral':
            return `${theme.alert.neutral.color}`;
        case 'unhappy':
            return `${theme.alert.unhappy.color}`;
        case 'serious':
            return `${theme.alert.serious.color}`;
        case 'confirmation':
            return `${theme.alert.confirmation.color}`;
        default:
            return `${theme.alert.neutral.color}`;
    }
}

export function iconColor({
    variant,
    theme,
}: {
    variant?: VariantType;
    theme: DefaultTheme;
}): string {
    switch (variant) {
        case 'neutral':
            return `${theme.alert.neutral.color}`;
        case 'unhappy':
            return `${theme.alert.unhappy.color}`;
        case 'serious':
            return `${theme.alert.serious.color}`;
        case 'confirmation':
            return `${theme.alert.confirmation.color}`;
        default:
            return `${theme.alert.neutral.color}`;
    }
}
