/**
 * Parse a URL query string into an object
 */
import { IQuery } from '../types/session.types';

const QUERY_PATTERN = /\?(.+)/;

export function parseQuery(url: string): IQuery {
    const query_data: IQuery = {};

    const query_match = QUERY_PATTERN.exec(url);

    if (query_match) {
        const params = query_match[1].split('&');

        for (const param of params) {
            const s = param.split('=');

            const k = s[0].toLowerCase();
            let val = s[1] || null;

            if (val === 'false' || val === 'null' || val === '0') {
                val = null;
            }

            query_data[k] = val;
        }
    }

    return query_data;
}

export function replaceUrlQuery(url: string, query_params: IQuery): string {
    const base_url = url.replace(QUERY_PATTERN, '');

    return `${base_url}?${stringifyQueryParams(query_params)}`;
}

export function appendQueryParameters(url: string, additional_query_params: IQuery): string {
    const base_url = url.replace(QUERY_PATTERN, '');

    return `${base_url}?${stringifyQueryParams({
        ...parseQuery(url),
        ...additional_query_params,
    })}`;
}

function stringifyQueryParams(query_params: IQuery): string {
    return Object.keys(query_params)
        .map(key => `${key}=${encodeURIComponent(query_params[key] as string)}`)
        .join('&');
}
