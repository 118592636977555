/**
 * Find the SDK embed script in the page HTML
 * - Used in both the SDK & the frontend-route
 */
import { getTagCollection } from '../../client-js/common/domutil';
import { Online } from '../../client-js/sdk/src/lib/common/globals';

/**  Globals  */
export const CDN_SRC_PATTERN = /^(http|https):\/\/([^\/]+?)\/js\/(sdk|sdk\.min|sdk\.min\.\d+)?\.js\?(id=|.+?&id=)[A-Za-z0-9-_]+?($|&)/;
export const LOCALHOST_SRC_PATTERN = /^(http):\/\/(localhost:[0-9]+)\/sdk\.min\.js\?(id=|.*&id=)([A-Za-z0-9-_]+?)($|&)/;
const SRC_DOMAIN_PATTERN = /(^|\.)(nicejob\.(co|com)|reviewly\.io)$/;

/**
 * Find the SDK embed script in the page HTML
 */
export function findSDKScript(): HTMLScriptElement | null {
    const scripts = getTagCollection('SCRIPT');

    for (const script of scripts as HTMLCollectionOf<HTMLScriptElement>) {
        const { src = '' } = script;

        /**
         * SRC_PATTERN:
         *  [2]: Full domain
         */
        const src_match = Online
            ? CDN_SRC_PATTERN.exec(src)
            : LOCALHOST_SRC_PATTERN.exec(src) || CDN_SRC_PATTERN.exec(src);

        if (src_match) {
            const src_domain = src_match[2];
            if (Online && !SRC_DOMAIN_PATTERN.test(src_domain)) {
                console.warn('src pattern rejected:', src, src_match);
                continue;
            }

            return script;
        }
    }

    return null;
}
