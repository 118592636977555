import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { IButtonIconProps, IButtonProps, IButtonStyleOverrideProps } from './Button';

/**
 * The styled <button /> component. The ButtonBase handles the styling
 * of the button component across the different states of the button.
 */

const padding = '6px 16px';
const padding_with_border = '5px 15px';

export const ButtonBase = styled.button<IButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: ${({ sx }): string | number => sx?.width || 'fit-content'};
    ${({ size }): FlattenSimpleInterpolation => buttonSize(size)}

    cursor: ${({ loading, disabled }): string => (loading || disabled ? 'default' : 'pointer')};

    outline: none;
    box-sizing: border-box;
    border-radius: 60px;

    ${(props): FlattenSimpleInterpolation => {
        const { theme, variant, success, error } = props;
        const { button } = theme;
        const { default: base, hover, active, disabled, loading } = button[variant];

        if (props.loading) {
            return css`
                color: ${loading.color};
                background: ${loading.background};
                border: ${loading.border || 'none'};
                padding: ${loading.border ? padding_with_border : padding};
            `;
        }

        if (success) {
            return css`
                color: ${theme.colors.green600};
                background: ${theme.colors.grey100};
                border: ${base.border || 'none'};
                padding: ${base.border ? padding_with_border : padding};
                cursor: default;
            `;
        }

        if (error) {
            return css`
                color: ${theme.colors.red600};
                background: ${theme.colors.grey100};
                border: ${base.border || 'none'};
                padding: ${base.border ? padding_with_border : padding};
                cursor: default;
            `;
        }

        return css`
            color: ${base.color};
            background: ${base.background};
            border: ${base.border || 'none'};
            padding: ${base.border ? padding_with_border : padding};

            &:hover {
                color: ${hover.color};
                background: ${hover.background};
                border: ${hover.border || 'none'};
                padding: ${hover.border ? padding_with_border : padding};
            }

            &:active {
                color: ${active.color};
                background: ${active.background};
                border: ${active.border || 'none'};
                padding: ${active.border ? padding_with_border : padding};
            }

            &:disabled {
                color: ${disabled.color};
                background: ${disabled.background};
                border: ${disabled.border || 'none'};
                padding: ${disabled.border ? padding_with_border : padding};
            }
        `;
    }}
`;

/**
 * Determines the height of the button based on the size prop
 * @param size - The size of the button, defaults to medium
 * @returns The css for the height of the button
 */
function buttonSize(size: IButtonProps['size']): FlattenSimpleInterpolation {
    switch (size) {
        case 'small':
            return css`
                height: 32px;
                font-size: 14px;
                line-height: 1;
            `;
        case 'large':
            return css`
                height: 44px;
                font-size: 16px;
                line-height: 1;
            `;
        case 'medium':
        default:
            return css`
                height: 36px;
                font-size: 16px;
                line-height: 1;
            `;
    }
}

/**
 * Wrapper for the button content (text and icon).
 *
 * This hides the content when the button is loading without
 * affecting the button's size. Additionally, it handles
 * the positioning of the icon relative to the text.
 */

export const ButtonContentWrapper = styled.span<
    Pick<IButtonProps, 'loading' | 'success' | 'error' | 'icon' | 'sx'>
>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${({ sx }): string => sx?.justifyContent || 'center'}};
    gap: 8px;
    flex-direction: ${(props): string =>
        props.icon?.position === 'right' ? 'row-reverse' : 'row'};

    visibility: ${({ loading, success, error }): string =>
        loading || success || error ? 'hidden' : 'visible'};
`;

/**
 * Wrapper for the button icon.
 *
 * This controls the size and color of the icon.
 *
 * Font Awesome
 * icons scale with the font size, while svg icons scale with
 * the width and height. This wrapper handles both cases by using
 * the size prop.
 *
 * Font Awesome icons use the color css property, while svg icons
 * use the fill css property. This wrapper handles both cases by
 * using the color prop.
 */

export const ButtonIconWrapper = styled.span<Pick<IButtonIconProps, 'size' | 'color'>>`
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    // handle Font Awesome icons
    font-size: ${({ size }): string => size || '1em'};

    // handle svg icons
    width: ${({ size }): string => size || '1em'};
    height: ${({ size }): string => size || '1em'};

    > svg {
        // handle Font Awesome icons
        color: ${({ color }): string => color || 'currentColor'};
        // handle svg icons
        fill: ${({ color }): string => color || 'currentColor'};
    }
`;

/**
 * Wrapper for the button display states (loading, success, error).
 *
 * Correctly positions the loading animation or icon in the center of the button.
 */

export const ButtonStateWrapper = styled.div`
    position: absolute;
`;
