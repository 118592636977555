import { theme } from '@nicejob-library/design-system';
import { AuthLoading } from '@nicejob-library/design-system/authentication';
import { Alert } from '@nicejob-library/design-system/core';
import { getLanguage, ILanguage, Trans, translate } from '@nicejob-library/internationalization';
import { ENGLISH_LANGUAGE } from '@nicejob-library/internationalization/data/locales/en';
import { FRENCH_LANGUAGE } from '@nicejob-library/internationalization/data/locales/fr';
import { initNiceJobSDK, trackClick, TrackingData } from '@nicejob-library/tracking';
import React, { ReactElement, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GIVEPOINT_URL } from '../../../../../../common';
import { IPaymentsLoginPageProps } from '../../../../types/login.types';
import {
    AuthErrorContainer,
    AuthErrorLink,
    AuthSideCar,
    LanguageSwitcher,
    PaymentsAuthCard,
    PaymentsAuthCardContent,
    PaymentsAuthCardHeader,
    PaymentsAuthContainer,
    PaymentsAuthContent,
    PaystoneDarkBanner,
    SideCarContent,
    SideCarMessage,
} from './PaymentsLogin.styles';
import { PaymentsLoginForm } from './PaymentsLoginForm';

/**
 * Login page for payments customers
 */
export function PaymentsLoginPage(props: IPaymentsLoginPageProps): ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <PaymentsLoginPageContent {...props} />
        </ThemeProvider>
    );
}

/**
 * Login page content for payments customers
 */
function PaymentsLoginPageContent(props: IPaymentsLoginPageProps): ReactElement {
    const { post_url, email, branding } = props;
    const { brand_name } = branding;

    const [is_loading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState<ILanguage | null>(null);

    const language_switcher_text =
        language === ENGLISH_LANGUAGE.language ? FRENCH_LANGUAGE.name : ENGLISH_LANGUAGE.name;

    useEffect(() => {
        initNiceJobSDK(process.env.REACT_APP_SDK_COMPANY_PUBLIC_KEY as string);
    }, []);

    useEffect(() => {
        // Initializing language from local storage must be done on the client as it is not available on the server.
        // Calling inside a useEffect hook ensures that it is only called on the client.
        const local_language = localStorage.getItem('language') as ILanguage;

        if (local_language) {
            setLanguage(local_language);
        } else {
            setLanguage(getLanguage());
        }
    }, []);

    /**
     * Set loading state when submitting the form
     */
    function onLogin(): void {
        setIsLoading(true);
    }

    /**
     * Update language in local storage and state
     */
    function onLanguageSwitch(): void {
        const new_language =
            language === ENGLISH_LANGUAGE.language
                ? FRENCH_LANGUAGE.language
                : ENGLISH_LANGUAGE.language;

        localStorage.setItem('language', new_language);

        setLanguage(new_language);
    }

    /**
     * Track click and navigate to givepoint
     */
    async function onNavigateToGivepoint(): Promise<void> {
        setIsLoading(true);

        const tracking_data: TrackingData = {
            type: 'link',
            id: 'givepoint_error',
            is_anonymous: true,
        };

        try {
            await trackClick(tracking_data);
        } catch (error) {
            console.log(error);
        }

        window.location.href = GIVEPOINT_URL;
    }

    return (
        <PaymentsAuthContainer>
            {is_loading && <AuthLoading />}

            {/* Page will flicker if initial state for language is different than local language.
            Therefore we initialize as null and wait for useEffect to fire before displaying content. */}

            {!!language && (
                <>
                    <PaymentsAuthContent is_loading={is_loading}>
                        <AuthSideCar>
                            <SideCarContent>
                                <PaystoneDarkBanner />
                                <SideCarMessage>
                                    {translate({
                                        namespace: 'login',
                                        key: 'payments.sidecar_message',
                                        language,
                                    })}
                                </SideCarMessage>
                            </SideCarContent>
                        </AuthSideCar>
                        <PaymentsAuthCard>
                            <PaymentsAuthCardContent>
                                <PaymentsAuthCardHeader>
                                    <LanguageSwitcher onClick={onLanguageSwitch}>
                                        {language_switcher_text}
                                    </LanguageSwitcher>
                                </PaymentsAuthCardHeader>
                                <PaymentsLoginForm
                                    brand_name={brand_name}
                                    post_url={post_url}
                                    email={email}
                                    onSubmit={onLogin}
                                    language={language}
                                    setIsLoading={setIsLoading}
                                />
                            </PaymentsAuthCardContent>
                        </PaymentsAuthCard>
                    </PaymentsAuthContent>

                    <AuthErrorContainer is_loading={is_loading}>
                        <Alert
                            variant='serious'
                            show_alert={!!props.error_message}
                            message={
                                <Trans
                                    namespace='login'
                                    k='login.error.givepoint_suffix'
                                    params={{ error: props.error_message || '' }}
                                    tOptions={{ lng: language }}
                                    components={{
                                        anchor: <AuthErrorLink onClick={onNavigateToGivepoint} />,
                                    }}
                                />
                            }
                        ></Alert>
                    </AuthErrorContainer>
                </>
            )}
        </PaymentsAuthContainer>
    );
}
