import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    APIDomain,
    awaitSDKSessionAndBuildClient,
    INiceJobApolloClient,
} from '@nicejob-library/apollo-client';
import { Paths } from '@nicejob-library/paths';
import React, { createContext, ReactElement, ReactNode, useEffect, useState } from 'react';
import rp from 'request-promise';
export interface IAuthContext {
    apollo_client: INiceJobApolloClient | null;
    csrf_retrieval_error: boolean;
    loading: boolean;
    fetchCSRFToken: () => Promise<void>;
}

export interface IAuthContextProps {
    children: ReactNode;
}

const default_auth_value = {
    apollo_client: null,
    csrf_retrieval_error: false,
    loading: true,
    fetchCSRFToken: async (): Promise<void> => {},
};

export const AuthContext = createContext<IAuthContext>(default_auth_value);

// eslint-disable-next-line @nicejob/snake-case
export const AuthContextConsumer = AuthContext.Consumer;

export function AuthContextProvider(props: IAuthContextProps): ReactElement {
    const [apollo_client, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
        null
    );
    const [csrf_retrieval_error, setCsrfRetrievalError] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);

    async function fetchCSRFToken(): Promise<void> {
        const response = await rp({
            method: 'GET',
            url: `${APIDomain()}/graphql/generate-csrf-token`,
            withCredentials: true,
            json: true,
            simple: false,
        });

        if (response.csrf_token) {
            //  Set client in state
            const client = await awaitSDKSessionAndBuildClient(
                response.csrf_token,
                APIDomain(),
                APIDomain({ websocket: true }),
                Paths.ONBOARDING_BASE
            );

            setApolloClient(client);

            setCsrfRetrievalError(false);
        } else {
            setCsrfRetrievalError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchCSRFToken();
    }, []);

    return (
        <AuthContext.Provider
            value={{ apollo_client, csrf_retrieval_error, loading, fetchCSRFToken }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}
