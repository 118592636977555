import { translate } from '@nicejob-library/internationalization';
import { ICountryData } from './types';

export function getCountries(): Array<ICountryData> {
    return [
        {
            alpha2: 'US',
            alpha3: 'USA',
            countryCallingCodes: ['+1'],
            currencies: ['USD'],
            ioc: 'USA',
            languages: ['eng'],
            name: 'United States',
            status: 'assigned',
            provinces: [
                {
                    short: 'AL',
                    name: 'Alabama',
                    alias: [],
                },
                {
                    short: 'AK',
                    name: 'Alaska',
                    alias: [],
                },
                {
                    short: 'AZ',
                    name: 'Arizona',
                    alias: [],
                },
                {
                    short: 'AR',
                    name: 'Arkansas',
                    alias: [],
                },
                {
                    short: 'CA',
                    name: 'California',
                    alias: [],
                },
                {
                    short: 'CO',
                    name: 'Colorado',
                    alias: [],
                },
                {
                    short: 'CT',
                    name: 'Connecticut',
                    alias: [],
                },
                {
                    short: 'DC',
                    name: 'District of Columbia',
                    alias: [],
                },
                {
                    short: 'DE',
                    name: 'Delaware',
                    alias: [],
                },
                {
                    short: 'FL',
                    name: 'Florida',
                    alias: [],
                },
                {
                    short: 'FM',
                    name: 'Micronesia',
                    alias: [],
                },
                {
                    short: 'GA',
                    name: 'Georgia',
                    alias: [],
                },
                {
                    short: 'HI',
                    name: 'Hawaii',
                    alias: [],
                },
                {
                    short: 'ID',
                    name: 'Idaho',
                    alias: [],
                },
                {
                    short: 'IL',
                    name: 'Illinois',
                    alias: [],
                },
                {
                    short: 'IN',
                    name: 'Indiana',
                    alias: [],
                },
                {
                    short: 'IA',
                    name: 'Iowa',
                    alias: [],
                },
                {
                    short: 'KS',
                    name: 'Kansas',
                    alias: [],
                },
                {
                    short: 'KY',
                    name: 'Kentucky',
                    alias: [],
                },
                {
                    short: 'LA',
                    name: 'Louisiana',
                    alias: [],
                },
                {
                    short: 'ME',
                    name: 'Maine',
                    alias: [],
                },
                {
                    short: 'MD',
                    name: 'Maryland',
                    alias: [],
                },
                {
                    short: 'MA',
                    name: 'Massachusetts',
                    alias: [],
                },
                {
                    short: 'MI',
                    name: 'Michigan',
                    alias: [],
                },
                {
                    short: 'MN',
                    name: 'Minnesota',
                    alias: [],
                },
                {
                    short: 'MH',
                    name: 'Marshall Islands',
                    alias: [],
                },
                {
                    short: 'MS',
                    name: 'Mississippi',
                    alias: [],
                },
                {
                    short: 'MO',
                    name: 'Missouri',
                    alias: [],
                },
                {
                    short: 'MT',
                    name: 'Montana',
                    alias: [],
                },
                {
                    short: 'NE',
                    name: 'Nebraska',
                    alias: [],
                },
                {
                    short: 'NV',
                    name: 'Nevada',
                    alias: [],
                },
                {
                    short: 'NH',
                    name: 'New Hampshire',
                    alias: [],
                },
                {
                    short: 'NJ',
                    name: 'New Jersey',
                    alias: [],
                },
                {
                    short: 'NM',
                    name: 'New Mexico',
                    alias: [],
                },
                {
                    short: 'NY',
                    name: 'New York',
                    alias: [],
                },
                {
                    short: 'NC',
                    name: 'North Carolina',
                    alias: [],
                },
                {
                    short: 'ND',
                    name: 'North Dakota',
                    alias: [],
                },
                {
                    short: 'OH',
                    name: 'Ohio',
                    alias: [],
                },
                {
                    short: 'OK',
                    name: 'Oklahoma',
                    alias: [],
                },
                {
                    short: 'OR',
                    name: 'Oregon',
                    alias: [],
                },
                {
                    short: 'PA',
                    name: 'Pennsylvania',
                    alias: [],
                },
                {
                    short: 'PW',
                    name: 'Palau',
                    alias: [],
                },
                {
                    short: 'RI',
                    name: 'Rhode Island',
                    alias: [],
                },
                {
                    short: 'SC',
                    name: 'South Carolina',
                    alias: [],
                },
                {
                    short: 'SD',
                    name: 'South Dakota',
                    alias: [],
                },
                {
                    short: 'TN',
                    name: 'Tennessee',
                    alias: [],
                },
                {
                    short: 'TX',
                    name: 'Texas',
                    alias: [],
                },
                {
                    short: 'UT',
                    name: 'Utah',
                    alias: [],
                },
                {
                    short: 'VT',
                    name: 'Vermont',
                    alias: [],
                },
                {
                    short: 'VA',
                    name: 'Virginia',
                    alias: [],
                },
                {
                    short: 'WA',
                    name: 'Washington',
                    alias: [],
                },
                {
                    short: 'WV',
                    name: 'West Virginia',
                    alias: [],
                },
                {
                    short: 'WI',
                    name: 'Wisconsin',
                    alias: [],
                },
                {
                    short: 'WY',
                    name: 'Wyoming',
                    alias: [],
                },
                {
                    short: 'AS',
                    name: 'American Samoa',
                    alias: [],
                },
                {
                    short: 'GU',
                    name: 'Guam',
                    alias: [],
                },
                {
                    short: 'MP',
                    name: 'Northern Mariana Islands',
                    alias: [],
                },
                {
                    short: 'PR',
                    name: 'Puerto Rico',
                    alias: [],
                },
                {
                    short: 'UM',
                    name: 'United States Minor Outlying Islands',
                    alias: [],
                },
                {
                    short: 'VI',
                    name: 'Virgin Islands',
                    alias: [],
                },
            ],
            alias: ['US', 'U.S', 'U.S.', 'USA', 'U.S.A', 'U.S.A.'],
        },
        {
            alpha2: 'CA',
            alpha3: 'CAN',
            countryCallingCodes: ['+1'],
            currencies: ['CAD'],
            ioc: 'CAN',
            languages: ['eng', 'fra'],
            name: 'Canada',
            status: 'assigned',
            provinces: [
                {
                    short: 'AB',
                    name: translate({ namespace: 'country', key: 'canada.province.alberta' }),
                    alias: [],
                },
                {
                    short: 'BC',
                    name: translate({
                        namespace: 'country',
                        key: 'canada.province.british_columbia',
                    }),
                    alias: [],
                },
                {
                    short: 'MB',
                    name: translate({ namespace: 'country', key: 'canada.province.manitoba' }),
                    alias: [],
                },
                {
                    short: 'NB',
                    name: translate({ namespace: 'country', key: 'canada.province.new_brunswick' }),
                    alias: [],
                },
                {
                    short: 'NL',
                    name: translate({
                        namespace: 'country',
                        key: 'canada.province.newfoundland_and_labrador',
                    }),
                    alias: ['Newfoundland', 'Labrador'],
                },
                {
                    short: 'NS',
                    name: translate({ namespace: 'country', key: 'canada.province.nova_scotia' }),
                    alias: [],
                },
                {
                    short: 'NU',
                    name: translate({ namespace: 'country', key: 'canada.province.nunavut' }),
                    alias: [],
                },
                {
                    short: 'NT',
                    name: translate({
                        namespace: 'country',
                        key: 'canada.province.northwest_territories',
                    }),
                    alias: [],
                },
                {
                    short: 'ON',
                    name: translate({ namespace: 'country', key: 'canada.province.ontario' }),
                    alias: [],
                },
                {
                    short: 'PE',
                    name: translate({
                        namespace: 'country',
                        key: 'canada.province.prince_edward_island',
                    }),
                    alias: [],
                },
                {
                    short: 'QC',
                    name: translate({ namespace: 'country', key: 'canada.province.quebec' }),
                    alias: [],
                },
                {
                    short: 'SK',
                    name: translate({ namespace: 'country', key: 'canada.province.saskatchewan' }),
                    alias: [],
                },
                {
                    short: 'YT',
                    name: translate({ namespace: 'country', key: 'canada.province.yukon' }),
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'AU',
            alpha3: 'AUS',
            countryCallingCodes: ['+61'],
            currencies: ['AUD'],
            ioc: 'AUS',
            languages: ['eng'],
            name: 'Australia',
            status: 'assigned',
            provinces: [
                {
                    name: 'Ashmore and Cartier Islands',
                    alias: [],
                },
                {
                    name: 'Australian Antarctic Territory',
                    alias: [],
                },
                {
                    short: 'ACT',
                    name: 'Australian Capital Territory',
                    alias: [],
                },
                {
                    short: 'CX',
                    name: 'Christmas Island',
                    alias: [],
                },
                {
                    short: 'CC',
                    name: 'Cocos Islands',
                    alias: ['Keeling Islands'],
                },
                {
                    name: 'Coral Sea Islands',
                    alias: [],
                },
                {
                    short: 'HM',
                    name: 'Heard Island and McDonald Islands',
                    alias: [],
                },
                {
                    short: 'JBT',
                    name: 'Jervis Bay Territory',
                    alias: [],
                },
                {
                    short: 'NSW',
                    name: 'New South Wales',
                    alias: [],
                },
                {
                    short: 'NF',
                    name: 'Norfolk Island',
                    alias: [],
                },
                {
                    short: 'NT',
                    name: 'Northern Territory',
                    alias: [],
                },
                {
                    short: 'QLD',
                    name: 'Queensland',
                    alias: [],
                },
                {
                    short: 'SA',
                    name: 'South Australia',
                    alias: [],
                },
                {
                    short: 'TAS',
                    name: 'Tasmania',
                    alias: [],
                },
                {
                    short: 'VIC',
                    name: 'Victoria',
                    alias: [],
                },
                {
                    short: 'WA',
                    name: 'Western Australia',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'NZ',
            alpha3: 'NZL',
            countryCallingCodes: ['+64'],
            currencies: ['NZD'],
            ioc: 'NZL',
            languages: ['eng'],
            name: 'New Zealand',
            status: 'assigned',
            provinces: [],
            alias: [],
        },

        {
            alpha2: 'GB',
            alpha3: 'GBR',
            countryCallingCodes: ['+44'],
            currencies: ['GBP'],
            ioc: 'GBR',
            languages: ['eng', 'cor', 'gle', 'gla', 'cym'],
            name: 'United Kingdom',
            status: 'assigned',
            provinces: [
                {
                    name: 'Avon',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Bedfordshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Berkshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Borders',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Bristol',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Buckinghamshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Cambridgeshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Channel Islands',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Cheshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Cleveland',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Cornwall',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Cumbria',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Derbyshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Devon',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Dorset',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Durham',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'East Riding of Yorkshire',
                    region: 'England',
                    alias: ['East Yorkshire'],
                },
                {
                    name: 'East Sussex',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Essex',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Gloucestershire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Greater Manchester',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Hampshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Herefordshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Hertfordshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Humberside',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Isle of Man',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Isle of Wight',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Isles of Scilly',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Kent',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Lancashire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Leicestershire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Lincolnshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'London',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Merseyside',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Middlesex',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Norfolk',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'North Yorkshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Northamptonshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Northumberland',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Nottinghamshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Oxfordshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Rutland',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Shropshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Somerset',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'South Yorkshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Staffordshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Suffolk',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Surrey',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Tyne and Wear',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Warwickshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'West Midlands',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'West Sussex',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'West Yorkshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Wiltshire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Worcestershire',
                    region: 'England',
                    alias: [],
                },
                {
                    name: 'Antrim',
                    region: 'Northern Ireland',
                    alias: [],
                },
                {
                    name: 'Down',
                    region: 'Northern Ireland',
                    alias: [],
                },
                {
                    name: 'Fermanagh',
                    region: 'Northern Ireland',
                    alias: [],
                },
                {
                    name: 'Londonderry',
                    region: 'Northern Ireland',
                    alias: [],
                },
                {
                    name: 'Tyrone',
                    region: 'Northern Ireland',
                    alias: [],
                },
                {
                    name: 'Aberdeen City',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Aberdeenshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Angus',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Argyll and Bute',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Armagh',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Carmarthenshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Clackmannan',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Dumfries and Galloway',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'East Ayrshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'East Dunbartonshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'East Lothian',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'East Renfrewshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Edinburgh City',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Falkirk',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Fife',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Glasgow',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Highland',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Inverclyde',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Midlothian',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Moray',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'North Ayrshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'North Lanarkshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Orkney',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Perthshire and Kinross',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Renfrewshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Roxburghshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Shetland',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'South Ayrshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'South Lanarkshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Stirling',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'West Dunbartonshire',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'West Lothian',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Western Isles',
                    region: 'Scotland',
                    alias: [],
                },
                {
                    name: 'Blaenau Gwent',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Bridgend',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Caerphilly',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Cardiff',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Ceredigion',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Conwy',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Denbighshire',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Flintshire',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Gwynedd',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Isle of Anglesey',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Merthyr Tydfil',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Monmouthshire',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Neath Port Talbot',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Newport',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Pembrokeshire',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Powys',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Rhondda Cynon Taff',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Swansea',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'The Vale of Glamorgan',
                    region: 'Wales',
                    alias: ['The Vale'],
                },
                {
                    name: 'Torfaen',
                    region: 'Wales',
                    alias: [],
                },
                {
                    name: 'Wrexham',
                    region: 'Wales',
                    alias: [],
                },
            ],
            alias: ['UK'],
        },
        {
            alpha2: 'AC',
            alpha3: '',
            countryCallingCodes: ['+247'],
            currencies: ['USD'],
            ioc: 'SHP',
            languages: ['eng'],
            name: 'Ascension Island',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AD',
            alpha3: 'AND',
            countryCallingCodes: ['+376'],
            currencies: ['EUR'],
            ioc: 'AND',
            languages: ['cat'],
            name: 'Andorra',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AE',
            alpha3: 'ARE',
            countryCallingCodes: ['+971'],
            currencies: ['AED'],
            ioc: 'UAE',
            languages: ['ara'],
            name: 'United Arab Emirates',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AF',
            alpha3: 'AFG',
            countryCallingCodes: ['+93'],
            currencies: ['AFN'],
            ioc: 'AFG',
            languages: ['pus'],
            name: 'Afghanistan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AG',
            alpha3: 'ATG',
            countryCallingCodes: ['+1 268'],
            currencies: ['XCD'],
            ioc: 'ANT',
            languages: ['eng'],
            name: 'Antigua And Barbuda',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AI',
            alpha3: 'AIA',
            countryCallingCodes: ['+1 264'],
            currencies: ['XCD'],
            ioc: '',
            languages: ['eng'],
            name: 'Anguilla',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AI',
            alpha3: 'AFI',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'French Afar and Issas',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AL',
            alpha3: 'ALB',
            countryCallingCodes: ['+355'],
            currencies: ['ALL'],
            ioc: 'ALB',
            languages: ['sqi'],
            name: 'Albania',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AM',
            alpha3: 'ARM',
            countryCallingCodes: ['+374'],
            currencies: ['AMD'],
            ioc: 'ARM',
            languages: ['hye', 'rus'],
            name: 'Armenia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AN',
            alpha3: 'ANT',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Netherlands Antilles',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AO',
            alpha3: 'AGO',
            countryCallingCodes: ['+244'],
            currencies: ['AOA'],
            ioc: 'ANG',
            languages: ['por'],
            name: 'Angola',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AQ',
            alpha3: 'ATA',
            countryCallingCodes: ['+672'],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Antarctica',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AR',
            alpha3: 'ARG',
            countryCallingCodes: ['+54'],
            currencies: ['ARS'],
            ioc: 'ARG',
            languages: ['spa'],
            name: 'Argentina',
            status: 'assigned',
            provinces: [
                {
                    name: 'Ciudad Autónoma de Buenos Aires',
                    alias: ['Ciudad Autonoma de Buenos Aires'],
                },
                {
                    name: 'Buenos Aires',
                    alias: [],
                },
                {
                    name: 'Catamarca',
                    alias: [],
                },
                {
                    name: 'Chaco',
                    alias: [],
                },
                {
                    name: 'Chubut',
                    alias: [],
                },
                {
                    name: 'Córdoba',
                    alias: ['Cordoba'],
                },
                {
                    name: 'Corrientes',
                    alias: [],
                },
                {
                    name: 'Entre Ríos',
                    alias: ['Entre Rios'],
                },
                {
                    name: 'Formosa',
                    alias: [],
                },
                {
                    name: 'Jujuy',
                    alias: [],
                },
                {
                    name: 'La Pampa',
                    alias: [],
                },
                {
                    name: 'La Rioja',
                    alias: [],
                },
                {
                    name: 'Mendoza',
                    alias: [],
                },
                {
                    name: 'Misiones',
                    alias: [],
                },
                {
                    name: 'Neuquén',
                    alias: ['Neuquen'],
                },
                {
                    name: 'Río Negro',
                    alias: ['Rio Negro'],
                },
                {
                    name: 'Salta',
                    alias: [],
                },
                {
                    name: 'San Juan',
                    alias: [],
                },
                {
                    name: 'San Luis',
                    alias: [],
                },
                {
                    name: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Santa Fe',
                    alias: [],
                },
                {
                    name: 'Santiago del Estero',
                    alias: [],
                },
                {
                    name: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
                    alias: ['Tierra del Fuego, Antartida e Islas del Atlantico Sur'],
                },
                {
                    name: 'Tucumán',
                    alias: ['Tucuman'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'AS',
            alpha3: 'ASM',
            countryCallingCodes: ['+1 684'],
            currencies: ['USD'],
            ioc: 'ASA',
            languages: ['eng', 'smo'],
            name: 'American Samoa',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AT',
            alpha3: 'AUT',
            countryCallingCodes: ['+43'],
            currencies: ['EUR'],
            ioc: 'AUT',
            languages: ['deu'],
            name: 'Austria',
            status: 'assigned',
            provinces: [
                {
                    short: 'B',
                    name: 'Burgenland',
                    alias: [],
                },
                {
                    short: 'K',
                    name: 'Kärnten',
                    alias: ['Karnten'],
                },
                {
                    short: 'NÖ',
                    name: 'Niederösterreich',
                    alias: ['Niederosterreich'],
                },
                {
                    short: 'OÖ',
                    name: 'Oberösterreich',
                    alias: ['Oberosterreich'],
                },
                {
                    short: 'S',
                    name: 'Salzburg',
                    alias: [],
                },
                {
                    short: 'ST',
                    name: 'Steiermark',
                    alias: [],
                },
                {
                    short: 'T',
                    name: 'Tirol',
                    alias: [],
                },
                {
                    short: 'V',
                    name: 'Vorarlberg',
                    alias: [],
                },
                {
                    short: 'W',
                    name: 'Wien',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'AW',
            alpha3: 'ABW',
            countryCallingCodes: ['+297'],
            currencies: ['AWG'],
            ioc: 'ARU',
            languages: ['nld'],
            name: 'Aruba',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'AX',
            alpha3: 'ALA',
            countryCallingCodes: ['+358'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['swe'],
            name: 'Åland Islands',
            status: 'assigned',
            provinces: [],
            alias: ['Aland Islands'],
        },
        {
            alpha2: 'AZ',
            alpha3: 'AZE',
            countryCallingCodes: ['+994'],
            currencies: ['AZN'],
            ioc: 'AZE',
            languages: ['aze'],
            name: 'Azerbaijan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BA',
            alpha3: 'BIH',
            countryCallingCodes: ['+387'],
            currencies: ['BAM'],
            ioc: 'BIH',
            languages: ['bos', 'cre', 'srp'],
            name: 'Bosnia and Herzegovina',
            status: 'assigned',
            provinces: [],
            alias: ['Bosnia & Herzegovina', 'B&H'],
        },
        {
            alpha2: 'BB',
            alpha3: 'BRB',
            countryCallingCodes: ['+1 246'],
            currencies: ['BBD'],
            ioc: 'BAR',
            languages: ['eng'],
            name: 'Barbados',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BD',
            alpha3: 'BGD',
            countryCallingCodes: ['+880'],
            currencies: ['BDT'],
            ioc: 'BAN',
            languages: ['ben'],
            name: 'Bangladesh',
            status: 'assigned',
            provinces: [
                {
                    name: 'বরগুনা',
                    region: 'Barisal',
                    alias: ['Barguna'],
                },
                {
                    name: 'বরিশাল',
                    region: 'Barisal',
                    alias: ['Barisal'],
                },
                {
                    name: 'ভোলা',
                    region: 'Barisal',
                    alias: ['Bhola'],
                },
                {
                    name: 'ঝালকাঠি',
                    region: 'Barisal',
                    alias: ['Jhalokati'],
                },
                {
                    name: 'পটুয়াখালী',
                    region: 'Barisal',
                    alias: ['Patuakhali'],
                },
                {
                    name: 'পিরোজপুর',
                    region: 'Barisal',
                    alias: ['Pirojpur'],
                },
                {
                    name: 'বান্দরবান',
                    region: 'Chittagong',
                    alias: ['Bandarban'],
                },
                {
                    name: 'ব্রাহ্মণবাড়ীয়া',
                    region: 'Chittagong',
                    alias: ['Brahmanbaria'],
                },
                {
                    name: 'চাঁদপুর',
                    region: 'Chittagong',
                    alias: ['Chandpur'],
                },
                {
                    name: 'চট্টগ্রাম',
                    region: 'Chittagong',
                    alias: ['Chittagong'],
                },
                {
                    name: 'কুমিল্লা',
                    region: 'Chittagong',
                    alias: ['Comilla'],
                },
                {
                    name: 'কক্সবাজার',
                    region: 'Chittagong',
                    alias: ["Cox's Bazar"],
                },
                {
                    name: 'ফেনী',
                    region: 'Chittagong',
                    alias: ['Feni'],
                },
                {
                    name: 'খাগড়াছড়ি',
                    region: 'Chittagong',
                    alias: ['Khagrachhari'],
                },
                {
                    name: 'লক্ষীপুর',
                    region: 'Chittagong',
                    alias: ['Lakshmipur'],
                },
                {
                    name: 'নোয়াখালী',
                    region: 'Chittagong',
                    alias: ['Noakhali'],
                },
                {
                    name: 'রাঙ্গামাটি',
                    region: 'Chittagong',
                    alias: ['Rangamati'],
                },
                {
                    name: 'ঢাকা',
                    region: 'Dhaka',
                    alias: ['Dhaka'],
                },
                {
                    name: 'ফরিদপুর',
                    region: 'Dhaka',
                    alias: ['Faridpur'],
                },
                {
                    name: 'গাজীপুর',
                    region: 'Dhaka',
                    alias: ['Gazipur'],
                },
                {
                    name: 'গোপালগঞ্জ',
                    region: 'Dhaka',
                    alias: ['Gopalganj'],
                },
                {
                    name: 'জামালপুর',
                    region: 'Dhaka',
                    alias: ['Jamalpur'],
                },
                {
                    name: 'কিশোরগঞ্জ',
                    region: 'Dhaka',
                    alias: ['Kishoreganj'],
                },
                {
                    name: 'মাদারীপুর',
                    region: 'Dhaka',
                    alias: ['Madaripur'],
                },
                {
                    name: 'মানিকগঞ্জ',
                    region: 'Dhaka',
                    alias: ['Manikganj'],
                },
                {
                    name: 'মুন্সীগঞ্জ',
                    region: 'Dhaka',
                    alias: ['Munshiganj'],
                },
                {
                    name: 'ময়মনসিংহ',
                    region: 'Dhaka',
                    alias: ['Mymensingh'],
                },
                {
                    name: 'নারায়ণগঞ্জ',
                    region: 'Dhaka',
                    alias: ['Narayanganj'],
                },
                {
                    name: 'নরসিংদী',
                    region: 'Dhaka',
                    alias: ['Narsingdi'],
                },
                {
                    name: 'নেত্রকোনা',
                    region: 'Dhaka',
                    alias: ['Netrakona'],
                },
                {
                    name: 'রাজবাড়ী',
                    region: 'Dhaka',
                    alias: ['Rajbari'],
                },
                {
                    name: 'শরীয়তপুর',
                    region: 'Dhaka',
                    alias: ['Shariatpur'],
                },
                {
                    name: 'শেরপুর',
                    region: 'Dhaka',
                    alias: ['Sherpur'],
                },
                {
                    name: 'টাঙ্গাইল',
                    region: 'Dhaka',
                    alias: ['Tangail'],
                },
                {
                    name: 'বাগেরহাট',
                    region: 'Khulna',
                    alias: ['Bagerhat'],
                },
                {
                    name: 'চুয়াডাঙ্গা',
                    region: 'Khulna',
                    alias: ['Chuadanga'],
                },
                {
                    name: 'যশোর',
                    region: 'Khulna',
                    alias: ['Jessore'],
                },
                {
                    name: 'ঝিনাইদহ',
                    region: 'Khulna',
                    alias: ['Jhenaidah'],
                },
                {
                    name: 'খুলনা',
                    region: 'Khulna',
                    alias: ['Khulna'],
                },
                {
                    name: 'কুষ্টিয়া',
                    region: 'Khulna',
                    alias: ['Kushtia'],
                },
                {
                    name: 'মাগুরা',
                    region: 'Khulna',
                    alias: ['Magura'],
                },
                {
                    name: 'মেহেরপুর',
                    region: 'Khulna',
                    alias: ['Meherpur'],
                },
                {
                    name: 'নড়াইল',
                    region: 'Khulna',
                    alias: ['Narail'],
                },
                {
                    name: 'সাতক্ষিরা',
                    region: 'Khulna',
                    alias: ['Satkhira'],
                },
                {
                    name: 'বগুড়া',
                    region: 'Rajshahi',
                    alias: ['Bogra'],
                },
                {
                    name: 'জয়পুরহাট',
                    region: 'Rajshahi',
                    alias: ['Joypurhat'],
                },
                {
                    name: 'নওগাঁ',
                    region: 'Rajshahi',
                    alias: ['Naogaon'],
                },
                {
                    name: 'নাটোর',
                    region: 'Rajshahi',
                    alias: ['Natore'],
                },
                {
                    name: 'নওয়াবগঞ্জ',
                    region: 'Rajshahi',
                    alias: ['Chapainawabganj'],
                },
                {
                    name: 'পাবনা',
                    region: 'Rajshahi',
                    alias: ['Pabna'],
                },
                {
                    name: 'রাজশাহী',
                    region: 'Rajshahi',
                    alias: ['Rajshahi'],
                },
                {
                    name: 'সিরাজগঞ্জ',
                    region: 'Rajshahi',
                    alias: ['Sirajganj'],
                },
                {
                    name: 'দিনাজপুর',
                    region: 'Rangpur',
                    alias: ['Dinajpur'],
                },
                {
                    name: 'গাইবান্ধা',
                    region: 'Rangpur',
                    alias: ['Gaibandha'],
                },
                {
                    name: 'কুড়িগ্রাম',
                    region: 'Rangpur',
                    alias: ['Kurigram'],
                },
                {
                    name: 'লালমনিরহাট',
                    region: 'Rangpur',
                    alias: ['Lalmonirhat'],
                },
                {
                    name: 'নীলফামারী',
                    region: 'Rangpur',
                    alias: ['Nilphamari'],
                },
                {
                    name: 'পঞ্চগড়',
                    region: 'Rangpur',
                    alias: ['Panchagarh'],
                },
                {
                    name: 'রংপুর',
                    region: 'Rangpur',
                    alias: ['Rangpur'],
                },
                {
                    name: 'ঠাকুরগাঁ',
                    region: 'Rangpur',
                    alias: ['Thakurgaon'],
                },
                {
                    name: 'হবিগঞ্জ',
                    region: 'Sylhet',
                    alias: ['Habiganj'],
                },
                {
                    name: 'মৌলভীবাজার',
                    region: 'Sylhet',
                    alias: ['Moulvibazar'],
                },
                {
                    name: 'সুনামগঞ্জ',
                    region: 'Sylhet',
                    alias: ['Sunamganj'],
                },
                {
                    name: 'সিলেট',
                    region: 'Sylhet',
                    alias: ['Sylhet'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'BE',
            alpha3: 'BEL',
            countryCallingCodes: ['+32'],
            currencies: ['EUR'],
            ioc: 'BEL',
            languages: ['nld', 'fra', 'deu'],
            name: 'Belgium',
            status: 'assigned',
            provinces: [
                {
                    short: 'ANT',
                    name: 'Antwerpen',
                    alias: [],
                },
                {
                    short: 'HAI',
                    name: 'Henegouwen',
                    alias: ['Hainaut'],
                },
                {
                    short: 'LIE',
                    name: 'Luik',
                    alias: ['Liège', 'Liege'],
                },
                {
                    short: 'LIM',
                    name: 'Limburg',
                    alias: [],
                },
                {
                    short: 'LUX',
                    name: 'Luxemburg',
                    alias: [],
                },
                {
                    short: 'NAM',
                    name: 'Namen',
                    alias: [],
                },
                {
                    short: 'OVL',
                    name: 'Oost-Vlaanderen',
                    alias: ['Oost Vlaanderen'],
                },
                {
                    short: 'VBR',
                    name: 'Vlaams-Brabant',
                    alias: ['Vlaams Brabant'],
                },
                {
                    short: 'WBR',
                    name: 'Waals-Brabant',
                    alias: ['Waals Brabant'],
                },
                {
                    short: 'WVL',
                    name: 'West-Vlaanderen',
                    alias: ['West Vlaanderen'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'BF',
            alpha3: 'BFA',
            countryCallingCodes: ['+226'],
            currencies: ['XOF'],
            ioc: 'BUR',
            languages: ['fra'],
            name: 'Burkina Faso',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BG',
            alpha3: 'BGR',
            countryCallingCodes: ['+359'],
            currencies: ['BGN'],
            ioc: 'BUL',
            languages: ['bul'],
            name: 'Bulgaria',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BH',
            alpha3: 'BHR',
            countryCallingCodes: ['+973'],
            currencies: ['BHD'],
            ioc: 'BRN',
            languages: ['ara'],
            name: 'Bahrain',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BI',
            alpha3: 'BDI',
            countryCallingCodes: ['+257'],
            currencies: ['BIF'],
            ioc: 'BDI',
            languages: ['fra'],
            name: 'Burundi',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BJ',
            alpha3: 'BEN',
            countryCallingCodes: ['+229'],
            currencies: ['XOF'],
            ioc: 'BEN',
            languages: ['fra'],
            name: 'Benin',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BL',
            alpha3: 'BLM',
            countryCallingCodes: ['+590'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'Saint Barthélemy',
            status: 'assigned',
            provinces: [],
            alias: ['Saint Barthelemy'],
        },
        {
            alpha2: 'BM',
            alpha3: 'BMU',
            countryCallingCodes: ['+1 441'],
            currencies: ['BMD'],
            ioc: 'BER',
            languages: ['eng'],
            name: 'Bermuda',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BN',
            alpha3: 'BRN',
            countryCallingCodes: ['+673'],
            currencies: ['BND'],
            ioc: 'BRU',
            languages: ['msa', 'eng'],
            name: 'Nation of Brunei, the Abode of Peace',
            status: 'assigned',
            provinces: [],
            alias: ['Negara Brunei Darussalam', 'Brunei Darussalam', 'Brunei'],
        },
        {
            alpha2: 'BO',
            alpha3: 'BOL',
            countryCallingCodes: ['+591'],
            currencies: ['BOB', 'BOV'],
            ioc: 'BOL',
            languages: ['spa', 'aym', 'que'],
            name: 'Bolivia, Plurinational State Of',
            status: 'assigned',
            provinces: [
                {
                    name: 'Cercado',
                    region: 'Beni',
                    alias: [],
                },
                {
                    name: 'Iténez',
                    region: 'Beni',
                    alias: ['Itenez'],
                },
                {
                    name: 'José Ballivián',
                    region: 'Beni',
                    alias: ['Jose Ballivian'],
                },
                {
                    name: 'Mamoré',
                    region: 'Beni',
                    alias: ['Mamore'],
                },
                {
                    name: 'Marbán',
                    region: 'Beni',
                    alias: ['Marban'],
                },
                {
                    name: 'Moxos',
                    region: 'Beni',
                    alias: [],
                },
                {
                    name: 'Vaca Díez',
                    region: 'Beni',
                    alias: ['Vaca Diez'],
                },
                {
                    name: 'Yacuma',
                    region: 'Beni',
                    alias: [],
                },
                {
                    name: 'Azurduy',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Belisario Boeto',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Hernando Siles',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Jaime Zudáñez',
                    region: 'Chuquisaca',
                    alias: ['Jaime Zudanez'],
                },
                {
                    name: 'Luis Calvo',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Nor Cinti',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Oropeza',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Sud Cinti',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Tomina',
                    region: 'Chuquisaca',
                    alias: [],
                },
                {
                    name: 'Yamparáez',
                    region: 'Chuquisaca',
                    alias: ['Yamparaez'],
                },
                {
                    name: 'Arani',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Arque',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Ayopaya',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Capinota',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Carrasco',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Cercado',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Chapare',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Esteban Arce',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Germán Jordán',
                    region: 'Cochabamba',
                    alias: ['German Jordan'],
                },
                {
                    name: 'Mizque',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Campero',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Punata',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Quillacollo',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Bolívar',
                    region: 'Cochabamba',
                    alias: ['Bolivar'],
                },
                {
                    name: 'Tapacarí',
                    region: 'Cochabamba',
                    alias: ['Tapacari'],
                },
                {
                    name: 'Tiraque',
                    region: 'Cochabamba',
                    alias: [],
                },
                {
                    name: 'Abel Iturralde',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Aroma',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Bautista Saavedra',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Caranavi',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Eliodoro Camacho',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Franz Tamayo',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Gualberto Villarroel',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Ingavi',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Inquisivi',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'José Manuel Pando',
                    region: 'La Paz',
                    alias: ['Jose Manuel Pando'],
                },
                {
                    name: 'Larecaja',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Loayza',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Los Andes',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Manco Kapac',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Muñecas',
                    region: 'La Paz',
                    alias: ['Munecas'],
                },
                {
                    name: 'Nor Yungas',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Omasuyos',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Pacajes',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Murillo',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Sud Yungas',
                    region: 'La Paz',
                    alias: [],
                },
                {
                    name: 'Atahuallpa',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Carangas',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Cercado',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Eduardo Avaroa',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Ladislao Cabrera',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Litoral',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Nor Carangas',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Pantaléon Dalence',
                    region: 'Oruro',
                    alias: ['Pantaleon Dalence'],
                },
                {
                    name: 'Poopó',
                    region: 'Oruro',
                    alias: ['Poopo'],
                },
                {
                    name: 'Puerto de Mejillones',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Sajama',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'San Pedro de Totora',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Saucarí',
                    region: 'Oruro',
                    alias: ['Saucari'],
                },
                {
                    name: 'Sebastián Pagador',
                    region: 'Oruro',
                    alias: ['Sebastian Pagador'],
                },
                {
                    name: 'Sud Carangas',
                    region: 'Oruro',
                    alias: [],
                },
                {
                    name: 'Tomas Barrón',
                    region: 'Oruro',
                    alias: ['Tomas Barron'],
                },
                {
                    name: 'Abuná',
                    region: 'Pando',
                    alias: ['Abuna'],
                },
                {
                    name: 'Federico Román',
                    region: 'Pando',
                    alias: ['Federico Roman'],
                },
                {
                    name: 'Madre de Dios',
                    region: 'Pando',
                    alias: [],
                },
                {
                    name: 'Manuripi',
                    region: 'Pando',
                    alias: [],
                },
                {
                    name: 'Nicolás Suárez',
                    region: 'Pando',
                    alias: ['Nicolas Suarez'],
                },
                {
                    name: 'Alonso de Ibáñez',
                    region: 'Potosí',
                    alias: ['Alonso de Ibanez'],
                },
                {
                    name: 'Antonio Quijarro',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Bernardino Bilbao',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Charcas',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Chayanta',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Cornelio Saavedra',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Daniel Campos',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Enrique Baldivieso',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'José María Linares',
                    region: 'Potosí',
                    alias: ['José Maria Linares'],
                },
                {
                    name: 'Modesto Omiste',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Nor Chichas',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Nor Lípez',
                    region: 'Potosí',
                    alias: ['Nor Lipez'],
                },
                {
                    name: 'Rafael Bustillo',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Sur Chichas',
                    region: 'Potosí',
                    alias: [],
                },
                {
                    name: 'Sur Lípez',
                    region: 'Potosí',
                    alias: ['Sur Lipez'],
                },
                {
                    name: 'Tomás Frías',
                    region: 'Potosí',
                    alias: ['Tomas Frias'],
                },
                {
                    name: 'Andrés Ibáñez',
                    region: 'Santa Cruz',
                    alias: ['Andres Ibanez'],
                },
                {
                    name: 'Ángel Sandoval',
                    region: 'Santa Cruz',
                    alias: ['Angel Sandoval'],
                },
                {
                    name: 'Chiquitos',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Cordillera',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Florida',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Germán Busch',
                    region: 'Santa Cruz',
                    alias: ['German Busch'],
                },
                {
                    name: 'Guarayos',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Ichilo',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Ignacio Warnes',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'José Miguel de Velasco',
                    region: 'Santa Cruz',
                    alias: ['Jose Miguel de Velasco'],
                },
                {
                    name: 'Manuel María Caballero',
                    region: 'Santa Cruz',
                    alias: ['Manuel Maria Caballero'],
                },
                {
                    name: 'Ñuflo de Chávez',
                    region: 'Santa Cruz',
                    alias: ['Nuflo de Chavez'],
                },
                {
                    name: 'Obispo Santistevan',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Sara',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Vallegrande',
                    region: 'Santa Cruz',
                    alias: [],
                },
                {
                    name: 'Aniceto Arce',
                    region: 'Tarija',
                    alias: [],
                },
                {
                    name: "Burnet O'Connor",
                    region: 'Tarija',
                    alias: [],
                },
                {
                    name: 'Cercado',
                    region: 'Tarija',
                    alias: [],
                },
                {
                    name: 'Eustaquio Méndez',
                    region: 'Tarija',
                    alias: ['Eustaquio Mendez'],
                },
                {
                    name: 'Gran Chaco',
                    region: 'Tarija',
                    alias: [],
                },
                {
                    name: 'José María Avilés',
                    region: 'Tarija',
                    alias: ['Jose María Aviles'],
                },
            ],
            alias: ['Bolivia'],
        },
        {
            alpha2: 'BQ',
            alpha3: 'BES',
            countryCallingCodes: ['+599'],
            currencies: ['USD'],
            ioc: '',
            languages: ['nld'],
            name: 'Bonaire, Saint Eustatius And Saba',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BQ',
            alpha3: 'ATB',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'British Antarctic Territory',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BR',
            alpha3: 'BRA',
            countryCallingCodes: ['+55'],
            currencies: ['BRL'],
            ioc: 'BRA',
            languages: ['por'],
            name: 'Brazil',
            status: 'assigned',
            provinces: [
                {
                    short: 'AC',
                    name: 'Acre',
                    alias: [],
                },
                {
                    short: 'AL',
                    name: 'Alagoas',
                    alias: [],
                },
                {
                    short: 'AP',
                    name: 'Amapá',
                    alias: ['Amapa'],
                },
                {
                    short: 'AM',
                    name: 'Amazonas',
                    alias: [],
                },
                {
                    short: 'BA',
                    name: 'Bahia',
                    alias: [],
                },
                {
                    short: 'CE',
                    name: 'Ceará',
                    alias: ['Ceara'],
                },
                {
                    short: 'DF',
                    name: 'Distrito Federal',
                    alias: [],
                },
                {
                    short: 'ES',
                    name: 'Espírito Santo',
                    alias: ['Espirito Santo'],
                },
                {
                    short: 'GO',
                    name: 'Goiás',
                    alias: ['Goias'],
                },
                {
                    short: 'MA',
                    name: 'Maranhão',
                    alias: ['Maranhao'],
                },
                {
                    short: 'MT',
                    name: 'Mato Grosso',
                    alias: [],
                },
                {
                    short: 'MS',
                    name: 'Mato Grosso do Sul',
                    alias: [],
                },
                {
                    short: 'MG',
                    name: 'Minas Gerais',
                    alias: [],
                },
                {
                    short: 'PA',
                    name: 'Pará',
                    alias: ['Para'],
                },
                {
                    short: 'PB',
                    name: 'Paraíba',
                    alias: ['Paraiba'],
                },
                {
                    short: 'PR',
                    name: 'Paraná',
                    alias: ['Parana'],
                },
                {
                    short: 'PE',
                    name: 'Pernambuco',
                    alias: [],
                },
                {
                    short: 'PI',
                    name: 'Piauí',
                    alias: ['Piaui'],
                },
                {
                    short: 'RJ',
                    name: 'Rio de Janeiro',
                    alias: [],
                },
                {
                    short: 'RN',
                    name: 'Rio Grande do Norte',
                    alias: [],
                },
                {
                    short: 'RS',
                    name: 'Rio Grande do Sul',
                    alias: [],
                },
                {
                    short: 'RO',
                    name: 'Rondônia',
                    alias: ['Rondonia'],
                },
                {
                    short: 'RR',
                    name: 'Roraima',
                    alias: [],
                },
                {
                    short: 'SC',
                    name: 'Santa Catarina',
                    alias: [],
                },
                {
                    short: 'SP',
                    name: 'São Paulo',
                    alias: ['Sao Paulo'],
                },
                {
                    short: 'SE',
                    name: 'Sergipe',
                    alias: [],
                },
                {
                    short: 'TO',
                    name: 'Tocantins',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'BS',
            alpha3: 'BHS',
            countryCallingCodes: ['+1 242'],
            currencies: ['BSD'],
            ioc: 'BAH',
            languages: ['eng'],
            name: 'Bahamas',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BT',
            alpha3: 'BTN',
            countryCallingCodes: ['+975'],
            currencies: ['INR', 'BTN'],
            ioc: 'BHU',
            languages: ['dzo'],
            name: 'Bhutan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BU',
            alpha3: 'BUR',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Burma',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BV',
            alpha3: 'BVT',
            countryCallingCodes: [],
            currencies: ['NOK'],
            ioc: '',
            languages: [],
            name: 'Bouvet Island',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BW',
            alpha3: 'BWA',
            countryCallingCodes: ['+267'],
            currencies: ['BWP'],
            ioc: 'BOT',
            languages: ['eng', 'tsn'],
            name: 'Botswana',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BY',
            alpha3: 'BLR',
            countryCallingCodes: ['+375'],
            currencies: ['BYR'],
            ioc: 'BLR',
            languages: ['bel', 'rus'],
            name: 'Belarus',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BY',
            alpha3: 'BYS',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Byelorussian SSR',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'BZ',
            alpha3: 'BLZ',
            countryCallingCodes: ['+501'],
            currencies: ['BZD'],
            ioc: 'BIZ',
            languages: ['eng'],
            name: 'Belize',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CC',
            alpha3: 'CCK',
            countryCallingCodes: ['+61'],
            currencies: ['AUD'],
            ioc: '',
            languages: ['eng'],
            name: 'Cocos (Keeling) Islands',
            status: 'assigned',
            provinces: [],
            alias: ['Cocos Islands', 'Keeling Islands'],
        },
        {
            alpha2: 'CD',
            alpha3: 'COD',
            countryCallingCodes: ['+243'],
            currencies: ['CDF'],
            ioc: 'COD',
            languages: ['fra', 'lin', 'kon', 'swa'],
            name: 'Democratic Republic Of Congo',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CF',
            alpha3: 'CAF',
            countryCallingCodes: ['+236'],
            currencies: ['XAF'],
            ioc: 'CAF',
            languages: ['fra', 'sag'],
            name: 'Central African Republic',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CG',
            alpha3: 'COG',
            countryCallingCodes: ['+242'],
            currencies: ['XAF'],
            ioc: 'CGO',
            languages: ['fra', 'lin'],
            name: 'Republic Of Congo',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CH',
            alpha3: 'CHE',
            countryCallingCodes: ['+41'],
            currencies: ['CHF', 'CHE', 'CHW'],
            ioc: 'SUI',
            languages: ['deu', 'fra', 'ita', 'roh'],
            name: 'Switzerland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CI',
            alpha3: 'CIV',
            countryCallingCodes: ['+225'],
            currencies: ['XOF'],
            ioc: 'CIV',
            languages: ['fra'],
            name: "Côte d'Ivoire",
            status: 'assigned',
            provinces: [],
            alias: ["Cote d'Ivoire", 'Ivory Coast'],
        },
        {
            alpha2: 'CK',
            alpha3: 'COK',
            countryCallingCodes: ['+682'],
            currencies: ['NZD'],
            ioc: 'COK',
            languages: ['eng', 'mri'],
            name: 'Cook Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CL',
            alpha3: 'CHL',
            countryCallingCodes: ['+56'],
            currencies: ['CLP', 'CLF'],
            ioc: 'CHI',
            languages: ['spa'],
            name: 'Chile',
            status: 'assigned',
            provinces: [
                {
                    name: 'Arica',
                    region: 'XV Arica and Parinacota',
                    alias: [],
                },
                {
                    name: 'Parinacota',
                    region: 'XV Arica and Parinacota',
                    alias: [],
                },
                {
                    name: 'Iquique',
                    region: 'I Tarapacá',
                    alias: [],
                },
                {
                    name: 'Tamarugal',
                    region: 'I Tarapacá',
                    alias: [],
                },
                {
                    name: 'Antofagasta',
                    region: 'II Antofagasta',
                    alias: [],
                },
                {
                    name: 'El Loa',
                    region: 'II Antofagasta',
                    alias: [],
                },
                {
                    name: 'Tocopilla',
                    region: 'II Antofagasta',
                    alias: [],
                },
                {
                    name: 'Copiapó',
                    region: 'III Atacama',
                    alias: ['Copiapo'],
                },
                {
                    name: 'Huasco',
                    region: 'III Atacama',
                    alias: [],
                },
                {
                    name: 'Chañaral',
                    region: 'III Atacama',
                    alias: ['Chanaral'],
                },
                {
                    name: 'Elqui',
                    region: 'IV Coquimbo',
                    alias: [],
                },
                {
                    name: 'Limarí',
                    region: 'IV Coquimbo',
                    alias: ['Limari'],
                },
                {
                    name: 'Choapa',
                    region: 'IV Coquimbo',
                    alias: [],
                },
                {
                    name: 'Isla de Pascua',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'Los Andes',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'Marga Marga',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'Petorca',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'Quillota',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'San Antonio',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'San Felipe de Aconcagua',
                    region: 'V Valparaíso',
                    alias: [],
                },
                {
                    name: 'Valparaíso',
                    region: 'V Valparaíso',
                    alias: ['Valparaiso'],
                },
                {
                    name: 'Cachapoal',
                    region: "VI O'Higgins",
                    alias: [],
                },
                {
                    name: 'Colchagua',
                    region: "VI O'Higgins",
                    alias: [],
                },
                {
                    name: 'Cardenal Caro',
                    region: "VI O'Higgins",
                    alias: [],
                },
                {
                    name: 'Talca',
                    region: 'VII Maule',
                    alias: [],
                },
                {
                    name: 'Linares',
                    region: 'VII Maule',
                    alias: [],
                },
                {
                    name: 'Curicó',
                    region: 'VII Maule',
                    alias: ['Curico'],
                },
                {
                    name: 'Cauquenes',
                    region: 'VII Maule',
                    alias: [],
                },
                {
                    name: 'Concepción',
                    region: 'VIII Biobío',
                    alias: ['Concepcion'],
                },
                {
                    name: 'Ñuble',
                    region: 'VIII Biobío',
                    alias: ['Nuble'],
                },
                {
                    name: 'Biobío',
                    region: 'VIII Biobío',
                    alias: ['Biobio'],
                },
                {
                    name: 'Arauco',
                    region: 'VIII Biobío',
                    alias: [],
                },
                {
                    name: 'Cautin',
                    region: 'IX Araucanía',
                    alias: [],
                },
                {
                    name: 'Malleco',
                    region: 'IX Araucanía',
                    alias: [],
                },
                {
                    name: 'Valdivia',
                    region: 'XIV Los Ríos',
                    alias: [],
                },
                {
                    name: 'Ranco',
                    region: 'XIV Los Ríos',
                    alias: [],
                },
                {
                    name: 'Llanquihue',
                    region: 'X Los Lagos',
                    alias: [],
                },
                {
                    name: 'Osorno',
                    region: 'X Los Lagos',
                    alias: [],
                },
                {
                    name: 'Chiloe',
                    region: 'X Los Lagos',
                    alias: [],
                },
                {
                    name: 'Palena',
                    region: 'X Los Lagos',
                    alias: [],
                },
                {
                    name: 'Coihaique',
                    region: 'XI Aisén',
                    alias: [],
                },
                {
                    name: 'Aisén',
                    region: 'XI Aisén',
                    alias: ['Aisen'],
                },
                {
                    name: 'General Carrera',
                    region: 'XI Aisén',
                    alias: [],
                },
                {
                    name: 'Capitan Prat',
                    region: 'XI Aisén',
                    alias: [],
                },
                {
                    name: 'Magallanes',
                    region: 'XII Magallanes',
                    alias: [],
                },
                {
                    name: 'Ultima Esperanza',
                    region: 'XII Magallanes',
                    alias: [],
                },
                {
                    name: 'Tierra del Fuego',
                    region: 'XII Magallanes',
                    alias: [],
                },
                {
                    name: 'Antártica Chilena',
                    region: 'XII Magallanes',
                    alias: ['Antartica Chilena'],
                },
                {
                    name: 'Santiago',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
                {
                    name: 'Cordillera',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
                {
                    name: 'Maipo',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
                {
                    name: 'Talagante',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
                {
                    name: 'Melipilla',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
                {
                    name: 'Chacabuco',
                    region: 'RM Santiago Metropolitan',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'CM',
            alpha3: 'CMR',
            countryCallingCodes: ['+237'],
            currencies: ['XAF'],
            ioc: 'CMR',
            languages: ['eng', 'fra'],
            name: 'Cameroon',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CN',
            alpha3: 'CHN',
            countryCallingCodes: ['+86'],
            currencies: ['CNY'],
            ioc: 'CHN',
            languages: ['zho'],
            name: 'China',
            status: 'assigned',
            provinces: [
                {
                    name: 'Chongqing',
                    short: 'CQ',
                    alias: ['重庆', '渝', 'Chongqing Shi'],
                },
                {
                    name: 'Heilongjiang',
                    short: 'HL',
                    alias: ['黑龙江', '黑', 'Heilongjiang Sheng'],
                },
                {
                    name: 'Jilin',
                    short: 'JL',
                    alias: ['吉林', '吉', 'Jilin Sheng'],
                },
                {
                    name: 'Hainan',
                    short: 'HI',
                    alias: ['海南', '琼', 'Hainan Sheng'],
                },
                {
                    name: 'Beijing',
                    short: 'BJ',
                    alias: ['北京', '京', 'Beijing Shi'],
                },
                {
                    name: 'Liaoning',
                    short: 'LN',
                    alias: ['辽宁', '辽', 'Liaoning Sheng'],
                },
                {
                    name: 'Inner Mongolia',
                    short: 'NM',
                    alias: ['内蒙古', '蒙', 'Nei Menggu Zizhiqu'],
                },
                {
                    name: 'Tibet',
                    short: 'XZ',
                    alias: ['Xizang', '西藏', '藏', 'Xizang Zizhiqu'],
                },
                {
                    name: 'Qinghai',
                    short: 'QH',
                    alias: ['青海', '青', 'Qinghai Sheng'],
                },
                {
                    name: 'Ningxia',
                    short: 'NX',
                    alias: ['宁夏', '宁', 'Ningxia Huizu Zizhiqu'],
                },
                {
                    name: 'Xinjiang',
                    short: 'XJ',
                    alias: ['新疆', '新', 'Xinjiang Weiwuer Zizhiqu'],
                },
                {
                    name: 'Gansu',
                    short: 'GS',
                    alias: ['甘肃', '甘', 'Gansu Sheng'],
                },
                {
                    name: 'Hebei',
                    short: 'HE',
                    alias: ['河北', '冀', 'Hebei Sheng'],
                },
                {
                    name: 'Henan',
                    short: 'HA',
                    alias: ['河南', '豫', 'Henan Sheng'],
                },
                {
                    name: 'Hubei',
                    short: 'HB',
                    alias: ['湖北', '鄂', 'Hubei Sheng'],
                },
                {
                    name: 'Hunan',
                    short: 'HN',
                    alias: ['湖南', '湘', 'Hunan Sheng'],
                },
                {
                    name: 'Shandong',
                    short: 'SD',
                    alias: ['山东', '鲁', 'Shandong Sheng'],
                },
                {
                    name: 'Jiangsu',
                    short: 'JS',
                    alias: ['江苏', '苏', 'Jiangsu Sheng'],
                },
                {
                    name: 'Anhui',
                    short: 'AH',
                    alias: ['安徽', '皖', 'Anhui Sheng'],
                },
                {
                    name: 'Shanxi',
                    short: 'SX',
                    alias: ['山西', '晋', 'Shānxī Sheng'],
                },
                {
                    name: 'Shaanxi',
                    short: 'SN',
                    alias: ['陕西', '陕', 'Shǎnxī Sheng'],
                },
                {
                    name: 'Sichuan',
                    short: 'SC',
                    alias: ['四川', '川', 'Sichuan Sheng'],
                },
                {
                    name: 'Yunnan',
                    short: 'YN',
                    alias: ['云南', '滇', 'Yunnan Sheng'],
                },
                {
                    name: 'Guizhou',
                    short: 'GZ',
                    alias: ['贵州', '黔', 'Guizhou Sheng'],
                },
                {
                    name: 'Zhejiang',
                    short: 'ZJ',
                    alias: ['浙江', '浙', 'Zhejiang Sheng'],
                },
                {
                    name: 'Fujian',
                    short: 'FJ',
                    alias: ['福建', '闽', 'Fujian Sheng'],
                },
                {
                    name: 'Guangxi',
                    short: 'GX',
                    alias: ['广西', '桂', 'Guangxi Zhuangzu Zizhiqu'],
                },
                {
                    name: 'Shanghai',
                    short: 'SH',
                    alias: ['上海', '沪', 'Shanghai Shi'],
                },
                {
                    name: 'Tianjin',
                    short: 'TJ',
                    alias: ['天津', '津', 'Tianjin Shi'],
                },
                {
                    name: 'Hong Kong',
                    short: 'HK',
                    alias: ['Xianggang', '香港', '港', 'Xianggang Tebie Xingzhengqu'],
                },
                {
                    name: 'Macau',
                    short: 'MC',
                    alias: ['Aomen', '澳门', '澳', 'Aomen Tebie Xingzhengqu'],
                },
                {
                    name: 'Taiwan',
                    short: 'TW',
                    alias: ['台湾', '台', 'Taiwan Shi'],
                },
                {
                    name: 'Jiangxi',
                    short: 'JX ',
                    alias: ['江西', '赣', 'Jiangxi Sheng'],
                },
                {
                    name: 'Guangdong',
                    short: 'GD',
                    alias: ['广东', '粤', 'Guangdong Sheng'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'CO',
            alpha3: 'COL',
            countryCallingCodes: ['+57'],
            currencies: ['COP', 'COU'],
            ioc: 'COL',
            languages: ['spa'],
            name: 'Colombia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CP',
            alpha3: '',
            countryCallingCodes: [],
            currencies: ['EUR'],
            ioc: '',
            languages: [],
            name: 'Clipperton Island',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CR',
            alpha3: 'CRI',
            countryCallingCodes: ['+506'],
            currencies: ['CRC'],
            ioc: 'CRC',
            languages: ['spa'],
            name: 'Costa Rica',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CS',
            alpha3: 'CSK',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Czechoslovakia',
            status: 'deleted',
            provinces: [],
            alias: ['Czecho-Slovakia'],
        },
        {
            alpha2: 'CS',
            alpha3: 'SCG',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Serbia and Montenegro',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CT',
            alpha3: 'CTE',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Canton and Enderbury Islands',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CU',
            alpha3: 'CUB',
            countryCallingCodes: ['+53'],
            currencies: ['CUP', 'CUC'],
            ioc: 'CUB',
            languages: ['spa'],
            name: 'Cuba',
            status: 'assigned',
            provinces: [
                {
                    name: 'Camagüey',
                    alias: ['Camaguey'],
                },
                {
                    name: 'Ciego de Ávila',
                    alias: ['Ciego de Avila'],
                },
                {
                    name: 'Cienfuegos',
                    alias: [],
                },
                {
                    name: 'Havana',
                    alias: [],
                },
                {
                    name: 'Bayamo',
                    alias: [],
                },
                {
                    name: 'Guantánamo',
                    alias: ['Guantanamo'],
                },
                {
                    name: 'Holguín',
                    alias: ['Holguin'],
                },
                {
                    name: 'Nueva Gerona',
                    alias: [],
                },
                {
                    name: 'Artemisa',
                    alias: [],
                },
                {
                    name: 'Las Tunas',
                    alias: [],
                },
                {
                    name: 'Matanzas',
                    alias: [],
                },
                {
                    name: 'San José de las Lajas',
                    alias: ['San Jose de las Lajas'],
                },
                {
                    name: 'Pinar del Río',
                    alias: ['Pinar del Rio'],
                },
                {
                    name: 'Sancti Spíritus',
                    alias: ['Sancti Spiritus'],
                },
                {
                    name: 'Santiago de Cuba',
                    alias: [],
                },
                {
                    name: 'Santa Clara',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'CV',
            alpha3: 'CPV',
            countryCallingCodes: ['+238'],
            currencies: ['CVE'],
            ioc: 'CPV',
            languages: ['por'],
            name: 'Cape Verde',
            status: 'assigned',
            provinces: [],
            alias: ['Cabo Verde'],
        },
        {
            alpha2: 'CW',
            alpha3: 'CUW',
            countryCallingCodes: ['+599'],
            currencies: ['ANG'],
            ioc: '',
            languages: ['nld'],
            name: 'Curacao',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CX',
            alpha3: 'CXR',
            countryCallingCodes: ['+61'],
            currencies: ['AUD'],
            ioc: '',
            languages: ['eng'],
            name: 'Christmas Island',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CY',
            alpha3: 'CYP',
            countryCallingCodes: ['+357'],
            currencies: ['EUR'],
            ioc: 'CYP',
            languages: ['ell', 'tur'],
            name: 'Cyprus',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'CZ',
            alpha3: 'CZE',
            countryCallingCodes: ['+420'],
            currencies: ['CZK'],
            ioc: 'CZE',
            languages: ['ces'],
            name: 'Czech Republic',
            status: 'assigned',
            provinces: [],
            alias: ['Czechia'],
        },
        {
            alpha2: 'DD',
            alpha3: 'DDR',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'German Democratic Republic',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DE',
            alpha3: 'DEU',
            countryCallingCodes: ['+49'],
            currencies: ['EUR'],
            ioc: 'GER',
            languages: ['deu'],
            name: 'Germany',
            status: 'assigned',
            provinces: [
                {
                    short: 'BW',
                    name: 'Baden-Württemberg',
                    alias: ['Baden-Wurttemberg', 'Baden Württemberg', 'Baden Wurttemberg'],
                },
                {
                    short: 'BY',
                    name: 'Bayern',
                    alias: [],
                },
                {
                    short: 'BE',
                    name: 'Berlin',
                    alias: [],
                },
                {
                    short: 'BB',
                    name: 'Brandenburg',
                    alias: [],
                },
                {
                    short: 'HB',
                    name: 'Bremen',
                    alias: [],
                },
                {
                    short: 'HH',
                    name: 'Hamburg',
                    alias: [],
                },
                {
                    short: 'HE',
                    name: 'Hessen',
                    alias: [],
                },
                {
                    short: 'MV',
                    name: 'Mecklenburg-Vorpommern',
                    alias: ['Mecklenburg Vorpommern'],
                },
                {
                    short: 'NI',
                    name: 'Niedersachsen',
                    alias: [],
                },
                {
                    short: 'NW',
                    name: 'Nordrhein-Westfalen',
                    alias: ['Nordrhein Westfalen'],
                },
                {
                    short: 'RP',
                    name: 'Rheinland-Pfalz',
                    alias: ['Rheinland Pfalz'],
                },
                {
                    short: 'SL',
                    name: 'Saarland',
                    alias: [],
                },
                {
                    short: 'SN',
                    name: 'Sachsen',
                    alias: [],
                },
                {
                    short: 'ST',
                    name: 'Sachsen-Anhalt',
                    alias: ['Sachsen Anhalt'],
                },
                {
                    short: 'SH',
                    name: 'Schleswig-Holstein',
                    alias: ['Schleswig Holstein'],
                },
                {
                    short: 'TH',
                    name: 'Thüringen',
                    alias: ['Thuringen'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'DG',
            alpha3: '',
            countryCallingCodes: [],
            currencies: ['USD'],
            ioc: '',
            languages: [],
            name: 'Diego Garcia',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DJ',
            alpha3: 'DJI',
            countryCallingCodes: ['+253'],
            currencies: ['DJF'],
            ioc: 'DJI',
            languages: ['ara', 'fra'],
            name: 'Djibouti',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DK',
            alpha3: 'DNK',
            countryCallingCodes: ['+45'],
            currencies: ['DKK'],
            ioc: 'DEN',
            languages: ['dan'],
            name: 'Denmark',
            status: 'assigned',
            provinces: [
                {
                    name: 'Hovedstaden',
                    alias: [],
                },
                {
                    name: 'Midtjylland',
                    alias: [],
                },
                {
                    name: 'Nordjylland',
                    alias: [],
                },
                {
                    name: 'Sjælland',
                    alias: ['Zealand'],
                },
                {
                    name: 'Syddanmark',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'DM',
            alpha3: 'DMA',
            countryCallingCodes: ['+1 767'],
            currencies: ['XCD'],
            ioc: 'DMA',
            languages: ['eng'],
            name: 'Dominica',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DO',
            alpha3: 'DOM',
            countryCallingCodes: ['+1 809', '+1 829', '+1 849'],
            currencies: ['DOP'],
            ioc: 'DOM',
            languages: ['spa'],
            name: 'Dominican Republic',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DY',
            alpha3: 'DHY',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Dahomey',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'DZ',
            alpha3: 'DZA',
            countryCallingCodes: ['+213'],
            currencies: ['DZD'],
            ioc: 'ALG',
            languages: ['ara'],
            name: 'Algeria',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'EA',
            alpha3: '',
            countryCallingCodes: [],
            currencies: ['EUR'],
            ioc: '',
            languages: [],
            name: 'Ceuta, Mulilla',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'EC',
            alpha3: 'ECU',
            countryCallingCodes: ['+593'],
            currencies: ['USD'],
            ioc: 'ECU',
            languages: ['spa', 'que'],
            name: 'Ecuador',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'EE',
            alpha3: 'EST',
            countryCallingCodes: ['+372'],
            currencies: ['EUR'],
            ioc: 'EST',
            languages: ['est'],
            name: 'Estonia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'EG',
            alpha3: 'EGY',
            countryCallingCodes: ['+20'],
            currencies: ['EGP'],
            ioc: 'EGY',
            languages: ['ara'],
            name: 'Egypt',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'EH',
            alpha3: 'ESH',
            countryCallingCodes: ['+212'],
            currencies: ['MAD'],
            ioc: '',
            languages: [],
            name: 'Western Sahara',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ER',
            alpha3: 'ERI',
            countryCallingCodes: ['+291'],
            currencies: ['ERN'],
            ioc: 'ERI',
            languages: ['eng', 'ara', 'tir'],
            name: 'Eritrea',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ES',
            alpha3: 'ESP',
            countryCallingCodes: ['+34'],
            currencies: ['EUR'],
            ioc: 'ESP',
            languages: ['spa'],
            name: 'Spain',
            status: 'assigned',
            provinces: [
                {
                    name: 'La Coruña',
                    short: 'C',
                    alias: ['La Coruna'],
                },
                {
                    name: 'Lugo',
                    short: 'LU',
                    alias: [],
                },
                {
                    name: 'Vizcaya',
                    short: 'BI',
                    alias: [],
                },
                {
                    name: 'Guipúzcoa',
                    short: 'SS',
                    alias: ['Guipuzcoa'],
                },
                {
                    name: 'Huesca',
                    short: 'HU',
                    alias: [],
                },
                {
                    name: 'Lérida',
                    short: 'L',
                    alias: ['Lerida'],
                },
                {
                    name: 'Gerona',
                    short: 'GI',
                    alias: [],
                },
                {
                    name: 'Barcelona',
                    short: 'B',
                    alias: [],
                },
                {
                    name: 'Tarragona',
                    short: 'T',
                    alias: [],
                },
                {
                    name: 'Castellón',
                    short: 'CS',
                    alias: ['Castellon'],
                },
                {
                    name: 'Valencia',
                    short: 'V',
                    alias: [],
                },
                {
                    name: 'Alicante',
                    short: 'A',
                    alias: [],
                },
                {
                    name: 'Murcia',
                    short: 'MU',
                    alias: [],
                },
                {
                    name: 'Zaragoza',
                    short: 'Z',
                    alias: [],
                },
                {
                    name: 'Teruel',
                    short: 'TE',
                    alias: [],
                },
                {
                    name: 'Cuenca',
                    short: 'CU',
                    alias: [],
                },
                {
                    name: 'Albacete',
                    short: 'AB',
                    alias: [],
                },
                {
                    name: 'Almeria',
                    short: 'AL',
                    alias: [],
                },
                {
                    name: 'Granada',
                    short: 'GR',
                    alias: [],
                },
                {
                    name: 'Málaga',
                    short: 'MA',
                    alias: ['Malaga'],
                },
                {
                    name: 'Tenerife',
                    short: 'TF',
                    alias: [],
                },
                {
                    name: 'Cádiz',
                    short: 'CA',
                    alias: ['Cadiz'],
                },
                {
                    name: 'Sevilla',
                    short: 'SE',
                    alias: [],
                },
                {
                    name: 'Huelva',
                    short: 'H',
                    alias: [],
                },
                {
                    name: 'Las Palmas',
                    short: 'GC',
                    alias: [],
                },
                {
                    name: 'Madrid',
                    short: 'M',
                    alias: [],
                },
                {
                    name: 'Badajoz',
                    short: 'BA',
                    alias: [],
                },
                {
                    name: 'Cáceres',
                    short: 'CC',
                    alias: ['Caceres'],
                },
                {
                    name: 'Toledo',
                    short: 'TO',
                    alias: [],
                },
                {
                    name: 'Ciudad Real',
                    short: 'CR',
                    alias: [],
                },
                {
                    name: 'Salamanca',
                    short: 'SA',
                    alias: [],
                },
                {
                    name: 'Córdoba',
                    short: 'CO',
                    alias: ['Cordoba'],
                },
                {
                    name: 'Jaén',
                    short: 'J',
                    alias: ['Jaen'],
                },
                {
                    name: 'Ávila',
                    short: 'AV',
                    alias: ['Avila'],
                },
                {
                    name: 'Valladolid',
                    short: 'VA',
                    alias: [],
                },
                {
                    name: 'Zamora',
                    short: 'ZA',
                    alias: [],
                },
                {
                    name: 'Álava',
                    short: 'VI',
                    alias: ['Alava'],
                },
                {
                    name: 'Segovia',
                    short: 'SG',
                    alias: [],
                },
                {
                    name: 'Burgos',
                    short: 'BU',
                    alias: [],
                },
                {
                    name: 'Pontevedra',
                    short: 'PO',
                    alias: [],
                },
                {
                    name: 'León',
                    short: 'LE',
                    alias: ['Leon'],
                },
                {
                    name: 'Orense',
                    short: 'OU',
                    alias: [],
                },
                {
                    name: 'Palencia',
                    short: 'P',
                    alias: [],
                },
                {
                    name: 'La Rioja',
                    short: 'LO',
                    alias: [],
                },
                {
                    name: 'Soria',
                    short: 'SO',
                    alias: [],
                },
                {
                    name: 'Guadalajara',
                    short: 'GU',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'ET',
            alpha3: 'ETH',
            countryCallingCodes: ['+251'],
            currencies: ['ETB'],
            ioc: 'ETH',
            languages: ['amh'],
            name: 'Ethiopia',
            status: 'assigned',
            provinces: [
                {
                    name: 'Addis Ababa',
                    alias: [],
                },
                {
                    name: 'Afar Region',
                    alias: [],
                },
                {
                    name: 'Amhara Region',
                    alias: [],
                },
                {
                    name: 'Benishangul-Gumuz',
                    alias: ['Benishangul Gumuz'],
                },
                {
                    name: 'Dire Dawa',
                    alias: [],
                },
                {
                    name: 'Gambela',
                    alias: [],
                },
                {
                    name: 'Harari',
                    alias: [],
                },
                {
                    name: 'Oromia',
                    alias: [],
                },
                {
                    name: 'Somali',
                    alias: [],
                },
                {
                    name: "Southern Nations, Nationalities, and Peoples' Region",
                    alias: ['SNNPR'],
                },
                {
                    name: 'Tigray Region',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'EU',
            alpha3: '',
            countryCallingCodes: ['+388'],
            currencies: ['EUR'],
            ioc: '',
            languages: [],
            name: 'European Union',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FI',
            alpha3: 'FIN',
            countryCallingCodes: ['+358'],
            currencies: ['EUR'],
            ioc: 'FIN',
            languages: ['fin', 'swe'],
            name: 'Finland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FJ',
            alpha3: 'FJI',
            countryCallingCodes: ['+679'],
            currencies: ['FJD'],
            ioc: 'FIJ',
            languages: ['eng', 'fij'],
            name: 'Fiji',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FK',
            alpha3: 'FLK',
            countryCallingCodes: ['+500'],
            currencies: ['FKP'],
            ioc: '',
            languages: ['eng'],
            name: 'Falkland Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FM',
            alpha3: 'FSM',
            countryCallingCodes: ['+691'],
            currencies: ['USD'],
            ioc: '',
            languages: ['eng'],
            name: 'Federated States of Micronesia',
            status: 'assigned',
            provinces: [],
            alias: ['Micronesia'],
        },
        {
            alpha2: 'FO',
            alpha3: 'FRO',
            countryCallingCodes: ['+298'],
            currencies: ['DKK'],
            ioc: 'FAI',
            languages: ['fao', 'dan'],
            name: 'Faroe Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FQ',
            alpha3: 'ATF',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'French Southern and Antarctic Territories',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FR',
            alpha3: 'FRA',
            countryCallingCodes: ['+33'],
            currencies: ['EUR'],
            ioc: 'FRA',
            languages: ['fra'],
            name: 'France',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'FX',
            alpha3: '',
            countryCallingCodes: ['+241'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'France, Metropolitan',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GA',
            alpha3: 'GAB',
            countryCallingCodes: ['+241'],
            currencies: ['XAF'],
            ioc: 'GAB',
            languages: ['fra'],
            name: 'Gabon',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GD',
            alpha3: 'GRD',
            countryCallingCodes: ['+473'],
            currencies: ['XCD'],
            ioc: 'GRN',
            languages: ['eng'],
            name: 'Grenada',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GE',
            alpha3: 'GEO',
            countryCallingCodes: ['+995'],
            currencies: ['GEL'],
            ioc: 'GEO',
            languages: ['kat'],
            name: 'Georgia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GE',
            alpha3: 'GEL',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Gilbert and Ellice Islands',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GF',
            alpha3: 'GUF',
            countryCallingCodes: ['+594'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'French Guiana',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GG',
            alpha3: 'GGY',
            countryCallingCodes: ['+44'],
            currencies: ['GBP'],
            ioc: 'GCI',
            languages: ['fra'],
            name: 'Guernsey',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GH',
            alpha3: 'GHA',
            countryCallingCodes: ['+233'],
            currencies: ['GHS'],
            ioc: 'GHA',
            languages: ['eng'],
            name: 'Ghana',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GI',
            alpha3: 'GIB',
            countryCallingCodes: ['+350'],
            currencies: ['GIP'],
            ioc: '',
            languages: ['eng'],
            name: 'Gibraltar',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GL',
            alpha3: 'GRL',
            countryCallingCodes: ['+299'],
            currencies: ['DKK'],
            ioc: '',
            languages: ['kal'],
            name: 'Greenland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GM',
            alpha3: 'GMB',
            countryCallingCodes: ['+220'],
            currencies: ['GMD'],
            ioc: 'GAM',
            languages: ['eng'],
            name: 'Gambia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GN',
            alpha3: 'GIN',
            countryCallingCodes: ['+224'],
            currencies: ['GNF'],
            ioc: 'GUI',
            languages: ['fra'],
            name: 'Guinea',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GP',
            alpha3: 'GLP',
            countryCallingCodes: ['+590'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'Guadeloupe',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GQ',
            alpha3: 'GNQ',
            countryCallingCodes: ['+240'],
            currencies: ['XAF'],
            ioc: 'GEQ',
            languages: ['spa', 'fra', 'por'],
            name: 'Equatorial Guinea',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GR',
            alpha3: 'GRC',
            countryCallingCodes: ['+30'],
            currencies: ['EUR'],
            ioc: 'GRE',
            languages: ['ell'],
            name: 'Greece',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GS',
            alpha3: 'SGS',
            countryCallingCodes: [],
            currencies: ['GBP'],
            ioc: '',
            languages: ['eng'],
            name: 'South Georgia And The South Sandwich Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GT',
            alpha3: 'GTM',
            countryCallingCodes: ['+502'],
            currencies: ['GTQ'],
            ioc: 'GUA',
            languages: ['spa'],
            name: 'Guatemala',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GU',
            alpha3: 'GUM',
            countryCallingCodes: ['+1 671'],
            currencies: ['USD'],
            ioc: 'GUM',
            languages: ['eng'],
            name: 'Guam',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'GW',
            alpha3: 'GNB',
            countryCallingCodes: ['+245'],
            currencies: ['XOF'],
            ioc: 'GBS',
            languages: ['por'],
            name: 'Guinea-Bissau',
            status: 'assigned',
            provinces: [],
            alias: ['Guinea Bissau'],
        },
        {
            alpha2: 'GY',
            alpha3: 'GUY',
            countryCallingCodes: ['+592'],
            currencies: ['GYD'],
            ioc: 'GUY',
            languages: ['eng'],
            name: 'Guyana',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HK',
            alpha3: 'HKG',
            countryCallingCodes: ['+852'],
            currencies: ['HKD'],
            ioc: 'HKG',
            languages: ['zho', 'eng'],
            name: 'Hong Kong',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HM',
            alpha3: 'HMD',
            countryCallingCodes: [],
            currencies: ['AUD'],
            ioc: '',
            languages: [],
            name: 'Heard Island And McDonald Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HN',
            alpha3: 'HND',
            countryCallingCodes: ['+504'],
            currencies: ['HNL'],
            ioc: 'HON',
            languages: ['spa'],
            name: 'Honduras',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HR',
            alpha3: 'HRV',
            countryCallingCodes: ['+385'],
            currencies: ['HRK'],
            ioc: 'CRO',
            languages: ['hrv'],
            name: 'Croatia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HT',
            alpha3: 'HTI',
            countryCallingCodes: ['+509'],
            currencies: ['HTG', 'USD'],
            ioc: 'HAI',
            languages: ['fra', 'hat'],
            name: 'Haiti',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HU',
            alpha3: 'HUN',
            countryCallingCodes: ['+36'],
            currencies: ['HUF'],
            ioc: 'HUN',
            languages: ['hun'],
            name: 'Hungary',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'HV',
            alpha3: 'HVO',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Upper Volta',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IC',
            alpha3: '',
            countryCallingCodes: [],
            currencies: ['EUR'],
            ioc: '',
            languages: [],
            name: 'Canary Islands',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ID',
            alpha3: 'IDN',
            countryCallingCodes: ['+62'],
            currencies: ['IDR'],
            ioc: 'INA',
            languages: ['ind'],
            name: 'Indonesia',
            status: 'assigned',
            provinces: [
                {
                    short: 'ID-AC',
                    name: 'Special Region of Aceh',
                    alias: [],
                },
                {
                    short: 'ID-BA',
                    name: 'Bali',
                    alias: [],
                },
                {
                    short: 'ID-BB',
                    name: 'Bangka–Belitung Islands',
                    alias: ['Bangka Belitung Islands'],
                },
                {
                    short: 'ID-BT',
                    name: 'Banten',
                    alias: [],
                },
                {
                    short: 'ID-BE',
                    name: 'Bengkulu',
                    alias: [],
                },
                {
                    short: 'ID-JT',
                    name: 'Central Java',
                    alias: [],
                },
                {
                    short: 'ID-KT',
                    name: 'Central Kalimantan',
                    alias: [],
                },
                {
                    short: 'ID-ST',
                    name: 'Central Sulawesi',
                    alias: [],
                },
                {
                    short: 'ID-JI',
                    name: 'East Java',
                    alias: [],
                },
                {
                    short: 'ID-KI',
                    name: 'East Kalimantan',
                    alias: [],
                },
                {
                    short: 'ID-NT',
                    name: 'East Nusa Tenggara',
                    alias: [],
                },
                {
                    short: 'ID-GO',
                    name: 'Gorontalo',
                    alias: [],
                },
                {
                    short: 'ID-JK',
                    name: 'Jakarta Special Capital Region',
                    alias: [],
                },
                {
                    short: 'ID-JA',
                    name: 'Jambi',
                    alias: [],
                },
                {
                    short: 'ID-LA',
                    name: 'Lampung',
                    alias: [],
                },
                {
                    short: 'ID-MA',
                    name: 'Maluku',
                    alias: [],
                },
                {
                    short: 'ID-KU',
                    name: 'North Kalimantan',
                    alias: [],
                },
                {
                    short: 'ID-MU',
                    name: 'North Maluku',
                    alias: [],
                },
                {
                    short: 'ID-SA',
                    name: 'North Sulawesi',
                    alias: [],
                },
                {
                    short: 'ID-SU',
                    name: 'North Sumatra',
                    alias: [],
                },
                {
                    short: 'ID-PA',
                    name: 'Special Region of Papua',
                    alias: [],
                },
                {
                    short: 'ID-RI',
                    name: 'Riau',
                    alias: [],
                },
                {
                    short: 'ID-KR',
                    name: 'Riau Islands',
                    alias: [],
                },
                {
                    short: 'ID-SG',
                    name: 'Southeast Sulawesi',
                    alias: [],
                },
                {
                    short: 'ID-KS',
                    name: 'South Kalimantan',
                    alias: [],
                },
                {
                    short: 'ID-SN',
                    name: 'South Sulawesi',
                    alias: [],
                },
                {
                    short: 'ID-SS',
                    name: 'South Sumatra',
                    alias: [],
                },
                {
                    short: 'ID-JB',
                    name: 'West Java',
                    alias: [],
                },
                {
                    short: 'ID-KB',
                    name: 'West Kalimantan',
                    alias: [],
                },
                {
                    short: 'ID-NB',
                    name: 'West Nusa Tenggara',
                    alias: [],
                },
                {
                    short: 'ID-PB',
                    name: 'Special Region of West Papua',
                    alias: [],
                },
                {
                    short: 'ID-SR',
                    name: 'West Sulawesi',
                    alias: [],
                },
                {
                    short: 'ID-SB',
                    name: 'West Sumatra',
                    alias: [],
                },
                {
                    short: 'ID-YO',
                    name: 'Special Region of Yogyakarta',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'IE',
            alpha3: 'IRL',
            countryCallingCodes: ['+353'],
            currencies: ['EUR'],
            ioc: 'IRL',
            languages: ['eng', 'gle'],
            name: 'Ireland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IL',
            alpha3: 'ISR',
            countryCallingCodes: ['+972'],
            currencies: ['ILS'],
            ioc: 'ISR',
            languages: ['heb', 'ara', 'eng'],
            name: 'Israel',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IM',
            alpha3: 'IMN',
            countryCallingCodes: ['+44'],
            currencies: ['GBP'],
            ioc: '',
            languages: ['eng', 'glv'],
            name: 'Isle Of Man',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IN',
            alpha3: 'IND',
            countryCallingCodes: ['+91'],
            currencies: ['INR'],
            ioc: 'IND',
            languages: ['eng', 'hin'],
            name: 'India',
            status: 'assigned',
            provinces: [
                {
                    short: 'AP',
                    name: 'Andhra Pradesh',
                    alias: [],
                },
                {
                    short: 'AR',
                    name: 'Arunachal Pradesh',
                    alias: [],
                },
                {
                    short: 'AS',
                    name: 'Assam',
                    alias: [],
                },
                {
                    short: 'BR',
                    name: 'Bihar',
                    alias: [],
                },
                {
                    short: 'CT',
                    name: 'Chhattisgarh',
                    alias: [],
                },
                {
                    short: 'GA',
                    name: 'Goa',
                    alias: [],
                },
                {
                    short: 'GJ',
                    name: 'Gujarat',
                    alias: [],
                },
                {
                    short: 'HR',
                    name: 'Haryana',
                    alias: [],
                },
                {
                    short: 'HP',
                    name: 'Himachal Pradesh',
                    alias: [],
                },
                {
                    short: 'JK',
                    name: 'Jammu and Kashmir',
                    alias: [],
                },
                {
                    short: 'JH',
                    name: 'Jharkhand',
                    alias: [],
                },
                {
                    short: 'KA',
                    name: 'Karnataka',
                    alias: [],
                },
                {
                    short: 'KL',
                    name: 'Kerala',
                    alias: [],
                },
                {
                    short: 'MP',
                    name: 'Madhya Pradesh',
                    alias: [],
                },
                {
                    short: 'MH',
                    name: 'Maharashtra',
                    alias: [],
                },
                {
                    short: 'MN',
                    name: 'Manipur',
                    alias: [],
                },
                {
                    short: 'ML',
                    name: 'Meghalaya',
                    alias: [],
                },
                {
                    short: 'MZ',
                    name: 'Mizoram',
                    alias: [],
                },
                {
                    short: 'NL',
                    name: 'Nagaland',
                    alias: [],
                },
                {
                    short: 'OR',
                    name: 'Odisha',
                    alias: [],
                },
                {
                    short: 'PB',
                    name: 'Punjab',
                    alias: [],
                },
                {
                    short: 'RJ',
                    name: 'Rajasthan',
                    alias: [],
                },
                {
                    short: 'SK',
                    name: 'Sikkim',
                    alias: [],
                },
                {
                    short: 'TN',
                    name: 'Tamil Nadu',
                    alias: [],
                },
                {
                    short: 'TG',
                    name: 'Telangana',
                    alias: [],
                },
                {
                    short: 'TR',
                    name: 'Tripura',
                    alias: [],
                },
                {
                    short: 'UP',
                    name: 'Uttar Pradesh',
                    alias: [],
                },
                {
                    short: 'UT',
                    name: 'Uttarakhand',
                    alias: [],
                },
                {
                    short: 'WB',
                    name: 'West Bengal',
                    alias: [],
                },
                {
                    short: 'AN',
                    name: 'Andaman and Nicobar Islands',
                    alias: [],
                },
                {
                    short: 'CH',
                    name: 'Chandigarh',
                    alias: [],
                },
                {
                    short: 'DN',
                    name: 'Dadra and Nagar Haveli',
                    alias: [],
                },
                {
                    short: 'DD',
                    name: 'Daman and Diu',
                    alias: [],
                },
                {
                    short: 'LD',
                    name: 'Lakshadweep',
                    alias: [],
                },
                {
                    short: 'DL',
                    name: 'National Capital Territory of Delhi',
                    alias: [],
                },
                {
                    short: 'PY',
                    name: 'Puducherry',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'IO',
            alpha3: 'IOT',
            countryCallingCodes: ['+246'],
            currencies: ['USD'],
            ioc: '',
            languages: ['eng'],
            name: 'British Indian Ocean Territory',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IQ',
            alpha3: 'IRQ',
            countryCallingCodes: ['+964'],
            currencies: ['IQD'],
            ioc: 'IRQ',
            languages: ['ara', 'kur'],
            name: 'Iraq',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IR',
            alpha3: 'IRN',
            countryCallingCodes: ['+98'],
            currencies: ['IRR'],
            ioc: 'IRI',
            languages: ['fas'],
            name: 'Iran',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IS',
            alpha3: 'ISL',
            countryCallingCodes: ['+354'],
            currencies: ['ISK'],
            ioc: 'ISL',
            languages: ['isl'],
            name: 'Iceland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'IT',
            alpha3: 'ITA',
            countryCallingCodes: ['+39'],
            currencies: ['EUR'],
            ioc: 'ITA',
            languages: ['ita'],
            name: 'Italy',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'JE',
            alpha3: 'JEY',
            countryCallingCodes: ['+44'],
            currencies: ['GBP'],
            ioc: 'JCI',
            languages: ['eng', 'fra'],
            name: 'Jersey',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'JM',
            alpha3: 'JAM',
            countryCallingCodes: ['+1 876'],
            currencies: ['JMD'],
            ioc: 'JAM',
            languages: ['eng'],
            name: 'Jamaica',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'JO',
            alpha3: 'JOR',
            countryCallingCodes: ['+962'],
            currencies: ['JOD'],
            ioc: 'JOR',
            languages: ['ara'],
            name: 'Jordan',
            status: 'assigned',
            provinces: [
                {
                    name: 'Irbid',
                    alias: [],
                },
                {
                    name: 'Ajloun',
                    alias: [],
                },
                {
                    name: 'Jerash',
                    alias: [],
                },
                {
                    name: 'Mafraq',
                    alias: [],
                },
                {
                    name: 'Balqa',
                    alias: [],
                },
                {
                    name: 'Amman',
                    alias: [],
                },
                {
                    name: 'Zarqa',
                    alias: [],
                },
                {
                    name: 'Madaba',
                    alias: [],
                },
                {
                    name: 'Karak',
                    alias: [],
                },
                {
                    name: 'Tafilah',
                    alias: [],
                },
                {
                    name: "Ma'an",
                    alias: [],
                },
                {
                    name: 'Aqaba',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'JP',
            alpha3: 'JPN',
            countryCallingCodes: ['+81'],
            currencies: ['JPY'],
            ioc: 'JPN',
            languages: ['jpn'],
            name: 'Japan',
            status: 'assigned',
            provinces: [
                {
                    name: '愛知県',
                    alias: ['Aichi'],
                },
                {
                    name: '秋田県',
                    alias: ['Akita'],
                },
                {
                    name: '青森県',
                    alias: ['Aomori'],
                },
                {
                    name: '千葉県',
                    alias: ['Chiba'],
                },
                {
                    name: '愛媛県',
                    alias: ['Ehime'],
                },
                {
                    name: '福井県',
                    alias: ['Fukui'],
                },
                {
                    name: '福岡県',
                    alias: ['Fukuoka'],
                },
                {
                    name: '福島県',
                    alias: ['Fukushima'],
                },
                {
                    name: '岐阜県',
                    alias: ['Gifu'],
                },
                {
                    name: '群馬県',
                    alias: ['Gunma'],
                },
                {
                    name: '広島県',
                    alias: ['Hiroshima'],
                },
                {
                    name: '北海道',
                    alias: ['Hokkaidō'],
                },
                {
                    name: '兵庫県',
                    alias: ['Hyōgo', 'Hyogo'],
                },
                {
                    name: '茨城県',
                    alias: ['Ibaraki'],
                },
                {
                    name: '石川県',
                    alias: ['Ishikawa'],
                },
                {
                    name: '岩手県',
                    alias: ['Iwate'],
                },
                {
                    name: '香川県',
                    alias: ['Kagawa'],
                },
                {
                    name: '鹿児島県',
                    alias: ['Kagoshima'],
                },
                {
                    name: '神奈川県',
                    alias: ['Kanagawa'],
                },
                {
                    name: '高知県',
                    alias: ['Kōchi', 'Kochi'],
                },
                {
                    name: '熊本県',
                    alias: ['Kumamoto'],
                },
                {
                    name: '京都府',
                    alias: ['Kyōto', 'Kyoto'],
                },
                {
                    name: '三重県',
                    alias: ['Mie'],
                },
                {
                    name: '宮城県',
                    alias: ['Miyagi'],
                },
                {
                    name: '宮崎県',
                    alias: ['Miyazaki'],
                },
                {
                    name: '長野県',
                    alias: ['Nagano'],
                },
                {
                    name: '長崎県',
                    alias: ['Nagasaki'],
                },
                {
                    name: '奈良県',
                    alias: ['Nara'],
                },
                {
                    name: '新潟県',
                    alias: ['Niigata'],
                },
                {
                    name: '大分県',
                    alias: ['Ōita', 'Oita'],
                },
                {
                    name: '岡山県',
                    alias: ['Okayama'],
                },
                {
                    name: '沖縄県',
                    alias: ['Okinawa'],
                },
                {
                    name: '大阪府',
                    alias: ['Ōsaka'],
                },
                {
                    name: '佐賀県',
                    alias: ['Saga'],
                },
                {
                    name: '埼玉県',
                    alias: ['Saitama'],
                },
                {
                    name: '滋賀県',
                    alias: ['Shiga'],
                },
                {
                    name: '島根県',
                    alias: ['Shimane'],
                },
                {
                    name: '静岡県',
                    alias: ['Shizuoka'],
                },
                {
                    name: '栃木県',
                    alias: ['Tochigi'],
                },
                {
                    name: '徳島県',
                    alias: ['Tokushima'],
                },
                {
                    name: '東京都',
                    alias: ['Tōkyō', 'Tokyo'],
                },
                {
                    name: '鳥取県',
                    alias: ['Tottori'],
                },
                {
                    name: '富山県',
                    alias: ['Toyama'],
                },
                {
                    name: '和歌山県',
                    alias: ['Wakayama'],
                },
                {
                    name: '山形県',
                    alias: ['Yamagata'],
                },
                {
                    name: '山口県',
                    alias: ['Yamaguchi'],
                },
                {
                    name: '山梨県',
                    alias: ['Yamanashi'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'JT',
            alpha3: 'JTN',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Johnston Island',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KE',
            alpha3: 'KEN',
            countryCallingCodes: ['+254'],
            currencies: ['KES'],
            ioc: 'KEN',
            languages: ['eng', 'swa'],
            name: 'Kenya',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KG',
            alpha3: 'KGZ',
            countryCallingCodes: ['+996'],
            currencies: ['KGS'],
            ioc: 'KGZ',
            languages: ['rus'],
            name: 'Kyrgyzstan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KH',
            alpha3: 'KHM',
            countryCallingCodes: ['+855'],
            currencies: ['KHR'],
            ioc: 'CAM',
            languages: ['khm'],
            name: 'Cambodia',
            status: 'assigned',
            provinces: [
                {
                    name: 'ភ្នំពេញ',
                    alias: ['Phnom Penh Municipality'],
                },
                {
                    name: 'បន្ទាយមានជ័យ',
                    alias: ['Banteay Meanchey'],
                },
                {
                    name: 'បាត់ដំបង',
                    alias: ['Battambang'],
                },
                {
                    name: 'កំពង់ចាម',
                    alias: ['Kampong Cham'],
                },
                {
                    name: 'កំពង់ឆ្នាំង',
                    alias: ['Kampong Chhnang'],
                },
                {
                    name: 'កំពង់ស្ពឺ',
                    alias: ['Kampong Speu'],
                },
                {
                    name: 'កំពង់ធំ',
                    alias: ['Kampong Thom'],
                },
                {
                    name: 'កំពត',
                    alias: ['Kampot'],
                },
                {
                    name: 'កណ្តាល',
                    alias: ['Kandal'],
                },
                {
                    name: 'កោះកុង',
                    alias: ['Koh Kong'],
                },
                {
                    name: 'កែប',
                    alias: ['Kep'],
                },
                {
                    name: 'ក្រចេះ',
                    alias: ['Kratié', 'Kratie'],
                },
                {
                    name: 'មណ្ឌលគីរី',
                    alias: ['Mondulkiri'],
                },
                {
                    name: 'ឧត្តរមានជ័យ',
                    alias: ['Oddar Meanchey'],
                },
                {
                    name: 'បៃលិន',
                    alias: ['Pailin'],
                },
                {
                    name: 'ព្រះសីហនុ',
                    alias: ['Preah Sihanouk'],
                },
                {
                    name: 'ព្រះវិហារ',
                    alias: ['Preah Vihear'],
                },
                {
                    name: 'ពោធិ៍សាត់',
                    alias: ['Pursat'],
                },
                {
                    name: 'ព្រៃវែង',
                    alias: ['Prey Veng'],
                },
                {
                    name: 'រតនគីរី',
                    alias: ['Ratanakiri'],
                },
                {
                    name: 'សៀមរាប',
                    alias: ['Siem Reap'],
                },
                {
                    name: 'ស្ទឹងត្រែង',
                    alias: ['Stung Treng'],
                },
                {
                    name: 'ស្វាយរៀង',
                    alias: ['Svay Rieng'],
                },
                {
                    name: 'តាកែវ',
                    alias: ['Takéo', 'Takeo'],
                },
                {
                    name: 'ត្បូងឃ្មុំ',
                    alias: ['Tbong Khmum'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'KI',
            alpha3: 'KIR',
            countryCallingCodes: ['+686'],
            currencies: ['AUD'],
            ioc: 'KIR',
            languages: ['eng'],
            name: 'Kiribati',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KM',
            alpha3: 'COM',
            countryCallingCodes: ['+269'],
            currencies: ['KMF'],
            ioc: 'COM',
            languages: ['ara', 'fra'],
            name: 'Comoros',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KN',
            alpha3: 'KNA',
            countryCallingCodes: ['+1 869'],
            currencies: ['XCD'],
            ioc: 'SKN',
            languages: ['eng'],
            name: 'Saint Kitts And Nevis',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KP',
            alpha3: 'PRK',
            countryCallingCodes: ['+850'],
            currencies: ['KPW'],
            ioc: 'PRK',
            languages: ['kor'],
            name: 'North Korea',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KR',
            alpha3: 'KOR',
            countryCallingCodes: ['+82'],
            currencies: ['KRW'],
            ioc: 'KOR',
            languages: ['kor'],
            name: 'South Korea',
            status: 'assigned',
            provinces: [],
            alias: ['Korea'],
        },
        {
            alpha2: 'KW',
            alpha3: 'KWT',
            countryCallingCodes: ['+965'],
            currencies: ['KWD'],
            ioc: 'KUW',
            languages: ['ara'],
            name: 'Kuwait',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KY',
            alpha3: 'CYM',
            countryCallingCodes: ['+1 345'],
            currencies: ['KYD'],
            ioc: 'CAY',
            languages: ['eng'],
            name: 'Cayman Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'KZ',
            alpha3: 'KAZ',
            countryCallingCodes: ['+7', '+7 6', '+7 7'],
            currencies: ['KZT'],
            ioc: 'KAZ',
            languages: ['kaz', 'rus'],
            name: 'Kazakhstan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LA',
            alpha3: 'LAO',
            countryCallingCodes: ['+856'],
            currencies: ['LAK'],
            ioc: 'LAO',
            languages: ['lao'],
            name: "Lao People's Democratic Republic",
            status: 'assigned',
            provinces: [],
            alias: ['Laos'],
        },
        {
            alpha2: 'LB',
            alpha3: 'LBN',
            countryCallingCodes: ['+961'],
            currencies: ['LBP'],
            ioc: 'LIB',
            languages: ['ara', 'hye'],
            name: 'Lebanon',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LC',
            alpha3: 'LCA',
            countryCallingCodes: ['+1 758'],
            currencies: ['XCD'],
            ioc: 'LCA',
            languages: ['eng'],
            name: 'Saint Lucia',
            status: 'assigned',
            provinces: [],
            alias: ['St. Lucia', 'St Lucia'],
        },
        {
            alpha2: 'LI',
            alpha3: 'LIE',
            countryCallingCodes: ['+423'],
            currencies: ['CHF'],
            ioc: 'LIE',
            languages: ['deu'],
            name: 'Liechtenstein',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LK',
            alpha3: 'LKA',
            countryCallingCodes: ['+94'],
            currencies: ['LKR'],
            ioc: 'SRI',
            languages: ['sin', 'tam'],
            name: 'Sri Lanka',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LR',
            alpha3: 'LBR',
            countryCallingCodes: ['+231'],
            currencies: ['LRD'],
            ioc: 'LBR',
            languages: ['eng'],
            name: 'Liberia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LS',
            alpha3: 'LSO',
            countryCallingCodes: ['+266'],
            currencies: ['LSL', 'ZAR'],
            ioc: 'LES',
            languages: ['eng', 'sot'],
            name: 'Lesotho',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LT',
            alpha3: 'LTU',
            countryCallingCodes: ['+370'],
            currencies: ['EUR'],
            ioc: 'LTU',
            languages: ['lit'],
            name: 'Lithuania',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LU',
            alpha3: 'LUX',
            countryCallingCodes: ['+352'],
            currencies: ['EUR'],
            ioc: 'LUX',
            languages: ['fra', 'deu', 'ltz'],
            name: 'Luxembourg',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LV',
            alpha3: 'LVA',
            countryCallingCodes: ['+371'],
            currencies: ['EUR'],
            ioc: 'LAT',
            languages: ['lav'],
            name: 'Latvia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'LY',
            alpha3: 'LBY',
            countryCallingCodes: ['+218'],
            currencies: ['LYD'],
            ioc: 'LBA',
            languages: ['ara'],
            name: 'Libya',
            status: 'assigned',
            provinces: [],
            alias: ['Libyan Arab Jamahiriya'],
        },
        {
            alpha2: 'MA',
            alpha3: 'MAR',
            countryCallingCodes: ['+212'],
            currencies: ['MAD'],
            ioc: 'MAR',
            languages: ['ara'],
            name: 'Morocco',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MC',
            alpha3: 'MCO',
            countryCallingCodes: ['+377'],
            currencies: ['EUR'],
            ioc: 'MON',
            languages: ['fra'],
            name: 'Monaco',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MD',
            alpha3: 'MDA',
            countryCallingCodes: ['+373'],
            currencies: ['MDL'],
            ioc: 'MDA',
            languages: ['ron'],
            name: 'Moldova',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ME',
            alpha3: 'MNE',
            countryCallingCodes: ['+382'],
            currencies: ['EUR'],
            ioc: 'MNE',
            languages: ['mot'],
            name: 'Montenegro',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MF',
            alpha3: 'MAF',
            countryCallingCodes: ['+590'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'Saint Martin',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MG',
            alpha3: 'MDG',
            countryCallingCodes: ['+261'],
            currencies: ['MGA'],
            ioc: 'MAD',
            languages: ['fra', 'mlg'],
            name: 'Madagascar',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MH',
            alpha3: 'MHL',
            countryCallingCodes: ['+692'],
            currencies: ['USD'],
            ioc: 'MHL',
            languages: ['eng', 'mah'],
            name: 'Marshall Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MI',
            alpha3: 'MID',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Midway Islands',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MK',
            alpha3: 'MKD',
            countryCallingCodes: ['+389'],
            currencies: ['MKD'],
            ioc: 'MKD',
            languages: ['mkd'],
            name: 'Macedonia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ML',
            alpha3: 'MLI',
            countryCallingCodes: ['+223'],
            currencies: ['XOF'],
            ioc: 'MLI',
            languages: ['fra'],
            name: 'Mali',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MM',
            alpha3: 'MMR',
            countryCallingCodes: ['+95'],
            currencies: ['MMK'],
            ioc: 'MYA',
            languages: ['mya'],
            name: 'Myanmar',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MN',
            alpha3: 'MNG',
            countryCallingCodes: ['+976'],
            currencies: ['MNT'],
            ioc: 'MGL',
            languages: ['mon'],
            name: 'Mongolia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MO',
            alpha3: 'MAC',
            countryCallingCodes: ['+853'],
            currencies: ['MOP'],
            ioc: 'MAC',
            languages: ['zho', 'por'],
            name: 'Macao',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MP',
            alpha3: 'MNP',
            countryCallingCodes: ['+1 670'],
            currencies: ['USD'],
            ioc: '',
            languages: ['eng'],
            name: 'Northern Mariana Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MQ',
            alpha3: 'MTQ',
            countryCallingCodes: ['+596'],
            currencies: ['EUR'],
            ioc: '',
            languages: [],
            name: 'Martinique',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MR',
            alpha3: 'MRT',
            countryCallingCodes: ['+222'],
            currencies: ['MRO'],
            ioc: 'MTN',
            languages: ['ara', 'fra'],
            name: 'Mauritania',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MS',
            alpha3: 'MSR',
            countryCallingCodes: ['+1 664'],
            currencies: ['XCD'],
            ioc: '',
            languages: [],
            name: 'Montserrat',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MT',
            alpha3: 'MLT',
            countryCallingCodes: ['+356'],
            currencies: ['EUR'],
            ioc: 'MLT',
            languages: ['mlt', 'eng'],
            name: 'Malta',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MU',
            alpha3: 'MUS',
            countryCallingCodes: ['+230'],
            currencies: ['MUR'],
            ioc: 'MRI',
            languages: ['eng', 'fra'],
            name: 'Mauritius',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MV',
            alpha3: 'MDV',
            countryCallingCodes: ['+960'],
            currencies: ['MVR'],
            ioc: 'MDV',
            languages: ['div'],
            name: 'Maldives',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MW',
            alpha3: 'MWI',
            countryCallingCodes: ['+265'],
            currencies: ['MWK'],
            ioc: 'MAW',
            languages: ['eng', 'nya'],
            name: 'Malawi',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MX',
            alpha3: 'MEX',
            countryCallingCodes: ['+52'],
            currencies: ['MXN', 'MXV'],
            ioc: 'MEX',
            languages: ['spa'],
            name: 'Mexico',
            status: 'assigned',
            provinces: [
                {
                    name: 'Aguascalientes',
                    short: 'AG',
                    alias: ['AGS'],
                },
                {
                    name: 'Baja California',
                    short: 'BC',
                    alias: ['BCN'],
                },
                {
                    name: 'Baja California Sur',
                    short: 'BS',
                    alias: ['BCS'],
                },
                {
                    name: 'Campeche',
                    short: 'CM',
                    alias: ['Camp', 'CAM'],
                },
                {
                    name: 'Chiapas',
                    short: 'CS',
                    alias: ['Chis', 'CHP'],
                },
                {
                    name: 'Chihuahua',
                    short: 'CH',
                    alias: ['Chih', 'CHH'],
                },
                {
                    name: 'Coahuila',
                    short: 'MX',
                    alias: ['Coah', 'COA'],
                },
                {
                    name: 'Colima',
                    short: 'CL',
                    alias: ['COL'],
                },
                {
                    name: 'Federal District',
                    short: 'DF',
                    alias: ['DIF'],
                },
                {
                    name: 'Durango',
                    short: 'DG',
                    alias: ['Dgo', 'DUR'],
                },
                {
                    name: 'Guanajuato',
                    short: 'GT',
                    alias: ['Gto', 'GUA'],
                },
                {
                    name: 'Guerrero',
                    short: 'GR',
                    alias: ['Gro', 'GRO'],
                },
                {
                    name: 'Hidalgo',
                    short: 'HG',
                    alias: ['Hgo', 'HID'],
                },
                {
                    name: 'Jalisco',
                    short: 'JA',
                    alias: ['Jal', 'JAL'],
                },
                {
                    name: 'Mexico',
                    short: 'ME',
                    alias: ['Edomex', 'MEX'],
                },
                {
                    name: 'Michoacán',
                    short: 'MI',
                    alias: ['Mich', 'MIC', 'Michoacan'],
                },
                {
                    name: 'Morelos',
                    short: 'MO',
                    alias: ['Mor', 'MOR'],
                },
                {
                    name: 'Nayarit',
                    short: 'NA',
                    alias: ['Nay', 'NAY'],
                },
                {
                    name: 'Nuevo León',
                    short: 'NL',
                    alias: ['NLE', 'Nuevo Leon'],
                },
                {
                    name: 'Oaxaca',
                    short: 'OA',
                    alias: ['Oax', 'OAX'],
                },
                {
                    name: 'Puebla',
                    short: 'PU',
                    alias: ['Pue', 'PUE'],
                },
                {
                    name: 'Querétaro',
                    short: 'QE',
                    alias: ['Qro', 'QUE', 'Queretaro'],
                },
                {
                    name: 'Quintana Roo',
                    short: 'QR',
                    alias: ['Q Roo', 'ROO'],
                },
                {
                    name: 'San Luis Potosí',
                    short: 'SL',
                    alias: ['SLP', 'San Luis Potosi'],
                },
                {
                    name: 'Sinaloa',
                    short: 'SI',
                    alias: ['SIN'],
                },
                {
                    name: 'Sonora',
                    short: 'SO',
                    alias: ['SON'],
                },
                {
                    name: 'Tabasco',
                    short: 'TB',
                    alias: ['TAB'],
                },
                {
                    name: 'Tamaulipas',
                    short: 'TM',
                    alias: ['Tamps', 'TAM'],
                },
                {
                    name: 'Tlaxcala',
                    short: 'TL',
                    alias: ['Tlax', 'TLA'],
                },
                {
                    name: 'Veracruz',
                    short: 'VE',
                    alias: ['VER'],
                },
                {
                    name: 'Yucatán',
                    short: 'YU',
                    alias: ['YUC', 'Yucatan'],
                },
                {
                    name: 'Zacatecas',
                    short: 'ZA',
                    alias: ['ZAC'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'MY',
            alpha3: 'MYS',
            countryCallingCodes: ['+60'],
            currencies: ['MYR'],
            ioc: 'MAS',
            languages: ['msa', 'eng'],
            name: 'Malaysia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'MZ',
            alpha3: 'MOZ',
            countryCallingCodes: ['+258'],
            currencies: ['MZN'],
            ioc: 'MOZ',
            languages: ['por'],
            name: 'Mozambique',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NA',
            alpha3: 'NAM',
            countryCallingCodes: ['+264'],
            currencies: ['NAD', 'ZAR'],
            ioc: 'NAM',
            languages: ['eng'],
            name: 'Namibia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NC',
            alpha3: 'NCL',
            countryCallingCodes: ['+687'],
            currencies: ['XPF'],
            ioc: '',
            languages: ['fra'],
            name: 'New Caledonia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NE',
            alpha3: 'NER',
            countryCallingCodes: ['+227'],
            currencies: ['XOF'],
            ioc: 'NIG',
            languages: ['fra'],
            name: 'Niger',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NF',
            alpha3: 'NFK',
            countryCallingCodes: ['+672'],
            currencies: ['AUD'],
            ioc: '',
            languages: ['eng'],
            name: 'Norfolk Island',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NG',
            alpha3: 'NGA',
            countryCallingCodes: ['+234'],
            currencies: ['NGN'],
            ioc: 'NGR',
            languages: ['eng'],
            name: 'Nigeria',
            status: 'assigned',
            provinces: [
                {
                    name: 'Anambra',
                    alias: [],
                },
                {
                    name: 'Enugu',
                    alias: [],
                },
                {
                    name: 'Akwa Ibom',
                    alias: [],
                },
                {
                    name: 'Adamawa',
                    alias: [],
                },
                {
                    name: 'Abia',
                    alias: [],
                },
                {
                    name: 'Bauchi',
                    alias: [],
                },
                {
                    name: 'Bayelsa',
                    alias: [],
                },
                {
                    name: 'Benue',
                    alias: [],
                },
                {
                    name: 'Borno',
                    alias: [],
                },
                {
                    name: 'Cross River',
                    alias: [],
                },
                {
                    name: 'Delta',
                    alias: [],
                },
                {
                    name: 'Ebonyi',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'NH',
            alpha3: 'NHB',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'New Hebrides',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NI',
            alpha3: 'NIC',
            countryCallingCodes: ['+505'],
            currencies: ['NIO'],
            ioc: 'NCA',
            languages: ['spa'],
            name: 'Nicaragua',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NL',
            alpha3: 'NLD',
            countryCallingCodes: ['+31'],
            currencies: ['EUR'],
            ioc: 'NED',
            languages: ['nld'],
            name: 'Netherlands',
            status: 'assigned',
            provinces: [
                {
                    short: 'DR',
                    name: 'Drenthe',
                    alias: [],
                },
                {
                    short: 'FL',
                    name: 'Flevoland',
                    alias: [],
                },
                {
                    short: 'FR',
                    name: 'Friesland',
                    alias: [],
                },
                {
                    short: 'GD',
                    name: 'Gelderland',
                    alias: [],
                },
                {
                    short: 'GR',
                    name: 'Groningen',
                    alias: [],
                },
                {
                    short: 'LB',
                    name: 'Limburg',
                    alias: [],
                },
                {
                    short: 'NB',
                    name: 'Noord-Brabant',
                    alias: ['Noord Brabant'],
                },
                {
                    short: 'NH',
                    name: 'Noord-Holland',
                    alias: ['Noord Holland'],
                },
                {
                    short: 'OV',
                    name: 'Overijssel',
                    alias: [],
                },
                {
                    short: 'UT',
                    name: 'Utrecht',
                    alias: [],
                },
                {
                    short: 'ZH',
                    name: 'Zuid-Holland',
                    alias: ['Zuid Holland'],
                },
                {
                    short: 'ZL',
                    name: 'Zeeland',
                    alias: [],
                },
                {
                    short: 'AW',
                    name: 'Aruba',
                    alias: [],
                },
                {
                    short: 'BQ',
                    name: 'Bonaire',
                    alias: [],
                },
                {
                    short: 'BQ2',
                    name: 'Saba',
                    alias: [],
                },
                {
                    short: 'BQ3',
                    name: 'Sint Eustatius',
                    alias: [],
                },
                {
                    short: 'CW',
                    name: 'Curaçao',
                    alias: [],
                },
                {
                    short: 'SX',
                    name: 'Sint Maarten',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'NO',
            alpha3: 'NOR',
            countryCallingCodes: ['+47'],
            currencies: ['NOK'],
            ioc: 'NOR',
            languages: ['nor'],
            name: 'Norway',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NP',
            alpha3: 'NPL',
            countryCallingCodes: ['+977'],
            currencies: ['NPR'],
            ioc: 'NEP',
            languages: ['nep'],
            name: 'Nepal',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NQ',
            alpha3: 'ATN',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Dronning Maud Land',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NR',
            alpha3: 'NRU',
            countryCallingCodes: ['+674'],
            currencies: ['AUD'],
            ioc: 'NRU',
            languages: ['eng', 'nau'],
            name: 'Nauru',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NT',
            alpha3: 'NTZ',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Neutral Zone',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'NU',
            alpha3: 'NIU',
            countryCallingCodes: ['+683'],
            currencies: ['NZD'],
            ioc: '',
            languages: ['eng'],
            name: 'Niue',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'OM',
            alpha3: 'OMN',
            countryCallingCodes: ['+968'],
            currencies: ['OMR'],
            ioc: 'OMA',
            languages: ['ara'],
            name: 'Oman',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PA',
            alpha3: 'PAN',
            countryCallingCodes: ['+507'],
            currencies: ['PAB', 'USD'],
            ioc: 'PAN',
            languages: ['spa'],
            name: 'Panama',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PC',
            alpha3: 'PCI',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Trust Territory of the Pacific Islands',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PE',
            alpha3: 'PER',
            countryCallingCodes: ['+51'],
            currencies: ['PEN'],
            ioc: 'PER',
            languages: ['spa', 'aym', 'que'],
            name: 'Peru',
            status: 'assigned',
            provinces: [
                {
                    name: 'Chachapoyas',
                    region: 'Amazonas',
                    alias: [],
                },
                {
                    name: 'Bagua',
                    region: 'Amazonas',
                    alias: [],
                },
                {
                    name: 'Bongará',
                    region: 'Amazonas',
                    alias: ['Bongara'],
                },
                {
                    name: 'Condorcanqui',
                    region: 'Amazonas',
                    alias: [],
                },
                {
                    name: 'Luya',
                    region: 'Amazonas',
                    alias: [],
                },
                {
                    name: 'Rodríguez de Mendoza',
                    region: 'Amazonas',
                    alias: ['Rodriguez de Mendoza'],
                },
                {
                    name: 'Utcubamba',
                    region: 'Amazonas',
                    alias: [],
                },
                {
                    name: 'Huaraz',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Aija',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Antonio Raymondi',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Asunción',
                    region: 'Ancash',
                    alias: ['Asuncion'],
                },
                {
                    name: 'Bolognesi',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Carhuaz',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Carlos Fermín Fitzcarrald',
                    region: 'Ancash',
                    alias: ['Carlos Fermin Fitzcarrald'],
                },
                {
                    name: 'Casma',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Corongo',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Huari',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Huarmey',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Huaylas',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Mariscal Luzuriaga',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Ocros',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Pallasca',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Pomabamba',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Recuay',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Santa',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Sihuas',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Yungay',
                    region: 'Ancash',
                    alias: [],
                },
                {
                    name: 'Abancay',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Andahuaylas',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Antabamba',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Aymaraes',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Cotabambas',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Chincheros',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Grau',
                    region: 'Apurímac',
                    alias: [],
                },
                {
                    name: 'Arequipa',
                    region: 'Arequipa',
                    alias: [],
                },
                {
                    name: 'Camaná',
                    region: 'Arequipa',
                    alias: ['Camana'],
                },
                {
                    name: 'Caravelí',
                    region: 'Arequipa',
                    alias: ['Caraveli'],
                },
                {
                    name: 'Castilla',
                    region: 'Arequipa',
                    alias: [],
                },
                {
                    name: 'Caylloma',
                    region: 'Arequipa',
                    alias: [],
                },
                {
                    name: 'Condesuyos',
                    region: 'Arequipa',
                    alias: [],
                },
                {
                    name: 'Islay',
                    region: 'Arequipa',
                    alias: [],
                },
                {
                    name: 'La Unión',
                    region: 'Arequipa',
                    alias: ['La Union'],
                },
                {
                    name: 'Huamanga',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Cangallo',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Huanca Sancos',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Huanta',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'La Mar',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Lucanas',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Parinacochas',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Páucar del Sara Sara',
                    region: 'Ayacucho',
                    alias: ['Paucar del Sara Sara'],
                },
                {
                    name: 'Sucre',
                    region: 'Ayacucho',
                    alias: [],
                },
                {
                    name: 'Víctor Fajardo',
                    region: 'Ayacucho',
                    alias: ['Victor Fajardo'],
                },
                {
                    name: 'Vilcas Huamán',
                    region: 'Ayacucho',
                    alias: ['Vilcas Huaman'],
                },
                {
                    name: 'Cajamarca',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Cajabamba',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Celendín',
                    region: 'Cajamarca',
                    alias: ['Celendin'],
                },
                {
                    name: 'Chota',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Contumazá',
                    region: 'Cajamarca',
                    alias: ['Contumaza'],
                },
                {
                    name: 'Cutervo',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Hualgayoc',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Jaén',
                    region: 'Cajamarca',
                    alias: ['Jaen'],
                },
                {
                    name: 'San Ignacio',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'San Marcos',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'San Miguel',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'San Pablo',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Santa Cruz',
                    region: 'Cajamarca',
                    alias: [],
                },
                {
                    name: 'Callao',
                    region: 'Callao',
                    alias: [],
                },
                {
                    name: 'Cusco',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Acomayo',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Anta',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Calca',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Canas',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Canchis',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Chumbivilcas',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Espinar',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'La Convención',
                    region: 'Cusco',
                    alias: ['La Convencion'],
                },
                {
                    name: 'Paruro',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Paucartambo',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Quispicanchi',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Urubamba',
                    region: 'Cusco',
                    alias: [],
                },
                {
                    name: 'Huancavelica',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Acobamba',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Angaraes',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Castrovirreyna',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Churcampa',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Huaytará',
                    region: 'Huancavelica',
                    alias: ['Huaytara'],
                },
                {
                    name: 'Tayacaja',
                    region: 'Huancavelica',
                    alias: [],
                },
                {
                    name: 'Huánuco',
                    region: 'Huánuco',
                    alias: ['Huanuco'],
                },
                {
                    name: 'Ambo',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Dos de Mayo',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Huacaybamba',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Huamalíes',
                    region: 'Huánuco',
                    alias: ['Huamalies'],
                },
                {
                    name: 'Leoncio Prado',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Marañón',
                    region: 'Huánuco',
                    alias: ['Maranon'],
                },
                {
                    name: 'Pachitea',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Puerto Inca',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Lauricocha',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Yarowilca',
                    region: 'Huánuco',
                    alias: [],
                },
                {
                    name: 'Ica',
                    region: 'Ica',
                    alias: [],
                },
                {
                    name: 'Chincha',
                    region: 'Ica',
                    alias: [],
                },
                {
                    name: 'Nazca',
                    region: 'Ica',
                    alias: [],
                },
                {
                    name: 'Palpa',
                    region: 'Ica',
                    alias: [],
                },
                {
                    name: 'Pisco',
                    region: 'Ica',
                    alias: [],
                },
                {
                    name: 'Huancayo',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Concepción',
                    region: 'Junín',
                    alias: ['Concepcion'],
                },
                {
                    name: 'Chanchamayo',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Jauja',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Junín',
                    region: 'Junín',
                    alias: ['Junin'],
                },
                {
                    name: 'Satipo',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Tarma',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Yauli',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Chupaca',
                    region: 'Junín',
                    alias: [],
                },
                {
                    name: 'Trujillo',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Ascope',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Bolívar',
                    region: 'La Libertad',
                    alias: ['Bolivar'],
                },
                {
                    name: 'Chepén',
                    region: 'La Libertad',
                    alias: ['Chepen'],
                },
                {
                    name: 'Julcán',
                    region: 'La Libertad',
                    alias: ['Julcan'],
                },
                {
                    name: 'Otuzco',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Pacasmayo',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Pataz',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Sánchez Carrión',
                    region: 'La Libertad',
                    alias: ['Sanchez Carrion'],
                },
                {
                    name: 'Santiago de Chuco',
                    region: 'La Libertad',
                    alias: [],
                },
                {
                    name: 'Gran Chimú',
                    region: 'La Libertad',
                    alias: ['Gran Chimu'],
                },
                {
                    name: 'Virú',
                    region: 'La Libertad',
                    alias: ['Viru'],
                },
                {
                    name: 'Chiclayo',
                    region: 'Lambayeque',
                    alias: [],
                },
                {
                    name: 'Ferreñafe',
                    region: 'Lambayeque',
                    alias: ['Ferrenafe'],
                },
                {
                    name: 'Lambayeque',
                    region: 'Lambayeque',
                    alias: [],
                },
                {
                    name: 'Lima',
                    region: 'autonomous',
                    alias: [],
                },
                {
                    name: 'Huaura',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Barranca',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Cajatambo',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Canta',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Cañete',
                    region: 'Lima',
                    alias: ['Canete'],
                },
                {
                    name: 'Huaral',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Huarochirí',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Oyón',
                    region: 'Lima',
                    alias: ['Oyon'],
                },
                {
                    name: 'Yauyos',
                    region: 'Lima',
                    alias: [],
                },
                {
                    name: 'Maynas',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Aliaso Amazonas',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Loreto',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Mariscal Ramón Castilla',
                    region: 'Loreto',
                    alias: ['Mariscal Ramon Castilla'],
                },
                {
                    name: 'Putumayo',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Requena',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Ucayali',
                    region: 'Loreto',
                    alias: [],
                },
                {
                    name: 'Datem del Marañón',
                    region: 'Loreto',
                    alias: ['Datem del Maranon'],
                },
                {
                    name: 'Tambopata',
                    region: 'Madre de Dios',
                    alias: [],
                },
                {
                    name: 'Manú',
                    region: 'Madre de Dios',
                    alias: ['Manu'],
                },
                {
                    name: 'Tahuamanu',
                    region: 'Madre de Dios',
                    alias: [],
                },
                {
                    name: 'Mariscal Nieto',
                    region: 'Moquegua',
                    alias: [],
                },
                {
                    name: 'General Sánchez Cerro',
                    region: 'Moquegua',
                    alias: ['General Sanchez Cerro'],
                },
                {
                    name: 'Ilo',
                    region: 'Moquegua',
                    alias: [],
                },
                {
                    name: 'Pasco',
                    region: 'Pasco',
                    alias: [],
                },
                {
                    name: 'Daniel Alcídes Carrión',
                    region: 'Pasco',
                    alias: ['Daniel Alcides Carrion'],
                },
                {
                    name: 'Oxapampa',
                    region: 'Pasco',
                    alias: [],
                },
                {
                    name: 'Piura',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Ayabaca',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Huancabamba',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Morropón',
                    region: 'Piura',
                    alias: ['Morropon'],
                },
                {
                    name: 'Paita',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Sullana',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Talara',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Sechura',
                    region: 'Piura',
                    alias: [],
                },
                {
                    name: 'Puno',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Azángaro',
                    region: 'Puno',
                    alias: ['Azangaro'],
                },
                {
                    name: 'Carabaya',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Chucuito',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'El Collao',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Huancané',
                    region: 'Puno',
                    alias: ['Huancane'],
                },
                {
                    name: 'Lampa',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Melgar',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Moho',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'San Antonio de Putina',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'San Román',
                    region: 'Puno',
                    alias: ['San Roman'],
                },
                {
                    name: 'Sandia',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Yunguyo',
                    region: 'Puno',
                    alias: [],
                },
                {
                    name: 'Moyobamba',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Bellavista',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'El Dorado',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Huallaga',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Lamas',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Mariscal Cáceres',
                    region: 'San Martín',
                    alias: ['Mariscal Caceres'],
                },
                {
                    name: 'Picota',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Rioja',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'San Martín',
                    region: 'San Martín',
                    alias: ['San Martin'],
                },
                {
                    name: 'Tocache',
                    region: 'San Martín',
                    alias: [],
                },
                {
                    name: 'Tacna',
                    region: 'Tacna',
                    alias: [],
                },
                {
                    name: 'Candarave',
                    region: 'Tacna',
                    alias: [],
                },
                {
                    name: 'Jorge Basadre',
                    region: 'Tacna',
                    alias: [],
                },
                {
                    name: 'Tarata',
                    region: 'Tacna',
                    alias: [],
                },
                {
                    name: 'Tumbes',
                    region: 'Tumbes',
                    alias: [],
                },
                {
                    name: 'Contralmirante Villar',
                    region: 'Tumbes',
                    alias: [],
                },
                {
                    name: 'Zarumilla',
                    region: 'Tumbes',
                    alias: [],
                },
                {
                    name: 'Coronel Portillo',
                    region: 'Ucayali',
                    alias: [],
                },
                {
                    name: 'Atalaya',
                    region: 'Ucayali',
                    alias: [],
                },
                {
                    name: 'Padre Abad',
                    region: 'Ucayali',
                    alias: [],
                },
                {
                    name: 'Purús',
                    region: 'Ucayali',
                    alias: ['Purus'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'PF',
            alpha3: 'PYF',
            countryCallingCodes: ['+689'],
            currencies: ['XPF'],
            ioc: '',
            languages: ['fra'],
            name: 'French Polynesia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PG',
            alpha3: 'PNG',
            countryCallingCodes: ['+675'],
            currencies: ['PGK'],
            ioc: 'PNG',
            languages: ['eng'],
            name: 'Papua New Guinea',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PH',
            alpha3: 'PHL',
            countryCallingCodes: ['+63'],
            currencies: ['PHP'],
            ioc: 'PHI',
            languages: ['eng'],
            name: 'Philippines',
            status: 'assigned',
            provinces: [
                {
                    name: 'Abra',
                    alias: [],
                },
                {
                    name: 'Agusan del Norte',
                    alias: [],
                },
                {
                    name: 'Agusan del Sur',
                    alias: [],
                },
                {
                    name: 'Aklan',
                    alias: [],
                },
                {
                    name: 'Albay',
                    alias: [],
                },
                {
                    name: 'Antique',
                    alias: [],
                },
                {
                    name: 'Apayao',
                    alias: [],
                },
                {
                    name: 'Aurora',
                    alias: [],
                },
                {
                    name: 'Basilan',
                    alias: [],
                },
                {
                    name: 'Bataan',
                    alias: [],
                },
                {
                    name: 'Batanes',
                    alias: [],
                },
                {
                    name: 'Batangas',
                    alias: [],
                },
                {
                    name: 'Benguet',
                    alias: [],
                },
                {
                    name: 'Biliran',
                    alias: [],
                },
                {
                    name: 'Bohol',
                    alias: [],
                },
                {
                    name: 'Bukidnon',
                    alias: [],
                },
                {
                    name: 'Bulacan',
                    alias: [],
                },
                {
                    name: 'Cagayan',
                    alias: [],
                },
                {
                    name: 'Camarines Norte',
                    alias: [],
                },
                {
                    name: 'Camarines Sur',
                    alias: [],
                },
                {
                    name: 'Camiguin',
                    alias: [],
                },
                {
                    name: 'Capiz',
                    alias: [],
                },
                {
                    name: 'Catanduanes',
                    alias: [],
                },
                {
                    name: 'Cavite',
                    alias: [],
                },
                {
                    name: 'Cebu',
                    alias: [],
                },
                {
                    name: 'Compostela Valley',
                    alias: [],
                },
                {
                    name: 'Cotabato',
                    alias: [],
                },
                {
                    name: 'Davao del Norte',
                    alias: [],
                },
                {
                    name: 'Davao del Sur',
                    alias: [],
                },
                {
                    name: 'Davao Occidental',
                    alias: [],
                },
                {
                    name: 'Davao Oriental',
                    alias: [],
                },
                {
                    name: 'Dinagat Islands',
                    alias: [],
                },
                {
                    name: 'Eastern Samar',
                    alias: [],
                },
                {
                    name: 'Guimaras',
                    alias: [],
                },
                {
                    name: 'Ifugao',
                    alias: [],
                },
                {
                    name: 'Ilocos Norte',
                    alias: [],
                },
                {
                    name: 'Ilocos Sur',
                    alias: [],
                },
                {
                    name: 'Iloilo',
                    alias: [],
                },
                {
                    name: 'Isabela',
                    alias: [],
                },
                {
                    name: 'Kalinga',
                    alias: [],
                },
                {
                    name: 'La Union',
                    alias: [],
                },
                {
                    name: 'Laguna',
                    alias: [],
                },
                {
                    name: 'Lanao del Norte',
                    alias: [],
                },
                {
                    name: 'Lanao del Sur',
                    alias: [],
                },
                {
                    name: 'Leyte',
                    alias: [],
                },
                {
                    name: 'Maguindanao',
                    alias: [],
                },
                {
                    name: 'Marinduque',
                    alias: [],
                },
                {
                    name: 'Masbate',
                    alias: [],
                },
                {
                    name: 'Misamis Occidental',
                    alias: [],
                },
                {
                    name: 'Misamis Oriental',
                    alias: [],
                },
                {
                    name: 'Mountain Province',
                    alias: [],
                },
                {
                    name: 'Negros Occidental',
                    alias: [],
                },
                {
                    name: 'Negros Oriental',
                    alias: [],
                },
                {
                    name: 'Northern Samar',
                    alias: [],
                },
                {
                    name: 'Nueva Ecija',
                    alias: [],
                },
                {
                    name: 'Nueva Vizcaya',
                    alias: [],
                },
                {
                    name: 'Occidental Mindoro',
                    alias: [],
                },
                {
                    name: 'Oriental Mindoro',
                    alias: [],
                },
                {
                    name: 'Palawan',
                    alias: [],
                },
                {
                    name: 'Pampanga',
                    alias: [],
                },
                {
                    name: 'Pangasinan',
                    alias: [],
                },
                {
                    name: 'Quezon',
                    alias: [],
                },
                {
                    name: 'Quirino',
                    alias: [],
                },
                {
                    name: 'Rizal',
                    alias: [],
                },
                {
                    name: 'Romblon',
                    alias: [],
                },
                {
                    name: 'Samar',
                    alias: [],
                },
                {
                    name: 'Sarangani',
                    alias: [],
                },
                {
                    name: 'Siquijor',
                    alias: [],
                },
                {
                    name: 'Sorsogon',
                    alias: [],
                },
                {
                    name: 'South Cotabato',
                    alias: [],
                },
                {
                    name: 'Southern Leyte',
                    alias: [],
                },
                {
                    name: 'Sultan Kudarat',
                    alias: [],
                },
                {
                    name: 'Sulu',
                    alias: [],
                },
                {
                    name: 'Surigao del Norte',
                    alias: [],
                },
                {
                    name: 'Surigao del Sur',
                    alias: [],
                },
                {
                    name: 'Tarlac',
                    alias: [],
                },
                {
                    name: 'Tawi-Tawi',
                    alias: ['Tawi Tawi'],
                },
                {
                    name: 'Zambales',
                    alias: [],
                },
                {
                    name: 'Zamboanga del Norte',
                    alias: [],
                },
                {
                    name: 'Zamboanga del Sur',
                    alias: [],
                },
                {
                    name: 'Zamboanga Sibugay',
                    alias: [],
                },
                {
                    name: 'Metro Manila',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'PK',
            alpha3: 'PAK',
            countryCallingCodes: ['+92'],
            currencies: ['PKR'],
            ioc: 'PAK',
            languages: ['urd', 'eng'],
            name: 'Pakistan',
            status: 'assigned',
            provinces: [
                {
                    name: 'Azad Kashmir',
                    alias: [],
                },
                {
                    name: 'Bahawalpur',
                    alias: [],
                },
                {
                    name: 'Bannu',
                    alias: [],
                },
                {
                    name: 'Dera Ghazi Khan',
                    alias: [],
                },
                {
                    name: 'Dera Ismail Khan',
                    alias: [],
                },
                {
                    name: 'Faisalabad',
                    alias: [],
                },
                {
                    name: 'Federally Administered Tribal Areas',
                    alias: ['FATA', 'F.A.T.A.'],
                },
                {
                    name: 'Gujranwala',
                    alias: [],
                },
                {
                    name: 'Hazara',
                    alias: [],
                },
                {
                    name: 'Hyderabad',
                    alias: [],
                },
                {
                    name: 'Islamabad',
                    alias: [],
                },
                {
                    name: 'Kalat',
                    alias: [],
                },
                {
                    name: 'Karachi',
                    alias: [],
                },
                {
                    name: 'Kohat',
                    alias: [],
                },
                {
                    name: 'Lahore',
                    alias: [],
                },
                {
                    name: 'Larkana',
                    alias: [],
                },
                {
                    name: 'Makran',
                    alias: [],
                },
                {
                    name: 'Malakand',
                    alias: [],
                },
                {
                    name: 'Mardan',
                    alias: [],
                },
                {
                    name: 'Mirpur Khas',
                    alias: [],
                },
                {
                    name: 'Multan',
                    alias: [],
                },
                {
                    name: 'Nasirabad',
                    alias: [],
                },
                {
                    name: 'Northern Areas',
                    alias: [],
                },
                {
                    name: 'Peshawar',
                    alias: [],
                },
                {
                    name: 'Quetta',
                    alias: [],
                },
                {
                    name: 'Rawalpindi',
                    alias: [],
                },
                {
                    name: 'Sargodha',
                    alias: [],
                },
                {
                    name: 'Sahiwal',
                    alias: [],
                },
                {
                    name: 'Sibi',
                    alias: [],
                },
                {
                    name: 'Sukkur',
                    alias: [],
                },
                {
                    name: 'Zhob',
                    alias: [],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'PL',
            alpha3: 'POL',
            countryCallingCodes: ['+48'],
            currencies: ['PLN'],
            ioc: 'POL',
            languages: ['pol'],
            name: 'Poland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PM',
            alpha3: 'SPM',
            countryCallingCodes: ['+508'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['eng'],
            name: 'Saint Pierre And Miquelon',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PN',
            alpha3: 'PCN',
            countryCallingCodes: ['+872'],
            currencies: ['NZD'],
            ioc: '',
            languages: ['eng'],
            name: 'Pitcairn',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PR',
            alpha3: 'PRI',
            countryCallingCodes: ['+1 787', '+1 939'],
            currencies: ['USD'],
            ioc: 'PUR',
            languages: ['spa', 'eng'],
            name: 'Puerto Rico',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PS',
            alpha3: 'PSE',
            countryCallingCodes: ['+970'],
            currencies: ['JOD', 'EGP', 'ILS'],
            ioc: 'PLE',
            languages: ['ara'],
            name: 'Palestinian Territory, Occupied',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PT',
            alpha3: 'PRT',
            countryCallingCodes: ['+351'],
            currencies: ['EUR'],
            ioc: 'POR',
            languages: ['por'],
            name: 'Portugal',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PU',
            alpha3: 'PUS',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'U.S. Miscellaneous Pacific Islands',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PW',
            alpha3: 'PLW',
            countryCallingCodes: ['+680'],
            currencies: ['USD'],
            ioc: 'PLW',
            languages: ['eng'],
            name: 'Palau',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PY',
            alpha3: 'PRY',
            countryCallingCodes: ['+595'],
            currencies: ['PYG'],
            ioc: 'PAR',
            languages: ['spa'],
            name: 'Paraguay',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'PZ',
            alpha3: 'PCZ',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Panama Canal Zone',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'QA',
            alpha3: 'QAT',
            countryCallingCodes: ['+974'],
            currencies: ['QAR'],
            ioc: 'QAT',
            languages: ['ara'],
            name: 'Qatar',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'RE',
            alpha3: 'REU',
            countryCallingCodes: ['+262'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'Reunion',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'RH',
            alpha3: 'RHO',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Southern Rhodesia',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'RO',
            alpha3: 'ROU',
            countryCallingCodes: ['+40'],
            currencies: ['RON'],
            ioc: 'ROU',
            languages: ['ron'],
            name: 'Romania',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'RS',
            alpha3: 'SRB',
            countryCallingCodes: ['+381'],
            currencies: ['RSD'],
            ioc: 'SRB',
            languages: ['srp'],
            name: 'Serbia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'RU',
            alpha3: 'RUS',
            countryCallingCodes: ['+7', '+7 3', '+7 4', '+7 8'],
            currencies: ['RUB'],
            ioc: 'RUS',
            languages: ['rus'],
            name: 'Russian Federation',
            status: 'assigned',
            provinces: [],
            alias: ['Russia'],
        },
        {
            alpha2: 'RW',
            alpha3: 'RWA',
            countryCallingCodes: ['+250'],
            currencies: ['RWF'],
            ioc: 'RWA',
            languages: ['eng', 'fra', 'kin'],
            name: 'Rwanda',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SA',
            alpha3: 'SAU',
            countryCallingCodes: ['+966'],
            currencies: ['SAR'],
            ioc: 'KSA',
            languages: ['ara'],
            name: 'Saudi Arabia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SB',
            alpha3: 'SLB',
            countryCallingCodes: ['+677'],
            currencies: ['SBD'],
            ioc: 'SOL',
            languages: ['eng'],
            name: 'Solomon Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SC',
            alpha3: 'SYC',
            countryCallingCodes: ['+248'],
            currencies: ['SCR'],
            ioc: 'SEY',
            languages: ['eng', 'fra'],
            name: 'Seychelles',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SD',
            alpha3: 'SDN',
            countryCallingCodes: ['+249'],
            currencies: ['SDG'],
            ioc: 'SUD',
            languages: ['ara', 'eng'],
            name: 'Sudan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SE',
            alpha3: 'SWE',
            countryCallingCodes: ['+46'],
            currencies: ['SEK'],
            ioc: 'SWE',
            languages: ['swe'],
            name: 'Sweden',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SG',
            alpha3: 'SGP',
            countryCallingCodes: ['+65'],
            currencies: ['SGD'],
            ioc: 'SIN',
            languages: ['eng', 'zho', 'msa', 'tam'],
            name: 'Singapore',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SH',
            alpha3: 'SHN',
            countryCallingCodes: ['+290'],
            currencies: ['SHP'],
            ioc: '',
            languages: ['eng'],
            name: 'Saint Helena, Ascension And Tristan Da Cunha',
            status: 'assigned',
            provinces: [],
            alias: ['Saint Helena', 'St. Helena'],
        },
        {
            alpha2: 'SI',
            alpha3: 'SVN',
            countryCallingCodes: ['+386'],
            currencies: ['EUR'],
            ioc: 'SLO',
            languages: ['slv'],
            name: 'Slovenia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SJ',
            alpha3: 'SJM',
            countryCallingCodes: ['+47'],
            currencies: ['NOK'],
            ioc: '',
            languages: [],
            name: 'Svalbard And Jan Mayen',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SK',
            alpha3: 'SVK',
            countryCallingCodes: ['+421'],
            currencies: ['EUR'],
            ioc: 'SVK',
            languages: ['slk'],
            name: 'Slovakia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SK',
            alpha3: 'SKM',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Sikkim',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SL',
            alpha3: 'SLE',
            countryCallingCodes: ['+232'],
            currencies: ['SLL'],
            ioc: 'SLE',
            languages: ['eng'],
            name: 'Sierra Leone',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SM',
            alpha3: 'SMR',
            countryCallingCodes: ['+378'],
            currencies: ['EUR'],
            ioc: 'SMR',
            languages: ['ita'],
            name: 'San Marino',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SN',
            alpha3: 'SEN',
            countryCallingCodes: ['+221'],
            currencies: ['XOF'],
            ioc: 'SEN',
            languages: ['fra'],
            name: 'Senegal',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SO',
            alpha3: 'SOM',
            countryCallingCodes: ['+252'],
            currencies: ['SOS'],
            ioc: 'SOM',
            languages: ['som'],
            name: 'Somalia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SR',
            alpha3: 'SUR',
            countryCallingCodes: ['+597'],
            currencies: ['SRD'],
            ioc: 'SUR',
            languages: ['nld'],
            name: 'Suriname',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SS',
            alpha3: 'SSD',
            countryCallingCodes: ['+211'],
            currencies: ['SSP'],
            ioc: 'SSD',
            languages: ['eng'],
            name: 'South Sudan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ST',
            alpha3: 'STP',
            countryCallingCodes: ['+239'],
            currencies: ['STD'],
            ioc: 'STP',
            languages: ['por'],
            name: 'Sao Tome and Principe',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SU',
            alpha3: '',
            countryCallingCodes: [],
            currencies: ['RUB'],
            ioc: '',
            languages: ['rus'],
            name: 'USSR',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SV',
            alpha3: 'SLV',
            countryCallingCodes: ['+503'],
            currencies: ['USD'],
            ioc: 'ESA',
            languages: ['spa'],
            name: 'El Salvador',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SX',
            alpha3: 'SXM',
            countryCallingCodes: ['+1 721'],
            currencies: ['ANG'],
            ioc: '',
            languages: ['nld'],
            name: 'Sint Maarten',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SY',
            alpha3: 'SYR',
            countryCallingCodes: ['+963'],
            currencies: ['SYP'],
            ioc: 'SYR',
            languages: ['ara'],
            name: 'Syria',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'SZ',
            alpha3: 'SWZ',
            countryCallingCodes: ['+268'],
            currencies: ['SZL'],
            ioc: 'SWZ',
            languages: ['eng', 'ssw'],
            name: 'Swaziland',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TA',
            alpha3: '',
            countryCallingCodes: ['+290'],
            currencies: ['GBP'],
            ioc: '',
            languages: [],
            name: 'Tristan de Cunha',
            status: 'reserved',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TC',
            alpha3: 'TCA',
            countryCallingCodes: ['+1 649'],
            currencies: ['USD'],
            ioc: '',
            languages: ['eng'],
            name: 'Turks And Caicos Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TD',
            alpha3: 'TCD',
            countryCallingCodes: ['+235'],
            currencies: ['XAF'],
            ioc: 'CHA',
            languages: ['ara', 'fra'],
            name: 'Chad',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TF',
            alpha3: 'ATF',
            countryCallingCodes: [],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'French Southern Territories',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TG',
            alpha3: 'TGO',
            countryCallingCodes: ['+228'],
            currencies: ['XOF'],
            ioc: 'TOG',
            languages: ['fra'],
            name: 'Togo',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TH',
            alpha3: 'THA',
            countryCallingCodes: ['+66'],
            currencies: ['THB'],
            ioc: 'THA',
            languages: ['tha'],
            name: 'Thailand',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TJ',
            alpha3: 'TJK',
            countryCallingCodes: ['+992'],
            currencies: ['TJS'],
            ioc: 'TJK',
            languages: ['tgk', 'rus'],
            name: 'Tajikistan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TK',
            alpha3: 'TKL',
            countryCallingCodes: ['+690'],
            currencies: ['NZD'],
            ioc: '',
            languages: ['eng'],
            name: 'Tokelau',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TL',
            alpha3: 'TLS',
            countryCallingCodes: ['+670'],
            currencies: ['USD'],
            ioc: 'TLS',
            languages: ['por'],
            name: 'East Timor',
            status: 'assigned',
            provinces: [],
            alias: ['Timor-Leste', 'Timor Leste'],
        },
        {
            alpha2: 'TM',
            alpha3: 'TKM',
            countryCallingCodes: ['+993'],
            currencies: ['TMT'],
            ioc: 'TKM',
            languages: ['tuk', 'rus'],
            name: 'Turkmenistan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TN',
            alpha3: 'TUN',
            countryCallingCodes: ['+216'],
            currencies: ['TND'],
            ioc: 'TUN',
            languages: ['ara'],
            name: 'Tunisia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TO',
            alpha3: 'TON',
            countryCallingCodes: ['+676'],
            currencies: ['TOP'],
            ioc: 'TGA',
            languages: ['eng'],
            name: 'Tonga',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TR',
            alpha3: 'TUR',
            countryCallingCodes: ['+90'],
            currencies: ['TRY'],
            ioc: 'TUR',
            languages: ['tur'],
            name: 'Turkey',
            status: 'assigned',
            provinces: [
                {
                    name: 'Adana',
                    alias: [],
                },
                {
                    name: 'Adıyaman',
                    alias: ['Adiyaman'],
                },
                {
                    name: 'Afyonkarahisar',
                    alias: [],
                },
                {
                    name: 'Ağrı',
                    alias: ['Agri'],
                },
                {
                    name: 'Amasya',
                    alias: [],
                },
                {
                    name: 'Ankara',
                    alias: [],
                },
                {
                    name: 'Antalya',
                    alias: [],
                },
                {
                    name: 'Artvin',
                    alias: [],
                },
                {
                    name: 'Aydın',
                    alias: ['Aydin'],
                },
                {
                    name: 'Balıkesir',
                    alias: ['Balikesir'],
                },
                {
                    name: 'Bilecik',
                    alias: [],
                },
                {
                    name: 'Bingöl',
                    alias: ['Bingol'],
                },
                {
                    name: 'Bitlis',
                    alias: [],
                },
                {
                    name: 'Bolu',
                    alias: [],
                },
                {
                    name: 'Burdur',
                    alias: [],
                },
                {
                    name: 'Bursa',
                    alias: [],
                },
                {
                    name: 'Çanakkale',
                    alias: ['Canakkale'],
                },
                {
                    name: 'Çankırı',
                    alias: ['Cankiri'],
                },
                {
                    name: 'Çorum',
                    alias: ['Corum'],
                },
                {
                    name: 'Denizli',
                    alias: [],
                },
                {
                    name: 'Diyarbakır',
                    alias: ['Diyarbakir'],
                },
                {
                    name: 'Edirne',
                    alias: [],
                },
                {
                    name: 'Elazığ',
                    alias: ['Elazig'],
                },
                {
                    name: 'Erzincan',
                    alias: [],
                },
                {
                    name: 'Erzurum',
                    alias: [],
                },
                {
                    name: 'Eskişehir',
                    alias: ['Eskisehir'],
                },
                {
                    name: 'Gaziantep',
                    alias: [],
                },
                {
                    name: 'Giresun',
                    alias: [],
                },
                {
                    name: 'Gümüşhane',
                    alias: ['Gumushane'],
                },
                {
                    name: 'Hakkâri',
                    alias: ['Hakkari'],
                },
                {
                    name: 'Hatay',
                    alias: [],
                },
                {
                    name: 'Isparta',
                    alias: [],
                },
                {
                    name: 'Mersin',
                    alias: [],
                },
                {
                    name: 'Istanbul',
                    alias: [],
                },
                {
                    name: 'İzmir',
                    alias: ['Izmir'],
                },
                {
                    name: 'Kars',
                    alias: [],
                },
                {
                    name: 'Kastamonu',
                    alias: [],
                },
                {
                    name: 'Kayseri',
                    alias: [],
                },
                {
                    name: 'Kırklareli',
                    alias: [],
                },
                {
                    name: 'Kırşehir',
                    alias: ['Kırsehir'],
                },
                {
                    name: 'Kocaeli',
                    alias: [],
                },
                {
                    name: 'Konya',
                    alias: [],
                },
                {
                    name: 'Kütahya',
                    alias: ['Kutahya'],
                },
                {
                    name: 'Malatya',
                    alias: [],
                },
                {
                    name: 'Manisa',
                    alias: [],
                },
                {
                    name: 'Kahramanmaraş',
                    alias: ['Kahramanmaras'],
                },
                {
                    name: 'Mardin',
                    alias: [],
                },
                {
                    name: 'Muğla',
                    alias: ['Mugla'],
                },
                {
                    name: 'Muş',
                    alias: ['Mus'],
                },
                {
                    name: 'Nevşehir',
                    alias: ['Nevsehir'],
                },
                {
                    name: 'Niğde',
                    alias: ['Nigde'],
                },
                {
                    name: 'Ordu',
                    alias: [],
                },
                {
                    name: 'Rize',
                    alias: [],
                },
                {
                    name: 'Sakarya',
                    alias: [],
                },
                {
                    name: 'Samsun',
                    alias: [],
                },
                {
                    name: 'Siirt',
                    alias: [],
                },
                {
                    name: 'Sinop',
                    alias: [],
                },
                {
                    name: 'Sivas',
                    alias: [],
                },
                {
                    name: 'Tekirdağ',
                    alias: ['Tekirdag'],
                },
                {
                    name: 'Tokat',
                    alias: [],
                },
                {
                    name: 'Trabzon',
                    alias: [],
                },
                {
                    name: 'Tunceli',
                    alias: [],
                },
                {
                    name: 'Şanlıurfa',
                    alias: ['Sanliurfa'],
                },
                {
                    name: 'Uşak',
                    alias: ['Usak'],
                },
                {
                    name: 'Van',
                    alias: [],
                },
                {
                    name: 'Yozgat',
                    alias: [],
                },
                {
                    name: 'Zonguldak',
                    alias: [],
                },
                {
                    name: 'Aksaray',
                    alias: [],
                },
                {
                    name: 'Bayburt',
                    alias: [],
                },
                {
                    name: 'Karaman',
                    alias: [],
                },
                {
                    name: 'Kırıkkale',
                    alias: ['Kirikkale'],
                },
                {
                    name: 'Batman',
                    alias: [],
                },
                {
                    name: 'Şırnak',
                    alias: ['Sirnak'],
                },
                {
                    name: 'Bartın',
                    alias: ['Bartin'],
                },
                {
                    name: 'Ardahan',
                    alias: [],
                },
                {
                    name: 'Iğdır',
                    alias: ['Igdir'],
                },
                {
                    name: 'Yalova',
                    alias: [],
                },
                {
                    name: 'Karabük',
                    alias: ['Karabuk'],
                },
                {
                    name: 'Kilis',
                    alias: [],
                },
                {
                    name: 'Osmaniye',
                    alias: [],
                },
                {
                    name: 'Düzce',
                    alias: ['Duzce'],
                },
            ],
            alias: [],
        },
        {
            alpha2: 'TT',
            alpha3: 'TTO',
            countryCallingCodes: ['+1 868'],
            currencies: ['TTD'],
            ioc: 'TTO',
            languages: ['eng'],
            name: 'Trinidad And Tobago',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TV',
            alpha3: 'TUV',
            countryCallingCodes: ['+688'],
            currencies: ['AUD'],
            ioc: 'TUV',
            languages: ['eng'],
            name: 'Tuvalu',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TW',
            alpha3: 'TWN',
            countryCallingCodes: ['+886'],
            currencies: ['TWD'],
            ioc: 'TPE',
            languages: ['zho'],
            name: 'Taiwan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'TZ',
            alpha3: 'TZA',
            countryCallingCodes: ['+255'],
            currencies: ['TZS'],
            ioc: 'TAN',
            languages: ['swa', 'eng'],
            name: 'Tanzania',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'UA',
            alpha3: 'UKR',
            countryCallingCodes: ['+380'],
            currencies: ['UAH'],
            ioc: 'UKR',
            languages: ['ukr', 'rus'],
            name: 'Ukraine',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'UG',
            alpha3: 'UGA',
            countryCallingCodes: ['+256'],
            currencies: ['UGX'],
            ioc: 'UGA',
            languages: ['eng', 'swa'],
            name: 'Uganda',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'UM',
            alpha3: 'UMI',
            countryCallingCodes: ['+1'],
            currencies: ['USD'],
            ioc: '',
            languages: ['eng'],
            name: 'United States Minor Outlying Islands',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'UY',
            alpha3: 'URY',
            countryCallingCodes: ['+598'],
            currencies: ['UYU', 'UYI'],
            ioc: 'URU',
            languages: ['spa'],
            name: 'Uruguay',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'UZ',
            alpha3: 'UZB',
            countryCallingCodes: ['+998'],
            currencies: ['UZS'],
            ioc: 'UZB',
            languages: ['uzb', 'rus'],
            name: 'Uzbekistan',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VA',
            alpha3: 'VAT',
            countryCallingCodes: ['+379', '+39'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['ita'],
            name: 'Vatican City State',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VC',
            alpha3: 'VCT',
            countryCallingCodes: ['+1 784'],
            currencies: ['XCD'],
            ioc: 'VIN',
            languages: ['eng'],
            name: 'Saint Vincent And The Grenadines',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VD',
            alpha3: 'VDR',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Democratic Republic of Vietnam',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VE',
            alpha3: 'VEN',
            countryCallingCodes: ['+58'],
            currencies: ['VEF'],
            ioc: 'VEN',
            languages: ['spa'],
            name: 'Venezuela',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VG',
            alpha3: 'VGB',
            countryCallingCodes: ['+1 284'],
            currencies: ['USD'],
            ioc: 'ISV',
            languages: ['eng'],
            name: 'Virgin Islands (British)',
            status: 'assigned',
            provinces: [],
            alias: ['British Virgin Islands'],
        },
        {
            alpha2: 'VI',
            alpha3: 'VIR',
            countryCallingCodes: ['+1 340'],
            currencies: ['USD'],
            ioc: 'ISV',
            languages: ['eng'],
            name: 'Virgin Islands (US)',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'VN',
            alpha3: 'VNM',
            countryCallingCodes: ['+84'],
            currencies: ['VND'],
            ioc: 'VIE',
            languages: ['vie'],
            name: 'Viet Nam',
            status: 'assigned',
            provinces: [
                {
                    name: 'Hà Nội',
                    alias: ['Ha Noi'],
                },
                {
                    name: 'Hà Giang',
                    alias: ['Ha Giang'],
                },
                {
                    name: 'Cao Bằng',
                    alias: ['Cao Bang'],
                },
                {
                    name: 'Bắc Kạn',
                    alias: ['Bac Kan'],
                },
                {
                    name: 'Tuyên Quang',
                    alias: ['Tuyen Quang'],
                },
                {
                    name: 'Lào Cai',
                    alias: ['Lao Cai'],
                },
                {
                    name: 'Điện Biên',
                    alias: ['Dien Bien'],
                },
                {
                    name: 'Lai Châu',
                    alias: ['Lai Chau'],
                },
                {
                    name: 'Sơn La',
                    alias: ['Son La'],
                },
                {
                    name: 'Yên Bái',
                    alias: ['Yen Bai'],
                },
                {
                    name: 'Hòa Bình',
                    alias: ['Hoa Binh'],
                },
                {
                    name: 'Thái Nguyên',
                    alias: ['Thai Nguyen'],
                },
                {
                    name: 'Lạng Sơn',
                    alias: ['Lang Son'],
                },
                {
                    name: 'Quảng Ninh',
                    alias: ['Quang Ninh'],
                },
                {
                    name: 'Bắc Giang',
                    alias: ['Bac Giang'],
                },
                {
                    name: 'Phú Thọ',
                    alias: ['Phu Tho'],
                },
                {
                    name: 'Vĩnh Phúc',
                    alias: ['Vinh Phuc'],
                },
                {
                    name: 'Bắc Ninh',
                    alias: ['Bac Ninh'],
                },
                {
                    name: 'Hải Dương',
                    alias: ['Hai Duong'],
                },
                {
                    name: 'Hải Phòng',
                    alias: ['Hai Phong'],
                },
                {
                    name: 'Hưng Yên',
                    alias: ['Hung Yen'],
                },
                {
                    name: 'Thái Bình',
                    alias: ['Thai Binh'],
                },
                {
                    name: 'Hà Nam',
                    alias: ['Ha Nam'],
                },
                {
                    name: 'Nam Định',
                    alias: ['Nam Dinh'],
                },
                {
                    name: 'Ninh Bình',
                    alias: ['Ninh Binh'],
                },
                {
                    name: 'Thanh Hóa',
                    alias: ['Thanh Hoa'],
                },
                {
                    name: 'Nghệ An',
                    alias: ['Nghe An'],
                },
                {
                    name: 'Hà Tĩnh',
                    alias: ['Ha Tinh'],
                },
                {
                    name: 'Quảng Bình',
                    alias: ['Quang Binh'],
                },
                {
                    name: 'Quảng Trị',
                    alias: ['Quang Tri'],
                },
                {
                    name: 'Thừa Thiên–Huế',
                    alias: ['Thua Thien–Hue', 'Thua Thien Hue'],
                },
                {
                    name: 'Đà Nẵng',
                    alias: ['Da Nang'],
                },
                {
                    name: 'Quảng Nam',
                    alias: ['Quang Nam'],
                },
                {
                    name: 'Quảng Ngãi',
                    alias: ['Quang Ngai'],
                },
                {
                    name: 'Bình Định',
                    alias: ['Binh Dinh'],
                },
                {
                    name: 'Phú Yên',
                    alias: ['Phu Yen'],
                },
                {
                    name: 'Khánh Hòa',
                    alias: ['Khanh Hoa'],
                },
                {
                    name: 'Ninh Thuận',
                    alias: ['Ninh Thuan'],
                },
                {
                    name: 'Bình Thuận',
                    alias: ['Binh Thuan'],
                },
                {
                    name: 'Kon Tum',
                    alias: [],
                },
                {
                    name: 'Gia Lai',
                    alias: [],
                },
                {
                    name: 'Đắk Lắk',
                    alias: ['Dak Lak'],
                },
                {
                    name: 'Đắk Nông',
                    alias: ['Dak Nong'],
                },
                {
                    name: 'Lâm Đồng',
                    alias: ['Lam Dong'],
                },
                {
                    name: 'Bình Phước',
                    alias: ['Binh Phuoc'],
                },
                {
                    name: 'Tây Ninh',
                    alias: ['Tay Ninh'],
                },
                {
                    name: 'Bình Dương',
                    alias: ['Binh Duong'],
                },
                {
                    name: 'Đồng Nai',
                    alias: ['Dong Nai'],
                },
                {
                    name: 'Bà Rịa–Vũng Tàu',
                    alias: ['Ba Ria–Vung Tau', 'Ba Ria Vung Tau'],
                },
                {
                    name: 'Thành phố Hồ Chí Minh',
                    alias: ['Thanh pho Ho Chi Minh'],
                },
                {
                    name: 'Long An',
                    alias: [],
                },
                {
                    name: 'Tiền Giang',
                    alias: ['Tien Giang'],
                },
                {
                    name: 'Bến Tre',
                    alias: ['Ben Tre'],
                },
                {
                    name: 'Trà Vinh',
                    alias: ['Tra Vinh'],
                },
                {
                    name: 'Vĩnh Long',
                    alias: ['Vinh Long'],
                },
                {
                    name: 'Đồng Tháp',
                    alias: ['Dong Thap'],
                },
                {
                    name: 'An Giang',
                    alias: [],
                },
                {
                    name: 'Kiên Giang',
                    alias: ['Kien Giang'],
                },
                {
                    name: 'Cần Thơ',
                    alias: ['Can Thơ'],
                },
                {
                    name: 'Hậu Giang',
                    alias: ['Hau Giang'],
                },
                {
                    name: 'Sóc Trăng',
                    alias: ['Soc Trang'],
                },
                {
                    name: 'Bạc Liêu',
                    alias: ['Bac Lieu'],
                },
                {
                    name: 'Cà Mau',
                    alias: ['Ca Mau'],
                },
            ],
            alias: ['Vietnam'],
        },
        {
            alpha2: 'VU',
            alpha3: 'VUT',
            countryCallingCodes: ['+678'],
            currencies: ['VUV'],
            ioc: 'VAN',
            languages: ['bis', 'eng', 'fra'],
            name: 'Vanuatu',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'WF',
            alpha3: 'WLF',
            countryCallingCodes: ['+681'],
            currencies: ['XPF'],
            ioc: '',
            languages: ['fra'],
            name: 'Territory of the Wallis and Futuna Islands',
            status: 'assigned',
            provinces: [],
            alias: ['Wallis And Futuna', 'Wallis and Futuna Islands'],
        },
        {
            alpha2: 'WK',
            alpha3: 'WAK',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Wake Island',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'WS',
            alpha3: 'WSM',
            countryCallingCodes: ['+685'],
            currencies: ['WST'],
            ioc: 'SAM',
            languages: ['eng', 'smo'],
            name: 'Samoa',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'XK',
            alpha3: '',
            countryCallingCodes: ['+383'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['sqi', 'srp', 'bos', 'tur', 'rom'],
            name: 'Kosovo',
            status: 'user assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'YD',
            alpha3: 'YMD',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Yemen, Democratic',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'YE',
            alpha3: 'YEM',
            countryCallingCodes: ['+967'],
            currencies: ['YER'],
            ioc: 'YEM',
            languages: ['ara'],
            name: 'Yemen',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'YT',
            alpha3: 'MYT',
            countryCallingCodes: ['+262'],
            currencies: ['EUR'],
            ioc: '',
            languages: ['fra'],
            name: 'Mayotte',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'YU',
            alpha3: 'YUG',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Yugoslavia',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ZA',
            alpha3: 'ZAF',
            countryCallingCodes: ['+27'],
            currencies: ['ZAR'],
            ioc: 'RSA',
            languages: ['afr', 'eng', 'nbl', 'som', 'tso', 'ven', 'xho', 'zul'],
            name: 'South Africa',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ZM',
            alpha3: 'ZMB',
            countryCallingCodes: ['+260'],
            currencies: ['ZMW'],
            ioc: 'ZAM',
            languages: ['eng'],
            name: 'Zambia',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ZR',
            alpha3: 'ZAR',
            countryCallingCodes: [],
            currencies: [],
            ioc: '',
            languages: [],
            name: 'Zaire',
            status: 'deleted',
            provinces: [],
            alias: [],
        },
        {
            alpha2: 'ZW',
            alpha3: 'ZWE',
            countryCallingCodes: ['+263'],
            currencies: ['USD', 'ZAR', 'BWP', 'GBP', 'EUR'],
            ioc: 'ZIM',
            languages: ['eng', 'sna', 'nde'],
            name: 'Zimbabwe',
            status: 'assigned',
            provinces: [],
            alias: [],
        },
    ];
}
