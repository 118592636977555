import React, { ReactElement, useState, useRef } from 'react';
import { StyledOuterWrapper, SwitchPill, SwitchHandle } from './Switch.styles';
import SwitchCrownBlocked from './atoms/SwitchCrownBlocked';
import UpgradeOverlay from '../common/blocked/UpgradeOverlay';
import styled from 'styled-components';

export interface ISwitch {
    value: boolean;
    size: 'large' | 'small';
    on_text?: string;
    off_text?: string;
    onChange: (new_value: boolean) => void;
    disabled?: boolean;
    blocked?: boolean;
    className?: string;
}

export interface ISwitchHandle {
    on: number;
    size: ISwitch['size'];
    disabled?: boolean;
}
export interface ISwitchPill {
    size: ISwitch['size'];
    onClick: (new_value: boolean) => void;
    on: number;
    disabled?: boolean;
    blocked?: boolean;
}

const SwitchText = styled.p`
    font-size: 9px;
    margin: 0;
    color: ${(props): string => props.theme.colors.white};
    position: absolute;
`;

const LeftSwitchText = styled(SwitchText)`
    left: 8px;
`;
const RightSwitchText = styled(SwitchText)`
    left: 20px;
`;

export function Switch(props: ISwitch): ReactElement {
    const { value, on_text, off_text, size, disabled, blocked, className, onChange } = props;
    // Styled-components has a bug that means the boolean input value needs to be
    // converted to a number to avoid console errors: https://github.com/styled-components/styled-components/issues/1198
    const value_as_a_number = value ? 1 : 0;

    const [upgrade_open, setUpgradeOpen] = useState(false);
    const switch_ref = useRef(null);

    function toggle(): void {
        if (disabled) {
            return;
        }

        if (blocked) {
            setUpgradeOpen(!upgrade_open);

            return;
        }

        onChange(!value_as_a_number);
    }

    return (
        <div className={className}>
            <StyledOuterWrapper ref={switch_ref}>
                <SwitchPill
                    className='switch-pill'
                    data-testid={'test-switch'}
                    size={size}
                    onClick={toggle}
                    on={value_as_a_number}
                    disabled={disabled}
                    blocked={blocked}
                >
                    {value_as_a_number ? <LeftSwitchText>{on_text}</LeftSwitchText> : null}
                    <SwitchHandle size={size} on={value_as_a_number} disabled={disabled} />
                    {!value_as_a_number ? <RightSwitchText>{off_text}</RightSwitchText> : null}
                </SwitchPill>
                {blocked ? <SwitchCrownBlocked onClick={toggle} /> : null}
            </StyledOuterWrapper>

            {blocked ? <UpgradeOverlay trigger_ref={switch_ref} visible={upgrade_open} /> : null}
        </div>
    );
}
