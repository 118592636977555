import React, { ReactElement, ReactNode } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import {
    Close,
    iconColor,
    StyledIconContainer,
    StyledInlineLink,
    StyledNav,
    Text,
    textColor,
    Wrapper,
} from './Alert.styles';

export type VariantType = 'neutral' | 'unhappy' | 'serious' | 'confirmation';
export interface AlertProps {
    variant?: VariantType;
    message?: string | ReactElement;
    show_alert: boolean;
    show_icon?: boolean;
    icon?: (props: IconBaseProps) => ReactElement;
    onClose?: () => void;
    link?: AlertLinkProps;
    width?: string;
    renderAlertBody?: () => ReactNode;
    className?: string;
}

export interface AlertLinkProps {
    text: string;
    href: string;
    target?: string;
    nav?: boolean;
}

/**
 * Alert component
 * @param props
 * @param props.variant - variant of alert (neutral, unhappy, serious, confirmation) - default: unhappy
 * @param props.message - message to display in alert - default: ''
 * @param props.show_alert - whether to show alert or not - default: false
 * @param props.onClose - function to call when alert is closed
 * @param props.icon - icon to display in alert
 * @param props.link - link to display in alert
 * @param props.show_icon - whether to show icon or not - default: true
 * @param props.width - width of alert
 * @param props.className - className to apply to alert
 * @param props.renderAlertBody - function to render alert body
 * @example <Alert variant='unhappy' message='This is an unhappy alert' show_alert={true} setShowAlert={setShowAlert} />
 * @returns {ReactElement | null} - returns alert component or null
 */
export function Alert(props: AlertProps): ReactElement | null {
    const theme = useTheme();
    const {
        variant = 'unhappy',
        message = '',
        width,
        link,
        show_icon = true,
        show_alert = false,
        icon,
        onClose,
        renderAlertBody,
        className,
    } = props;

    const icon_color = iconColor({ variant, theme });

    return show_alert ? (
        <Wrapper variant={variant} width={width} className={className}>
            {show_icon && (
                <StyledIconContainer>
                    {icon
                        ? icon({ color: icon_color, size: '14px' })
                        : FaExclamationCircle({
                              color: icon_color,
                              size: '14px',
                          })}
                </StyledIconContainer>
            )}

            <Text variant={variant}>
                {renderAlertBody ? (
                    renderAlertBody()
                ) : (
                    <>
                        {message}{' '}
                        {link &&
                            (link.nav ? (
                                <StyledNav exact to={link.href}>
                                    {link.text}
                                </StyledNav>
                            ) : (
                                <StyledInlineLink
                                    href={link.href}
                                    target={link.target}
                                    size='medium'
                                    color={textColor({ variant, theme })}
                                >
                                    {link.text}
                                </StyledInlineLink>
                            ))}
                    </>
                )}
            </Text>

            {onClose && (
                <Close onClick={(): void => onClose()}>
                    <FaTimes color={icon_color} size='10px' />
                </Close>
            )}
        </Wrapper>
    ) : null;
}
