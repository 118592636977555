import { AuthLinkButton } from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { ILoginFooterProps } from '../../../../types/login.types';

export function LoginLink(props: ILoginFooterProps): ReactElement {
    return (
        <AuthLinkButton href='/login'>
            {translate({
                namespace: 'login',
                key: 'common.link.login',
                params: { brand: props.branding.brand_name },
                language: props.language,
            })}
        </AuthLinkButton>
    );
}
