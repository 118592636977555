import styled from 'styled-components';

/** Graphics */
export const GraphicsContainer = styled.div`
    position: relative;
    width: 100%;
    height: 401px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 571px;
        height: 458px;

        top: 52px;
        left: 130px;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        position: absolute;
        top: 99px;
        left: 529px;
    }
`;

export const YellowCircle = styled.div`
    z-index: 1;

    position: absolute;
    top: 423px;
    right: 36px;

    width: 43.69px;
    height: 43.69px;

    background: ${({ theme }): string => theme.colors.gold500};
    border-radius: 495.427px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        z-index: 1;

        top: 355.24px;
        left: 480px;
    }
`;

export const YellowCylinder = styled.div`
    z-index: 1;

    position: absolute;

    top: 423px;
    right: -45px;

    height: 44px;
    width: 125.33px;

    background: ${({ theme }): string => theme.colors.gold500};
    opacity: 0.5;
    border-radius: 495.427px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        z-index: 1;

        top: 355.24px;
        left: 480px;
    }
`;

export const BlueCylinder = styled.div`
    z-index: 0;

    position: absolute;
    top: 165px;
    right: -161px;

    height: 279px;
    width: 712px;

    background: ${({ theme }): string => theme.colors.blue100};
    border-radius: 1031.47px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        z-index: 0;

        top: 99px;
        left: 9px;
    }
`;

export const BlueCircle = styled.div`
    position: absolute;
    width: 47px;
    height: 47px;

    top: -18px;
    left: -20px;

    display: flex;
    justify-content: center;

    border-radius: 47px;
    background: ${({ theme }): string => theme.colors.blue100};
`;

export const BlueStripedCylinder = styled.div`
    z-index: 0;

    position: absolute;

    border-radius: 110.341px;

    background: repeating-linear-gradient(
        135deg,
        ${({ theme }): string => theme.colors.blue200} 0px,
        ${({ theme }): string => theme.colors.blue200} 2px,
        transparent 2px,
        transparent 10px
    );

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 279.43px;
        height: 133.83px;

        top: 186px;
        left: 208.43px;
    }
`;

export const GreenCircle = styled.div`
    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        position: absolute;
        width: 90.22px;
        height: 90.22px;

        background: ${({ theme }): string => theme.colors.green500};
        border-radius: 495.427px;
        width: 71.98px;
        height: 71.98px;

        top: 67.24px;
        left: 458.02px;
    }
`;

export const GreenCylinder = styled.div`
    z-index: 0;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        position: absolute;
        width: 90.38px;
        height: 328.97px;

        background: ${({ theme }): string => theme.colors.green500};
        opacity: 0.5;
        border-radius: 495.427px;
        height: 73.23px;
        width: 314px;

        top: 66px;
        left: 216px;
    }
`;

export const TestimonialContainer = styled.div`
    z-index: 1;
    position: absolute;

    top: 62px;
    right: 30px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        top: 20px;
        left: 76px;
    }
`;

export const ApostropheContainer = styled.div`
    position: absolute;
    top: -13px;

    font-family: 'Bogart';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 100px;

    color: ${({ theme }): string => theme.colors.blue600};
`;
