import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthFooter,
    AuthLoading,
    RegistrationForm,
} from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IJoinAccountPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function JoinAccountPage(props: IJoinAccountPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */
    return <JoinAccountPageContent {...props} />;
}

function JoinAccountPageContent(props: IJoinAccountPageProps): ReactElement {
    const { account, account_invitation, branding, error_message, language } = props;

    const [is_loading, setIsLoading] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onRegister(): void {
        setIsLoading(true);
    }

    return (
        <ThemeProvider theme={theme}>
            <AuthContainer>
                <HeaderLogo branding={props.branding} />

                {is_loading && <AuthLoading />}

                <AuthContent>
                    <AuthCard>
                        <RegistrationForm
                            brand_name={branding.brand_name}
                            email={account_invitation.email}
                            welcome_text={translate({
                                namespace: 'login',
                                key: 'join_account.welcome',
                                params: { brand: branding.brand_name },
                                language,
                            })}
                            post_url={`/u/join/account/${account.id}/${account_invitation.id}`}
                            onSubmit={onRegister}
                            name={`${account_invitation.first_name ||
                                ''} ${account_invitation.last_name || ''}`.trim()}
                            language={language}
                        />

                        <AuthError
                            error={error_message}
                            error_prefix={translate({
                                namespace: 'login',
                                key: 'common.error_prefix',
                                language,
                            })}
                        />
                    </AuthCard>

                    <AuthFooter>
                        <LoginLink branding={branding} language={language} />
                    </AuthFooter>
                </AuthContent>
            </AuthContainer>
        </ThemeProvider>
    );
}
