import React, { ReactElement } from 'react';
import styled, {
    FlattenSimpleInterpolation,
    Keyframes,
    css,
    keyframes,
    useTheme,
} from 'styled-components';
import { AppTheme } from '../../../theme';

export interface IDotSpinningProps {
    size?: number;
    color?: 'blue' | 'grey';
}

interface ILoadingAnimationProps {
    size: number;
    color: string;
}

/**
 * The main element of the loading animation.
 * - Each of the visible dots is a drop shadow positioned relative to the center
 *
 * - Scaling the size of the animation is achieved by using the font-size property. This
 * allows the size and position to be set in ems, which are relative to the font-size.
 *
 * @param props.size The size of the dots
 * @param props.color The color of the dots
 */
const Dot = styled.div<ILoadingAnimationProps>`
    position: relative;
    font-size: ${({ size }): string => `${size}px`};
    width: 1em;
    height: 1em;
    margin: 1.8em 1.8em;
    border-radius: 100%;
    background: transparent;
    color: transparent;
    box-shadow: ${({ color }): FlattenSimpleInterpolation => css`
        /* 90° */
        0 -1.8em 0 0 ${color}, 
        /* 45° */
        calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color},
        /* 0° */
        1.8em 0 0 0 ${color}, 
        /* 315° */
        calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 transparent,
        /* 270° */
        0 1.8em 0 0 transparent, 
        /* 225° */
        calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 transparent,
        /* 180° */
        -1.8em 0 0 0 transparent,
        /* 135° */
        calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 transparent;
    `};

    animation: ${(props): Keyframes => dotAnimation(props)} 1.5s infinite linear;
`;

/**
 * Creates the spinning animation.
 *
 * The animation is split into 8 parts. Each frame the dots appear to rotate 45° clockwise.
 * This is achieved by toggling the visibility of 3 points at a time. Each frame the
 * visible points advance 45° clockwise.
 */
function dotAnimation({ color }: Pick<ILoadingAnimationProps, 'color'>): Keyframes {
    return keyframes`
         0%, 100% {
            box-shadow: 
                0 -1.8em 0 0 ${color}, 
                calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color}, 
                1.8em 0 0 0 ${color}, 
                calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent, 
                0 1.8em 0 -0.5em transparent, 
                calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent, 
                -1.8em 0 0 -0.5em transparent, 
                calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent;
        }
        12.5% {
            box-shadow: 0 -1.8em 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color},
                 1.8em 0 0 0 ${color},
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 0 1.8em 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 -1.8em 0 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent;
        }
        25% {
            box-shadow: 0 -1.8em 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent,
                 1.8em 0 0 0 ${color},
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 0 1.8em 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 -1.8em 0 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent;
        }
        37.5% {
            box-shadow: 0 -1.8em 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent,
                 1.8em 0 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 0 1.8em 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 -1.8em 0 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent;
        }
        50% {
            box-shadow: 0 -1.8em 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent,
                 1.8em 0 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 0 1.8em 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 -1.8em 0 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent;
        }
        62.5% {
            box-shadow: 0 -1.8em 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent,
                 1.8em 0 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 0 1.8em 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 0 ${color},
                 -1.8em 0 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color};
        }
        75% {
            box-shadow: 0 -1.8em 0 0 ${color},
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 -0.5em transparent,
                 1.8em 0 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 0 1.8em 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 -1.8em 0 0 0 ${color},
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color};
        }
        87.5% {
            box-shadow: 0 -1.8em 0 0 ${color},
                 calc(1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color},
                 1.8em 0 0 -0.5em transparent,
                 calc(1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 0 1.8em 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(1.8em * sin(45deg)) 0 -0.5em transparent,
                 -1.8em 0 0 -0.5em transparent,
                 calc(-1.8em * sin(45deg)) calc(-1.8em * sin(45deg)) 0 0 ${color};
        }
    `;
}

export function DotsSpinning(props: IDotSpinningProps): ReactElement {
    const { color = 'blue', size = 10 } = props;

    const { colors } = useTheme() as AppTheme;
    const dot_color = color === 'blue' ? colors.blue500 : colors.grey500;

    return <Dot color={dot_color} size={size} />;
}
