import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';

export function formatAddressAsPrediction(address: Partial<IAddress> | null): string {
    if (!address) {
        return '';
    }

    const { address: address_line_1, city, zip, country, state } = address;

    const components = [address_line_1, city, state, zip, country];

    return components.filter(x => !!x).join(', ');
}
