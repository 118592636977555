import styled from 'styled-components';
import { IconProps } from '../common/types/iconProps';
import { Typography } from '../typography/Typography';
import { IMenuItem, IMenuItemBlockedProps, IMenuProps, LoadingAnimationProps } from './Menu';

export const StyledMenuContainer = styled.div<IMenuProps>`
    position: relative;

    & .menu-section:not(:last-child) {
        border-bottom: 1px solid ${(props): string => props.theme.colors.grey200};
    }
`;

export const NoMargingH5 = styled(Typography.H5)`
    margin: 0;
`;

export const StyledMenuIconRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
`;

export const StyledMenuIconWrapper = styled.div`
    margin-right: 8px;
`;

export const StyledMenuScrollContainer = styled.div<IMenuProps>`
    width: ${(props): string => (props.width ? props.width : 'auto')};
    max-height: ${(props): string => (props.max_height ? `${props.max_height}px` : `none`)};
    overflow-y: auto;

    & .menu-item-wrapper:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    & .menu-item-wrapper:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const StyledMenuItemWrapper = styled.div<{ disabled: boolean }>`
    overflow: hidden;

    & .sub-menu {
        position: absolute;
        transform: translateY(-50%);
        left: 100%;
        z-index: 999;
        visibility: hidden;
    }

    &:hover .sub-menu {
        visibility: visible;
    }
`;

export const StyledMenuItem = styled.div<IMenuItemBlockedProps & IMenuItem>`
    width: ${(props): string => (props.width ? props.width : `100%`)};
    padding: 10px 16px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${(props): string =>
        props.icon && props.icon.position_right && !props.blocked && !props.shrink
            ? 'row-reverse'
            : 'row'};
    color: ${(props): string => {
        if (props.disabled) {
            return props.theme.colors.grey500;
        }
        if (props.selected) {
            return props.theme.colors.blue500;
        }
        switch (props.variant) {
            case 'default':
                return props.theme.colors.grey900;
            case 'subtle':
                return props.theme.colors.grey600;
            case 'link':
                return props.theme.colors.blue500;
            default:
                return props.theme.colors.grey900;
        }
    }};
    cursor: ${(props): string =>
        props.variant === 'link' || typeof props.onClick === 'function' ? 'pointer' : 'auto'};
    font-size: 14px;
    background-color: ${(props): string =>
        props.disabled ? props.theme.colors.grey50 : 'transparent'};
    box-sizing: border-box;
    pointer-events: ${(props): string => (props.disabled ? 'none' : 'auto')};

    &:hover {
        background-color: ${(props): string =>
            !props.disabled ? props.theme.colors.grey100 : 'transparent'};
    }

    &:active {
        background-color: ${(props): string =>
            !props.disabled ? props.theme.colors.blue500 : 'transparent'};
        color: ${(props): string => (!props.disabled ? props.theme.colors.white : 'canvastext')};
    }

    &:focus {
        outline: none;
    }
    span,
    a {
        color: inherit;
        text-decoration: none;
    }
`;

export const StyledMenuItemIconWrapper = styled.div`
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledMenuItemText = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
`;

export const LoadingAnimation = styled.img<LoadingAnimationProps>`
    width: ${(props): string => (props.size === 'large' ? '32px' : '30px')};
    height: ${(props): string => (props.size === 'large' ? '32px' : '30px')};
    padding: 0px 50px 0px 12px;
`;

export const CrownBlockedWrapper = styled.div`
    margin-left: auto;
`;

export const IconWrapper = styled.div<Omit<IconProps, 'value'>>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${(props): string => (props.position_right ? 'auto' : 'none')};
    width: 30px;
    height: 30px;
`;

export const SelectOptionWrapper = styled.div`
    min-width: 200px;
`;
