import styled from 'styled-components';

/** Types */
interface IGraphicsContainerProps {
    show_manual_address: boolean;
}

/** Graphics */
export const GraphicsContainer = styled.div<IGraphicsContainerProps>`
    width: 401.14px;
    min-height: 447.8px;

    position: relative;
    display: ${({ show_manual_address }): string => (show_manual_address ? 'none' : 'block')};
    bottom: 24px;
    left: -27px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        display: block;
        top: -100px;
        left: 301px;
    }

    @media screen and (min-width: 1280px) {
        position: absolute;
        top: 136px;
        left: 588px;
    }
`;

export const StarsContainer = styled.div`
    z-index: 1;

    width: 172px;
    height: 43px;

    position: absolute;
    display: flex;
    left: 217px;
    top: 39px;
    align-items: center;
    justify-content: center;
    gap: 7px;

    background: ${({ theme }): string => theme.colors.white};
    box-shadow: 1.1469px 1.1469px 5.73451px rgba(0, 0, 0, 0.25);
    border-radius: 8.60176px;
`;

export const GreenArrow = styled.img`
    z-index: 1;

    width: 131.29px;
    height: 104.22px;

    position: absolute;
    left: 67px;
    top: 70px;
`;

export const GraphicsImage = styled.img`
    height: 300px;

    position: absolute;
    top: 112px;

    border-radius: 100%;
`;

export const BlueCylinder = styled.div`
    height: 407.83px;
    width: 189.21px;

    position: absolute;
    left: 124px;
    top: 18px;
    transform: rotate(-30deg);

    background: ${({ theme }): string => theme.colors.blue100};
    border-radius: 410.267px;
`;

export const RedCircle = styled.div`
    width: 107.89px;
    height: 107.89px;

    position: absolute;
    left: 0px;
    top: 314.5px;

    background: ${({ theme }): string => theme.colors.red400};
    border-radius: 100%;
`;
