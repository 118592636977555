import styled from 'styled-components';

export const LoadingBar = styled.div<{
    height: number | string;
    width: number | string;
}>`
    @keyframes placeHolderShimmer {
        0% {
            background-position: -400px 0;
        }
        100% {
            background-position: 400px 0;
        }
    }

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    position: relative;
    background-color: ${(props): string => props.theme.colors.grey100};
    background: linear-gradient(
        to right,
        ${(props): string => props.theme.colors.grey100} 8%,
        ${(props): string => props.theme.colors.grey200} 38%,
        ${(props): string => props.theme.colors.grey100} 54%
    );
    background-size: 800px 640px;

    border-radius: 5px;
    height: ${(props): string | number => props.height};
    width: ${(props): string | number => props.width};
`;
