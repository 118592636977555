import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    height: 24px;
    align-items: center;
    margin: 24px;
    width: calc(100% - 48px);
`;
