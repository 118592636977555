import { ILanguage, translate } from '@nicejob-library/internationalization';
import React, { FormEvent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { validatePassword as commonValidatePassword } from '../../../../common/validation/validatePassword';
import { InputField } from '../../core';
import { PasswordField } from '../input/password-field/PasswordField';
import { HiddenInput, PasswordValidation, Row, RowSubmit, SubmitInput } from './Form.styles';

interface RegistrationFormProps {
    brand_name: string;
    post_url: string;
    welcome_text: string;

    email?: string;
    name?: string;
    onSubmit?: (e: FormEvent) => void;
    language: ILanguage;
}

const WelcomeText = styled.p`
    font-size: 17px;
    line-height: 1.6;
    color: ${(props): string => props.theme.colors.grey800};
    margin: 0px;
    padding: 10px 20px 30px;
    font-size: 17px;
    text-align: center;
`;

export function RegistrationForm(props: RegistrationFormProps): ReactElement {
    const {
        brand_name,
        post_url,
        welcome_text,
        onSubmit,
        email: input_email,
        name: input_name,
    } = props;

    const [email, setEmail] = useState(props.email);
    const [password, setPassword] = useState('');
    const [validation, setValidation] = useState({
        valid: false,
        message: '',
        color: '',
    });

    function onChange(name: string) {
        return function(value: string): void {
            if (name === 'email') {
                setEmail(value);
            } else if (name === 'password') {
                setPassword(value);
            }

            const { valid, message, color } = commonValidatePassword({
                password: name === 'password' ? value : password,
                email: name === 'email' ? value : email,
                language: props.language,
            });
            setValidation({ valid, message, color });
        };
    }

    function onSubmitHandler(e: FormEvent): void {
        if (onSubmit) {
            onSubmit(e);
        }
    }

    return (
        <div>
            <form onSubmit={onSubmitHandler} action={post_url} method='POST'>
                <WelcomeText>{welcome_text}</WelcomeText>

                <Row>
                    <InputField
                        required
                        variant='stretch'
                        placeholder={translate({
                            namespace: 'login',
                            key: 'registration_form.name',
                            language: props.language,
                        })}
                        type='text'
                        name='name'
                        initial_value={input_name}
                    />
                </Row>
                <Row>
                    <InputField
                        required
                        variant='stretch'
                        placeholder={translate({
                            namespace: 'login',
                            key: 'registration_form.email_address',
                            language: props.language,
                        })}
                        type='email'
                        name='email'
                        initial_value={input_email}
                        onChange={onChange('email')}
                    />
                </Row>
                <Row>
                    <PasswordField required variant='stretch' onChange={onChange('password')} />
                    <PasswordValidation style={{ color: validation.color }}>
                        {validation.message}
                    </PasswordValidation>
                </Row>
                <RowSubmit>
                    <SubmitInput
                        type='submit'
                        value={translate({
                            namespace: 'login',
                            key: 'registration_form.submit',
                            params: { brand: brand_name },
                            language: props.language,
                        })}
                    />
                </RowSubmit>
                <HiddenInput type='text' name='skip_password_match' value={1} />
                <HiddenInput type='text' name='language_preference' value={props.language} />
            </form>
        </div>
    );
}
