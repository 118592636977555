import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FaCrown } from 'react-icons/fa';

const Blocked = styled.div`
    width: 100%;
`;

const CrownIcon = styled(FaCrown)`
    width: 13px;
    height: 16px;
    color: ${(props): string => props.theme.colors.gold600};
`;

const CrownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 24px;
    background-color: ${(props): string => props.theme.colors.gold50};
    border-radius: 16px;
`;

export default function CrownBlocked(): ReactElement {
    return (
        <Blocked>
            <CrownContainer>
                <CrownIcon />
            </CrownContainer>
        </Blocked>
    );
}
