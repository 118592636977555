import styled from 'styled-components';

export const StyledInput = styled.input`
    margin: 0px 8px;
    width: 100%;
    border-style: none;
    font-size: 16px;
    line-height: 24px;
`;

export const IconContainer = styled.div`
    width: 15px;
    flex-shrink: 0;
    font-size: 15px;
    cursor: pointer;
    color: ${(props): string => props.theme.colors.grey600};
    &:active {
        border: none;
        box-shadow: none;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-style: none;
    border-radius: 18px;
    height: 38px;
    border-color: #c9d7df;
    background-color: #fff;
    border-style: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: #1e2024;
    padding: 8px 12px;

    &:hover {
        background-color: #fafafb;
        ${StyledInput} {
            background-color: #fafafb;
        }
    }
    &:focus-within {
        border: 1px solid #1874fd;
        box-shadow: none;
    }
`;

export const StaticIconContainer = styled.div`
    width: 15px;
    flex-shrink: 0;
    font-size: 15px;
    color: ${(props): string => props.theme.colors.grey600};
`;
