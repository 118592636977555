/**
 * Client constants
 */

//  Local globals
export const Online = document.URL.indexOf('http://localhost') !== 0;
export const JS_VOID = 'javascript:void(0)';
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_PATTERN = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/;

export const DATA_ELEMENT_HANDLED = 'data-nj-element-handled';
export const DATA_RELATED_MODAL = 'data-related-modal';
export const DATA_LEAD_FORM_DISABLE_MODAL = 'data-lead-form-disable';
export const DATA_LEAD_FORM_CUSTOM_HREF = 'data-lead-form-custom-href';
export const DATA_INSTALL_TIME = 'data-install-time';
export const DATA_LOADED_TIME = 'data-loaded-time';
export const DATA_METADATA = 'data-metadata';

export const LOADING_CLASS = 'nj--widget-loading';
export const LOADING_BACKGROUND_CLASS = 'nj-background_loading_gif';

export const AUTO_OPEN_MODAL_CLASS = `nj--auto-open`;

export const VISIBLE_REVIEW_SOURCES = 2;
