import styled from 'styled-components';

/** Types */
export const MainSideBarContainer = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 160px;
    height: 100%;
    max-height: 100%;

    flex-shrink: 0;
    flex-basis: 160px;

    background: ${(props): string => props.theme.sidebar.background};
    color: ${(props): string => props.theme.sidebar.color};
`;

export const CompanySwitcher = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-right: 0px;
    margin-left: 0px;
    padding: 20px 10px 15px 20px;

    line-height: 20px;
    font-weight: 400;

    flex-wrap: nowrap;
    flex: 0 0 auto;

    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    color: ${(props): string => props.theme.colors.white};
`;

export const CompanySwitcherText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;

    flex: 1 1;
`;

export const CompanyName = styled.div`
    position: relative;
    white-space: nowrap;
    overflow: hidden;

    max-width: 110px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: ${(props): string => props.theme.colors.white};
`;

export const CompanySwitchExpand = styled.div`
    margin-top: 5px;
    margin-left: 10px;

    font-family: 'Nicejob v2', sans-serif;
    font-size: 14px;
    text-align: right;
`;

export const NavItemContainer = styled.div`
    flex: auto;
    margin-top: 8px;
`;

export const NavItem = styled.div`
    position: relative;
    padding: 16px;
    display: flex;
`;

export const NavItemText = styled.div`
    height: 24px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;

export const NavItemIcon = styled.div`
    position: relative;
    top: 2px;
    margin-right: 12px;

    display: flex;
    align-items: end;
    line-height: 18px;
    align-self: flex-start;

    font-family: 'Nicejob v2', sans-serif;
    font-size: 12px;
    font-weight: 400;
`;

export const NavDivider = styled.div`
    margin: 8px;

    border-bottom: 1px solid hsla(0, 0%, 98%, 0.12);
    color: #cfcfcf;
`;

export const NavSettingsContainer = styled.div`
    display: flex;
    align-items: center;

    flex-shrink: 0;
    flex-basis: 60px;
`;

export const NavSettingsItem = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;

    align-self: center;
    flex: 1 1;

    color: #8d8d8d;
`;

export const NavSettingsAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    font-size: 12px;
    text-align: center;

    overflow: hidden;
    border-radius: 20px;
    background-color: ${({ theme }): string => theme.colors.blue400};
    color: #fff;
`;

export const NavSettingsTitle = styled.div`
    margin-left: 10px;
    margin-top: -1px;

    font-size: 16px;
    font-weight: 400;
    text-transform: none;

    color: #eee;
`;

export const NavSettingsExpand = styled.div`
    width: 22px;
    height: 22px;
    padding-top: 5px;
    text-align: center;

    font-family: 'Nicejob v2', sans-serif;
    font-size: 13px;
    text-align: center;

    color: #eee;
`;
