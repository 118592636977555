import { NetworkName } from '@nicejob-library/data-platform-implementation/entities/Network/enums';
import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import { theme } from '@nicejob-library/design-system';
import { Paths } from '@nicejob-library/paths';
import { useWindowSize } from '@nicejob-library/react-hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { NicejobFacade } from './components/NicejobFacade';
import { useOnboardingState } from './context/OnboardingStateContext';
import { MainModal, MainPageContainer } from './Onboarding.styles';
import { AdditionalInfo } from './pages/AdditionalInfo';
import { ConnectGBP } from './pages/ConnectGBP';
import { ErrorScreen } from './pages/ErrorScreen';
import { LoadingScreen } from './pages/LoadingScreen';
import { Register } from './pages/Register';

export function OnboardingRouter(): ReactElement {
    const { onboarding_state } = useOnboardingState();

    const [current_company_name, setCurrentCompanyName] = useState<string | null>(null);

    const { height: window_height } = useWindowSize();

    if (!onboarding_state) {
        return <Register />;
    }

    return (
        <>
            <MainPageContainer theme={theme}>
                <NicejobFacade company_name={current_company_name} />
            </MainPageContainer>

            <MainModal theme={theme} window_height={window_height}>
                <OnboardingView
                    onboarding_state={onboarding_state}
                    setCurrentCompanyName={setCurrentCompanyName}
                />
            </MainModal>
        </>
    );
}

interface IRenderOnboardingViewProps {
    onboarding_state: IClientSafeOnboardingState;
    setCurrentCompanyName: (current_company_name: string) => void;
}

function OnboardingView(props: IRenderOnboardingViewProps): ReactElement {
    const { onboarding_state, setCurrentCompanyName } = props;

    // if we're all done, create a login session and redirect to the frontend-route
    useEffect(() => {
        if (onboarding_state.is_onboarding_complete) {
            window.location.href = Paths.ONBOARDING_LOGIN_AND_REDIRECT;
        }
    }, [onboarding_state.is_onboarding_complete]);

    // if there's an error during onboarding that causing onboarding state to be backtracked, display the error message
    if (onboarding_state.has_backtracked) {
        return <ErrorScreen />;
    }

    // if they have neither connected nor aborted their Google Business Profile, render ConnectGBP
    if (
        onboarding_state?.authenticated_networks &&
        !onboarding_state?.authenticated_networks.includes(NetworkName.GOOGLE) &&
        !onboarding_state?.has_aborted_google_connect
    ) {
        return <ConnectGBP />;
    }

    // if they have missing required information, render AdditionalInfo
    const { onboarding_state_id } = onboarding_state;
    const { company_name, company_address } = onboarding_state?.form_data || {};

    if (!company_address || !company_name) {
        return (
            <AdditionalInfo
                onboarding_state_id={onboarding_state_id}
                company_name={company_name || null}
                company_address={company_address || null}
                setCurrentCompanyName={setCurrentCompanyName}
            />
        );
    }

    // if we are waiting for whatever reason (e.g. they have not yet merged), render LoadingScreen
    return <LoadingScreen />;
}
