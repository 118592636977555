import styled from 'styled-components';

interface IOverlayContainerProps {
    visible?: boolean;
}

export const OverlayContainer = styled.div<IOverlayContainerProps>`
    visibility: ${(props): string => (props.visible ? 'visible' : 'hidden')};
    pointer-events: ${(props): string => (props.visible ? 'auto' : 'none')};
`;
export const OverlayInner = styled.div<IOverlayContainerProps>`
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: ${(props): string => props.theme.elevations.z8};
    background: ${(props): string => props.theme.colors.white};
    margin-top: 10px;
    overflow: hidden;
`;
