import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StarRating } from '@nicejob-library/design-system/core';
import React, { ReactElement } from 'react';
import {
    ClientNameText,
    QuoteContainer,
    RatingContainer,
    StarsContainer,
    TestimonialContainer,
    TextContainer,
} from './LoadingScreenTestimonial.styles';

/** Types */
interface ILoadingScreenTestimonialProps {
    text: string;
    client_name: string;
    rating: number;
}

export function LoadingScreenTestimonial(props: ILoadingScreenTestimonialProps): ReactElement {
    const { text, client_name, rating } = props;

    return (
        <TestimonialContainer>
            <RatingContainer>
                <QuoteContainer>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                </QuoteContainer>

                <StarsContainer>
                    <StarRating rating={rating} />
                </StarsContainer>

                <QuoteContainer>
                    <FontAwesomeIcon icon={faQuoteRight} />
                </QuoteContainer>
            </RatingContainer>

            <TextContainer>{text}</TextContainer>

            <ClientNameText>{client_name}</ClientNameText>
        </TestimonialContainer>
    );
}
