import { Alert, Typography } from '@nicejob-library/design-system/core';
import styled from 'styled-components';

export const AccountNotFoundAlert = styled(Alert)`
    margin: 24px auto;
    width: 100%;
    max-width: 432px;
`;

export const AccountNotFoundBody = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const AccountNotFoundBodyText = styled(Typography.P)`
    color: inherit;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    a {
        color: inherit;
        &:active {
            color: inherit;
        }
    }
`;

export const AtomLoginText = styled.h1`
    margin-bottom: 10px;
    font-family: Bogart;
    font-size: 24px;
    color: ${({ theme }): string => theme.colors.grey900};
    line-height: 20px;
`;
