import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Chip, IChipProps } from './Chip';

interface IChipGroupProps {
    values: Array<IChipProps>;
    onRemove?: (index: number) => void;
}

const ChipWrapper = styled.div`
    margin: 4px;
`;

const ChipGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export function ChipGroup({ values, onRemove }: IChipGroupProps): ReactElement {
    return (
        <ChipGroupWrapper>
            {values.map(
                (value, index): ReactElement => {
                    const { text, icon, variant, stretch, chip_size } = value;
                    const key = text ? text : Math.random() * 1000;

                    return (
                        <ChipWrapper key={key}>
                            <Chip
                                key={text}
                                text={text}
                                icon={icon}
                                variant={variant}
                                stretch={stretch}
                                chip_size={chip_size}
                                onRemove={onRemove && ((): void => onRemove(index))}
                            />
                        </ChipWrapper>
                    );
                }
            )}
        </ChipGroupWrapper>
    );
}
