import React, { ReactElement, ReactNode } from 'react';
import {
    StyledLSOLeftSheet,
    StyledLSOMainSheet,
    StyledRSORightSheet,
    StyledRSOMainSheet,
    StyledMSOMainSheet,
    StyledASLeftSheet,
    StyledASMainSheet,
    StyledASRightSheet,
} from './Layout.styles';

export * from './Layout.styles';
export type SheetStatus = 'present' | 'current';

export type CurrentSheet = 'left' | 'right' | 'main';

type SheetsPresentOnPage =
    | 'left_sheet_only'
    | 'right_sheet_only'
    | 'main_sheet_only'
    | 'all_sheets';

export interface IStyledSheetWrapper {
    sheets: Partial<Record<CurrentSheet, Extract<SheetStatus, 'present'>>>;
}
export interface ISheetsPresent {
    left?: SheetStatus;
    main?: SheetStatus;
    right?: SheetStatus;
}

export interface ISheet<T extends CurrentSheet> {
    sheets: Record<T, Extract<SheetStatus, 'current'>> &
        Partial<Record<Exclude<CurrentSheet, T>, Extract<SheetStatus, 'present'>>>;
    children?: ReactNode;
    show_left_sheet_on_mobile?: T extends Extract<CurrentSheet, 'left'>
        ? boolean | undefined
        : undefined;
}

export function sheetsPresent(sheets: ISheetsPresent): SheetsPresentOnPage {
    if (sheets.left && sheets.main && !sheets.right) {
        return 'left_sheet_only';
    }
    if (!sheets.left && sheets.main && sheets.right) {
        return 'right_sheet_only';
    }
    if (!sheets.left && sheets.main && !sheets.right) {
        return 'main_sheet_only';
    }

    return 'all_sheets';
}

export function Sheet<T extends CurrentSheet>(props: ISheet<T>): ReactElement {
    const { sheets, children } = props;

    const sheets_present = sheetsPresent(sheets);
    const current_sheet = (Object.keys(sheets) as CurrentSheet[]).find(
        s => (sheets as ISheetsPresent)[s] === 'current'
    );

    if (sheets_present === 'left_sheet_only') {
        if (current_sheet === 'left') {
            return <StyledLSOLeftSheet>{children}</StyledLSOLeftSheet>;
        }
        if (current_sheet === 'main') {
            return <StyledLSOMainSheet>{children}</StyledLSOMainSheet>;
        }
    }

    if (sheets_present === 'right_sheet_only') {
        if (current_sheet === 'right') {
            return <StyledRSORightSheet>{children}</StyledRSORightSheet>;
        }
        if (current_sheet === 'main') {
            return <StyledRSOMainSheet>{children}</StyledRSOMainSheet>;
        }
    }

    if (sheets_present === 'main_sheet_only') {
        return <StyledMSOMainSheet>{children}</StyledMSOMainSheet>;
    }

    if (sheets_present === 'all_sheets') {
        if (current_sheet === 'left') {
            return <StyledASLeftSheet>{children}</StyledASLeftSheet>;
        }
        if (current_sheet === 'main') {
            return <StyledASMainSheet>{children}</StyledASMainSheet>;
        }
        if (current_sheet === 'right') {
            return <StyledASRightSheet>{children}</StyledASRightSheet>;
        }
    }

    return <StyledMSOMainSheet>{children}</StyledMSOMainSheet>;
}
