import React, {
    Children,
    cloneElement,
    isValidElement,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useRef,
} from 'react';
import styled from 'styled-components';

/** Types */
interface ITabColumnProps {
    current_index: number;
    width?: string;
    onChange: (new_index: number) => void;
    className?: string;
}

/** Styled components */

const TabColumnWrapper = styled.div<{ width?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props): string => props.width || '100%'};
`;

/** Main component */
export function TabColumn(props: PropsWithChildren<ITabColumnProps>): ReactElement {
    const { current_index, children, onChange, width, className } = props;

    const tabs_wrapper_ref = useRef<HTMLDivElement>(null);

    return (
        <TabColumnWrapper width={width} className={className} ref={tabs_wrapper_ref}>
            {renderChildren(current_index, children, onChange)}
        </TabColumnWrapper>
    );
}

/** Helper functions */
function renderChildren(
    current_index: number,
    children: ReactNode,
    onChange: ITabColumnProps['onChange']
): ReactNode {
    const children_with_props = Children.map(
        children,
        (child, i): ReactNode => {
            if (isValidElement(child)) {
                return cloneElement(child, {
                    onClick: (e: MouseEvent) => {
                        child.props.onClick && child.props.onClick(e);

                        // prevent disabled tabs from being selected
                        if (!child.props.disabled) {
                            onChange(i);
                        }
                    },
                    active: i === current_index,
                    column: true,
                });
            }

            return child;
        }
    );

    return children_with_props;
}
