import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthCardText,
    AuthContainer,
    AuthContent,
    AuthFooter,
} from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { IForgotPasswordSentPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function ForgotPasswordSentPage(props: IForgotPasswordSentPageProps): ReactElement {
    const { branding, language } = props;

    return (
        <ThemeProvider theme={theme}>
            <AuthContainer>
                <HeaderLogo branding={branding} />

                <AuthContent>
                    <AuthCard>
                        <AuthCardText>
                            {translate({
                                namespace: 'login',
                                key: 'forgot_password.email_sent',
                                language,
                            })}
                        </AuthCardText>
                    </AuthCard>
                </AuthContent>

                <AuthFooter>
                    <LoginLink branding={branding} language={language} />
                </AuthFooter>
            </AuthContainer>
        </ThemeProvider>
    );
}
