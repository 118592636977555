/**
 * Top 1000 most common passwords after removing ones our validation catches
 *
 * https://raw.githubusercontent.com/danielmiessler/SecLists/master/Passwords/Common-Credentials/10-million-password-list-top-100000.txt
 */
export const COMMON_PASSWORDS = [
    '1qaz2wsx',
    'trustno1',
    'q1w2e3r4t5',
    'q1w2e3r4',
    '1q2w3e4r',
    'jordan23',
    'password1',
    'qwerty123',
    '1q2w3e4r5t',
    'rush2112',
    'passw0rd',
    '1qazxsw2',
    'blink182',
    '12qwaszx',
    '1232323q',
    'Usuckballz1',
    'qwerty12',
    'zaq12wsx',
    'ncc1701d',
    'hello123',
    'michael1',
    '123qweasd',
    'charlie1',
    'a1b2c3d4',
    'password123',
    'Soso123aljg',
    '123qweasdzxc',
    'letmein1',
    'qazwsx123',
    'mustang1',
    'freedom1',
    'fuckyou2',
    '1qaz2wsx3edc',
    'welcome1',
    '123qwe123',
    'wrinkle1',
    'access14',
    'babylon5',
    'yankees1',
    'q1w2e3r4t5y6',
    'jessica1',
    'ncc1701e',
    'super123',
    'letmein2',
    'gn56gn56',
    'matthew1',
    'anthony1',
    'satan666',
    '1q2w3e4r5t6y',
    'fuckyou1',
    'shaney14',
    '1a2b3c4d',
    'Mailcreated5240',
    'william1',
    'zaq1xsw2',
    'formula1',
    'a1s2d3f4',
    'thunder1',
    'heather1',
    'chelsea1',
    'Passw0rd',
    '8J4yE3Uz',
    'richard1',
    'asshole1',
    'qwert123',
    'scooter1',
    'ncc1701a',
    'pa55word',
    'patrick1',
    'gateway1',
    'cowboys1',
    'agent007',
    'porsche9',
    'diamond1',
    'Password1',
    '1qaz1qaz',
    'pokemon1',
    'Turkey50',
    'front242',
    'apollo13',
    'gordon24',
    'brandon1',
    'arsenal1',
    'raiders1',
    '1Passwor',
    '0.0.0.000',
    'Sojdlg123aljg',
    'jackson1',
    'fordf150',
    'pa55w0rd',
    'melissa1',
    'kcj9wx5n',
    'happy123',
    'football1',
    '1qa2ws3ed',
    'rangers1',
    'p0015123',
    'nwo4life',
    'phoenix1',
    'chester1',
    'jasmine1',
    'r2d2c3po',
    'chicken1',
    'Passwor1',
    'marino13',
    'apple123',
    'samsung1',
    '1x2zkg8w',
    'america1',
    'qazwsx12',
    'montgom240',
    '12qw34er',
    '123qwerty',
    '1q2w3e4r5',
    'superman1',
    'zxcvbnm1',
    'james007',
    'zxasqw12',
    'gfhjkm123',
    'packers1',
    'newpass6',
    'charles1',
    'shannon1',
    'madison1',
    'PolniyPizdec0211',
    'nokia6300',
    'chicago1',
    '7uGd5HIp2J',
    'florida1',
    'baseball1',
    '123qq123',
    '50spanks',
    'password2',
    'digital1',
    'z1x2c3v4',
    'vSjasnel12',
    'q2w3e4r5',
    'lineage2',
    'fuckoff1',
    'newyork1',
    'fishing1',
    'dragon12',
    'wg8e3wjf',
    'rebecca1',
    'ferrari1',
    'monster1',
    'crystal1',
    'winston1',
    'monkey12',
    'jackson5',
    'panther1',
    'green123',
    '1a2s3d4f',
    'gandalf1',
    'devil666',
    '9293709b13',
    'rainbow6',
    'qazwsxedc123',
    'scorpio1',
    'iverson3',
    'bulldog1',
    'Michael1',
    'master12',
    'Good123654',
    'dolphin1',
    'pussy123',
    'tiger123',
    'summer99',
    'playboy1',
    'michael2',
    'killer12',
    'iloveyou2',
    'zxcvbnm123',
    'pool6123',
    'mazdarx7',
    'hawaii50',
    'gabriel1',
    '45M2DO5BS',
    '1z2x3c4v',
    'yankees2',
    'tiffany1',
    'nascar24',
    'mazda626',
    'asdfgh01',
    'just4fun',
    'cameron1',
    'andyod22',
    'password12',
    'james123',
    'drummer1',
    'qwerty11',
    'qweasd123',
    'broncos1',
    'zxcasdqwe123',
    'soccer12',
    'soccer10',
    'sasha_007',
    'pumpkin1',
    'porsche1',
    'noname123',
    'death666',
    '12qw12qw',
    'Kordell1',
    'angel123',
    'WP2003WP',
    'pufunga7782',
    'iloveyou1',
    'david123',
    'yamahar1',
    'spencer1',
    'marcius2',
    'ghbdtn123',
    'cygnusx1',
    'buddy123',
    'zachary1',
    'qwe123qwe',
    'mustang6',
    'Misfit99',
    'Letmein1',
    'jackass1',
    'ghhh47hj7649',
    'vikings1',
    'penguin1',
    'Password123',
    'shadow12',
    'private1',
    'nokian73',
    'hallo123',
    'cbr900rr',
    'asdqwe123',
    'warrior1',
    'Trustno1',
    'nirvana1',
    'money123',
    'marines1',
    'cricket1',
    'chris123',
    'bubba123',
    'Welcome1',
    'f00tball',
    'peaches1',
    'nokia6233',
    'maxwell1',
    'mash4077',
    'spartan1',
    'power123',
    'genesis1',
    'favorite6',
    'dodgers1',
    'awesome1',
    'trouble1',
    'testing1',
    'summer69',
    'segblue2',
    'p0o9i8u7',
    'gsxr1000',
    'austin31',
    '5Wr2i7H8',
    '23skidoo',
    '123qwert',
    'voyager1',
    'sammy123',
    'rainbow1',
    'perfect1',
    'pantera1',
    'p4ssw0rd',
    'Jordan23',
    'johnson1',
    'dragon69',
    'sabrina1',
    'ncc74656',
    'natasha1',
    'destiny1',
    '1qazzaq1',
    '1qazxsw23edc',
    'stephen1',
    'Mustang1',
    'liverpool1',
    'killer123',
    'buffalo1',
    '1passwor',
    'therock1',
    'success1',
    'password9',
    'eclipse1',
    'charlie2',
    '1qw23er4',
    '1q1q1q1q',
    'weare138',
    'vanessa1',
    'patches1',
    'password99',
    'forever1',
    'captain1',
    'bubbles1',
    'beatles1',
    'apollo11',
    'starwars1',
    'nemrac58',
    'left4dead',
    'jesus123',
    'flyers88',
    'cartman1',
    'alpha123',
    '55BGates',
    'z1x2c3v4b5',
    'vincent1',
    'sunshine1',
    'mustang2',
    'houston1',
    'care1839',
    'cantona7',
    '69camaro',
    'trinity1',
    'stanley1',
    'skipper1',
    'Qwerty123',
    'natalie1',
    'jupiter1',
    'gizmodo1',
    'dilbert1',
    'computer1',
    'websol76',
    'timoxa94',
    'soccer11',
    'sasha123',
    'q1q2q3q4q5',
    'popcorn1',
    'nokia5800',
    'myspace1',
    'magic123',
    'huskers1',
    'gateway2',
    'fuckme69',
    'beckham7',
    'asdfg123',
    'a1a2a3a4',
    '20spanks',
    '1z2x3c4v5b',
    '1q2q3q4q',
    'zxcvb123',
    'trumpet1',
    'trooper1',
    'qwerty13',
    'qweasdzxc123',
    'princess1',
    'naughty1',
    'mustang5',
    'montana1',
    'mazda323',
    'england1',
    'chickenwing101',
    'bradley1',
    '1million',
    'quant4307s',
    'nokia123',
    'lucky123',
    'justice1',
    'asdasd123',
    'access99',
    '3rJs1la7qE',
    'special1',
    'playboy2',
    'nancy123',
    'molly123',
    'jennifer1',
    'Groupd2013',
    'frankie1',
    'favorite2',
    'Charlie1',
    'anthony7',
    'alexander1',
    'a1b2c3d4e5',
    '987654321a',
    '1qaz2wsx3edc4rfv',
    'vampire1',
    'monkey69',
    'mercury1',
    'Madala11',
    'dragon123',
    'buster12',
    'a1s2d3f4g5',
    '1Michael',
    '0o9i8u7y',
    'warcraft3',
    'spiderman1',
    'nothing1',
    'nokia5530',
    'melanie1',
    'jupiter2',
    'jesus777',
    '911turbo',
    '2wsx3edc',
    '123qwe123qwe',
    '0987654321q',
    'winter99',
    'thumper1',
    'revival47',
    'qaz123wsx',
    'pippen33',
    'paladin1',
    'newproject2004',
    'india123',
    'batman12',
    'angel666',
    'allison1',
    'smoke420',
    'pussy4me',
    'password01',
    'metallica1',
    'jeffrey1',
    'futyn007',
    'friday13',
    'dima1995',
    'dallas22',
    'corvet07',
    'cobra427',
    'chevys10',
    '3edc4rfv',
    '1a2s3d4f5g',
    '12locked',
    'start123',
    'q1q2q3q4',
    'phantom1',
    'L58jkdjP!',
    'kristin1',
    'jessica2',
    'hunter12',
    'Gandalf1',
    'friends1',
    'dragon01',
    'arizona1',
    '1a2a3a4a',
    '123mudar',
    'welcome2',
    'sublime1',
    'sevenof9',
    'sandman1',
    'rt6YTERE',
    'raymond1',
    'monkey123',
    'michelle1',
    'kenneth1',
    'ireland1',
    'flipper1',
    'daniel12',
    'anna2614',
    '1password',
    '1a2b3c4d5e',
    'victory1',
    'sooners1',
    'skeeter1',
    'sanity72',
    'rb26dett',
    'qwerty99',
    'P@ssw0rd',
    'porsche911',
    'liberty1',
    'j3qq4h7h2v',
    'fordf350',
    'enter123',
    'demon666',
    'chopper1',
    'birthday4',
    'austin316',
    'admin123',
    'x72jHhu3Z',
    'William1',
    'qazxsw123',
    'maximus1',
    'master123',
    'freedom2',
    'frank123',
    'everton1',
    'domainlock2005',
    'charlie123',
    'blessed1',
    'sk8ordie',
    'Russian7',
    'rebbyt34',
    'r4e3w2q1',
    'qazwsxedc1',
    'nokia5230',
    'kevin123',
    'Jessica1',
    'Hd764nW5d7E1vb1',
    'detroit1',
    '987654321q',
    '17171717aa',
    '12s3t4p55',
    '123qweas',
    'ZZ8807zpl',
    'timothy1',
    'rocky123',
    'qwerty78',
    'prelude1',
    'password11',
    'nokia5130',
    'nemesis1',
    'music123',
    'madonna1',
    'kristen1',
    'elvis123',
    'dragon11',
    'cezer121',
    'bruno123',
    'anthony2',
    '8PHroWZ622',
    '67camaro',
    '4rfv3edc',
    '1hxboqg2',
    '123masha',
    '123hfjdk147',
    'windows1',
    'Thunder1',
    'soccer13',
    'snowman1',
    'russell1',
    'pokemon123',
    'p455w0rd',
    'newpass1',
    'luv2epus',
    'loveyou2',
    'jason123',
    'homer123',
    'heather2',
    'gregory1',
    'fordf250',
    'eleven11',
    'deborah1',
    'barbara1',
    '5tgb6yhn',
    '5hsU75kpoT',
    '085tzzqi',
    '063dyjuy',
    'yamahar6',
    'thelast1',
    'saab9000',
    'qwerty77',
    'pegasus1',
    'lotus123',
    'lincoln1',
    'lebron23',
    'ironman1',
    'gfhjkm12',
    'georgia1',
    'fireman1',
    'cbr600rr',
    '8PHroWZ624',
    '44magnum',
    '1Letmein',
    '123qwe456',
    'vegas123',
    'usmc0311',
    'trucker1',
    'stewart1',
    'steve123',
    'spartan117',
    'service1',
    'ronaldo7',
    'redneck1',
    'qwerty777',
    'postov1000',
    'payton34',
    'newport1',
    'monkey11',
    'Matthew1',
    'lakers24',
    'kitty123',
    'jimmy123',
    'Gy3Yt2RGLs',
    'f15eagle',
    'emmitt22',
    'element1',
    'capital1',
    'black123',
    'bigdick1',
    'a1a2a3a4a5',
    '1Mustang',
    'unicorn1',
    'teacher1',
    'summer01',
    'scruffy1',
    'qwaszx12',
    'qazxsw12',
    '!QAZ2wsx',
    'pontiac1',
    'october2',
    'mustang9',
    'mustang8',
    'mustang0',
    'michele1',
    'master01',
    'manager1',
    'LoveMe89',
    'letmein22',
    'joshua12',
    'ilya1992',
    'hitman47',
    'favorite8',
    'favorite7',
    'dragon13',
    'douglas1',
    'control1',
    'claudia1',
    'chipper1',
    'camelot1',
    'boris123',
    'andrew12',
    'alabama1',
    'admin18533362',
    '7hrdnw23',
    '1qaz!QAZ',
    '1Basebal',
    '1a2a3a4a5a',
    '151nxjmt',
    '12qwerty',
    'whatever1',
    'thomas12',
    'slayer666',
    'shadow01',
    'Q1w2e3r4',
    'pyramid1',
    'polniypizdec0211',
    'Phoenix1',
    'pepsi123',
    'nokian70',
    'michael9',
    'Lineage2',
    'jbond007',
    'ghbdtn12',
    'general1',
    'dragon99',
    'damage11',
    'boscoe01',
    'birthday1',
    'atlanta1',
    '33rjhjds',
    '2wsxzaq1',
    'william2',
    'viper123',
    'trojans1',
    'trader12',
    'tigger12',
    'sk84life',
    'shadow11',
    'Rush2112',
    'phoenix2',
    'password3',
    'oscar123',
    'orange12',
    'nonrev67',
    'lucas123',
    'lindsay1',
    'germany1',
    'fuckyou123',
    'dreamer1',
    'cynthia1',
    'c3por2d2',
    'buckeye1',
    'art131313',
    '23176djivanfros',
    '1qay2wsx',
    '123321qwe',
    'zaq1xsw2cde3',
    'westham1',
    'w1w2w3w4',
    'summer12',
    'stinger1',
    'slimed123',
    'save13tx',
    'redhead1',
    'psychnaut1',
    'porn4life',
    'peter123',
    'october1',
    'oc247ngUcZ',
    'nokia5300',
    'nokia3250',
    'mustang3',
    'monique1',
    'miranda1',
    'michael8',
    'lindsey1',
    'isacs155',
    'francis1',
    'fatluvr69',
    'ducati99',
    'camaroz2',
    'blondie1',
    'Babylon5',
    'antonio1',
    'account1',
    '1q2q3q4q5q',
    '1Jennife',
    '1357911q',
    '123qweASD',
    'warcraft1',
    'tigger01',
    'system32',
    'slayer66',
    'rosebud1',
    'qwerty22',
    'Qwerty12',
    'q1q1q1q1',
    'mounta1n',
    'michael3',
    'lucky777',
    'loveyou1',
    'justin12',
    'january1',
    'jamaica1',
    'ibill123',
    'hooters1',
    'hershey1',
    'halflife2',
    'ginger12',
    'dragon88',
    'derrick1',
    'cvzefh1gkc',
    'crimson1',
    'chrissy1',
    'chocolate1',
    'charlie3',
    'charles2',
    'catfish1',
    'camaro69',
    'brianna1',
    'brian123',
    'Blink182',
    'blaster1',
    '68camaro',
    '4r3e2w1q',
    '4904s677075',
    '2112rush',
    '123q123q',
    '123123123q',
    'xsw21qaz',
    'vlad1996',
    'trustn01',
    'thomas01',
    't34vfrc1991',
    'skyline1',
    'scott123',
    'samantha1',
    'qwaszx123',
    'police22',
    'player69',
    'pi314159',
    'peaches2',
    'passion1',
    'pandora1',
    'oceans11',
    'metal666',
    'maverick1',
    'matthew2',
    'master11',
    'internet1',
    'hot2trot',
    'Elvira26',
    'caitlin1',
    'buster01',
    'azerty123',
    '3edcvfr4',
    '380zliki',
    '2wsxcde3',
    '123123qwe',
    'winston2',
    'water123',
    'soccer15',
    'soccer14',
    'shadow69',
    'shadow13',
    'seattle1',
    'qwer4321',
    'q8zo8wzq',
    'pioneer1',
    'pepper12',
    'Patrick1',
    'orlando1',
    'nokia3310',
    'matthew7',
    'kirill123',
    'jonathan1',
    'jackson2',
    'golfing1',
    'dragon22',
    'daddy123',
    'bulldog2',
    'biteme69',
    'batman99',
    'asd123asd',
    '3stooges',
    '123qaz123',
    '013cpfza',
    '007james',
    'venom121293',
    'toronto1',
    'taylor12',
    'sunrise1',
    'soldier1',
    'slipknot1',
    'silver12',
    'scooby12',
    'sarah123',
    'rooster1',
    'ronaldo1',
    'ready2go',
    'r2d2c3p0',
    'phillip1',
    'patrick2',
    'password0',
    'nolimit8',
    'nikita123',
    'nicholas1',
    'london12',
    'kelly123',
    'kelly001',
    'indiana1',
    'hockey12',
    'gnasher23',
    'george12',
    'express1',
    'elaine22',
    'dragon23',
    'doma77ns',
    'dima1996',
    'death123',
    'DAD2OWNu',
    'college1',
    'Chelsea1',
    'celtics1',
    'cadr14nu',
    'brother1',
    'bobby123',
    'birthday21',
    'Basebal1',
    'ashley12',
    'amanda18',
    'a1a1a1a1',
    '78N3s5Af',
    '4rfv5tgb',
    '383pdjvl',
    '1Footbal',
    '154ugeiu',
    'Year2005',
    'winter12',
    'whiskey1',
    'vaz21099',
    'usmc1775',
    'trigger1',
    'titanic1',
    'sunny123',
    'strange1',
    'stalker1',
    'soccer22',
    'smile123',
    'shadow123',
    'scooter2',
    'richard2',
    'review69',
    'redsox04',
    'qwerty00',
    'qazxsw21',
    'q1w2e3r4t',
    'pxx3eftp',
    'postov10',
    'poiu0987',
    'piazza31',
    'password69',
    'password4',
    'orange44',
    'network1',
    'mine2306',
    'million1',
    'larry123',
    'Jennife1',
    'holiday1',
    'hihje863',
    'hawkeye1',
    'gizmo123',
    'freedom7',
    'free4all',
    'Footbal1',
    'ferrari3',
    'favorite5',
    'dragons1',
    'denis123',
    'dapzu455',
    'daniel123',
    'cupcake1',
    'connect1',
    'CidKid86',
    'chicago2',
    'chester2',
    'chelsea2',
    'broncos7',
    'blackie1',
    'biscuit1',
    'bigfoot1',
    'batman69',
    'anton123',
    'amanda12',
    '2w3e4r5t',
    '2sexy2ho',
    '123qwe321',
    '123asd123',
    '10inches',
    'xaccess2',
    'Winston1',
    'victoria1',
    'toshiba1',
    'take8422',
    'steelers1',
    'slipknot666',
    'seven777',
    'ronaldo9',
    'roberto1',
    'robert12',
    'rasta220',
    'qwerty21',
    'qwe123asd',
    'patrick9',
    'password7',
    'password6',
    'Panther1',
    'Packers1',
    'packard1',
    'nolimit9',
    'nokia6303',
    'monkey13',
    'mnbvcxz1',
    'michael7',
    'mazdarx8',
    'ladybug1',
    'jordan12',
    'hammers1',
    'google123',
    'garrett1',
    'Fuckyou1',
    'Front242',
    'fortune12',
    'fantasy1',
    'EFBCAPA201',
    'dragon66',
    'devilmaycry4',
    'defiant1',
    'combat123654',
    'christy1',
    'chevyz71',
    'charmed1',
    'bella123',
    'babygirl1',
    'acUn3t1x',
    'abigail1',
    '89231243658s',
    '4z34l0ts',
    '12monkey',
    '12e3E456',
    '11223344q',
    'yy5rbfsc',
    'Yankees1',
    'xsw23edc',
    'wrinkle5',
    'wrest666',
    'verizon1',
    'testing123',
    'teddy123',
    'studio54',
    'stealth1',
    'shotgun1',
    'sherman1',
    'shadows1',
    'senha123',
    'satana666',
    'sandy123',
    'samsung2',
    'Richard1',
    'ranger99',
    'Raiders1',
    'qweqwe123',
    'qw12er34',
    'ptfe3xxp',
    'polaris1',
    'pokemon12',
    'pentium4',
    'patriot1',
    'passwor1',
    'Pa55word',
    'onelove1',
    'nokia5310',
    'nascar88',
    'mustang7',
    'master99',
    'martin12',
    'maria123',
    'm6cJy69u35',
    'katie123',
    'just4you',
    'huskies1',
];
