import React, { ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { IconProps } from '../../common/types/iconProps';
import { Typography } from '../../typography/Typography';

/** Types */
interface ITabProps {
    text: string;
    active?: boolean;
    disabled?: boolean;
    icon?: IconProps;
    left_element?: ReactElement;
    right_element?: ReactElement;
    column?: boolean;
    onClick?: () => void;
    intercom_target?: string;
    className?: string;
}

/** Styled Components */
const TabWrapper = styled.div<{
    active: boolean;
    disabled: boolean;
    column: boolean;
    onClick?: () => void;
}>`
    display: flex;
    width: ${(props): string => (props.column ? '100%' : 'auto')};
    padding: ${(props): string => (props.column ? '16px 20px' : '0 20px')};
    align-items: center;
    border-bottom: ${(props): string =>
        props.column ? `1px solid ${props.theme.colors.grey200}` : 'none'};

    cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
    color: ${getColor};
    fill: ${getColor};
    background-color: ${getBackgroundColor};

    &:hover {
        color: ${getHoverColor};
        fill: ${getHoverColor};
        background-color: ${getHoverBackgroundColor};
    }
`;

const TabP = styled(Typography.P)`
    color: inherit;
    flex: 1;
`;

const IconWrapper = styled.div<{
    position_right: boolean;
}>`
    display: flex;
    height: 14px;
    width: 14px;
    fill: inherit;
    margin-left: ${(props): string => (props.position_right ? '8px' : '0px')};
    margin-right: ${(props): string => (props.position_right ? '0px' : '8px')};
`;

const RightElementWrapper = styled.div`
    margin-left: 8px;
`;

/** Helper functions */
function getColor(props: { disabled: boolean; active: boolean; theme: DefaultTheme }): string {
    return props.disabled
        ? props.theme.colors.grey400
        : props.active
        ? props.theme.colors.blue600
        : props.theme.colors.grey900;
}

function getHoverColor(props: { disabled: boolean; column: boolean; theme: DefaultTheme }): string {
    return props.disabled
        ? props.theme.colors.grey400
        : props.column
        ? props.theme.colors.grey900
        : props.theme.colors.blue600;
}

function getHoverBackgroundColor(props: {
    active: boolean;
    column: boolean;
    disabled: boolean;
    theme: DefaultTheme;
}): string {
    return props.column
        ? props.disabled
            ? props.theme.colors.white
            : props.theme.colors.grey100
        : 'transparent';
}

function getBackgroundColor(props: {
    active: boolean;
    column: boolean;
    theme: DefaultTheme;
}): string {
    return props.column
        ? props.active
            ? props.theme.colors.blue50
            : props.theme.colors.white
        : 'transparent';
}

/** Main component */
export function Tab(props: ITabProps): ReactElement {
    const {
        text,
        active = false,
        disabled = false,
        left_element,
        right_element,
        icon = null,
        column = false,
        onClick,
        intercom_target,
        className = '',
    } = props;

    const { value: icon_element, position_right = false } = icon || {};

    return (
        <TabWrapper
            active={active}
            disabled={disabled}
            onClick={onClick}
            column={column}
            data-intercom-target={intercom_target}
            className={className}
        >
            {left_element ? (
                <div
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    {left_element}
                </div>
            ) : null}

            {icon_element && !position_right ? (
                <IconWrapper position_right={position_right}>{icon_element}</IconWrapper>
            ) : null}

            <TabP>{text}</TabP>

            {icon_element && position_right ? (
                <IconWrapper position_right={position_right}>{icon_element}</IconWrapper>
            ) : null}

            {right_element ? (
                <RightElementWrapper
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    {right_element}
                </RightElementWrapper>
            ) : null}
        </TabWrapper>
    );
}
