import styled, { keyframes } from 'styled-components';

const circle_animation = keyframes`
    50% {
        transform: scale(2);
        opacity: 0;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
`;

export const Root = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Circle = styled.div`
    content: ' ';
    margin: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.3s;

    &::before {
        content: ' ';
        margin: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 0 auto;
        transition: all 0.3s;

        animation-name: ${circle_animation};
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        position: absolute;
        background-color: #00ff00;
    }
`;
