import { Online } from '@nicejob/nicejob-toolbox';
import { NiceJobProjectId } from '../../../../../../../../types/common.types';

export function trackRegistrationInPartnerstack(data: {
    info: {
        user_name: string;
        email: string;
    };
}): boolean {
    try {
        if (
            !Online ||
            process.env.REACT_APP_PROJECT_ID !== NiceJobProjectId.production ||
            !growsumo
        ) {
            return false;
        }

        const {
            info: { user_name, email },
        } = data;

        // global-scoped, see login-route/custom.d.ts
        growsumo.data.name = user_name || '';
        growsumo.data.email = email || '';
        growsumo.data.customer_key = growsumo.data.email;
        growsumo.createSignup();

        return true;
    } catch (error) {
        // no throw

        return false;
    }
}
