import styled from 'styled-components';
import { Divider } from '../../core';

/** Types */
interface IConnectGBPTypography {
    font_weight: number;
    font_size: number;
    is_link?: boolean;
}

interface IMainModalProps {
    window_height: number;
}

/** Styled Components */
/** Main */
export const DimmedOverlayBackground = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
`;

export const MainModal = styled.div<IMainModalProps>`
    position: fixed;
    background: ${({ theme }): string => theme.colors.white};
    overflow-x: hidden;
    height: 100vh;
    /* Default style for mobile */
    z-index: ${({ theme }): number => theme.zIndex.z100};
    top: 0;
    width: 100%;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 720px;
        height: ${({ window_height }): string => `${window_height - 48}px`};
        max-height: 884px;

        background: ${({ theme }): string => theme.colors.white};
        box-shadow: ${({ theme }): string => theme.elevations.z24};
        border-radius: 8px;
    }

    @media screen and (min-width: 1280px) {
        width: 1100px;
        max-height: 720px;
    }
`;

export const MainContainer = styled.div`
    position: relative;
    background: ${({ theme }): string => theme.colors.white};
    overflow-x: hidden;

    height: 100vh;
    display: flex;
    flex-direction: column;

    height: 100%;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        flex-direction: column;
        align-items: normal;

        max-height: inherit;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        flex-direction: row;
        align-items: center;
    }
`;

export const MainActionContainer = styled.div`
    position: inherit;
    min-width: 282px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    top: 16px;
    margin-left: 19px;
    margin-right: 19px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        position: inherit;
        width: 594px;

        margin-top: 41px;
        margin-left: 56px;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        position: inherit;
        width: 442px;
        justify-content: center;

        margin-top: 0;
        margin-left: 56px;
        margin-bottom: 85px;
    }
`;

export const WelcomeText = styled.p`
    font-family: 'Bogart';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    margin-bottom: 0;

    color: ${({ theme }): string => theme.colors.blue950};
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    min-width: 320px;
    margin-top: 31px;
`;

export const Typography = styled.p<IConnectGBPTypography>`
    width: fit-content;

    display: flex;
    align-items: center;
    text-align: right;

    font-style: normal;
    font-weight: ${({ font_weight }): number => font_weight};
    font-size: ${({ font_size }): string => `${font_size}px`};
    line-height: 24px;
    margin-bottom: 0;

    color: ${({ is_link, theme }): string =>
        is_link ? theme.colors.blue600 : theme.colors.typography};

    cursor: ${(props): string => (props.onClick ? 'pointer' : 'auto')};
`;

export const CreateGBPTipContainer = styled.div`
    width: 375px;
    height: 80px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;

    background-color: ${({ theme }): string => theme.colors.blue50};
    border-radius: 16px;
`;

export const CreateGBPTipContainerText = styled.p`
    color: ${({ theme }): string => theme.colors.blue900};
    font-weight: 400;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    line-height: 24px;
`;

/** Footer */
export const MainContainerFooter = styled.div`
    margin-top: 24px;
    width: 100%;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        position: fixed;
        bottom: 0px;
        top: auto;

        margin-bottom: 36px;
    }
`;

export const ExitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-style: normal;
    font-weight: 0;
    font-size: 14px;
    line-height: 18px;

    margin-right: 19.5px;
    margin-top: 12px;

    cursor: pointer;

    color: ${({ theme }): string => theme.colors.typography};

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        display: none;
    }
`;

export const ConnectGBPDivider = styled(Divider)`
    margin-bottom: 8px;
    max-width: 468px;
`;
