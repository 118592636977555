import { ENGLISH_LANGUAGE } from '../data/locales/en';
import { INamespaceTranslationKeyMap } from '../data/locales/types';
import { IInternationalizationNamespace } from '../data/namespaces';

/**
 * @function determineDefaultValue
 * @description Determine the default value for a given translation key
 */
export function determineDefaultValue<Namespace extends IInternationalizationNamespace>({
    namespace,
    key,
}: {
    namespace: Namespace;
    key: INamespaceTranslationKeyMap<Namespace>;
}): string {
    const namespace_values = ENGLISH_LANGUAGE.namespaces[namespace];

    const value = key.split('.').reduce(
        (o, i) =>
            // @ts-expect-error: TS doesn't recognize `i` as `keyof o`
            o[i],
        namespace_values
    );

    //  Have to cast, since TS doesn't recognize that `value` finally resolves to a string
    return (value as unknown) as string;
}
