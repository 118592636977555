import { theme } from '@nicejob-library/design-system';
import { useState, useEffect } from 'react';

/**
 * useWindowSize: Hook for window resizing
 * - Returns the current window size (w + h)
 * - Will trigger a rerender of its component with a new response
 *   whenever the window size changes
 * - e.g: This component will rerender with every window resize
 *
 *   function component(){
 *      const size = useWindowSize();
 *
 *      return (
 *          // something...
 *      )
 *   }
 */

interface WindowSize {
    width: number;
    height: number;
    is_at_least: Record<WindowType, boolean>;
}

export enum WindowType {
    DESKTOP,
    TABLET,
    MOBILE_HORIZONTAL,
    MOBILE_VERTICAL,
}

export function useWindowSize(): WindowSize {
    function getSize(): WindowSize {
        const width = window.innerWidth;
        const height = window.innerHeight;

        const is_at_least: Record<WindowType, boolean> = {
            [WindowType.DESKTOP]: width >= theme.responsiveBreakPoints.desktop,
            [WindowType.TABLET]: width >= theme.responsiveBreakPoints.tablet,
            [WindowType.MOBILE_HORIZONTAL]: width >= theme.responsiveBreakPoints.mobile_horizontal,
            [WindowType.MOBILE_VERTICAL]: width >= theme.responsiveBreakPoints.mobile_vertical,
        };

        return {
            width,
            height,
            is_at_least,
        };
    }

    const [window_size, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize(): void {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);

        return (): void => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return window_size;
}
