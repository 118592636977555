import React, { useRef, useEffect } from 'react';

export function usePrevious<T>(value: T): React.MutableRefObject<T | undefined>['current'] {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
