/* eslint-disable @nicejob/snake-case */
export enum Role {
    // User Roles
    OWNER = 'OWNER',
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',

    // Admins
    ADMIN = 'ADMIN',
    SUPERADMIN = 'SUPERADMIN',

    // States
    DELETE = 'DELETE',
    EDIT = 'EDIT',

    // No User Access
    NO_ACCESS = 'NO_ACCESS',
}

export const CompanyRoles = [Role.MANAGER_PLUS_PAYMENT, Role.MANAGER, Role.TEAM_MEMBER] as const;
export const AccountRoles = [
    Role.OWNER,
    Role.MANAGER_PLUS_PAYMENT,
    Role.MANAGER,
    Role.TEAM_MEMBER,
] as const;
export const UserRoles = [
    Role.OWNER,
    Role.MANAGER_PLUS_PAYMENT,
    Role.MANAGER,
    Role.TEAM_MEMBER,
] as const;
export const AdminRoles = [Role.ADMIN, Role.SUPERADMIN] as const;

export type ICompanyRole = typeof CompanyRoles[number];
export type IAccountRole = typeof AccountRoles[number];
export type IUserRole = typeof UserRoles[number];
export type IAdminRole = typeof AdminRoles[number];
