import { config } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ILoginRoutePages } from '../types/login.types';
import { OnboardingApp } from './onboarding/client/OnboardingApp';
import {
    ForgotPasswordPage,
    ForgotPasswordSentPage,
    InvalidInvitationPage,
    JoinAccountPage,
    JoinCompanyPage,
    LoginPage,
    PaymentsLoginPage,
    RegisterTest,
    ResetPasswordDonePage,
    ResetPasswordInvalidPage,
    ResetPasswordPage,
} from './pages';
import { SetPasswordPage } from './pages/SetPassword';
import './scss/login.scss';

// Disable fontawesome auto CSS injection since it is already included in the index.html head
config.autoAddCss = false;

//  NB: Using `declare global { interface Window {` in multiple places in the codebase (i.e. in each src/index.tsx file) causes issues
//  - Need to use (window as Window)
interface Window {
    __APP_PROPS__?: ILoginRoutePages;
}

/**
 * 1: Get <App> props
 */
const root = document.getElementById('root');

const app_props = (window as Window).__APP_PROPS__;

/**
 * 2: Hydrate
 */

switch (app_props?.page) {
    case 'login':
        ReactDOM.hydrate(<LoginPage {...app_props} />, root);
        break;

    case 'payments-login':
        ReactDOM.hydrate(<PaymentsLoginPage {...app_props} />, root);
        break;

    case 'join-account':
        ReactDOM.hydrate(<JoinAccountPage {...app_props} />, root);
        break;

    case 'join-company':
        ReactDOM.hydrate(<JoinCompanyPage {...app_props} />, root);
        break;

    case 'invalid-invitation':
        ReactDOM.hydrate(<InvalidInvitationPage {...app_props} />, root);
        break;

    case 'forgot-password':
        ReactDOM.hydrate(<ForgotPasswordPage {...app_props} />, root);
        break;

    case 'forgot-password-sent':
        ReactDOM.hydrate(<ForgotPasswordSentPage {...app_props} />, root);
        break;

    case 'reset-password':
        ReactDOM.hydrate(<ResetPasswordPage {...app_props} />, root);
        break;

    case 'reset-password-done':
        ReactDOM.hydrate(<ResetPasswordDonePage {...app_props} />, root);
        break;

    case 'reset-password-invalid':
        ReactDOM.hydrate(<ResetPasswordInvalidPage {...app_props} />, root);
        break;

    case 'register-test':
        ReactDOM.hydrate(<RegisterTest {...app_props} />, root);
        break;

    case 'set-password':
        ReactDOM.hydrate(<SetPasswordPage {...app_props} />, root);
        break;

    case 'onboarding':
        ReactDOM.hydrate(
            <BrowserRouter>
                <OnboardingApp {...app_props} />
            </BrowserRouter>,
            root
        );
        break;
}

// Clear out APP_PROPS after hydration
delete (window as Window).__APP_PROPS__;
