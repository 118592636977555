import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICountryPhoneInfo } from '@nicejob-library/phone/types';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { Divider, Dropdown, Menu, MenuItem } from '../../../core';
import {
    FlagImage,
    FlagImageOnMenu,
    InputWrapper,
    PhoneInputDivider,
    Root,
    StyledButton,
    StyledInput,
    StyledMenuWrapper,
} from './PhoneNumberInput.styles';

export type IPhoneNumberInputStates = ICountryPhoneInfo & {
    formatted: string;
    raw: string;
    is_valid: boolean;
};
export type IPhoneNumberInfo = Pick<
    IPhoneNumberInputStates,
    'formatted' | 'raw' | 'is_valid' | 'calling_code' | 'country_iso_code'
>;

export interface IPhoneNumberInputProps {
    placeholder?: string;
    disabled?: boolean;
    value?: string;
    selected_country_iso_code?: CountryCode;

    input_ref?: React.RefObject<HTMLInputElement>;

    onRemovePhoneFormat: (phone_number: string) => string;
    onGetCountriesInfo: () => ICountryPhoneInfo[];

    onChange?: (phone_number_info: IPhoneNumberInfo) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function PhoneNumberInput(props: IPhoneNumberInputProps): ReactElement {
    const {
        placeholder,
        disabled,
        value,
        selected_country_iso_code,
        input_ref: init_input_ref = null,
        onRemovePhoneFormat,
        onGetCountriesInfo,
        onChange,
        onKeyDown,
    } = props;

    const countries = useMemo<ICountryPhoneInfo[]>(onGetCountriesInfo, []);
    const initial_country = selected_country_iso_code
        ? countries.find(country => country.country_iso_code === selected_country_iso_code) ||
          countries[0]
        : countries[0];
    const [input_value, setInputValue] = useState<IPhoneNumberInputStates>({
        ...initial_country,
        formatted: value || `+${initial_country.calling_code}`,
        raw: initial_country.calling_code,
        is_valid: false,
    });
    const [dropdown_open, setDropdownOpen] = useState<boolean>(false);

    if (selected_country_iso_code !== input_value.country_iso_code) {
        const selected_country = countries.find(
            country => country.country_iso_code === selected_country_iso_code
        );

        if (selected_country !== undefined) {
            setInputValue(prev => ({
                ...prev,
                ...selected_country,
            }));
        }
    }

    function handleDropdownButtonClick(e: React.MouseEvent<HTMLButtonElement>): void {
        e.stopPropagation();
        setDropdownOpen(!dropdown_open);
    }

    function handleCountrySelect(country: ICountryPhoneInfo): void {
        setDropdownOpen(false);

        setInputValue(prev => {
            const new_value = {
                ...prev,
                ...country,
                formatted: `+${country.calling_code}`,
                raw: country.calling_code,
            };

            onChange?.({
                ...new_value,
                formatted: `+${new_value.calling_code}`,
                raw: `${new_value.calling_code}`,
                calling_code: new_value.calling_code,
                is_valid: false,
            });

            return new_value;
        });
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
        const value = event.target.value;
        const removing_char = value.length < input_value.formatted.length;

        const entered_calling_code = value.replace(/\D/g, '');

        // Check if the selected_country_iso_code matches the entered calling code
        const selected_country = selected_country_iso_code
            ? countries.find(
                  c =>
                      c.country_iso_code === selected_country_iso_code &&
                      entered_calling_code.startsWith(c.calling_code)
              )
            : countries.find(c => entered_calling_code.startsWith(c.calling_code));

        let country_code: string;
        let current_country: ICountryPhoneInfo | undefined;

        if (value.length === 0 || (value.length === 1 && value === '+')) {
            // If the input is empty, use the last selected country
            country_code = input_value.country_iso_code;
            current_country = countries.find(c => c.country_iso_code === country_code);
        } else {
            country_code = selected_country
                ? selected_country.country_iso_code
                : input_value.country_iso_code;
            current_country = selected_country;
        }

        const output = new AsYouType(country_code as CountryCode).input(value);
        const phone = removing_char
            ? {
                  formatted: onRemovePhoneFormat(value),
                  raw: onRemovePhoneFormat(value),
              }
            : {
                  formatted: output,
                  raw: onRemovePhoneFormat(value),
              };

        const is_valid = isValidPhoneNumber(phone.formatted, country_code as CountryCode);

        setInputValue(prev => ({
            ...prev,
            ...current_country,
            formatted: phone.formatted,
            raw: phone.raw,
            is_valid,
        }));

        onChange?.({
            ...input_value,
            formatted: phone.formatted,
            raw: phone.raw,
            is_valid,
            calling_code: current_country?.calling_code || '',
            country_iso_code: current_country?.country_iso_code || '',
        });
    }

    return (
        <Root>
            <Dropdown
                default_open={dropdown_open}
                renderTrigger={(): ReactElement => (
                    <StyledButton
                        data-testid='phone-number-input-dropdown-button'
                        onClick={handleDropdownButtonClick}
                        disabled={disabled}
                        read_only={!!disabled}
                    >
                        <FlagImage alt={`${input_value.label} flag`} src={input_value.icon} />

                        <FontAwesomeIcon icon={faCaretDown} />
                    </StyledButton>
                )}
            >
                <StyledMenuWrapper>
                    <Menu max_height={300} width={'300px'}>
                        {countries.map((country, index) => (
                            <>
                                <MenuItem
                                    key={`${country.calling_code}-${country.country_iso_code}`}
                                    variant={'default'}
                                    text={`${country.label} (+${country.calling_code})`}
                                    icon={{
                                        value: (
                                            <FlagImageOnMenu
                                                alt={`${country.label} flag`}
                                                src={country.icon}
                                            />
                                        ),
                                    }}
                                    onClick={(): void => handleCountrySelect(country)}
                                    style={{
                                        fontSize: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                />

                                {country.priority < 1000 &&
                                    countries[index + 1]?.priority === 1000 && <Divider />}
                            </>
                        ))}
                    </Menu>
                </StyledMenuWrapper>
            </Dropdown>

            {!disabled && <PhoneInputDivider />}

            <InputWrapper read_only={!!disabled}>
                <StyledInput
                    data-testid='phone-number-input-input'
                    type='input'
                    inputMode='tel'
                    ref={init_input_ref}
                    placeholder={placeholder}
                    value={input_value.formatted}
                    disabled={disabled}
                    read_only={!!disabled}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                />
            </InputWrapper>
        </Root>
    );
}
