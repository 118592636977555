import React, { AnchorHTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const StyledAuthLinkButton = styled.a`
    line-height: 36px;
    height: 36px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: ${(props): string => props.theme.colors.grey700};

    padding: 0 24px;
    border-radius: 18px;
    cursor: pointer;
    transition: background-color 300ms ease;
    will-change: background-color;

    :hover {
        background-color: ${(props): string => props.theme.colors.grey200};
    }
`;

/**
 * Link element with "plain text" styling and button hover effect
 */
export function AuthLinkButton(props: AnchorHTMLAttributes<HTMLAnchorElement>): ReactElement {
    const { children, ...anchor_props } = props;

    return (
        <StyledAuthLinkButton {...anchor_props}>
            <span>{children}</span>
        </StyledAuthLinkButton>
    );
}
