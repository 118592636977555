import styled from 'styled-components';
import { MEDIA_QUERIES } from '../../media-queries/MediaQueries';

export const StyledLogo = styled.img`
    height: 41.5px;
    width: 96px;

    @media ${MEDIA_QUERIES.tablet} {
        height: 67px;
        width: 155px;
    }
`;
