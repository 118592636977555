import styled from 'styled-components';

export const TestimonialContainer = styled.div`
    box-sizing: border-box;

    width: 342px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #f5f9ff;
    border-radius: 8px;
    border: ${(props): string => `1px solid ${props.theme.colors.blue500}`};

    @media screen and ${(props): string => props.theme.responsive_media_queries.tablet} {
        width: 395px;
    }
`;
export const RatingContainer = styled.div`
    box-sizing: border-box;

    position: relative;
    top: -24px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 17px;

    width: 222px;
    height: 48px;

    background: ${(props): string => props.theme.colors.white};
    border: ${(props): string => `1px solid ${props.theme.colors.blue500}`};
    border-radius: 32px;
`;
export const QuoteContainer = styled.div`
    display: flex;
    align-items: center;

    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;

    color: ${(props): string => props.theme.colors.blue500};
`;
export const StarsContainer = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    gap: 4px;

    display: flex;
    align-items: center;

    color: #ffcc00;
`;
export const TextContainer = styled.div`
    width: 283px;
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #025bde;

    @media screen and ${(props): string => props.theme.responsive_media_queries.tablet} {
        width: 331px;
    }
`;
export const ClientNameText = styled.p`
    width: 283px;
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #0146ac;

    @media screen and ${(props): string => props.theme.responsive_media_queries.tablet} {
        width: 331px;
    }
`;
