/**
 * Transform a string to have the first letter as uppercase while
 * the rest of the string becomes lowercase.
 *
 * i.e: 'TEST STrING' => 'Test string'
 * @param str - String
 * @returns - String
 */
export function capitalizeString(str: string): string {
    if (typeof str !== 'string') {
        return '';
    }

    // Convert first character to uppercase and the rest to lowercase
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
