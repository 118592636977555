import styled, { DefaultTheme } from 'styled-components';
import { Button } from '../button/Button';
import { Typography } from '../typography/Typography';
import { IAppbarTitleProps } from './Appbar';

interface StyledAppbarWrapperProps {
    is_sticky: boolean;
}

export const StyledAppbarWrapper = styled.div<StyledAppbarWrapperProps>`
    position: ${(props): string => (props.is_sticky ? 'sticky' : 'relative')};
    ${(props): string => (props.is_sticky ? 'top: 0px' : 'auto')};
    z-index: 2;
    width: 100%;
    height: 60px;
    padding: 10px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${(props): string => props.theme.colors.grey300};
    background-color: ${(props): string => props.theme.colors.white};
    @media screen and (max-width: ${(props): number => props.theme.legacyMediaWidth.tablet}px) {
        padding: 10px 24px;
    }
    @media screen and (max-width: ${(props): number =>
            props.theme.legacyMediaWidth.mobile_horizontal}px) {
        padding: 10px 16px;
    }
`;

export const StyledMobileMenuTrigger = styled.a`
    display: none;
    position: relative;
    top: 2px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    @media screen and (max-width: ${(props): number => props.theme.legacyMediaWidth.desktop}px) {
        display: block;
    }
`;

export const StyledAppbarTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
    background-color: none;
    align-items: center;
    border: none;
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        margin-left: 16px;
    }
`;

export const StyledAppTitle = styled(Typography.H2)<IAppbarTitleProps>`
    font-size: 16px;
    color: inherit;
    display: block;
    margin: 0px 16px;
    cursor: ${(props): string => (props.editable ? 'pointer' : 'default')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: ${(props): string =>
        `calc(${props.window_width}px - ${determineOffset({
            window_width: props.window_width,
            theme: props.theme,
        })})`};
    min-width: 32px;
`;

export const StyledAppbarBackNavigation = styled(Button).attrs({ variant: 'subtle' })`
    background: none;
    border: none;
    color: ${(props): string => props.theme.colors.grey600};
    :hover {
        background: none;
        border: none;
    }
    :active {
        background: none;
        border: none;
    }
`;

export const StyledActionsWrapper = styled.div`
    margin-left: auto;
    min-height: 30px;
`;

export const StyledAppbarTitleInputContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;
`;

export const StyledAppbarTitleInputWrapper = styled.div`
    margin-right: 15px;
`;

// Helper functions
function determineOffset({
    window_width,
    theme,
}: {
    window_width: number;
    theme: DefaultTheme;
}): string {
    if (window_width <= theme.legacyMediaWidth.mobile_horizontal) {
        return '250px';
    }

    if (window_width <= theme.legacyMediaWidth.tablet) {
        return '300px';
    }

    if (window_width <= theme.legacyMediaWidth.desktop) {
        return '400px';
    }

    return '600px';
}
