import styled from 'styled-components';

export const SubmitInput = styled.input`
    padding: 0 24px;
    height: 53px;
    line-height: 18.75px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    width: 100%;

    transition: background-color 0.3s ease;
    will-change: background-color;

    color: white;
    background-color: ${(props): string => props.theme.colors.paystoneBlue};
`;

export const DirectiveText = styled.span`
    font-size: 24px;
    font-weight: 500;
`;

export const Row = styled.div`
    padding: 10px 0;
`;

export const RowSubmit = styled(Row)`
    padding-bottom: 0;
`;

export const ForgotPasswordLink = styled.a`
    font-size: 14px;
    text-decoration: underline;
    color: ${(props): string => props.theme.colors.paystoneGrey};
    :visited {
        color: ${(props): string => props.theme.colors.paystoneGrey};
    }
`;

export const GivePointPortal = styled.span`
    font-size: 14px;
    color: ${(props): string => props.theme.colors.paystoneGrey};
`;

export const GivePointLink = styled.span`
    color: ${(props): string => props.theme.colors.paystoneBlue};
    text-decoration: underline;
    cursor: pointer;
`;
