import React, { ReactElement } from 'react';
import styled, { Keyframes, keyframes, useTheme } from 'styled-components';
import { AppTheme } from '../../../theme';
import { Box } from '../../box';

export interface IDotsShufflingProps {
    size?: number;
    shrink?: boolean;
    colors?: Array<string>;
}

interface ILoadingAnimationProps {
    size: number;
    shrink: boolean;
    color: string;
    index: number;
}

/**
 * One of the dots in the loading animation.
 * @param props.size The size of the dot
 * @param props.color The color of the dot
 * @param props.index The index of the dot, used to stagger the animation
 * @param props.shrink Whether the dots should skink as they move to the right
 */
const Dot = styled.div<ILoadingAnimationProps>`
    position: absolute;

    background-color: ${(props): string => props.color};
    border-radius: 100%;

    height: ${({ size }): string => `${size}px`};
    width: ${({ size }): string => `${size}px`};

    animation: ${(props): Keyframes => dotAnimation(props)} 2s infinite both;
    animation-delay: ${({ index }): string => `${index * 0.5}s`};
`;

/**
 * Creates the dots expanding on the left and cycling right before shrinking.
 *
 * For the timings to work out correctly there must be 4 dots, each with a
 * different hue of the same color.
 *
 * Since there are 4 dots, the animation is split into 4 parts:
 * - 0% to 15%: The dot expands on the left
 * - 15% to 40%: The dot is at full size and moves to the right, pausing in the middle
 * - 40% to 60%: The dot is at full size and moves to the right, pausing at the end
 * - 60% to 75%: The dot shrinks on the right
 * - 75% to 100%: The dot is hidden and repositioned to start again
 *
 * When shrink is true:
 * - Between the left position and the middle the dot shrinks to 3/4 size
 * - Between the middle and the right position the dot shrinks to 1/2 size
 */
function dotAnimation({ size, shrink }: { size: number; shrink: boolean }): Keyframes {
    return keyframes`
        0% {
            width: 0;
            height: 0;
            transform: translateX(${-1.5 * size}px);
        }
        15% {
            width: ${size}px;
            height: ${size}px;
            transform: translateX(${-1.5 * size}px);
            z-index: 1;
        }
        40% {
            width: ${shrink ? size * (3 / 4) : size}px;
            height: ${shrink ? size * (3 / 4) : size}px;
            transform: translateX(0);
            z-index: 2;
        }
        60% {
            width: ${shrink ? size * (1 / 2) : size}px;
            height: ${shrink ? size * (1 / 2) : size}px;
            transform: translateX(${(shrink ? 1.25 : 1.5) * size}px);
            z-index: 2;
        }
        75% {
            width: 0;
            height: 0;
            transform: translateX(${(shrink ? 1.25 : 1.5) * size}px);
            z-index: 1;
        }
        100% {
            width: 0;
            height: 0;
            transform: translateX(${(shrink ? 1.25 : 1.5) * size}px);
        }
    `;
}

/**
 * A "3 dot" loading animation that cycles through 4 different colors.
 * @param props.size - The size of the dot, the width of the container is 4x this value
 * @param props.color - The color of the dots, one of 'blue', 'grey', or 'red'
 * @param props.shrink - Whether the dots should shrink as they move to the right
 */
export function DotsShuffling(props: Partial<IDotsShufflingProps>): ReactElement {
    const { colors } = useTheme() as AppTheme;
    const {
        colors: dot_colors = [colors.blue100, colors.blue200, colors.blue300, colors.blue400],
        size = 20,
        shrink = false,
    } = props;

    /**
     * When shrink is false the total width is 4x the size of the dot.
     *  - 3 dots and 2 gaps that are 0.5x the size of the dot
     *
     * When shrink is true the total width is 3x the size of the dot.
     * - left dot is 1x the size of the dot
     * - Left gap is 0.5x the size of the dot
     * - Middle dot is 0.75x the size of the dot
     * - Right gap is 0.25x the size of the dot
     * - Right dot is 0.5x the size of the dot
     */
    const width = shrink ? 3 * size : 4 * size;

    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            style={{ width, height: size }}
        >
            {dot_colors.map((dot_color, index) => (
                <Dot
                    key={`loading-dot-${index}`}
                    size={size}
                    shrink={shrink}
                    color={dot_color}
                    index={index}
                />
            ))}
        </Box>
    );
}
