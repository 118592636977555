import styled from 'styled-components';

export const StyledTextFieldWithSelect = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledSelect = styled.div`
    margin-right: 8px;
`;

export const StyledTextFieldWithSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 20px;
`;
