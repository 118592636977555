/* eslint-disable react/no-children-prop */
import React, { ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Divider } from '../card/Card';
import { StyledBackground } from './Modal.styles';

interface FullScreenModalProps {
    header?: ReactElement;
    content: ReactNode;
    footer?: ReactNode;
    className?: string;
    // this is to test the modal component in test environment
    test_render?: boolean;
    'data-testid'?: string;
    need_intercom_overlay?: boolean;
}

const FullScreenModalBackground = styled(StyledBackground)<{ need_intercom_overlay?: boolean }>`
    background-color: ${(props): string => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: ${(props): number => (props.need_intercom_overlay ? 2147483000 : 2147483004)};
`;

const FullScreenModalHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
`;

const FullScreenModalContent = styled.div`
    width: 100%;
    flex: 1;
`;

const FullScreenModalFooter = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    height: 72px;
`;

export const PaddingWrapper = styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export function FullScreenModal(props: FullScreenModalProps): ReactElement {
    const { header, content, footer, className, test_render, need_intercom_overlay } = props;

    const full_screen_modal_element = (
        <FullScreenModalBackground
            className={className}
            data-testid={props['data-testid'] ? props['data-testid'] : `fullscreen-modal`}
            need_intercom_overlay={need_intercom_overlay}
        >
            {header ? (
                <PaddingWrapper>
                    <FullScreenModalHeader>{header}</FullScreenModalHeader>
                    <Divider />
                </PaddingWrapper>
            ) : null}

            <FullScreenModalContent>{content}</FullScreenModalContent>

            {footer ? (
                <PaddingWrapper>
                    <Divider />
                    <FullScreenModalFooter>{footer}</FullScreenModalFooter>
                </PaddingWrapper>
            ) : null}
        </FullScreenModalBackground>
    );

    if (test_render) {
        return full_screen_modal_element;
    }

    return ReactDOM.createPortal(
        full_screen_modal_element,
        document.getElementById('modal-root') as HTMLElement
    );
}
