import { ApolloError } from '@apollo/client';
import { FRIENDLY_EXTRACT_PATTERN, INVALID_EXTRACT_PATTERN } from '../../../common';

/**
 * Parse a friendly error message from backend
 * check for `[ friendly] message` or `[ invalid ] message` to return the `message`
 * @param input
 * @returns
 */
export function parseFriendlyErrorMessage(input: ApolloError | Error | string): string | null {
    if (!input) {
        return null;
    }

    const message = !input
        ? ''
        : typeof input === 'string'
        ? input
        : typeof input === 'object'
        ? input.message
        : '';

    /** See if it has `[ friendly ] message` pattern, and return the message */
    const friendly_match = FRIENDLY_EXTRACT_PATTERN.exec(message);
    if (friendly_match) {
        return friendly_match[1];
    }

    /** See if it has `[ invalid ] message` pattern, and return the message */
    const invalid_match = INVALID_EXTRACT_PATTERN.exec(message);
    if (invalid_match) {
        return invalid_match[1];
    }

    return null;
}
