import { faStar as faStarEmpty, faStarHalfAlt } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';

/** Types */
interface IStarRatingProps {
    rating: number;
}

export function StarRating(props: IStarRatingProps): ReactElement {
    const { rating } = props;

    // This is redundant if we already round ratings to the nearest 0.5
    const rounded_rating = Math.round(rating * 2) / 2;

    const stars: Array<ReactElement> = [];

    /** Calculate full stars */
    for (let i = 0.5; i < rounded_rating; i++) {
        stars.push(
            <FontAwesomeIcon icon={faStar} fontSize='14px' color='#FFCC00' key={`full-star-${i}`} />
        );
    }

    /** Calculate half stars */
    if (rounded_rating % 1 !== 0) {
        stars.push(
            <FontAwesomeIcon
                icon={faStarHalfAlt}
                fontSize='14px'
                color='#FFCC00'
                key={`half-star`}
            />
        );
    }

    /** Calculate empty stars */
    for (let i = 0.5; i < 5 - rounded_rating; i++) {
        stars.push(
            <FontAwesomeIcon
                icon={faStarEmpty}
                fontSize='14px'
                color='#FFCC00'
                key={`empty-star-${i}`}
            />
        );
    }

    return <>{stars.map(star => star)}</>;
}
