import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrackingData, trackClick } from '@nicejob-library/tracking';
import React, { PropsWithChildren, forwardRef } from 'react';
import { DotsSpinning } from '../loading-shapes/dots-spinning';
import { IconButtonBase } from './IconButton.styles';

export const ICON_BUTTON_VARIANTS = ['action', 'subtle', 'navigation'] as const;
export type IIconButtonVariants = typeof ICON_BUTTON_VARIANTS[number];

export const ICON_BUTTON_STATES = ['default', 'hover', 'active', 'disabled', 'loading'] as const;
export type IIconButtonStates = typeof ICON_BUTTON_STATES[number];

export const ICON_BUTTON_SIZES = ['small', 'medium'] as const;
export type IIconButtonSizes = typeof ICON_BUTTON_SIZES[number];

interface IIconButtonCSSProperties {
    color: string;
    background: string;
}

export type IIconButtonTheme = {
    [variant in IIconButtonVariants]: {
        [state in IIconButtonStates]: IIconButtonCSSProperties;
    };
};

interface IIconButtonBaseProps {
    variant: IIconButtonVariants;
    size?: IIconButtonSizes;
    disabled?: boolean;
    loading?: boolean;
    id?: string;
    tracking?: TrackingData;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type IIconButtonWithChildren = PropsWithChildren<IIconButtonBaseProps & { fa_icon?: never }>;
type IIconButtonUsingFontAwesome = IIconButtonBaseProps & {
    fa_icon: IconDefinition;
    children?: never;
};

export type IIconButtonProps = IIconButtonWithChildren | IIconButtonUsingFontAwesome;

/**
 * ## IconButton
 *
 * The IconButton comes in 3 different variants:
 * - `action`: Used for primary actions.
 * - `subtle`: Used for secondary actions.
 * - `navigation`: Used for navigation actions.
 *   - The navigation variant doesn't apply additional styling on `:hover` and `:active`.
 *
 * The button comes in 2 different sizes:
 * - `small`, and `medium`.
 * - default is `medium` if not specified.
 *
 * Icons can be added to the button in 2 different ways:
 * - Using the `fa_icon` prop, which accepts a FontAwesome icon definition.
 * - Using the `children` prop, which accepts any React element.
 *
 * The button can be disabled by setting the `disabled` prop to `true`.
 *
 * The button can display a loading animation by setting the `loading` prop to `true`.
 *
 * The button click can be tracked by setting the `tracking` prop. This calls the
 * `trackClick` function from the `@nicejob-library/tracking` package before
 * propagating the click event to the `onClick` handler prop.
 */
export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>((props, ref) => {
    const { children, fa_icon, onClick, tracking, ...base_props } = props;
    const { disabled, loading, size } = base_props;

    function onClickHandler(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        // don't allow clicking when the button is disabled or loading
        if (!disabled && !loading) {
            // track the click if tracking data is provided
            if (tracking) {
                trackClick(tracking);
            }

            onClick(event);
        }
    }

    return (
        <IconButtonBase {...base_props} onClick={onClickHandler} ref={ref}>
            {loading ? (
                <DotsSpinning
                    size={size === 'small' ? 4 : 6}
                    color={props.variant === 'action' ? 'blue' : 'grey'}
                />
            ) : (
                (fa_icon && <FontAwesomeIcon icon={fa_icon} />) || children
            )}
        </IconButtonBase>
    );
});
