import { LDClient } from 'launchdarkly-js-client-sdk';
import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { Paths } from '@nicejob-library/paths';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { ContextKind } from '@nicejob-library/features/types';

export interface ILaunchDarklyContextProps {
    children: React.ReactNode;
}

export interface ILaunchDarklyContext {
    is_ready: boolean;
    setIsReady: React.Dispatch<boolean>;

    client: LDClient;
    setClient: React.Dispatch<LDClient>;
}

const default_context = {
    is_ready: false,
    setIsReady: (): void => {},

    client: {} as LDClient,
    setClient: (): void => {},
};

export const LaunchDarklyContext = React.createContext<ILaunchDarklyContext>(default_context);

const { REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID } = process.env;

export function LaunchDarklyContextProvider(props: ILaunchDarklyContextProps): React.ReactElement {
    const [is_ready, setIsReady] = React.useState<boolean>(false);
    const [client, setClient] = React.useState<LDClient>({} as LDClient);

    // eslint-disable-next-line @nicejob/snake-case
    const createLDClient = useCallback(async () => {
        const response = await axios.post(Paths.FEATURES_CREATE_CONTEXT, {
            type: ContextKind.DEVICE,
        });
        const data = response.data;
        const context = LaunchDarkly.initialize(
            REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID as string,
            data.context,
            {
                hash: data.hash,
            }
        );

        await context.waitForInitialization();
        setClient(context);
        setIsReady(true);
    }, []);

    useEffect(() => {
        createLDClient();
    }, []);

    return (
        <LaunchDarklyContext.Provider
            value={{
                is_ready,
                setIsReady,
                client,
                setClient,
            }}
        >
            {props.children}
        </LaunchDarklyContext.Provider>
    );
}
