import styled, { DefaultTheme, FlattenSimpleInterpolation, css } from 'styled-components';
import { ISwitchHandle, ISwitchPill } from './Switch';

export const StyledOuterWrapper = styled.div`
    position: relative;
`;

export const SwitchPill = styled.div<ISwitchPill>`
    display: flex;
    width: ${(props): string => (props.size === 'large' ? '56px' : '38px')};

    position: relative;
    height: 22px;
    padding-right: 2px;
    padding-left: 2px;
    align-items: center;
    border: 1px solid ${(props): string => switchPillBorderColorOff(props)};
    background-color: ${(props): string =>
        props.size === 'large' ? props.theme.colors.grey500 : props.theme.colors.white};
    border-radius: 20px;
    transition: padding 200ms ease, background-color 200ms ease;
    cursor: ${(props): string => switchPillCursor(props)};
    &:hover {
        background-color: ${(props): string => switchPillHoverBackgroundColorOff(props)};
        border: 1px solid ${(props): string => switchPillHoverBorderColorOff(props)};
    }
    &:active {
        background-color: ${(props): string => switchPillActiveBackgroundColorOff(props)};
        border: 1px solid ${(props): string => switchPillActiveBorderColorOff(props)};
    }
    ${(props): FlattenSimpleInterpolation => {
        if (props.on) {
            return css`
                // position SwitchHandle
                & > div {
                    left: ${props.size === 'large' ? '36px' : '18px'};
                }
                justify-content: flex-start;
                border: 1px solid ${switchPillBorderColorOn(props)};
                background-color: ${switchPillBackgroundColorOn(props)};
                &:hover {
                    background-color: ${switchPillHoverBackgroundColorOn(props)};
                    border: 1px solid ${switchPillHoverBorderColorOn(props)};
                }
                &:active {
                    background-color: ${switchPillActiveBackgroundColorOn(props)};
                    border: 1px solid ${switchPillActiveBorderColorOn(props)};
                }
            `;
        }

        return css``;
    }}
`;

export const SwitchHandle = styled.div<ISwitchHandle>`
    position: absolute;
    transition: left 200ms ease, background-color 200ms ease;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${(props): string => switchHandleBackgroundColor(props)};
    box-shadow: ${(props): string => switchHandleBoxShadow(props)};
`;

// HELPERS
// --> SwitchPill
function switchPillCursor(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return 'default';
    }

    return 'pointer';
}

// --> OFF border color
function switchPillBorderColorOff(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.grey300;
    }

    return props.size === 'large' ? props.theme.colors.grey500 : props.theme.colors.grey400;
}

function switchPillHoverBorderColorOff(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.grey300;
    }

    return props.size === 'large' ? props.theme.colors.grey500 : props.theme.colors.grey400;
}

function switchPillActiveBorderColorOff(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.grey300;
    }

    return props.size === 'large' ? props.theme.colors.grey500 : props.theme.colors.grey400;
}

// --> OFF background color
function switchPillHoverBackgroundColorOff(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled || props.blocked) {
        return props.theme.colors.white;
    }

    if (props.size === 'large') {
        return props.theme.colors.grey500;
    }

    return props.theme.colors.grey50;
}

function switchPillActiveBackgroundColorOff(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled || props.blocked) {
        return props.theme.colors.white;
    }

    return props.theme.colors.grey100;
}

// --> ON border color
function switchPillBorderColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    return props.theme.colors.green600;
}

function switchPillHoverBorderColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    if (props.blocked) {
        return props.theme.colors.green600;
    }

    return props.theme.colors.green700;
}

function switchPillActiveBorderColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    if (props.blocked) {
        return props.theme.colors.green600;
    }

    return props.theme.colors.green700;
}

// --> ON background color
function switchPillBackgroundColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    return props.theme.colors.green600;
}

function switchPillHoverBackgroundColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    if (props.blocked) {
        return props.theme.colors.green600;
    }

    return props.theme.colors.green700;
}

function switchPillActiveBackgroundColorOn(props: ISwitchPill & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.green200;
    }

    if (props.blocked) {
        return props.theme.colors.green600;
    }

    return props.theme.colors.green700;
}

// --> SwitchHandle
function switchHandleBackgroundColor(props: ISwitchHandle & { theme: DefaultTheme }): string {
    if (props.disabled) {
        return props.theme.colors.grey200;
    }

    return props.theme.colors.white;
}

function switchHandleBoxShadow(props: ISwitchHandle): string {
    if (props.disabled) {
        return 'none';
    }

    return `0px 0px 5px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14),
        0px 1px 1px rgba(0, 0, 0, 0.2)`;
}
