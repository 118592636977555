import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { IconButton } from '../icon-button/IconButton';

// Interfaces
export enum Position {
    start,
    middle,
    end,
}

interface IProgressTrackerBase {
    editing?: boolean;
}

interface IProgressTrackerStart extends IProgressTrackerBase {
    position: Position.start;
}

interface IProgressTrackerMiddle extends IProgressTrackerBase {
    position: Position.middle;
    onClick: () => void;
    disable_button?: boolean;
}

interface IProgressTrackerEnd extends IProgressTrackerBase {
    position: Position.end;
    onClick: () => void;
    disable_button?: boolean;
}

type IProgressTracker = IProgressTrackerStart | IProgressTrackerMiddle | IProgressTrackerEnd;

// Styled components
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
    width: 32px;
    justify-content: center;
    align-items: center;
    @media screen and ${(props): string => props.theme.legacy_screen_size.desktop} {
        margin-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.tablet} {
        margin-right: 32px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_horizontal} {
        margin-right: 16px;
    }
    @media screen and ${(props): string => props.theme.legacy_screen_size.mobile_vertical} {
        margin-right: 8px;
    }
`;

const TrackingDot = styled.div<{ editing: boolean }>`
    width: 12px;
    height: 12px;
    z-index: 1;
    border-radius: 100%;
    background: ${(props): string =>
        props.editing ? props.theme.colors.blue500 : props.theme.colors.grey300};
`;

const ButtonWrapper = styled.div`
    position: absolute;
    top: -24px;
    z-index: 1;
`;

const PlusButton = styled(IconButton).attrs({
    variant: 'subtle',
    fa_icon: faPlus,
})`
    border: 1px solid ${(props): string => props.theme.colors.grey300};
    width: 32px;
    height: 32px;
`;

// --> START
const StartWrapper = styled(Wrapper)`
    margin-bottom: 0;
`;

const StartVerticalLine = styled.div`
    width: 1px;
    border-left: dashed 1px ${(props): string => props.theme.colors.grey300};
    position: absolute;
    top: 50%;
    bottom: -32px;
    left: 50%;
`;
// <-- START

// --> MIDDLE
const MiddleWrapper = styled(Wrapper)``;

const MiddleVerticalLine = styled.div`
    width: 1px;
    border-left: dashed 1px ${(props): string => props.theme.colors.grey300};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
`;

// <-- MIDDLE

// END
const EndWrapper = styled(Wrapper)``;

const EndVerticalLine = styled.div`
    width: 1px;
    border-left: dashed 1px ${(props): string => props.theme.colors.grey300};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    height: calc(100% + 40px);
`;

const EndTrackingDotFirst = styled(TrackingDot)`
    position: absolute;
`;

const EndTrackingDotSecond = styled(TrackingDot)`
    position: absolute;
    bottom: -48px;
`;
// <-- END

export function ProgressTracker(props: IProgressTracker): ReactElement | null {
    const { editing } = props;

    switch (props.position) {
        case Position.start:
            return (
                <StartWrapper>
                    <TrackingDot editing={!!editing} />
                    <StartVerticalLine />
                </StartWrapper>
            );
        case Position.middle:
            return (
                <MiddleWrapper>
                    <ButtonWrapper>
                        <PlusButton onClick={props.onClick} disabled={props.disable_button} />
                    </ButtonWrapper>
                    <MiddleVerticalLine />
                    <TrackingDot editing={!!editing} />
                    <MiddleVerticalLine />
                </MiddleWrapper>
            );
        case Position.end:
            return (
                <EndWrapper>
                    <ButtonWrapper>
                        <PlusButton onClick={props.onClick} disabled={props.disable_button} />
                    </ButtonWrapper>
                    <EndTrackingDotFirst editing={!!editing} />
                    <EndVerticalLine />
                    <EndTrackingDotSecond editing={false} />
                </EndWrapper>
            );
        default:
            return null;
    }
}
