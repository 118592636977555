import { AuthInput } from '@nicejob-library/design-system/authentication';
import { PasswordField } from '@nicejob-library/design-system/authentication/input/password-field/PasswordField';
import { ILanguage, translate } from '@nicejob-library/internationalization';
import { trackClick, TrackingData } from '@nicejob-library/tracking';
import React, { FormEvent, ReactElement } from 'react';
import { GIVEPOINT_URL } from '../../../../../../common';
import { TEST_ID } from '../../../../../../common/testing/constants';
import { LoginForm, PaystoneLightBanner } from './PaymentsLogin.styles';
import {
    DirectiveText,
    ForgotPasswordLink,
    GivePointLink,
    GivePointPortal,
    Row,
    SubmitInput,
} from './PaymentsLoginForm.styles';

export interface PaymentsLoginFormProps {
    brand_name: string;
    post_url: string;
    language: ILanguage;
    email?: string;
    onSubmit?: (e: FormEvent) => void;
    setIsLoading: (is_loading: boolean) => void;
}

/**
 * Login form for payments login page
 */
export function PaymentsLoginForm(props: PaymentsLoginFormProps): ReactElement {
    const { post_url, email, onSubmit, language, setIsLoading } = props;

    function onSubmitHandler(e: FormEvent): void {
        if (onSubmit) {
            onSubmit(e);
        }
    }

    /**
     * Track click and navigate to givepoint
     */
    async function onNavigateToGivepoint(): Promise<void> {
        setIsLoading(true);

        const tracking_data: TrackingData = {
            type: 'link',
            id: 'givepoint_login',
            is_anonymous: true,
        };

        try {
            await trackClick(tracking_data);
        } catch (error) {
            console.log(error);
        }

        window.location.href = GIVEPOINT_URL;
    }

    return (
        <LoginForm onSubmit={onSubmitHandler} action={post_url} method='POST'>
            <Row>
                <PaystoneLightBanner />
            </Row>
            <Row style={{ marginBottom: '5px' }}>
                <DirectiveText>
                    {translate({ namespace: 'login', key: 'login.log_in', language })}
                </DirectiveText>
            </Row>
            <Row>
                <AuthInput
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'common',
                        key: 'textfield.email_address',
                        language,
                    })}
                    type='email'
                    name='email'
                    test_id={TEST_ID.login.username_input}
                    initial_value={email}
                    hide_background={true}
                />
            </Row>
            <Row>
                <PasswordField
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'common',
                        key: 'textfield.password',
                        language,
                    })}
                    test_id={TEST_ID.login.password_input}
                    hide_background={true}
                />
            </Row>
            <Row>
                <SubmitInput
                    name='login'
                    type='submit'
                    data-testid={TEST_ID.login.submit_button}
                    value={translate({ namespace: 'login', key: 'login.log_in', language })}
                />
            </Row>
            <Row style={{ textAlign: 'center', marginBottom: '8px' }}>
                <ForgotPasswordLink href='/u/forgot_password'>
                    {translate({ namespace: 'login', key: 'login.forgot_password', language })}
                </ForgotPasswordLink>
            </Row>
            <Row style={{ textAlign: 'center' }}>
                <GivePointPortal>
                    {translate({ namespace: 'login', key: 'login.givepoint_user', language })}{' '}
                    <GivePointLink onClick={onNavigateToGivepoint}>
                        {translate({ namespace: 'login', key: 'login.login_here', language })}
                    </GivePointLink>
                </GivePointPortal>
            </Row>
        </LoginForm>
    );
}
