import styled from 'styled-components';
import { HeaderProps } from './CardHeader';

// Styled components

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props): string => props.theme.colors.black};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 9px;
`;

export const StyledTitle = styled.div<Pick<HeaderProps, 'size'>>`
    color: ${(props): string => props.theme.colors.black};
    font-weight: 600;
    font-size: ${(props): string => titleFontSize(props.size)};
    padding-bottom: ${(props): string => titlePaddingBottom(props.size)};
    line-height: ${(props): string => titleLineHeight(props.size)};
`;

export const StyledDescription = styled.div<Pick<HeaderProps, 'description' | 'size'>>`
    color: ${(props): string => props.theme.colors.grey600};
    font-size: ${(props): string => descriptionFontSize(props.size)};
    line-height: 20px;
    padding-bottom: ${(props): string =>
        descriptionPaddingBottom(props.size as 'medium' | 'large')};
`;

export const StyledActionComponent = styled.div<Pick<HeaderProps, 'size'>>`
    padding-bottom: ${(props): string => titlePaddingBottom(props.size)};
`;

// Helpers
function titleFontSize(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '12px';
        case 'medium':
            return '16px';
        case 'large':
            return '24px';
        default:
            return '16px';
    }
}

function descriptionFontSize(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'large':
            return '16px';
        default:
            return '14px';
    }
}

function titlePaddingBottom(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '8px';
        default:
            return '16px';
    }
}

function titleLineHeight(size: 'small' | 'medium' | 'large'): string {
    switch (size) {
        case 'small':
            return '16px';
        case 'medium':
            return '24px';
        case 'large':
            return '32px';
        default:
            return '24px';
    }
}

function descriptionPaddingBottom(size: 'medium' | 'large'): string {
    switch (size) {
        case 'medium':
            return '16px';
        case 'large':
            return '16px';
        default:
            return '16px';
    }
}
