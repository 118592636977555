import { useState } from 'react';
import { parseQuery } from '../../../../../../common/parseQuery';
import { deriveProductsFromSlug } from '../../common/product-slugs';
import { IRegisterPageQueryParams, PREPOPULATED_VALUE_KEYS } from '../pages/Register/types';

enum utm_types {
    'referrer' = 'referrer',
    'landing_page' = 'landing_page',
    'os' = 'os',
    'browser' = 'browser',
    'utm_medium' = 'utm_medium',
    'utm_campaign' = 'utm_campaign',
    'utm_term' = 'utm_term',
    'utm_content' = 'utm_content',
    'utm_source' = 'utm_source',
}

export function useRegisterPageParams(): IRegisterPageQueryParams {
    const [query_params] = useState<IRegisterPageQueryParams>(
        (): IRegisterPageQueryParams => {
            const url_query = window.location.search;
            const decoded_url = decodeURIComponent(url_query);

            const params = parseQuery(decoded_url);

            const has_utm = Object.keys(params).some(param => param in utm_types);
            const utm: Record<string, string | null> = {};
            if (has_utm) {
                for (const item in utm_types) {
                    utm[item] = params[item];
                }
            }

            return {
                ...params,
                utm,
                has_utm,
                parsed_products: deriveProductsFromSlug(params.products),
                prepopulated_values: PREPOPULATED_VALUE_KEYS.reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: params[key],
                    }),
                    {}
                ),
            };
        }
    );

    return query_params;
}
