import { SDKBaseElementProperties } from '../../types/entities.types';

export interface TrackingData {
    id: string;
    type: 'action' | 'link';

    // properties forwarded
    properties?: Record<string, any>;

    // click metadata
    metadata?: SDKBaseElementProperties['metadata'];

    // Tracking is anonymous?
    is_anonymous?: boolean;
}

export enum NJSource {
    APP = 'app',
}
