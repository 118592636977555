import { useRef, useLayoutEffect, MutableRefObject } from 'react';

export function useLatestRef<T>(value: T): MutableRefObject<T> {
    const ref = useRef(value);

    useLayoutEffect((): void => {
        ref.current = value;
    });

    return ref;
}
