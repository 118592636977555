import React, { ReactElement } from 'react';
import styled from 'styled-components';
import CrownBlocked from '../../common/blocked/CrownBlocked';

const CrownBlockedWrapper = styled.div`
    position: absolute;
    top: -12px;
    right: -4px;
    width: 20px;
`;

export function ButtonRoadBlock(): ReactElement {
    return (
        <CrownBlockedWrapper>
            <CrownBlocked />
        </CrownBlockedWrapper>
    );
}
