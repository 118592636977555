import { TProductPlanString } from '@nicejob-library/design-system/billing/types/interfaces';
import { TrackingData } from '@nicejob-library/tracking';

interface IProductPlanString {
    [string: string]: TProductPlanString;
}

export const PRODUCT_PLAN_STRINGS: IProductPlanString = {
    reviews_partner: 'reviews.PARTNER',
    reviews_pro: 'reviews.PRO',
    reviews_lite: 'reviews.LITE',
    reviews_free: 'reviews.FREE',
    convert_standard: 'convert.STANDARD',
    convert_landing_pages_standard: 'convert_landing_pages.STANDARD',
};

export const REGISTER_PARAM = 'registration=1';
export const PARTNER_PARAM = 'partner=1';

export const MANUAL_REGISTRATION_TRACKING_DATA: TrackingData = {
    id: 'signup_manual',
    type: 'action',
    is_anonymous: true,
};
