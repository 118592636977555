import { capitalizeString } from './capitalizeString';

/**
 * Convert a string to title case.
 * @param str - String
 * @returns title cased string
 */
export function titleCase(str: string): string {
    if (typeof str !== 'string') {
        return '';
    }

    return str
        .split(' ')
        .map(word => capitalizeString(word))
        .join(' ');
}
