import { getProvinceOptions } from '.';

export function getProvinceNameFromCode({
    province_code,
    country_code,
}: {
    province_code: string;
    country_code: string;
}): string {
    const province_options = getProvinceOptions({ country_code });

    const province_option = province_options.find(p => p.short === province_code);

    if (!province_option) {
        return '';
    }

    return province_option.name;
}
