import { ILanguage, translate } from '@nicejob-library/internationalization';
import React, { FormEvent, ReactElement } from 'react';
import { InputField } from '../../core';
import { FormDescription, Row, RowSubmit, SubmitInput } from './Form.styles';

export interface ForgotPasswordFormProps {
    post_url: string;
    language: ILanguage;
    onSubmit?: (e: FormEvent) => void;
}

export function ForgotPasswordForm(props: ForgotPasswordFormProps): ReactElement {
    const { post_url, onSubmit, language } = props;

    function onSubmitHandler(e: FormEvent): void {
        if (onSubmit) {
            onSubmit(e);
        }
    }

    return (
        <form action={post_url} method='POST' onSubmit={onSubmitHandler}>
            <FormDescription>
                {translate({ namespace: 'login', key: 'forgot_password.description', language })}
            </FormDescription>

            <Row>
                <InputField
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'common',
                        key: 'textfield.email_address',
                        language,
                    })}
                    type='email'
                    name='email'
                />
            </Row>
            <RowSubmit>
                <SubmitInput
                    type='submit'
                    value={translate({ namespace: 'common', key: 'button.submit', language })}
                />
            </RowSubmit>
        </form>
    );
}
