import React from 'react';
import styled from 'styled-components';

/**
 * Logo for the brand to authenticate with
 */
export const AuthLogo = styled.img`
    max-width: 40px;
    max-height: 40px;
`;
