import { FetchResult, MutationHookOptions } from '@apollo/client';

/** Types */
interface IRetryMutationArgs {
    mutationFunction: (options: MutationHookOptions) => Promise<FetchResult>;
    options: Pick<MutationHookOptions, 'onError' | 'onCompleted' | 'variables'>;
    max_retry: number;
    current_retry_count?: number;
}

export function retryMutation(input: IRetryMutationArgs): void {
    const {
        mutationFunction,
        options,
        max_retry,
        current_retry_count: input_current_retry_count = 0,
    } = input;
    const { onError, onCompleted, variables } = options;

    let current_retry_count = input_current_retry_count;

    mutationFunction({
        variables,
    })
        .then(response => {
            onCompleted && onCompleted(response);
        })
        .catch(error => {
            current_retry_count++;

            if (current_retry_count > max_retry) {
                onError && onError(error);
            } else {
                retryMutation({
                    mutationFunction,
                    options,
                    max_retry,
                    current_retry_count,
                });
            }
        });
}
