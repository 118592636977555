import { useContext } from 'react';
import { FeatureFlag, FeatureFlagCheckResult } from '../../../../../../common';
import { LaunchDarklyContext } from '../context/LaunchDarklyContext';

interface IUseFeatureFlagProps<Flag extends FeatureFlag> {
    key: Flag;
    fallback?: FeatureFlagCheckResult<Flag>;
}

/**
 * Hook to fetch feature flag value
 *
 * @param args.key - feature flag key
 * @param args.fallback - fallback value if feature flag is not found
 * @returns the result of the feature flag - usually a boolean
 */
export function useFeatureFlag<Flag extends FeatureFlag>(
    args: IUseFeatureFlagProps<Flag>
): FeatureFlagCheckResult<Flag> {
    const { key, fallback = false } = args;
    const { is_ready, client } = useContext(LaunchDarklyContext);

    if (key && is_ready) {
        return client.variation(key, fallback);
    }

    return fallback as FeatureFlagCheckResult<Flag>;
}
