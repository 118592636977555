/**
 * One-screen Onboarding experience for partner agents like Puulse who are
 * creating an account for a user who is not present.  The can pre-populate the fields
 * on the form through URL query parameters.
 */

import { Online } from '@nicejob/nicejob-toolbox';
import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { PasswordField } from '@nicejob-library/design-system/authentication/input/password-field/PasswordField';
import { TextField } from '@nicejob-library/design-system/core';
import { translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import {
    IValidationRules,
    validatePassword,
    Validation,
} from '@nicejob-library/react-form-validation';
import axios from 'axios';
import React, {
    KeyboardEvent,
    MouseEvent,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { PARTNER_PARAM, REGISTER_PARAM } from '../../../shared/constants';
import { VALIDATION_FIELD } from '../../../shared/types';
import { ManualAddress } from '../../components/ManualAddress';
import { AuthContext } from '../../context/AuthContext';
import {
    FullWidthButton,
    InputFieldAndLabelContainer,
    SignupButtonContainer,
    TwoColumnLayoutContainer,
} from '../../Onboarding.styles';
import { RegisterAlert } from './ClientRegister.styles';
import { trackRegistrationInDreamData } from './common/trackRegistrationInDreamData';
import { trackRegistrationInPartnerstack } from './common/trackRegistrationInPartnerstack';
import { FormContainer, FormRow, MainContainer, NicejobLogo } from './PartnerRegister.styles';
import { IPostRegisterUserData, IRegisterBaseProps, IRegisterUser } from './types';

const NICEJOB_LOGO_URL = 'https://cdn.nicejob.co/assets/nicejob-v2.png';

export interface IPartnerRegisterUser extends IRegisterUser {
    company_name: string;
    coupon?: string;
}

/** Globals */
const VALIDATION_RULES: IValidationRules = {
    [VALIDATION_FIELD.company_name]: {
        type: 'company_name',
        label: translate({ namespace: 'common', key: 'textfield.company_name' }),
        required: true,
    },
    [VALIDATION_FIELD.company_address]: {
        label: translate({
            namespace: 'login',
            key: 'onboarding.register_company.company_address',
        }),
        required: true,
    },
    [VALIDATION_FIELD.street_address]: {
        label: translate({
            namespace: 'login',
            key: 'onboarding.register_company.manual_address.street',
        }),
        required: true,
    },
    [VALIDATION_FIELD.zip]: {
        label: translate({
            namespace: 'login',
            key: 'common.textfield.postal_code',
        }),
        required: true,
    },
    [VALIDATION_FIELD.country]: {
        label: translate({ namespace: 'common', key: 'text.country' }),
        required: true,
    },
    [VALIDATION_FIELD.password]: {
        label: translate({ namespace: 'common', key: 'textfield.password' }),
        required: true,
        type: 'password',
    },
    [VALIDATION_FIELD.user_name]: {
        label: translate({ namespace: 'common', key: 'textfield.full_name' }),
        required: true,
    },
    [VALIDATION_FIELD.phone]: {
        label: translate({ namespace: 'common', key: 'textfield.phone_number' }),
        required: true,
    },
    [VALIDATION_FIELD.email]: {
        type: 'email',
        label: translate({ namespace: 'common', key: 'textfield.email' }),
        required: true,
    },
};

export function PartnerRegister(props: IRegisterBaseProps): ReactElement {
    const {
        // context
        language,

        // props
        query_params,

        // state
        alert_message,
        duplicate_email_validation_errors,
        is_loading,
        is_valid,
        setIsValid,
        validation_errors,
        clearValidationError,

        // handlers
        onSubmit,
        onValidation,
    } = props;

    const { fetchCSRFToken } = useContext(AuthContext);

    const [form_values, setFormValues] = useState<IPartnerRegisterUser>({
        email: '',
        user_name: '',
        password: '',
        phone: '',
        company_name: '',
    });
    const [current_company_address, setCompanyAddress] = useState<IAddress>({
        address: '',
        unit: '',
        city: '',
        country: '',
        state: '',
        zip: '',

        // initialize explicit null defaults
        geo: null,
        timezone: null,
        googlePlaceId: null,
    });
    const [is_password_valid, setPasswordValid] = useState<boolean>(false);

    const validation = useMemo(
        () =>
            new Validation({
                rules: VALIDATION_RULES,
                onValidation,
            }),
        []
    );

    function onManualAddressChanged(updated_address: Partial<IAddress>): void {
        setCompanyAddress(prev => ({
            ...prev,
            ...updated_address,
        }));
    }

    async function onPartnerRegisterSubmit(
        e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
    ): Promise<void> {
        onSubmit(e, async () => {
            const data: IPostRegisterUserData = {
                info: {
                    ...form_values,
                    address: current_company_address,
                    skip_password_match: 1,
                },
                products: query_params.parsed_products,
                utm: query_params.has_utm ? query_params.utm : undefined,
                language,
                should_resolve_synchronously: true,
            };

            trackRegistrationInDreamData(data);
            trackRegistrationInPartnerstack(data);

            await axios.post<{ success: boolean }>(Paths.REGISTER_BASE, data);

            await fetchCSRFToken();

            const redirect_path = `${Paths.BASE}?${REGISTER_PARAM}&${PARTNER_PARAM}`;

            window.location.href = Online ? redirect_path : `http://localhost:3000${redirect_path}`;
        });
    }

    /**
     *  when the password changes, validate it
     */
    useEffect(() => {
        const password = form_values.password;

        const { valid } = validatePassword({ password });

        const password_valid = valid && !validation_errors?.password;

        setPasswordValid(password_valid);
    }, [form_values.password, validation_errors?.password]);

    /**
     * when prepopulated values are read from the query
     */
    useEffect(() => {
        if (!query_params.prepopulated_values) {
            return;
        }

        const { prepopulated_values } = query_params;

        setFormValues({
            ...form_values,
            company_name: form_values.company_name || prepopulated_values?.company_name || '',
            user_name: form_values.user_name || prepopulated_values?.user_name || '',
            email: form_values.email || prepopulated_values?.email || '',
            phone: form_values.phone || prepopulated_values?.phone || '',
            coupon: form_values.coupon || prepopulated_values?.coupon || '',
        });

        setCompanyAddress({
            ...current_company_address,
            address: current_company_address.address || prepopulated_values?.address || '',
            unit: current_company_address.unit || prepopulated_values?.unit || '',
            city: current_company_address.city || prepopulated_values?.city || '',
            country: current_company_address.country || prepopulated_values?.country || '',
            state: current_company_address.state || prepopulated_values?.state || '',
            zip: current_company_address.zip || prepopulated_values?.zip || '',
        });
    }, [query_params.prepopulated_values]);

    /**
     * when form data changes, set is_valid
     */
    useEffect(() => {
        setIsValid(
            form_values.user_name.length > 0 &&
                form_values.email.length > 0 &&
                form_values.password.length >= 8 &&
                form_values.phone.length > 0 &&
                !!current_company_address?.address &&
                !!current_company_address?.zip &&
                Object.keys(duplicate_email_validation_errors).length === 0 &&
                Object.keys(validation_errors).length === 0
        );
    }, [
        form_values.user_name,
        form_values.email,
        form_values.password,
        form_values.phone,
        current_company_address?.address,
        current_company_address?.zip,
        duplicate_email_validation_errors,
        validation_errors,
    ]);

    return (
        <MainContainer>
            <NicejobLogo src={NICEJOB_LOGO_URL} />
            <FormContainer>
                <TwoColumnLayoutContainer variant={'full-page'}>
                    <InputFieldAndLabelContainer>
                        <TextField
                            label={translate({ namespace: 'common', key: 'textfield.email' })}
                            name='email'
                            variant='stretch'
                            onBlur={validation.input}
                            invalid={!!validation_errors?.email}
                            error={validation_errors?.email?.message}
                            initial_value={form_values.email}
                            onChange={(value: string): void => {
                                clearValidationError(VALIDATION_FIELD.email);
                                // setDuplicateEmailValidationErrors({});
                                setFormValues({
                                    ...form_values,
                                    email: value,
                                });
                            }}
                        ></TextField>
                    </InputFieldAndLabelContainer>
                    <InputFieldAndLabelContainer>
                        <PasswordField
                            label={translate({ namespace: 'common', key: 'textfield.password' })}
                            variant='stretch'
                            onKeyUp={(e): void => {
                                e.key !== 'Tab' &&
                                    validation.input(e, {
                                        email: form_values.email,
                                    });
                            }}
                            onBlur={validation.input}
                            invalid={!!validation_errors?.password}
                            valid={is_password_valid}
                            error={validation_errors?.password?.message}
                            color={validation_errors?.password?.color}
                            description={translate({
                                namespace: 'login',
                                key: 'onboarding.register.password_thank_you',
                            })}
                            onChange={(value: string): void => {
                                setFormValues({
                                    ...form_values,
                                    password: value,
                                });
                            }}
                        ></PasswordField>
                    </InputFieldAndLabelContainer>
                    <InputFieldAndLabelContainer>
                        <TextField
                            label={translate({
                                namespace: 'common',
                                key: 'textfield.company_name',
                            })}
                            variant='stretch'
                            name='company_name'
                            onChange={(value: string): void => {
                                setFormValues({
                                    ...form_values,
                                    company_name: value,
                                });
                            }}
                            initial_value={form_values.company_name}
                            error={validation_errors.company_name?.message}
                            invalid={!!validation_errors.company_name}
                            onBlur={validation.input}
                        ></TextField>
                    </InputFieldAndLabelContainer>
                    <InputFieldAndLabelContainer>
                        <TextField
                            label={translate({ namespace: 'common', key: 'textfield.full_name' })}
                            variant='stretch'
                            name='user_name'
                            invalid={!!validation_errors?.user_name}
                            initial_value={form_values.user_name}
                            error={validation_errors?.user_name?.message}
                            onBlur={validation.input}
                            onChange={(value: string): void => {
                                clearValidationError(VALIDATION_FIELD.user_name);
                                setFormValues({
                                    ...form_values,
                                    user_name: value,
                                });
                            }}
                        ></TextField>
                    </InputFieldAndLabelContainer>
                    <InputFieldAndLabelContainer>
                        <TextField
                            variant='stretch'
                            name='phone'
                            type='tel'
                            onBlur={validation.input}
                            invalid={!!validation_errors?.phone}
                            initial_value={form_values.phone}
                            error={validation_errors?.phone?.message}
                            label={translate({
                                namespace: 'login',
                                key: 'onboarding.register.phone_placeholder',
                            })}
                            onChange={(value: string): void => {
                                clearValidationError(VALIDATION_FIELD.phone);
                                setFormValues({
                                    ...form_values,
                                    phone: value,
                                });
                            }}
                        ></TextField>
                    </InputFieldAndLabelContainer>
                    <InputFieldAndLabelContainer>
                        <TextField
                            variant='stretch'
                            name='coupon'
                            initial_value={form_values.coupon}
                            label={translate({
                                namespace: 'login',
                                key: 'register_test.coupon',
                            })}
                            onChange={(value: string): void =>
                                setFormValues({
                                    ...form_values,
                                    coupon: value,
                                })
                            }
                        ></TextField>
                    </InputFieldAndLabelContainer>
                </TwoColumnLayoutContainer>
                <ManualAddress
                    variant={'full-page'}
                    company_address={current_company_address}
                    onChange={onManualAddressChanged}
                    validation={validation}
                    validation_errors={validation_errors}
                    clearValidationError={clearValidationError}
                />

                <FormRow>
                    <SignupButtonContainer>
                        <FullWidthButton
                            variant='ghost'
                            loading={is_loading}
                            disabled={!is_valid || is_loading}
                            onClick={onPartnerRegisterSubmit}
                        >
                            {translate({
                                namespace: 'login',
                                key: 'onboarding.register.button.email',
                            })}
                        </FullWidthButton>
                    </SignupButtonContainer>
                    <RegisterAlert
                        variant='serious'
                        message={alert_message}
                        show_alert={!!alert_message}
                    />
                </FormRow>
            </FormContainer>
        </MainContainer>
    );
}
