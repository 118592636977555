import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { IIconButtonProps } from './IconButton';

export const IconButtonBase = styled.button<Omit<IIconButtonProps, 'icon'>>`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    border-radius: 100%;

    ${({ size }): FlattenSimpleInterpolation => buttonSize(size)}

    ${(props): FlattenSimpleInterpolation => {
        const { theme, variant } = props;
        const { icon_button } = theme;
        const { default: base, hover, active, disabled, loading } = icon_button[variant];

        if (props.loading) {
            return css`
                color: ${loading.color};
                background: ${loading.background};
                cursor: default;
            `;
        }

        return css`
            color: ${base.color};
            background: ${base.background};

            &:hover {
                color: ${hover.color};
                background: ${hover.background};
            }

            &:active {
                color: ${active.color};
                background: ${active.background};
            }
            &:disabled {
                color: ${disabled.color};
                background: ${disabled.background};
            }
        `;
    }}
`;

function buttonSize(size: IIconButtonProps['size']): FlattenSimpleInterpolation {
    switch (size) {
        case 'small':
            return css`
                width: 24px;
                height: 24px;
            `;
        default:
            return css`
                width: 36px;
                height: 36px;
            `;
    }
}
