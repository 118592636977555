import { Alert, MEDIA_QUERIES } from '@nicejob-library/design-system/core';
import styled, { Keyframes, keyframes } from 'styled-components';
import { COLORS } from '../../themeUtil';
import { IStepState, ScreenStep } from './types';

/** Styled Components */
export const MainContainer = styled.div<IStepState>`
    z-index: -3;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;

    overflow-x: hidden;
    scrollbar-gutter: stable;

    background-color: #edf4ff;
`;

export const NicejobLogo = styled.img<IStepState>`
    visibility: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'visible' : 'hidden'};
    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 1 : 0)};

    position: absolute;
    top: 0;
    left: 0;

    width: 93px;
    margin: 8px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 150px;
        margin: 12px;
    }

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;

export const LogInContainer = styled.p<IStepState>`
    z-index: 1;

    position: absolute;
    right: 24px;
    top: 18px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: ${({ theme }): string => theme.colors.blue100};

    transition: color 0.3s ease-in-out;

    :hover {
        cursor: pointer;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        right: 28px;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        right: 28px;
        top: 25px;

        color: ${({ step, theme }): string =>
            step === ScreenStep.PHONE_NUMBER_INPUT
                ? `${theme.colors.blue100}`
                : `${theme.colors.blue600}`};
    }
`;

/** Top */
export const TopContainer = styled.div<IStepState>`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 132px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin-top: 286px;
    }

    transition: margin-top 0.3s ease-in-out;
`;

export const BlueCylinder = styled.div<IStepState>`
    visibility: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'hidden' : 'visible'};
    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 0 : 1)};

    position: absolute;
    top: 127px;
    width: 32px;

    border: 4px solid ${({ theme }): string => theme.colors.blue400};
    border-radius: 10px;

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;

/** Bottom */
export const BottomContainer = styled.div<IStepState>`
    position: absolute;

    pointer-events: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'none' : 'auto'};

    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 0.5 : 1)};
    width: 344px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    border-radius: 12px;
    background-color: ${({ theme }): string => theme.colors.white};

    top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '680px' : '264px')};
    margin-bottom: 123px;
    padding: 32px 36px;
    box-shadow: ${({ step, theme }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'none' : `0px 0px 26px ${theme.colors.blue200}`};

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '673px' : '220px')};
        margin-bottom: 167px;

        background-color: ${({ step, theme }): string =>
            step === ScreenStep.PHONE_NUMBER_INPUT ? '#edf4ff' : theme.colors.white};
    }

    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
`;

export const LightBlueHalfCircle = styled.div<IStepState & { variant: 'left' | 'right' }>`
    visibility: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'hidden' : 'visible'};
    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 0 : 1)};

    width: 12px;
    height: 24px;
    margin-top: -22px;
    background: #edf4ff;

    margin-left: ${({ variant }): string => (variant === 'left' ? '-36px' : '0')};
    margin-right: ${({ variant }): string => (variant === 'right' ? '-36px' : '0')};
    float: ${({ variant }): string => variant};
    border-top-left-radius: ${({ variant }): string => (variant === 'right' ? '24px' : '0')};
    border-bottom-left-radius: ${({ variant }): string => (variant === 'right' ? '24px' : '0')};
    border-top-right-radius: ${({ variant }): string => (variant === 'left' ? '24px' : '0')};
    border-bottom-right-radius: ${({ variant }): string => (variant === 'left' ? '24px' : '0')};

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;

export const InputContainer = styled.div<IStepState>`
    width: 100%;

    pointer-events: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'none' : 'auto'};
`;

export const LIWGInputContainer = styled.div`
    width: 100%;
`;

export const Disclaimer = styled.p`
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: ${COLORS.GREY600};
    line-height: 16px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;

    @media ${MEDIA_QUERIES.tablet} {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const DisclaimerLink = styled.a`
    color: inherit;

    &:hover {
        cursor: pointer;
    }
`;

export const RegisterTitle = styled.h1`
    color: ${COLORS.BLACK};
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;

    @media ${MEDIA_QUERIES.tablet} {
        font-size: 32px;
        line-height: 42px;
    }
`;

export const RegisterAlert = styled(Alert)`
    margin-top: 16px;
`;

export const PageMainContent = styled.div`
    margin: 0 auto;
    width: 100%;

    @media ${MEDIA_QUERIES.tablet} {
        width: 500px;
    }
`;

/** Animations */
const InputShake = keyframes`
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
`;

const VerificationInputAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(20px); }
  100% { transform: translateY(0); }
`;

export const Header = styled.h1<IStepState>`
    visibility: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'visible' : 'hidden'};
    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 1 : 0)};

    width: 300px;
    height: 88px;
    margin-bottom: 16px;

    font-family: 'Bogart';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    color: ${({ theme }): string => theme.colors.white};

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 702px;
        height: 44px;

        margin-bottom: 22px;

        font-size: 48px;
    }

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;

export const SubHeader = styled.h2<IStepState>`
    visibility: ${({ step }): string =>
        step === ScreenStep.PHONE_NUMBER_INPUT ? 'visible' : 'hidden'};
    opacity: ${({ step }): number => (step === ScreenStep.PHONE_NUMBER_INPUT ? 1 : 0)};

    width: 296px;
    height: 48px;

    margin-bottom: 50px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;

    color: ${({ theme }): string => theme.colors.white};

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 360px;

        margin-bottom: 42px;
    }

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;

export const PhoneNumberInputContainer = styled.div<
    IStepState & { shake: boolean; phone_verification_in_progress: boolean }
>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '334px' : '51px')};

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '442px' : '51px')};
    }

    transition: top 0.3s ease-in-out;

    animation-name: ${({ step, shake }): Keyframes | string => {
        if (shake && step === ScreenStep.PHONE_NUMBER_INPUT) {
            return InputShake;
        } else if (InputShake) {
            return VerificationInputAnimation;
        } else {
            return 'none'; // When no animation should be applied
        }
    }};

    animation-duration: ${({ step, shake, phone_verification_in_progress }): string => {
        if (shake && step === ScreenStep.PHONE_NUMBER_INPUT) {
            return '0.2s';
        } else if (phone_verification_in_progress) {
            return '0.5s';
        } else {
            return '0s'; // When no animation should be applied
        }
    }};

    animation-timing-function: ${({ step, shake }): string =>
        shake && step === ScreenStep.PHONE_NUMBER_INPUT ? 'ease-in-out' : 'ease'};
    animation-delay: ${({ step, shake }): string =>
        shake && step === ScreenStep.PHONE_NUMBER_INPUT ? '0s' : '0'};
    animation-iteration-count: ${({ step, shake }): string =>
        shake && step === ScreenStep.PHONE_NUMBER_INPUT ? '2' : '1'};
`;

export const PhoneNumberInputError = styled.p<{ show: boolean }>`
    display: ${({ show }): string => (show ? 'block' : 'none')};

    margin-top: 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    letter-spacing: 0em;
    text-align: center;

    color: ${({ theme }): string => theme.colors.red500};
`;

export const ArrowButtonContainer = styled.div<{
    onboarding_with_phone_verification: boolean;
    is_visible: boolean;
    phone_input_complete: boolean;
    phone_verification_error: boolean;
}>`
    display: flex;
    visibility: ${({ is_visible }): string => (is_visible ? 'visible' : 'hidden')};
    opacity: ${({ is_visible }): number => (is_visible ? 1 : 0)};

    margin-top: ${({ onboarding_with_phone_verification, phone_verification_error }): string => {
        if (onboarding_with_phone_verification) {
            return phone_verification_error ? '22px' : '0px';
        }

        return '22px';
    }};

    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;

    background-color: ${({ phone_input_complete, theme }): string =>
        phone_input_complete ? theme.colors.white : theme.colors.blue600};
    border-radius: 64px;

    :hover {
        cursor: pointer;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        top: 506px;
    }

    transition: visibility 0.3s, opacity 0.3s ease-in-out;
`;
