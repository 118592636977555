export enum CloudTaskQueue {
    'asana-api' = 'asana-api',
    BATCH_ENROLLMENT_PROCESSING = 'batch-enrollment-processing',
    BATCH_REVIEW_SCRAPE = 'batch-review-scrape',
    'batch-stripe-customer-updated' = 'batch-stripe-customer-updated',
    'batch-stripe-subscription-updated' = 'batch-stripe-subscription-updated',
    CAMPAIGN_REQUEST = 'campaign-request',
    COMPANY_BACKFILL = 'company-backfill',
    CREATE_REVIEWS = 'create-reviews',
    BATCH_COMPETITORS_REVIEW_SCRAPE = 'batch-competitors-review-scrape',
    'create-convert' = 'create-convert',
    'default' = 'default',
    'get-insights' = 'get-insights',
    GIFT_REPORT = 'gift-report',
    GOOGLE_ANALYTICS_API = 'google-analytics-api',
    GOOGLE_API_SCRAPE_QUEUE = 'google-api-scrape-queue',
    SCRAPING_HUB_API_QUEUE = 'scraping-hub-api-queue',
    EVALUATE_GIFT_INTENTS = 'evaluate-gift-intents',
    IMPORT_INTEGRATION_DATA = 'import-integration-data',
    SYNC_REVIEW_DATA_ALL_PEOPLE = 'sync-review-data-all-people',
    SYNC_REVIEW_DATA = 'sync-review-data',
    'intercom-api' = 'intercom-api',
    MAX_UNITS = 'max-units',
    'nudge-company-entities' = 'nudge-company-entities',
    OPPORTUNITIES = 'opportunities',
    PAYMENT_REPORT_EXPORT = 'payment-report-export',
    PROCESS_ALL_SUBJECTS = 'process-all-subjects',
    PROCESS_UNPROCESSED_REVIEWS = 'process-unprocessed-reviews',
    REINSTATE_REVIEWS = 'reinstate-reviews',
    SEND_MESSAGE = 'send-message',
    'schedule' = 'schedule',
    'scrape-reviews' = 'scrape-reviews',
    'story-auto-share' = 'story-auto-share',
    SYNC_ALL_INTERCOM_DATA = 'syncAllIntercomData',
    UPDATE_REVIEWS = 'update-reviews',
    'webhooks-clio' = 'webhooks-clio',
    'webhooks-freshbooks' = 'webhooks-freshbooks',
    'webhooks-housecallpro-0' = 'webhooks-housecallpro-0',
    'webhooks-housecallpro-1' = 'webhooks-housecallpro-1',
    'webhooks-housecallpro-2' = 'webhooks-housecallpro-2',
    'webhooks-housecallpro-3' = 'webhooks-housecallpro-3',
    'webhooks-intercom' = 'webhooks-intercom',
    'webhooks-intercom-0' = 'webhooks-intercom-0',
    'webhooks-intercom-1' = 'webhooks-intercom-1',
    'webhooks-intercom-2' = 'webhooks-intercom-2',
    'webhooks-intercom-3' = 'webhooks-intercom-3',
    'webhooks-jobber-0' = 'webhooks-jobber-0',
    'webhooks-jobber-1' = 'webhooks-jobber-1',
    'webhooks-jobber-2' = 'webhooks-jobber-2',
    'webhooks-jobber-3' = 'webhooks-jobber-3',
    'webhooks-jobber-4' = 'webhooks-jobber-4',
    'webhooks-jobber-5' = 'webhooks-jobber-5',
    'webhooks-jobber-6' = 'webhooks-jobber-6',
    'webhooks-jobber-7' = 'webhooks-jobber-7',
    'webhooks-jobber-8' = 'webhooks-jobber-8',
    'webhooks-jobber-9' = 'webhooks-jobber-9',
    'webhooks-jobber-10' = 'webhooks-jobber-10',
    'webhooks-jobber-11' = 'webhooks-jobber-11',
    'webhooks-jobber-12' = 'webhooks-jobber-12',
    'webhooks-jobber-13' = 'webhooks-jobber-13',
    'webhooks-jobber-14' = 'webhooks-jobber-14',
    'webhooks-jobber-15' = 'webhooks-jobber-15',
    'webhooks-jobber-v2-0' = 'webhooks-jobber-v2-0',
    'webhooks-jobber-v2-1' = 'webhooks-jobber-v2-1',
    'webhooks-jobber-v2-2' = 'webhooks-jobber-v2-2',
    'webhooks-jobber-v2-3' = 'webhooks-jobber-v2-3',
    'webhooks-jobber-v2-4' = 'webhooks-jobber-v2-4',
    'webhooks-jobber-v2-5' = 'webhooks-jobber-v2-5',
    'webhooks-jobber-v2-6' = 'webhooks-jobber-v2-6',
    'webhooks-jobber-v2-7' = 'webhooks-jobber-v2-7',
    'webhooks-jobber-v2-8' = 'webhooks-jobber-v2-8',
    'webhooks-jobber-v2-9' = 'webhooks-jobber-v2-9',
    'webhooks-jobber-v2-10' = 'webhooks-jobber-v2-10',
    'webhooks-jobber-v2-11' = 'webhooks-jobber-v2-11',
    'webhooks-jobber-v2-12' = 'webhooks-jobber-v2-12',
    'webhooks-jobber-v2-13' = 'webhooks-jobber-v2-13',
    'webhooks-jobber-v2-14' = 'webhooks-jobber-v2-14',
    'webhooks-jobber-v2-15' = 'webhooks-jobber-v2-15',
    'webhooks-quickbooks' = 'webhooks-quickbooks',
    'webhooks-responsibid' = 'webhooks-responsibid',
    'webhooks-stripe' = 'webhooks-stripe',
    'webhooks-xero' = 'webhooks-xero',
}
