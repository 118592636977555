import { useLazyQuery } from '@apollo/client';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { LoadingScreenTestimonial } from '../components/LoadingScreenTestimonial/LoadingScreenTestimonial';
import { useOnboardingState } from '../context/OnboardingStateContext';
import OnboardingStateQueries, {
    OnboardingStateData,
    OnboardingStateVariables,
} from '../queries/OnboardingState';
import {
    BlueCircle,
    MainContainer,
    MainText,
    ProgressBar,
    ProgressBarContainer,
    ProgressContainer,
    ProgressText,
    StarBackgroundContainer,
    StarIconContainer,
    TestimonialContainer,
} from './LoadingScreen.styles';

export function LoadingScreen(): ReactElement {
    const {
        onboarding_state,
        onOnboardingStateChange,
        onboarding_update_is_loading,
    } = useOnboardingState();

    const { onboarding_state_id } = onboarding_state || {};

    const [progress_bar_percentage, setProgressBarPercentage] = useState(0);

    const [fetchOnboardingState, { data: onboardingStateResponse }] = useLazyQuery<
        OnboardingStateData,
        OnboardingStateVariables
    >(OnboardingStateQueries.Query.onboardingState);
    const {
        has_company_data_merged,
        is_data_complete,
        is_company_signup_complete,
        has_backtracked,
    } = onboardingStateResponse?.onboardingState || {};

    const timeout_ref = useRef<NodeJS.Timeout | null>(null);

    // on mount, kick-off the poll of onboarding state
    useEffect(() => {
        if (onboarding_update_is_loading) {
            return;
        }

        timeout_ref.current = setInterval(() => {
            if (onboarding_state_id) {
                fetchOnboardingState({
                    variables: {
                        onboarding_state_id,
                    },
                    fetchPolicy: 'network-only',
                });
            }
        }, 1000);

        return (): void => {
            if (timeout_ref.current) {
                clearInterval(timeout_ref.current);
            }
        };
    }, [onboarding_update_is_loading]);

    // when a different onboarding_state response is received, update the progress bar
    useEffect(() => {
        if (!onboardingStateResponse) {
            return;
        }

        const new_onboarding_state = onboardingStateResponse.onboardingState;

        const new_progress_bar_percentage = calculateProgressPercentage(new_onboarding_state);

        setProgressBarPercentage(new_progress_bar_percentage);

        onOnboardingStateChange(onboardingStateResponse.onboardingState);
    }, [has_company_data_merged, is_data_complete, is_company_signup_complete, has_backtracked]);

    return (
        <MainContainer>
            <StarBackgroundContainer>
                <BlueCircle />
                <StarIconContainer>
                    <FontAwesomeIcon icon={faStar} />
                </StarIconContainer>
            </StarBackgroundContainer>

            <MainText>
                {translate({
                    namespace: 'login',
                    key: 'onboarding.register.loading.testimonial.start',
                })}
            </MainText>

            <TestimonialContainer>
                <LoadingScreenTestimonial
                    text={translate({
                        namespace: 'login',
                        key: 'onboarding.register.loading.testimonial.content',
                    })}
                    rating={5}
                    client_name='Jason Wilkat'
                />
            </TestimonialContainer>

            <ProgressContainer>
                <ProgressBarContainer>
                    <ProgressBar percent={progress_bar_percentage} />
                </ProgressBarContainer>
                <ProgressText>
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register.loading.progress',
                        params: { progress: progress_bar_percentage.toString() },
                    })}
                </ProgressText>
            </ProgressContainer>
        </MainContainer>
    );
}

function calculateProgressPercentage({
    has_company_data_merged,
    is_data_complete,
    is_company_signup_complete,
}: Pick<
    IClientSafeOnboardingState,
    'has_company_data_merged' | 'is_data_complete' | 'is_company_signup_complete'
>): number {
    return (
        [has_company_data_merged, is_data_complete, is_company_signup_complete].filter(x => !!x)
            .length * 33
    );
}
