import { Online } from '@nicejob/nicejob-toolbox';
import { NiceJobProjectId } from '../../../../../../../../types/common.types';

export function trackRegistrationInDreamData<
    T extends { info: { user_name: string; email: string } }
>(data: T): void {
    if (!Online || process.env.REACT_APP_PROJECT_ID !== NiceJobProjectId.production) {
        return;
    }

    // global-scoped, see login-route/custom.d.ts
    analytics.identify(null, {
        user_name: data.info.user_name,
        email: data.info.email,
    });
    analytics.track('formSubmitted');
}
