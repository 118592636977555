import { Button } from '@nicejob-library/design-system/core';
import styled, { createGlobalStyle } from 'styled-components';
import { COLORS, FONTS } from './themeUtil';

/** Types */
interface IMainModalProps {
    window_height: number;
}

export const InputFieldAndLabelContainer = styled.div`
    max-width: 240px;

    display: flex;
    flex-direction: column;

    font-size: 14px;
`;

export const InputContainer = styled.div`
    min-height: 36px;
    max-width: 240px;

    font-size: 16px;
`;

export const InputLabel = styled.label`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: ${({ theme }): string => theme.colors.grey900};
`;

export const InputField = styled.input`
    height: 36px;
    max-width: 240px;
    width: 240px;

    font-size: 16px;

    background: ${({ theme }): string => theme.colors.grey50};
    border-radius: 4px 4px 0px 0px;
    border-bottom: solid 1px #cfcfcf;
`;

export const MainPageContainer = styled.div`
    display: none;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        position: absolute;
        overflow-y: hidden;
        display: flex;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;

        background: ${({ theme }): string => theme.colors.grey50};
        filter: brightness(50%);
    }
`;

export const MainModal = styled.div<IMainModalProps>`
    position: relative;
    background: ${({ theme }): string => theme.colors.white};
    overflow-x: hidden;

    height: 100vh;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        z-index: ${({ theme }): number => theme.zIndex.z100};

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 720px;
        height: ${({ window_height }): string => `${window_height - 48}px`};
        max-height: 884px;

        background: ${({ theme }): string => theme.colors.white};
        box-shadow: ${({ theme }): string => theme.elevations.z24};
        border-radius: 8px;
    }

    @media screen and (min-width: 1280px) {
        width: 1100px;
        max-height: 720px;
    }
`;

export const GlobalStyle = createGlobalStyle`
  body,html {
    height: 100%;
    background-color: ${COLORS.WHITE};
    font-family: ${FONTS.LATO}, ${FONTS.ROBOTO}, ${FONTS.HELVETICA}, ${FONTS.ARIAL}, ${FONTS.SANS_SERIF};
  }
`;

export const Layout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export interface ITwoColumnLayoutContainerProps {
    variant: 'modal' | 'full-page';
}

export const TwoColumnLayoutContainer = styled.div<ITwoColumnLayoutContainerProps>`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: ${(props): string => (props.variant === 'full-page' ? '500px' : '380px')};
    justify-content: space-between;

    & > div {
        margin-bottom: 8px;
    }

    @media screen and (${(props): string => props.theme.responsive_media_queries.tablet}) {
        flex-direction: row;
        width: ${(props): string => (props.variant === 'full-page' ? '500px' : '384px')};

        & > div {
            flex: 1 0 50%;
            position: relative;
            width: ${(props): string => (props.variant === 'full-page' ? '240px' : '180px')};
            max-width: ${(props): string => (props.variant === 'full-page' ? '240px' : '180px')};

            & input,
            & select,
            & button {
                width: ${(props): string => (props.variant === 'full-page' ? '240px' : '180px')};
                max-width: ${(props): string =>
                    props.variant === 'full-page' ? '240px' : '180px'};
            }
        }
    }
`;

export const SignupButtonContainer = styled.div`
    width: 100%;
    margin-top: 16px;
`;

export const FullWidthButton = styled(Button)`
    width: 100%;
    justify-content: center;
`;
