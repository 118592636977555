import React from 'react';
import styled from 'styled-components';

/**
 * Top level container for horizontally centering page content and setting font styles
 */
export const AuthContainer = styled.div`
    font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
