/**
 * Apollo client configuration
 * - Featuring API domain
 * - Featuring error handling
 * - Featuring login redirect
 */
/**
 * subscriptions-transport is a peer dep of @apollo/client/link/ws
 * For some reason, just installing like the docs say does not work.
 * For now, importing at the root and will dig deeper to figure out the root issue.
 */
import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import 'subscriptions-transport-ws';
import { APOLLO_CLIENT_CSRF_HEADER, APOLLO_CLIENT_SDK_SESSION_ID_HEADER } from '../../common';
import { SCRIPT_SESSION_ID_ATTRIBUTE } from '../../common/sdk/globals';
import { API_QUERY_PATH, API_SUBSCRIPTION_PATH } from './src/APIDomain';
import { parseFriendlyErrorMessage } from './src/Errors';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

/**  Types  */
export type INiceJobApolloClient = ApolloClient<NormalizedCacheObject>;

export * from './src/APIDomain';

/**  Globals  */
const { REACT_APP_ONLINE_FROM_LOCALHOST, REACT_APP_ORIGIN_OVERRIDE_HEADER } = process.env;

/**
 * Config GQL requests
 * - HTTP auth: https://www.apollographql.com/docs/react/recipes/authentication.html#Cookie
 */
export function buildApolloClient({
    csrf_token,
    enable_subscriptions,
    sdk_session_id,
    pre_defined_cache,
    API_DOMAIN,
    API_DOMAIN_WEBSOCKET,
    LOGIN_PATH,
}: {
    csrf_token: string;
    enable_subscriptions: boolean;
    sdk_session_id: string | null;
    API_DOMAIN: string;
    API_DOMAIN_WEBSOCKET: string;
    LOGIN_PATH: string;
    pre_defined_cache?: InMemoryCache;
}): INiceJobApolloClient {
    const GQL_ROUTE_INTERNAL_ENDPOINT = `${API_DOMAIN}${API_QUERY_PATH}`;
    const GQL_ROUTE_INTERNAL_SUBSCRIPTION_ENDPOINT = `${API_DOMAIN_WEBSOCKET}${API_SUBSCRIPTION_PATH}`;
    /**
     * Headers to add to HTTP requests
     */

    //  Always add csrf_token
    const headers: Record<string, string> = {
        [APOLLO_CLIENT_CSRF_HEADER]: csrf_token,
    };

    //  Append sdk_session_id
    if (sdk_session_id) {
        headers[APOLLO_CLIENT_SDK_SESSION_ID_HEADER] = sdk_session_id;
    }

    //  Add override header (development only)
    if (REACT_APP_ONLINE_FROM_LOCALHOST && REACT_APP_ORIGIN_OVERRIDE_HEADER) {
        headers[`${REACT_APP_ORIGIN_OVERRIDE_HEADER}`] = '1';
    }

    /**
     * Links
     * - HTTP
     * - WebSocket for subscriptions
     */

    //  HTTP
    const gql_http_link = new HttpLink({
        uri: GQL_ROUTE_INTERNAL_ENDPOINT,
        credentials: 'include',
        headers,
    });

    //  WebSocket subscription link
    const gql_ws_link = enable_subscriptions
        ? new GraphQLWsLink(
              createClient({
                  url: GQL_ROUTE_INTERNAL_SUBSCRIPTION_ENDPOINT,
                  connectionParams: { headers },
              })
          )
        : null;

    /**
     * Request routing: Split destination based on operation type
     */
    const gql_split_link = new RetryLink().split(
        operation =>
            // @ts-ignore
            operation?.query?.definitions[0]?.operation === 'subscription',

        //@ts-ignore
        enable_subscriptions ? gql_ws_link : gql_http_link,
        gql_http_link
    );

    /**
     * Handle GraphQL errors
     */
    const gql_error_handler = onError(
        ({ graphQLErrors: gql_errors, networkError: network_error, response, operation }) => {
            //  Need to login? Redirect.
            if (
                response &&
                response.errors &&
                response.errors[0] &&
                // @ts-ignore
                response.errors[0].needsLogin
            ) {
                return window.location.replace(LOGIN_PATH);
            }

            //  Network error?
            if (network_error) {
                console.error('gql networkError:', network_error);
                console.log('gql networkError data:', {
                    operation,
                    network_error,
                    response,
                });
            }

            //  Log GQL errors
            if (gql_errors) {
                gql_errors.map(gql_error => {
                    // @ts-ignore
                    const { brief, message, locations, path } = gql_error || {};
                    const msg = brief || message;

                    //  Error object to be forwarded on
                    const is_friendly = !!parseFriendlyErrorMessage(msg);

                    if (!is_friendly) {
                        console.error('gql error:', operation, {
                            message: msg,
                            friendly: is_friendly,
                        });
                    }
                });
            }
        }
    );

    /**
     * Init Apollo client
     * - With InMemoryCache for pagination merging (see `CLIENT_CACHE`)
     */
    return new ApolloClient({
        link: from([gql_error_handler, gql_split_link]),
        cache: pre_defined_cache ? pre_defined_cache : new InMemoryCache(),
    });
}

export function awaitSDKSessionAndBuildClient(
    csrf_token: string,
    API_DOMAIN: string,
    API_DOMAIN_WEBSOCKET: string,
    LOGIN_PATH: string,
    pre_defined_cache?: InMemoryCache,
    MAX_SDK_SESSION_ID_WAIT?: number,
    find_sdk_script?: boolean
): Promise<INiceJobApolloClient> {
    return new Promise(resolve => {
        /**
         * Find SDK <script> element. Being explicit with find_sdk_script allows us to avoid errors when server-side-rendering.
         */
        let sdk_script_element: HTMLScriptElement | null = null;
        if (find_sdk_script) {
            // only require if we ask, requires document element to exist at run time.
            const { findSDKScript } = require('../../common/sdk/findSDKScript');
            sdk_script_element = findSDKScript();
        }

        //  If no <script>, build right away
        if (!sdk_script_element) {
            return resolve(
                buildApolloClient({
                    csrf_token,
                    enable_subscriptions: true,
                    sdk_session_id: null,
                    pre_defined_cache: pre_defined_cache || new InMemoryCache(),
                    API_DOMAIN,
                    API_DOMAIN_WEBSOCKET,
                    LOGIN_PATH,
                })
            );
        }

        /**
         * Poll for session_id
         * - Is added as data attribute to <script>
         * - Quit after `MAX_SDK_SESSION_ID_WAIT` ms
         */

        // eslint-disable-next-line prefer-const
        let sdk_session_id_interval: NodeJS.Timeout | undefined;

        //  Timeout: If we reach, we quit interval & return client without sdk_session_id
        const sdk_session_id_timeout = setTimeout(() => {
            if (sdk_session_id_interval) {
                clearInterval(sdk_session_id_interval);
            }

            //  Resolve with session_id-less client
            resolve(
                buildApolloClient({
                    csrf_token,
                    enable_subscriptions: true,
                    sdk_session_id: null,
                    pre_defined_cache: pre_defined_cache,
                    API_DOMAIN,
                    API_DOMAIN_WEBSOCKET,
                    LOGIN_PATH,
                })
            );
        }, MAX_SDK_SESSION_ID_WAIT || 300);

        //  Interval: Poll for data attribute
        sdk_session_id_interval = global.setInterval(() => {
            const sdk_session_id = sdk_script_element
                ? sdk_script_element.getAttribute(SCRIPT_SESSION_ID_ATTRIBUTE)
                : null;

            if (sdk_session_id) {
                //  Clear interval
                if (sdk_session_id_interval) {
                    clearInterval(sdk_session_id_interval);
                }

                //  Clear timeout
                clearTimeout(sdk_session_id_timeout);

                //  Resolve with sdk_session_id
                resolve(
                    buildApolloClient({
                        csrf_token,
                        sdk_session_id,
                        enable_subscriptions: true,
                        pre_defined_cache: pre_defined_cache || new InMemoryCache(),
                        API_DOMAIN,
                        API_DOMAIN_WEBSOCKET,
                        LOGIN_PATH,
                    })
                );
            }
        }, 10);

        sdk_session_id_timeout;
    });
}
