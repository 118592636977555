import styled from 'styled-components';
import { Typography } from '@nicejob-library/design-system/core';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const ContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 45px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin: 0;
        flex-direction: row;
        align-items: end;
        justify-content: space-around;
        width: 560px;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        width: 690px;
    }
`;
export const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    order: 2;
    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 360px;
        order: 1;
    }
`;

export const BottomLineText = styled(Typography.P)`
    color: ${({ theme }): string => theme.colors.grey700};
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const ErrorImage = styled.img`
    opacity: 0.5;
    width: 180px;
    margin-bottom: 24px;
    order: 1;
    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        order: 2;
        width: 281px;
        margin-bottom: 0px;
    }
`;
