import React, { Dispatch, ReactElement } from 'react';
import { ScreenStep } from '../types';
import {
    CometsContainer,
    DarkComet,
    GreenCometContainer,
    GreenCometHead,
    GreenCometTail,
    PurpleCometContainer,
    PurpleCometHead,
    PurpleCometTail,
    SmallCometsContainer,
} from './CometsBackgroundGraphic.styles';

interface CometsBackgroundGraphicProps {
    screen_step_state: ScreenStep;
    setScreenStep: Dispatch<ScreenStep>;
}

export function CometsBackgroundGraphic({
    screen_step_state,
    setScreenStep,
}: CometsBackgroundGraphicProps): ReactElement {
    return (
        <CometsContainer>
            <DarkComet
                step={screen_step_state}
                onClick={(): void => {
                    screen_step_state === ScreenStep.SIGNUP_INPUT &&
                        setScreenStep(ScreenStep.PHONE_NUMBER_INPUT);
                }}
            />
            <SmallCometsContainer step={screen_step_state}>
                <PurpleCometContainer>
                    <PurpleCometHead />
                    <PurpleCometTail />
                </PurpleCometContainer>
                <GreenCometContainer>
                    <GreenCometHead />
                    <GreenCometTail />
                </GreenCometContainer>
            </SmallCometsContainer>
        </CometsContainer>
    );
}
