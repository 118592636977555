import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface IAuthErrorProps {
    error: string | undefined;
    error_prefix?: string;
}

const Error = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $smoke;
    color: ${(props): string => props.theme.colors.red700};
    font-size: 15px;
    text-align: center;

    > * {
        display: inline;
        vertical-align: middle;
    }

    strong {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 13px;
        padding-right: 6px;
    }
`;

/**
 * Displays stylized authentication errors such as "invalid email or password"
 */
export function AuthError({ error, error_prefix }: IAuthErrorProps): ReactElement | null {
    if (!error) {
        return null;
    }

    return (
        <Error>
            {error_prefix && <strong>{error_prefix}:</strong>}
            <span>{error}</span>
        </Error>
    );
}
