import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthInput,
} from '@nicejob-library/design-system/authentication';
import {
    Row,
    RowSubmit,
    SubmitInput,
} from '@nicejob-library/design-system/authentication/forms/Form.styles';
import { PasswordField } from '@nicejob-library/design-system/authentication/input/password-field/PasswordField';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { IRegisterTestPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function RegisterTest(props: IRegisterTestPageProps): ReactElement {
    const { company_name, name, email, branding, error_message, language } = props;

    return (
        <ThemeProvider theme={theme}>
            <AuthContainer>
                <HeaderLogo branding={branding} />

                <AuthContent>
                    <AuthCard>
                        <form action='/u/register-test' method='POST'>
                            <Row>
                                <AuthInput
                                    required
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'common',
                                        key: 'textfield.company_name',
                                        language,
                                    })}
                                    type='text'
                                    name='company'
                                    initial_value={company_name}
                                />
                            </Row>
                            <Row>
                                <AuthInput
                                    required
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'login',
                                        key: 'register_test.name',
                                        language,
                                    })}
                                    type='text'
                                    name='name'
                                    initial_value={name}
                                />
                            </Row>
                            <Row>
                                <AuthInput
                                    required
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'common',
                                        language,
                                        key: 'textfield.email',
                                    })}
                                    type='email'
                                    name='email'
                                    initial_value={email}
                                />
                            </Row>
                            <Row>
                                <PasswordField
                                    required
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'common',
                                        key: 'textfield.password',
                                        language,
                                    })}
                                    type='password'
                                    name='password'
                                />
                            </Row>
                            <Row>
                                <AuthInput
                                    required
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'login',
                                        key: 'register_test.confirm_password',
                                        language,
                                    })}
                                    type='password'
                                    name='verify-password'
                                />
                            </Row>
                            <Row>
                                <AuthInput
                                    variant='stretch'
                                    placeholder={translate({
                                        namespace: 'login',
                                        key: 'register_test.coupon',
                                        language,
                                    })}
                                    type='text'
                                    name='coupon'
                                />
                            </Row>
                            <Row>
                                <input type='checkbox' name='convert' id='products.convert' />
                                <label htmlFor='products.convert'>
                                    {translate({
                                        namespace: 'login',
                                        key: 'register_test.add_convert_standard_plan',
                                        language,
                                    })}
                                </label>
                            </Row>
                            <RowSubmit>
                                <SubmitInput
                                    name='register'
                                    type='submit'
                                    value={translate({
                                        namespace: 'login',
                                        key: 'register_test.submit',
                                        params: { brand: branding.brand_name },
                                        language,
                                    })}
                                />
                            </RowSubmit>
                        </form>

                        {error_message && (
                            <AuthError
                                error_prefix={translate({
                                    namespace: 'login',
                                    key: 'common.error_prefix',
                                    language,
                                })}
                                error={error_message}
                            />
                        )}
                    </AuthCard>
                </AuthContent>

                <LoginLink branding={branding} language={language} />
            </AuthContainer>
        </ThemeProvider>
    );
}
