import { buildLanguageData } from '../buildLanguageData';
import CAMPAIGN from './campaign.json';
import CLOUD_FUNCTION from './cloud_function.json';
import COMMON from './common.json';
import CONTENT_TEMPLATE from './content_template.json';
import COUNTRY from './country.json';
import DATABASE_ENTITY from './database_entity.json';
import DESIGN_SYSTEM from './design_system.json';
import FRONTEND from './frontend.json';
import GOALS from './goals.json';
import GRAPHQL_ROUTE from './graphql_route.json';
import INSIGHTS from './insights.json';
import LOGIN from './login.json';
import MESSAGE_TEMPLATE from './message_template.json';
import MICROSITE from './microsite.json';
import NETWORK from './network.json';
import OUTBOUND_OAUTH from './outbound_oauth.json';
import PAYMENTS from './payments.json';
import PEOPLE from './people.json';
import SETTINGS from './settings.json';
import SIDEBAR from './sidebar.json';
import SITES from './sites.json';
import SMS from './sms.json';
import STORIES from './stories.json';
import TESTING from './testing.json';
import WIDGETS from './widgets.json';

/**
 * @const FRENCH_LANGUAGE
 * @description Language data for French
 * @comment ADD NEW NAMESPACES HERE
 */
export const FRENCH_LANGUAGE = buildLanguageData({
    language: 'fr',
    name: 'Français',
    namespaces: {
        campaign: CAMPAIGN,
        cloud_function: CLOUD_FUNCTION,
        common: COMMON,
        content_template: CONTENT_TEMPLATE,
        country: COUNTRY,
        database_entity: DATABASE_ENTITY,
        design_system: DESIGN_SYSTEM,
        frontend: FRONTEND,
        goals: GOALS,
        graphql_route: GRAPHQL_ROUTE,
        insights: INSIGHTS,
        login: LOGIN,
        message_template: MESSAGE_TEMPLATE,
        microsite: MICROSITE,
        network: NETWORK,
        outbound_oauth: OUTBOUND_OAUTH,
        payments: PAYMENTS,
        people: PEOPLE,
        settings: SETTINGS,
        sidebar: SIDEBAR,
        sites: SITES,
        sms: SMS,
        stories: STORIES,
        testing: TESTING,
        widgets: WIDGETS,
    },
});
