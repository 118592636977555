import { ILanguage, translate } from '@nicejob-library/internationalization';
import { trackClick, TrackingData } from '@nicejob-library/tracking';
import React, { ReactElement, useEffect, useState } from 'react';
import { OAuthOperationType } from '../../../../types/entities.types';
import {
    LoginWithAuthLink,
    OAuthLoginButton,
    OAuthLoginButtonContainer,
    OAuthLoginButtonIcon,
    OAuthLoginButtonIconContainer,
    OAuthLoginContainer,
    SeparatorText,
} from './styles/styles';

// Universal authentication type
interface IAuthBaseParams {
    post_login_url?: string;
    products?: string;
    coupon?: string;
}

interface ILoginWithAuthParams extends IAuthBaseParams {
    oauth_operation_type: OAuthOperationType.LOGIN;
    phone?: never;
}

interface ISignUpWithAuthParams extends IAuthBaseParams {
    oauth_operation_type: OAuthOperationType.SIGN_UP;
    phone: string;
    language: ILanguage;
    path?: string;
    referrer?: string;
    search?: string;
    title?: string;
    url?: string;
    user_agent?: string;
}

export type IAuthButtonParams = ISignUpWithAuthParams | ILoginWithAuthParams;

/**
 * Universal authentication button props type
 */
export interface ILoginWithAuthButtonProps {
    title: string;
    path: string;
    logo: string;
    with_separator: boolean;
    params?: IAuthButtonParams;
    tracking_data?: TrackingData;
    color: string;

    onClick?: () => void;
}

/**
 * Universal authentication button
 */
export function LoginWithAuthButton(props: ILoginWithAuthButtonProps): ReactElement {
    const { title, logo, path, params, with_separator, tracking_data, color, onClick } = props;

    const [query_string, setQueryString] = useState('');
    const [href, setHref] = useState(path);
    const [has_been_clicked, setHasBeenClicked] = useState(false);

    useEffect(() => {
        if (params) {
            setQueryString(convertParamsToQueryString(params));
        }
    }, [params]);

    useEffect(() => {
        if (query_string) {
            return setHref(`${path}?${query_string}`);
        }

        setHref(path);
    }, [path, query_string]);

    return (
        <OAuthLoginContainer>
            <LoginWithAuthLink
                data-testid='login-with-auth-link'
                href={href}
                onClick={async (event): Promise<void> => {
                    if (has_been_clicked) {
                        // Prevent multiple requests from being sent
                        event.preventDefault();

                        return;
                    }

                    setHasBeenClicked(true);

                    onClick && onClick();

                    tracking_data && (await trackClick(tracking_data));
                }}
            >
                <OAuthLoginButtonContainer>
                    <OAuthLoginButtonIconContainer
                        style={{
                            borderColor: color,
                        }}
                    >
                        <OAuthLoginButtonIcon>
                            <object data={logo}></object>
                        </OAuthLoginButtonIcon>
                    </OAuthLoginButtonIconContainer>
                    <OAuthLoginButton
                        style={{
                            backgroundColor: color,
                        }}
                    >
                        {title}
                    </OAuthLoginButton>
                </OAuthLoginButtonContainer>
            </LoginWithAuthLink>
            {with_separator && (
                <SeparatorText data-testid='liwg-separator'>
                    <span>
                        {translate({ namespace: 'common', key: 'text.or', text_case: 'upper' })}
                    </span>
                </SeparatorText>
            )}
        </OAuthLoginContainer>
    );
}

function convertParamsToQueryString(params: IAuthButtonParams | ILoginWithAuthParams): string {
    if (!params || Object.keys(params).length === 0) {
        return '';
    }

    const query_string = Object.keys(params)
        .map(key => {
            const param = params[key as keyof IAuthButtonParams];

            const encoded_param = param && encodeURIComponent(param);

            return `${encodeURIComponent(key)}=${encoded_param}`;
        })
        .join('&');

    return query_string;
}
