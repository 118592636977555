import { CompanyProductId } from '@nicejob-library/data-platform-implementation/entities/Company/types';
import { CompanyProductPlan } from '@nicejob-library/data-platform-implementation/entities/Company/ProductStatus/types';

export interface IProduct {
    product: CompanyProductId;
    plan: CompanyProductPlan;
}
export interface IProductSlugs {
    active: boolean;
    slug: string;
    products: Array<IProduct>;
}

export const DEFAULT_PRODUCT = [
    { product: CompanyProductId.reviews, plan: CompanyProductPlan.STANDARD },
];

export const PRODUCT_SLUGS: Array<IProductSlugs> = [
    {
        active: true,
        slug: 'reviews-standard-convert',
        products: [
            { product: CompanyProductId.reviews, plan: CompanyProductPlan.STANDARD },
            { product: CompanyProductId.convert, plan: CompanyProductPlan.STANDARD },
        ],
    },
    {
        active: true,
        slug: 'reviews-free',
        products: [{ product: CompanyProductId.reviews, plan: CompanyProductPlan.FREE }],
    },
    {
        active: true,
        slug: 'reviews-free-convert',
        products: [
            { product: CompanyProductId.reviews, plan: CompanyProductPlan.FREE },
            { product: CompanyProductId.convert, plan: CompanyProductPlan.STANDARD },
        ],
    },
];

export function deriveProductsFromSlug(slug: string | null): Array<IProduct> {
    // Find all active slugs...
    const active_slugs = PRODUCT_SLUGS.filter(product => product.active).map(
        product => product.slug
    );

    // If no slug is passed or the slug isn't in the active slugs,
    //it is safe to add reviews.STANDARD as the default product.
    if (!slug || !active_slugs.includes(slug)) {
        return DEFAULT_PRODUCT;
    }

    const selected_products = PRODUCT_SLUGS.find(product => product.slug === slug)?.products;

    if (!selected_products) {
        return DEFAULT_PRODUCT;
    }

    return selected_products;
}
