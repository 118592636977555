import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import {
    CompanyName,
    CompanySwitcher,
    CompanySwitcherText,
    CompanySwitchExpand,
    MainSideBarContainer,
    NavDivider,
    NavItem,
    NavItemContainer,
    NavItemIcon,
    NavItemText,
    NavSettingsAvatar,
    NavSettingsContainer,
    NavSettingsExpand,
    NavSettingsItem,
    NavSettingsTitle,
} from './NicejobFacade.styles';

/** Types */
interface INicejobFacadeProps {
    company_name: string | null;
}

export function NicejobFacade(props: INicejobFacadeProps): ReactElement {
    const { company_name } = props;

    return (
        <MainSideBarContainer>
            <CompanySwitcher>
                <CompanySwitcherText>
                    <CompanyName>
                        {company_name ||
                            translate({
                                namespace: 'login',
                                key: 'onboarding.nicejob_layout.company',
                            })}
                    </CompanyName>
                    <CompanySwitchExpand>j</CompanySwitchExpand>
                </CompanySwitcherText>
            </CompanySwitcher>
            <NavItemContainer>
                <NavItem>
                    <NavItemIcon></NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'sidebar', key: 'link.stories' })}
                    </NavItemText>
                </NavItem>
                <NavItem>
                    <NavItemIcon></NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'sidebar', key: 'link.people' })}
                    </NavItemText>
                </NavItem>
                <NavItem>
                    <NavItemIcon></NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'sidebar', key: 'link.campaigns' })}
                    </NavItemText>
                </NavItem>
                <NavDivider />
                <NavItem>
                    <NavItemIcon></NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'sidebar', key: 'link.insights' })}
                    </NavItemText>
                </NavItem>
                <NavItem>
                    <NavItemIcon></NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'common', key: 'text.convert' })}
                    </NavItemText>
                </NavItem>
                <NavItem>
                    <NavItemIcon>=</NavItemIcon>
                    <NavItemText>
                        {translate({ namespace: 'sidebar', key: 'link.goals' })}
                    </NavItemText>
                </NavItem>
                <NavDivider />
            </NavItemContainer>
            <NavSettingsContainer>
                <NavSettingsItem>
                    <NavSettingsAvatar>AB</NavSettingsAvatar>
                    <NavSettingsTitle>
                        {translate({ namespace: 'sidebar', key: 'settings.header' })}
                    </NavSettingsTitle>
                    <NavSettingsExpand>j</NavSettingsExpand>
                </NavSettingsItem>
            </NavSettingsContainer>
        </MainSideBarContainer>
    );
}
