import React from 'react';
import { Trans as TransComponent } from 'react-i18next';
import { IKeyComponents } from '../data/components';
import { INamespaceTranslationKeyMap } from '../data/locales';
import { IInternationalizationNamespace } from '../data/namespaces';
import { IKeyParams } from '../data/params';

/**
 * @interface ParamsArg
 * @description Conditional `params` argument for <Trans> component
 */
type ParamsArg<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
> = IKeyParams<Namespace, Key> extends never
    ? { params?: never }
    : { params: IKeyParams<Namespace, Key> };

/**
 * @interface ComponentsArg
 * @description Conditional `components` argument for <Trans> component
 */
type ComponentsArg<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
> = IKeyComponents<Namespace, Key> extends never
    ? { components?: never }
    : { components: IKeyComponents<Namespace, Key> };

/**
 * @function Trans
 * @description Type-safe wrapper component around the react-i18next <Trans> component
 */
export function Trans<
    Namespace extends IInternationalizationNamespace,
    Key extends INamespaceTranslationKeyMap<Namespace>
>(
    ...args: [
        {
            namespace: Namespace;
            k: Key;
            tOptions?: React.ComponentProps<typeof TransComponent>['tOptions'];
        } & ParamsArg<Namespace, Key> &
            ComponentsArg<Namespace, Key>
    ]
): React.ReactElement {
    const { namespace, k: key, params, components, tOptions } = args[0];

    return (
        <TransComponent<Key, string>
            i18nKey={key}
            ns={namespace}
            values={params}
            components={components}
            tOptions={tOptions}
        />
    );
}
