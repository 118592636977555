export const DOMAIN_KEYS = [
    'API',
    'APP',
    'MICROSITE',
    'TRACKING',
    'ENG',
    'LOGIN',
    'OUTBOUND_OAUTH',
] as const;
export type IDomainKey = typeof DOMAIN_KEYS[number];

export interface IDomainInput {
    key: IDomainKey;
    is_local?: boolean;
    is_payment_hub?: boolean;
    is_atom?: boolean;
    project_id_override?: string;
    reviewly?: boolean;
    resellerSettings?: null | {
        white_label_settings?: null | {
            enabled: boolean;
            app_domain: string | null;
            microsite_domain: string | null;
            tracking_domain: string | null;
        };
    };
}
