import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Chip } from '../chip';

export const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 16px;
    }
`;

export const StyledConnectedChip = styled(Chip).attrs({
    variant: 'green',
})`
    width: 28px;
    height: 18px;
    padding: 0 4px;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > *:second-child {
        margin-top: 4px;
    }
`;

export const StyledGreenIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.colors.green600};
`;

export const StyledIncompleteChip = styled(Chip)`
    background: ${(props): string => props.theme.colors.gold600};
    padding: 0 2px;
`;

export const StyledListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 84px;

    & > *:last-child {
        margin-right: 23px;
    }
`;

export const StyledMain = styled.div`
    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: 24px;
    }
`;

export const StyledNetworkIconContainer = styled.div`
    width: 32px;
    height: 32px;
`;

export const StyledNetworkIcon = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const StyledWhiteIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.colors.white};
    height: 12px;
`;
