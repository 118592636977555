import styled from 'styled-components';
import { ScreenStep, IStepState } from '../types';

export const CometsContainer = styled.div`
    position: absolute;

    top: 0px;
    left: 0px;
`;

export const SmallCometsContainer = styled.div<IStepState>`
    position: absolute;
    left: 38px;
    top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '350px' : '-19px')};

    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        left: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '96px' : '20px')};
        top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '269px' : '-170px')};
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        left: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '96px' : '251px')};
        top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '38px' : '-191px')};
    }
`;

export const GreenCometContainer = styled.div`
    position: relative;
`;

export const GreenCometHead = styled.div`
    z-index: 0;

    position: relative;
    width: 59.33px;
    height: 60.58px;
    left: 0px;
    top: 0px;
    transform: rotate(135deg);

    background: ${({ theme }): string => theme.colors.green500};
    border-radius: 495.427px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 86.53px;
        height: 88.34px;
    }
`;

export const GreenCometTail = styled.div`
    z-index: -1;

    position: relative;
    width: 59.33px;
    height: 297.34px;
    left: -84px;
    top: -262.5px;
    transform: rotate(135deg);

    opacity: 0.8;
    border-radius: 495.427px;
    background: rgba(44, 224, 128, 0.6);

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 86.53px;
        height: 433.62px;

        left: -122px;
        top: -383px;
    }
`;

export const PurpleCometContainer = styled.div`
    position: relative;
    top: 150px;
    left: -10px;

    transition: top 0.3s ease-in-out;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        left: -18px;
        top: 217px;
    }
`;

export const PurpleCometHead = styled.div`
    z-index: 0;

    position: relative;
    width: 35.12px;
    height: 35.12px;
    left: 0px;
    top: 0px;

    background: ${({ theme }): string => theme.colors.purple400};
    border-radius: 495.427px;
    transform: rotate(135deg);

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 51.21px;
        height: 51.21px;
    }
`;

export const PurpleCometTail = styled.div`
    z-index: 0;

    position: relative;
    width: 36.05px;
    height: 165.86px;
    left: -47px;
    top: -147px;

    background: rgba(124, 82, 240, 0.5);
    border-radius: 495.427px;
    transform: rotate(135deg);

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 52.57px;
        height: 241.88px;

        left: -69px;
        top: -214px;
    }
`;

export const DarkComet = styled.div<IStepState>`
    z-index: -2;

    position: absolute;
    width: 155vw;
    height: 608px;
    left: -49vw;
    top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '0px' : '-433px;')};

    border-bottom-left-radius: 122% 140%;
    border-bottom-right-radius: 77% 43%;

    background: #041733;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 140vw;
        height: 651px;
        left: ${({ step }): string =>
            step === ScreenStep.PHONE_NUMBER_INPUT ? '-27vw' : '-29vw;'};
        top: ${({ step }): string => (step === ScreenStep.PHONE_NUMBER_INPUT ? '0px' : '-477px')};

        border-bottom-left-radius: 134% 221%;
        border-bottom-right-radius: 108% 132%;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        width: 115vw;
        height: 2626px;
        left: ${({ step }): string =>
            step === ScreenStep.PHONE_NUMBER_INPUT ? '-14vw' : '-16.5vw'};
        top: ${({ step }): string =>
            step === ScreenStep.PHONE_NUMBER_INPUT ? '-1982px' : '-2462px'};

        border-bottom-left-radius: 104% 79%;
        border-bottom-right-radius: 79% 41%;
    }

    transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
`;
