import styled from 'styled-components';

/** Styled Components */
export const AdditionalInfoContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

/** Header */
export const ConnectGBPBackContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    min-width: 250px;
    margin-top: 23px;
    margin-left: 18px;

    cursor: pointer;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin-top: 40px;
        margin-left: 40px;
    }
`;

export const BackButtonContainer = styled.div`
    width: 220px;
    height: 17px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: ${({ theme }): string => theme.colors.typography};
`;

export const LeftArrowContainer = styled.div`
    font-size: 12px;
    color: ${({ theme }): string => theme.colors.typography};
`;

/** Main */
export const MainContainer = styled.div`
    z-index: 2;
    position: inherit;

    display: flex;
    flex-direction: column;
    gap: 32px;
    min-width: 284px;

    margin-left: 18px;
    margin-right: 18px;

    padding-bottom: 32px;

    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        margin-left: 40px;
        margin-bottom: 0px;
    }

    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}) {
        margin-left: 116px;
    }
`;

export const BalloonsIconContainer = styled.div`
    width: 40px;
    height: 32px;

    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    text-align: center;

    color: ${({ theme }): string => theme.colors.blue200};
`;

export const MainTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const WelcomeTextContainer = styled.div`
    width: 77px;
    height: 19px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: ${({ theme }): string => theme.colors.blue500};
`;

export const HeaderTextContainer = styled.div`
    @media screen and (${({ theme }): string => theme.responsive_media_queries.tablet}) {
        width: 475px;
        height: 62px;
    }
    @media screen and (${({ theme }): string => theme.responsive_media_queries.large_desktop}}) {
        width: 362px;
        height: 93px;
    }

    font-family: 'Bogart';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 130%;

    color: ${({ theme }): string => theme.colors.blue950};
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const LinkButtonContainer = styled.div`
    font-size: 14px;
    padding-top: 10px;
`;

/** Errors */
export const AlertContainer = styled.div`
    width: fit-content;
`;
