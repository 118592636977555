import styled from 'styled-components';
import { CardSectionProps, VariantType } from './CardSection';

// Styled components

export const StyledCardSection = styled.div<CardSectionProps>`
    padding: ${(props): string => cardSectionPadding(props.variant)};
    width: inherit;
    box-sizing: border-box;
`;

// Helpers
function cardSectionPadding(variant: VariantType): string {
    switch (variant) {
        case 'small':
            return '16px';
        case 'medium':
            return '24px 32px';
        case 'medium-no-horizontal-padding':
            return '24px 0px';
        default:
            return '24px 32px';
    }
}
