// new
/**
 * Input & form validation
 */

import { getLanguage, translate } from '@nicejob-library/internationalization';
import { parsePhoneNumber } from '@nicejob-library/phone';
import { containsUrlOrEmail } from '@nicejob-library/validation';
import { MutableRefObject } from 'react';
import { validatePassword } from './validatePassword';

export interface IValidatorResult {
    valid: boolean;
    typename?: string;
    message?: string;
    color?: string;
}
export interface IValidationRule {
    label: string;
    type?: 'email' | 'phone' | 'url' | 'password' | 'domain' | 'company_name';
    required?: boolean;
    required_or?: number;
    required_when?: string[];
    equal?: string;
    mutation_input_field?: string;
    ref?: any; // @todo: need to find how to handle ref type as it need argument
    array?: boolean;
    min_length?: number;
    error_message?: string;
}

export interface IValidationRules {
    [name: string]: IValidationRule;
}

export interface IValidationError {
    [name: string]: IValidateValueResult;
}

export interface IValidateValueResult {
    fields: string[];
    refs: any[]; // @todo: need to find how to handle ref type as it need argument
    message: string;
    color?: string;
}

export interface IGetInputValueArgs {
    rule: IValidationRule;
    name: string;
    mutationInput: { [name: string]: any };
}

interface IValidationOptions {
    email?: string;
}

type IOnValidationFunction = (errors: IValidationError) => void;

const REQUIRED_OR_PREFIX = '[ONE_OF] ';

function getValidators(): Record<
    string,
    (input: string, options?: IValidationOptions) => IValidatorResult
> {
    return {
        email: (email: string): IValidatorResult => {
            const email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            const valid = email_pattern.test(String(email).toLowerCase());

            if (!valid) {
                return {
                    valid: false,
                    typename: translate({
                        namespace: 'common',
                        key: 'textfield.email_address',
                        text_case: 'lower',
                    }),
                };
            }

            return {
                valid: true,
            };
        },

        phone: (phone: string): IValidatorResult => {
            const { is_valid } = parsePhoneNumber({ phone });
            if (!is_valid) {
                return {
                    valid: false,
                    typename: translate({
                        namespace: 'common',
                        key: 'textfield.phone_number',
                        text_case: 'lower',
                    }),
                };
            }

            return {
                valid: true,
            };
        },

        url: (url: string): IValidatorResult => {
            const url_pattern = /^(?:(?:(?:(?:https?|ftp):)\/\/)(?:\S+(?::\S*)?))?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

            const valid = url_pattern.test(String(url).toLowerCase());

            if (!valid) {
                return {
                    valid: false,
                    typename: translate({
                        namespace: 'common',
                        key: 'validation.typename.website',
                    }),
                };
            }

            return {
                valid: true,
            };
        },

        password: (password: string, opts?: IValidationOptions): IValidatorResult => {
            const email = opts?.email;
            const validation = validatePassword({
                password,
                email,
            });

            return {
                ...validation,
                typename: translate({
                    namespace: 'common',
                    key: 'textfield.password',
                    text_case: 'lower',
                }),
            };
        },

        domain: (domain: string): IValidatorResult => {
            const domain_pattern = /^(^|http:\/\/|https:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

            const valid = domain_pattern.test(String(domain).toLowerCase());

            if (!valid) {
                return {
                    valid: false,
                    typename: translate({ namespace: 'common', key: 'validation.typename.domain' }),
                };
            }

            return {
                valid: true,
            };
        },

        company_name: (company_name: string): IValidatorResult => {
            const valid = !containsUrlOrEmail(company_name);

            if (!valid) {
                return {
                    valid: false,
                    typename: translate({
                        namespace: 'common',
                        key: 'textfield.company_name',
                        text_case: 'lower',
                    }),
                    message: translate({
                        namespace: 'common',
                        key: 'validation.company_name_invalid',
                    }),
                };
            }

            return { valid: true };
        },
    };
}

export class Validation {
    rules: IValidationRules;
    onValidation: IOnValidationFunction;
    errors?: IValidationError;
    constructor({
        rules,
        onValidation,
    }: {
        rules: IValidationRules;
        onValidation: IOnValidationFunction;
    }) {
        this.rules = rules;
        this.onValidation = onValidation;

        /**  Methods  */
        this.input = this.input.bind(this);
        this.form = this.form.bind(this);
        this.validateValue = this.validateValue.bind(this);

        /**  Approve rules  */
        for (const field in rules) {
            const { type, required, required_or } = rules[field];

            //  Valid type
            if (type && !getValidators()[type]) {
                throw new Error(
                    `validation type "${type}" within field "${field}" is not a valid type; each type needs an entry in the MutationButton VALIDATORS global`
                );
            }

            //  Valid required
            if (typeof required !== 'undefined' && typeof required !== 'boolean') {
                throw new Error(`validation 'required' parameter needs to be boolean, if defined`);
            }

            //  Valid required_or
            if (
                typeof required_or !== 'undefined' &&
                (typeof required_or !== 'number' || required_or === 0)
            ) {
                throw new Error(
                    `validation 'required_or' parameter needs to be a non-zero number, if defined`
                );
            }
        }

        //  Require handler
        if (typeof onValidation !== 'function') {
            throw new Error(`Validation requires an onValidation function`);
        }

        this.errors = {};
    }

    /**
     * <input> onBlur listener - validate an input value
     * @param {Event} e - blur event
     */

    input(
        e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
        opts?: {
            email?: string;
        }
    ): IValidateValueResult | null {
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;

        const error = this.validateValue({ name, value, opts });

        if (error) {
            this.errors![name] = error;
        } else {
            delete this.errors![name];

            //  Delete sibling required_or
            const rules = this.rules;
            const rule = rules[name];

            const { required_or } = rule || {};

            if (rule && typeof required_or === 'number') {
                for (const sibling_name in rules) {
                    const r = rules[sibling_name];

                    //  required_or match
                    if (
                        sibling_name !== name &&
                        r.required_or === rule.required_or &&
                        this.errors![sibling_name] &&
                        this.errors![sibling_name].message.indexOf(REQUIRED_OR_PREFIX) === 0
                    ) {
                        delete this.errors![sibling_name];
                    }
                }
            } else if (!rule) {
                console.warn('No validation rule for', `"${name}"`);
            }
        }
        //  Create new object reference
        this.onValidation(
            JSON.parse(JSON.stringify(this.errors ? this.formatErrors(this.errors) : this.errors))
        );

        return error ? removePrefix(error) : error;
    }

    /**
     * Validate a mutationInput
     * @param {Object} mutationInput
     */
    form(mutationInput: any): IValidationError | null {
        const rules = this.rules;

        const errors: Array<IValidateValueResult> = [];

        const or_cases: Array<Array<{
            label: string;
            name: string;
            value: any;
            ref?: MutableRefObject<HTMLElement>;
        }>> = [];

        const equal_cases: Array<{
            label: string;
            name: string;
            value: any;
            equal: string;
            match_value: any;
            match_label: string;
            ref?: MutableRefObject<HTMLElement>;
        }> = [];

        /**
         * Validate input
         */
        for (const name in rules) {
            let { label, required_or, equal, mutation_input_field } = rules[name];

            mutation_input_field = mutation_input_field || name;

            /**
             * Get mutationInput value corresponding to field name
             */
            const value = getInputValue({
                rule: rules[name],
                name,
                mutationInput,
            });

            /**
             * Test to see if value meets requirements
             */
            const error = this.validateValue({
                name,
                value,
            });

            if (error) {
                errors.push(error);
            } else if (required_or) {
                //  Or cases: where one of `n` fields is required
                if (!or_cases[required_or]) {
                    or_cases[required_or] = [];
                }

                or_cases[required_or].push({
                    label,
                    value,
                    name,
                });
            } else if (equal) {
                //  Equality case: where two fields must match
                equal_cases.push({
                    label,
                    value,
                    name,
                    equal,
                    match_value: getInputValue({
                        rule: rules[equal],
                        name: equal,
                        mutationInput,
                    }),
                    match_label: rules[equal].label,
                });
            }
        }

        //  OR checks
        for (const or_case of or_cases) {
            if (!or_case) {
                continue;
            }

            let has_value = false;

            for (const { value } of or_case) {
                if (value || value === 0) {
                    has_value = true;
                    break;
                }
            }

            if (!has_value) {
                const labels = [];
                const fields = [];
                const refs = [];

                for (const { label, name, ref } of or_case) {
                    labels.push(label);
                    fields.push(name);
                    refs.push(ref);
                }

                errors.push({
                    fields,
                    message:
                        REQUIRED_OR_PREFIX +
                        translate({
                            namespace: 'common',
                            key: 'validation.subset_required',
                            params: {
                                labels: labels.join(
                                    ' ' + translate({ namespace: 'common', key: 'text.or' }) + ' '
                                ),
                            },
                        } as const),
                    refs,
                });
            }
        }

        //  Equality validation
        for (const equal_case of equal_cases) {
            const { value, match_value, match_label, name, ref, label } = equal_case;

            if (value !== match_value) {
                errors.push({
                    fields: [name],
                    message: translate({
                        namespace: 'common',
                        key: 'validation.not_match',
                        params: { label: label, match_label: match_label },
                    } as const),
                    refs: [ref],
                });
            }
        }

        /**
         * Respond
         */
        this.errors = {};

        if (errors.length) {
            for (const error of errors) {
                for (const field of error.fields) {
                    if (!this.errors[field]) {
                        this.errors[field] = error;
                    }
                }
            }
        }

        this.onValidation(this.formatErrors(this.errors));

        return errors.length ? this.formatErrors(this.errors) : null;
    }

    /**
     * Format or cleanup validation errors.
     * @param errors - validation errors
     */
    formatErrors(errors: IValidationError): IValidationError {
        if (!errors) {
            return errors;
        }

        return Object.keys(errors).reduce((obj, key: keyof IValidationError) => {
            return { ...obj, [key]: removePrefix(errors[key]) };
        }, {});
    }

    /**
     * Run a value validation
     * @param {String} name  - Input name / rule key
     * @param {String} value - Value
     *
     * @returns {Object} Validation error
     */
    validateValue({
        name,
        value,
        opts,
    }: {
        name: string;
        value: any;
        opts?: IValidationOptions;
    }): IValidateValueResult | null {
        const rules = this.rules;
        const language = getLanguage();

        if (!rules[name]) {
            return null;
        }

        const {
            type,
            label,
            required,
            array = false,
            min_length = 1,
            ref,
            error_message = null,
        } = rules[name];

        const has_value = array ? value.length >= min_length : value || value === 0;

        if (!has_value && required) {
            return {
                fields: [name],
                message:
                    error_message ||
                    translate({
                        namespace: 'common',
                        key: 'validation.is_required',
                        params: { label },
                        language,
                    }),
                refs: [ref],
            };
        } else if (type && has_value) {
            const { valid, typename, message, color } = getValidators()[type](value, opts);

            if (!valid) {
                return {
                    fields: [name],
                    message: message
                        ? message
                        : translate({
                              namespace: 'common',
                              key: 'validation.valid_value',
                              params: { typename: typename || '' },
                              language,
                          }),
                    refs: [ref],
                    color,
                };
            }
        }

        return null;
    }
}

/**
 * Return the value to validate from a given mutation input,
 *  for a given rule
 * @param {Object} rule
 * @param {Object} mutationInput
 */
function getInputValue({ rule, name, mutationInput }: IGetInputValueArgs): any {
    let { mutation_input_field } = rule;

    mutation_input_field = mutation_input_field || name;

    let value;

    //  nested field
    if (mutation_input_field.indexOf('.') > -1) {
        const split = mutation_input_field.split('.');

        value = mutationInput[split[0]];

        for (let i = 1; i < split.length; i++) {
            value = value[split[i]];
        }
    } else {
        value = mutationInput[mutation_input_field];
    }

    return value;
}

/**
 * Remove prefix in error message to be returned to the client code.
 * The prefix are identifiers that should be used internally by Validation class.
 * e.g: '[ONE_OF] One of phone or email is required' -> 'One of phone or email is required'
 * @param errors - validation errors
 * @returns validation errors without prefix
 */
function removePrefix(error: IValidateValueResult): IValidateValueResult {
    return {
        ...error,
        message:
            error.message && error.message.indexOf(REQUIRED_OR_PREFIX) !== -1
                ? error.message.replace(REQUIRED_OR_PREFIX, '').trim()
                : error.message,
    };
}
