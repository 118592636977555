import { NetworkName } from '@nicejob-library/data-platform-implementation/entities/Network/enums';
import React, { ReactElement, useState } from 'react';
import { StarRating } from './StarRating';
import {
    ClientPhoto,
    FooterContainer,
    LocationContainer,
    NameAndLocationContainer,
    NameContainer,
    NetworkIcon,
    StarContainer,
    TestimonialContainer,
    TextContainer,
} from './Testimonial.styles';

/** Types */
interface ITestimonialProps {
    text: string;
    text_snippet?: string;
    name?: string;
    location?: string;
    rating: number;
    network: NetworkName;
    photo_url?: string;
    href: string;
}

export function Testimonial({
    text,
    text_snippet,
    name,
    location,
    rating,
    network,
    photo_url,
    href,
}: ITestimonialProps): ReactElement {
    const [show_text_snippet, setShowTextSnippet] = useState(text_snippet ? true : false);

    return (
        <TestimonialContainer
            onMouseEnter={(): void => {
                !!text_snippet && setShowTextSnippet(false);
            }}
            onMouseLeave={(): void => {
                !!text_snippet && setShowTextSnippet(true);
            }}
            onClick={(): void => {
                !!href && window.open(href, '_blank');
            }}
        >
            {(show_text_snippet || !text_snippet) && photo_url ? (
                <ClientPhoto src={photo_url} alt='Client Photo' />
            ) : null}

            {show_text_snippet || !text_snippet ? (
                <StarContainer>
                    <StarRating rating={rating} />
                    <NetworkIcon src={`https://cdn.nicejob.co/icons/${network}.png`} />
                </StarContainer>
            ) : null}

            <TextContainer show_text_snippet={show_text_snippet || !text_snippet}>
                {show_text_snippet && text_snippet ? text_snippet : text}
            </TextContainer>

            <FooterContainer>
                <NameAndLocationContainer>
                    {name ? <NameContainer>{name}</NameContainer> : null}
                    {location ? <LocationContainer>{location}</LocationContainer> : null}
                </NameAndLocationContainer>
            </FooterContainer>
        </TestimonialContainer>
    );
}
