import { IProvinceDataWithShort, getProvinceNameFromCode } from '@nicejob-library/gps/regions';
import React, { ReactElement, useMemo } from 'react';
import { IValueProps, Select, SelectOption, ValueType } from '../../../../core/select';

interface IStateSelectProps {
    default_label: string;
    initial_value?: string | null;
    country_code?: string | null;
    onChange: (selected: IValueProps<string> | null) => void;
    states: Array<IProvinceDataWithShort>;
}

export function StateSelect(props: IStateSelectProps): ReactElement {
    const { initial_value, default_label, country_code, states, onChange } = props;

    function onSelectChange(selected: IValueProps<ValueType> | null): void {
        if (!selected) {
            return;
        }

        onChange(selected as IValueProps<string>);
    }

    const default_value = useMemo(() => {
        return initial_value && country_code
            ? {
                  label:
                      getProvinceNameFromCode({ province_code: initial_value, country_code }) ||
                      default_label,
                  value: initial_value,
              }
            : {
                  label: default_label,
                  value: '',
              };
    }, [initial_value, country_code]);

    return (
        <Select
            stretch
            max_menu_height={350}
            onChange={onSelectChange}
            default_value={default_value}
            disabled={!states || states.length === 0}
        >
            {states.map(state => (
                <SelectOption key={state.short} label={state.name} value={state.short} />
            ))}
        </Select>
    );
}
