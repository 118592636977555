import { AuthHeader, AuthLogo } from '@nicejob-library/design-system/authentication';
import React, { ReactElement } from 'react';
import { ILoginHeaderProps } from '../../../../types/login.types';

export function HeaderLogo(props: ILoginHeaderProps): ReactElement {
    const { brand_name, logo_img_src } = props.branding;

    return (
        <AuthHeader>
            <AuthLogo src={logo_img_src} alt={brand_name} />
        </AuthHeader>
    );
}
