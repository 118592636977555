import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthFooter,
    AuthLoading,
    ForgotPasswordForm,
} from '@nicejob-library/design-system/authentication';
import React, { ReactElement, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IForgotPasswordPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function ForgotPasswordPage(props: IForgotPasswordPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */
    return (
        <ThemeProvider theme={theme}>
            <ForgotPasswordPageContent {...props} />
        </ThemeProvider>
    );
}

export function ForgotPasswordPageContent(props: IForgotPasswordPageProps): ReactElement {
    const { branding, error_message, language } = props;

    const [is_loading, setIsLoading] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onReset(): void {
        setIsLoading(true);
    }

    return (
        <AuthContainer>
            <HeaderLogo branding={branding} />

            {is_loading && <AuthLoading />}

            <AuthContent is_loading={is_loading}>
                <AuthCard>
                    <ForgotPasswordForm
                        post_url='/u/forgot_password'
                        onSubmit={onReset}
                        language={language}
                    />
                    <AuthError error={error_message} error_prefix='Error' />
                </AuthCard>

                <AuthFooter>
                    <LoginLink branding={branding} language={language} />
                </AuthFooter>
            </AuthContent>
        </AuthContainer>
    );
}
