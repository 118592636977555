import { PROJECT_ID } from '@nicejob/nicejob-toolbox';
import { APPENGINE_ROUTE_PORTS } from '../../common';
import {
    API_SUB_DOMAIN,
    APP_SUB_DOMAIN,
    ATOM_DOMAIN,
    ATOM_MICROSITE_SUB_DOMAIN,
    ENG_SUB_DOMAIN,
    HUB_SUB_DOMAIN,
    NICEJOB_DOMAIN,
    PAYSTONE_DOMAIN,
    PROJECT_SUB_DOMAINS,
    REVIEWLY_DOMAIN,
} from './globals';
import { DOMAIN_KEYS, IDomainInput, IDomainKey } from './types';

const DOMAIN_KEY_MAP: Record<IDomainKey, string> = {
    API: 'graphql-route-external',
    APP: 'frontend-route',
    ENG: 'eng-route',
    MICROSITE: 'microsite-route',
    TRACKING: 'tracking-route',
    LOGIN: 'login-route',
    OUTBOUND_OAUTH: 'outbound-oauth-route',
};

export function domain({
    key,
    is_local,
    is_atom = false,
    is_payment_hub = false,
    project_id_override,
    resellerSettings,
    reviewly = false,
}: IDomainInput): string {
    // validate inputs
    if (!DOMAIN_KEYS.includes(key)) {
        throw new Error(`Invalid key passed into domain(): "${key}" is not a valid key`);
    }

    if (is_local) {
        return resolveLocalDomain(key);
    }

    const project_id = project_id_override || PROJECT_ID || process.env.REACT_APP_PROJECT_ID;

    if (!project_id) {
        throw new Error(`Project ID must be specified when is_local is not set to true`);
    }

    /**
     * A: Use white label domain if available
     */
    if (resellerSettings?.white_label_settings?.enabled) {
        const { white_label_settings } = resellerSettings;

        const whitelabel_domain = parseDomain(
            key === 'TRACKING'
                ? white_label_settings.tracking_domain
                : key === 'MICROSITE'
                ? white_label_settings.microsite_domain
                : ['APP', 'LOGIN', 'OUTBOUND_OAUTH'].includes(key)
                ? white_label_settings.app_domain
                : null
        );

        if (whitelabel_domain) {
            return whitelabel_domain;
        }
    }

    /**
     * B: Build domain
     */
    const domain_parts: string[] = [];

    // select key-specific subdomain
    if (['APP', 'LOGIN', 'OUTBOUND_OAUTH'].includes(key)) {
        if (is_payment_hub) {
            domain_parts.push(HUB_SUB_DOMAIN);
        } else {
            domain_parts.push(APP_SUB_DOMAIN);
        }
    } else if (key === 'API') {
        domain_parts.push(API_SUB_DOMAIN);
    } else if (key === 'ENG') {
        domain_parts.push(ENG_SUB_DOMAIN);
    } else if ((key === 'MICROSITE' || key === 'TRACKING') && is_atom) {
        domain_parts.push(ATOM_MICROSITE_SUB_DOMAIN);
    }

    // select project-specific subdomain
    const project_sub_domain = findProjectSpecificSubDomain(project_id);
    if (project_sub_domain) {
        domain_parts.push(project_sub_domain);
    }

    // select base domain
    if (
        reviewly ||
        // white-labelled microsite defaults to reviewly
        (key === 'MICROSITE' && resellerSettings?.white_label_settings?.enabled)
    ) {
        domain_parts.push(REVIEWLY_DOMAIN);
    } else {
        if (is_atom) {
            domain_parts.push(ATOM_DOMAIN);
        } else if (is_payment_hub) {
            domain_parts.push(PAYSTONE_DOMAIN);
        } else {
            domain_parts.push(NICEJOB_DOMAIN);
        }
    }

    // build domain and append protocol
    return `https://${domain_parts.join('.')}`;
}

function parseDomain(d: string | null | undefined): string | null {
    if (!d) {
        return null;
    }

    if (!d.startsWith('http')) {
        return `https://${d}`;
    }

    return d;
}

function findProjectSpecificSubDomain(project_id: string): string | undefined {
    const feature_project_pattern = /\w*\-\w{4}$/;
    const feature_project_subdomain_match = /(\w*)(\-\w{4})$/;

    if (PROJECT_SUB_DOMAINS[project_id]) {
        return PROJECT_SUB_DOMAINS[project_id];
    }

    if (feature_project_pattern.test(project_id)) {
        const subdomain_matches = feature_project_subdomain_match.exec(project_id);
        if (subdomain_matches && subdomain_matches.length > 1) {
            return subdomain_matches[1];
        }
    }

    return undefined;
}

function resolveLocalDomain(domain_key: IDomainKey): string {
    const port = APPENGINE_ROUTE_PORTS[DOMAIN_KEY_MAP[domain_key]];

    return `http://localhost:${port}`;
}
