import styled from 'styled-components';

export const CountdownContainer = styled.div``;

export const CountdownText = styled.span`
    display: inline;
    text-align: center;

    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;

    color: ${({ theme }): string => theme.colors.grey500};
`;

export const CountdownTimer = styled.span`
    display: inline;
    text-align: center;

    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;

    color: ${({ theme }): string => theme.colors.grey500};
`;
