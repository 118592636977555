import { ILanguage, translate } from '@nicejob-library/internationalization';
import React, { FormEvent, ReactElement, useState } from 'react';
import { validatePassword as commonValidatePassword } from '../../../../common/validation/validatePassword';
import { PasswordField } from '../input/password-field/PasswordField';
import {
    FormDescription,
    HiddenInput,
    PasswordValidation,
    Row,
    RowSubmit,
    SubmitInput,
} from './Form.styles';

export interface ResetPasswordFormProps {
    code: string;
    post_url: string;
    language: ILanguage;

    onSubmit?: (e: FormEvent) => void;
}

export function ResetPasswordForm(props: ResetPasswordFormProps): ReactElement {
    const { code, post_url, onSubmit } = props;

    const [validation, setValidation] = useState({
        valid: false,
        message: '',
        color: '',
    });

    function validatePassword(value: string): void {
        const { valid, message, color } = commonValidatePassword({ password: value });
        setValidation({ valid, message, color });
    }

    function onSubmitHandler(e: FormEvent): void {
        if (onSubmit) {
            onSubmit(e);
        }
    }

    return (
        <form onSubmit={onSubmitHandler} action={post_url} method='POST'>
            <FormDescription>
                {translate({
                    namespace: 'login',
                    key: 'reset_password.new_password_form.description',
                    language: props.language,
                })}
            </FormDescription>

            <Row>
                <PasswordField
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'login',
                        key: 'reset_password.new_password_form.placeholder',
                        language: props.language,
                    })}
                    onChange={validatePassword}
                />
                <PasswordValidation style={{ color: validation.color }}>
                    {validation.message}
                </PasswordValidation>
            </Row>

            <RowSubmit>
                <SubmitInput
                    type='submit'
                    value={translate({
                        namespace: 'common',
                        key: 'button.submit',
                        language: props.language,
                    })}
                />
            </RowSubmit>

            <HiddenInput type='text' name='code' value={code} />
            <HiddenInput type='text' name='skip_password_match' value={1} />
        </form>
    );
}
