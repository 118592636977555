export enum FONTS {
    LATO = 'Lato',
    INTER = 'Inter',
    ROBOTO = 'Roboto',
    ARIAL = 'Arial',
    HELVETICA = 'Helvetica',
    SANS_SERIF = 'sans-serif',
}

export enum COLORS {
    WHITE = '#FFF',
    BLACK = '#000',
    GREY600 = '#828B98',
}
