import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { CountdownContainer, CountdownText, CountdownTimer } from './Countdown.styles';

const INTERVAL = 1_000;

interface ICountdownProps {
    time_span_in_seconds: number;
    loading_message: string;
    children: ReactNode;
}

/**
 * Countdown is a component that provides a countdown timer functionality.
 * It takes three props: `time_span_in_seconds`, `loading_message`, and `children`.
 * `time_span_in_seconds` is the total time for the countdown in seconds.
 * `loading_message` is the message to display while the countdown is in progress.
 * `children` are the components to render when the countdown is over.
 *
 * The component uses a state variable `time_left` to keep track of the remaining time in the countdown.
 * It uses an effect to handle the countdown logic. If the remaining time is 0, it render children component.
 * Otherwise, it sets an interval to decrease the remaining time by 1 every second. When the component is unmounted, it clears the interval.
 *
 * In the render method, it checks if there is any time left. If there is, it displays the loading message and the remaining time.
 * If there isn't, it renders the children components.
 */
export function Countdown({
    time_span_in_seconds,
    loading_message,
    children,
}: ICountdownProps): ReactElement {
    const [time_left, setTimeLeft] = useState<number>(time_span_in_seconds);

    useEffect(() => {
        if (time_left <= 0) {
            return;
        }

        const interval_id = setInterval(() => {
            setTimeLeft(prev_time => prev_time - 1);
        }, INTERVAL);

        return (): void => clearInterval(interval_id);
    }, [time_left]);

    return (
        <CountdownContainer>
            {time_left > 0 ? (
                <CountdownText>
                    {loading_message}{' '}
                    <CountdownTimer>
                        {time_left}{' '}
                        {translate({
                            namespace: 'common',
                            key: 'date.seconds',
                        })}
                    </CountdownTimer>
                </CountdownText>
            ) : (
                children
            )}
        </CountdownContainer>
    );
}
