import styled, { FlattenSimpleInterpolation, css, DefaultTheme } from 'styled-components';
import { IRadioProps, IRadioCheckProps } from './Radio';

export const RadioContainer = styled.div<Pick<IRadioProps, 'disabled'>>`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    padding: 2px;
    :focus {
        outline: none;
    }
    ${(props): FlattenSimpleInterpolation | null => {
        if (props.disabled) {
            return css`
                pointer-events: none;
                opacity: 0.4;
            `;
        } else {
            return null;
        }
    }};
`;

export const RadioInputHidden = styled.input.attrs({ type: 'radio' })`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    margin: -1px;
    width: 1px;
    outline: none;
`;

export const RadioLabel = styled.label`
    margin: 0;
    margin-left: 8px;
    color: inherit;
    display: block;
    font-size: 14px;
`;

export const RadioActiveIndicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props): string => props.theme.colors.blue500};
`;

export const RadioCheck = styled.div<IRadioCheckProps>`
    display: flex;
    flex-shrink: 0;
    background-color: ${(props): string => getRadioBackgroundStyles(props)};
    border: 1px solid ${(props): string => getRadioBorderStyles(props)};
    border-radius: 50%;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${RadioInputHidden}:checked + & {
        border: 2px solid ${(props): string => props.theme.colors.blue500};
    }

    ${RadioActiveIndicator} {
        display: ${(props): string => (props.selected ? 'block' : 'none')};
    }
`;

export const CrownBlockedWrapper = styled.div`
    margin-left: auto;
`;

// Helpers
function getRadioBorderStyles(props: IRadioCheckProps & { theme: DefaultTheme }): string {
    if (props.selected || props.hovered) {
        return props.theme.colors.blue500;
    } else {
        return props.theme.colors.grey400;
    }
}

function getRadioBackgroundStyles(props: IRadioCheckProps & { theme: DefaultTheme }): string {
    if (props.selected) {
        return props.theme.colors.white;
    } else {
        return props.theme.colors.grey50;
    }
}
