import React, { ReactElement, ReactNode, useState, useEffect, useRef } from 'react';
import CrownBlocked from '../common/blocked/CrownBlocked';
import UpgradeOverlay from '../common/blocked/UpgradeOverlay';
import { FaCheck, FaMinus } from 'react-icons/fa';

import {
    CheckboxContainer,
    CheckboxInputHidden,
    CheckboxCheck,
    CheckboxActiveIndicator,
    CheckboxLabel,
    CrownBlockedWrapper,
} from './Checkbox.styles';
import { DefaultTheme } from 'styled-components';

export type TCheckboxActionType = 'ADD_ITEM' | 'REMOVE_ITEM';
export interface ICheckboxProps {
    selected_checkboxes: string[];
    deselected_checkboxes?: string[];
    label: ReactNode;
    value: string;
    onChange: (value: string, action: TCheckboxActionType) => void;
    indeterminate?: boolean;
    select_all?: boolean; // show all as active unless they are in selected checkboxes
    blocked?: boolean;
    disabled?: boolean;
    className?: string;
}

export interface ICheckboxCheckProps {
    selected: boolean;
    hovered?: boolean;
}

export interface ICheckboxCheckPropsWithTheme extends ICheckboxCheckProps {
    theme: DefaultTheme;
}

export interface ICheckboxAction {
    type: TCheckboxActionType;
    payload: string;
}

// Reducer to handle state change for selectboxes
export function checkboxValuesReducer(state: string[], action: ICheckboxAction): string[] {
    switch (action.type) {
        case 'ADD_ITEM':
            return [...state, action.payload];
        case 'REMOVE_ITEM':
            return state.filter(item => item !== action.payload);
        default:
            return [...state];
    }
}

export function Checkbox({
    label,
    value,
    onChange,
    select_all,
    disabled,
    selected_checkboxes,
    deselected_checkboxes,
    indeterminate,
    blocked,
    className,
}: ICheckboxProps): ReactElement {
    const [upgrade_open, setUpgradeOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [active, setActive] = useState(false);

    const Checkbox_ref = useRef(null);

    function setCheckboxHovered(state: boolean): void {
        setHovered(state);
    }

    function setCheckboxActive(): void {
        if (blocked) {
            setUpgradeOpen(!upgrade_open);
        } else {
            performSelect(active ? 'deselect' : 'select');
        }
    }

    function performSelect(action: 'select' | 'deselect'): void {
        if (action === 'select') {
            onChange(value, 'ADD_ITEM');
            setActive(true);
        } else {
            onChange(value, 'REMOVE_ITEM');
            setActive(false);
        }
    }

    // Set the Checkbox Active on Initial Render
    useEffect(() => {
        if (
            selected_checkboxes.includes(value) ||
            (select_all && !deselected_checkboxes?.includes(value))
        ) {
            setActive(true);
        } else {
            performSelect('deselect');
        }
    }, [selected_checkboxes.length, value, select_all]);

    return (
        <>
            <CheckboxContainer
                className={className}
                ref={Checkbox_ref}
                tabIndex={0}
                onClick={setCheckboxActive}
                onMouseEnter={(): void => {
                    setCheckboxHovered(true);
                }}
                onMouseLeave={(): void => {
                    setCheckboxHovered(false);
                }}
                disabled={disabled}
            >
                <>
                    {/* The Checkbox's indicator */}
                    {/* For accessibility reasons, we add a hidden Checkbox: 
                https://polished.js.org/docs/#hidevisually */}
                    <CheckboxInputHidden
                        onChange={(): void => performSelect(active ? 'deselect' : 'select')}
                    />
                    <CheckboxCheck selected={active} hovered={hovered}>
                        <CheckboxActiveIndicator>
                            {indeterminate ? <FaMinus size={'10px'} /> : <FaCheck size={'10px'} />}
                        </CheckboxActiveIndicator>
                    </CheckboxCheck>
                </>
                <CheckboxLabel>{label}</CheckboxLabel>
                {blocked && (
                    <CrownBlockedWrapper>
                        <CrownBlocked />
                    </CrownBlockedWrapper>
                )}
            </CheckboxContainer>

            {blocked ? <UpgradeOverlay trigger_ref={Checkbox_ref} visible={upgrade_open} /> : null}
        </>
    );
}
