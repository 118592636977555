import { theme } from '@nicejob-library/design-system';
import { AuthError } from '@nicejob-library/design-system/authentication';
import {
    HiddenInput,
    PasswordValidation,
} from '@nicejob-library/design-system/authentication/forms/Form.styles';
import { PasswordField } from '@nicejob-library/design-system/authentication/input/password-field/PasswordField';
import { Row } from '@nicejob-library/design-system/core';
import { domain } from '@nicejob-library/domain';
import { translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import React, { ReactElement, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { validatePassword as commonValidatePassword } from '../../../../common/validation/validatePassword';
import { ISetPasswordPageProps } from '../../types/login.types';
import {
    AtomLogo,
    CTA,
    Greeting,
    InputContainer,
    LogoContainer,
    SetPasswordContainer,
    SubmitInput,
} from './SetPassword.styles';

const ATOM_HEADER_LOGO = 'https://cdn.nicejob.co/assets/atom.svg';

export function SetPasswordPage(props: ISetPasswordPageProps): ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <SetPasswordPageContent {...props} />
        </ThemeProvider>
    );
}

export function SetPasswordPageContent(props: ISetPasswordPageProps): ReactElement {
    const { error_message, language, code, email = '', name = '', success, branding } = props;

    const [password, setPassword] = useState('');
    const [validation, setValidation] = useState({
        valid: false,
        message: '',
        color: '',
    });

    function validatePassword(value: string): void {
        const { valid, message, color } = commonValidatePassword(
            { password: value },
            { ignore_empty_password: true }
        );

        setValidation({ valid, message, color });
    }

    function confirmPassword(value: string): void {
        if (value !== password) {
            setValidation({
                valid: false,
                message: translate({
                    namespace: 'common',
                    key: 'validation.password.not_match',
                    language,
                }),
                color: '#FF281F',
            });
        } else {
            setValidation({
                valid: true,
                message: '',
                color: '',
            });
        }
    }

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                window.location.href = `${domain({ key: 'APP', is_atom: branding.is_atom })}${
                    Paths.LOGIN_BASE
                }`;
            }, 2000);
        }
    }, []);

    return (
        <SetPasswordContainer>
            <LogoContainer>
                <AtomLogo src={ATOM_HEADER_LOGO} />
            </LogoContainer>

            {success ? (
                <Greeting data-testid='set-password-success'>
                    {translate({
                        namespace: 'login',
                        key: 'set_password.password_set',
                        language,
                    })}
                    <br />
                    <br />
                    {translate({
                        namespace: 'login',
                        key: 'set_password.redirect_to_login',
                        language,
                    })}
                </Greeting>
            ) : (
                <InputContainer>
                    <Greeting>
                        {translate({
                            namespace: 'login',
                            key: 'set_password.welcome',
                            params: { name },
                            language,
                        })}
                    </Greeting>
                    <CTA>
                        {translate({
                            namespace: 'login',
                            key: 'set_password.set_password',
                            language,
                            params: { email },
                        })}
                    </CTA>

                    <form action={`${Paths.SET_PASSWORD}?code=${code}`} method='POST'>
                        <Row sx={{ mb: '10px', backgroundColor: theme.colors.grey100 }}>
                            <PasswordField
                                variant='large'
                                placeholder='Password'
                                hide_background={true}
                                onChange={(value: string): void => {
                                    validatePassword(value);
                                    setPassword(value);
                                }}
                            ></PasswordField>
                        </Row>

                        <Row sx={{ mb: '5px', backgroundColor: theme.colors.grey100 }}>
                            <PasswordField
                                name='confirm_password'
                                variant='large'
                                placeholder='Confirm Password'
                                hide_background={true}
                                onChange={(value: string): void => {
                                    confirmPassword(value);
                                }}
                            ></PasswordField>
                        </Row>

                        <Row sx={{ mb: '17px' }}>
                            <PasswordValidation style={{ color: validation.color }}>
                                {validation.message}
                            </PasswordValidation>
                        </Row>

                        <Row>
                            <SubmitInput type='submit' value='Save Password' />
                        </Row>

                        <HiddenInput type='text' name='code' value={code} />
                    </form>

                    <AuthError
                        error={error_message}
                        error_prefix={translate({
                            namespace: 'common',
                            key: 'text.error',
                            language,
                        })}
                    />
                </InputContainer>
            )}
        </SetPasswordContainer>
    );
}
