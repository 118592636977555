import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthFooter,
    AuthLoading,
    LoginForm,
    LoginWithAuthButton,
} from '@nicejob-library/design-system/authentication';
import { Trans, translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import React, { ReactElement, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { parseQuery } from '../../../../../common/parseQuery';
import { OAuthOperationType } from '../../../../../types/entities.types';
import { ILoginPageProps } from '../../../types/login.types';
import {
    GOOGLE_OAUTH_LOGIN_PATHS,
    XERO_OAUTH_LOGIN_PATHS,
} from '../../server/routes/common/globals';
import { ForgotPasswordLink } from '../components/common/ForgotPasswordLink';
import { GetStartedLink } from '../components/common/GetStartedLink';
import { HeaderLogo } from '../components/common/HeaderLogo';
import {
    AccountNotFoundAlert,
    AccountNotFoundBody,
    AccountNotFoundBodyText,
    AtomLoginText,
} from './Login.styles';

export function LoginPage(props: ILoginPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */
    return (
        <ThemeProvider theme={theme}>
            <LoginPageContent {...props} />
        </ThemeProvider>
    );
}

function LoginPageContent(props: ILoginPageProps): ReactElement {
    const [is_loading, setIsLoading] = useState(false);
    const [show_account_not_found_alert, setShowAccountNotFoundAlert] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onLogin(): void {
        setIsLoading(true);
    }

    const { post_url, email, branding } = props;
    const { brand_name, is_nicejob, is_atom } = branding;

    // Parse Query string to get possible error state.
    const url_query = typeof window !== 'undefined' ? window.location.search : '';

    // get Params
    const params = parseQuery(url_query);

    useEffect(() => {
        if (params.account_not_found) {
            return setShowAccountNotFoundAlert(true);
        }

        return setShowAccountNotFoundAlert(false);
    }, [params]);

    return (
        <AuthContainer>
            <HeaderLogo branding={branding} />

            {is_loading && <AuthLoading />}

            <AuthContent is_loading={is_loading}>
                <AccountNotFoundAlert
                    message={translate({
                        namespace: 'login',
                        key: 'login.error.email_not_found',
                        language: props.language,
                    })}
                    show_alert={show_account_not_found_alert}
                    variant='confirmation'
                    renderAlertBody={(): ReactElement => (
                        <AccountNotFoundBody>
                            <AccountNotFoundBodyText>
                                <b>
                                    {translate({
                                        namespace: 'login',
                                        key: 'login.error.email_not_found',
                                        language: props.language,
                                    })}
                                    .
                                </b>
                            </AccountNotFoundBodyText>
                            <AccountNotFoundBodyText>
                                <Trans
                                    namespace='login'
                                    k='login.account_not_found_solution'
                                    components={{
                                        link: <a href={`${Paths.ONBOARDING_BASE}`} />,
                                    }}
                                />
                            </AccountNotFoundBodyText>
                        </AccountNotFoundBody>
                    )}
                />
                <AuthCard>
                    {!is_atom ? (
                        <>
                            <LoginWithAuthButton
                                title={translate({
                                    namespace: 'login',
                                    key: 'login.google',
                                    language: props.language,
                                })}
                                logo='https://cdn.nicejob.co/icons/google.png'
                                path={GOOGLE_OAUTH_LOGIN_PATHS.request}
                                with_separator={false}
                                params={{
                                    oauth_operation_type: OAuthOperationType.LOGIN,
                                    post_login_url: params?.post_login_url || '',
                                }}
                                color={theme.colors.googleBlue}
                            />
                            <LoginWithAuthButton
                                title={translate({
                                    namespace: 'login',
                                    key: 'login.xero',
                                    language: props.language,
                                })}
                                logo='https://cdn.nicejob.com/icons/xero.png'
                                path={XERO_OAUTH_LOGIN_PATHS.request}
                                with_separator={true}
                                params={{
                                    oauth_operation_type: OAuthOperationType.LOGIN,
                                    post_login_url: params?.post_login_url || '',
                                }}
                                color={theme.colors.xeroBlue}
                            />
                        </>
                    ) : (
                        <AtomLoginText>
                            {translate({
                                namespace: 'login',
                                key: 'login.atom_login_text',
                                language: props.language,
                            })}
                        </AtomLoginText>
                    )}

                    <LoginForm
                        brand_name={brand_name}
                        post_url={post_url}
                        email={email}
                        onSubmit={onLogin}
                        language={props.language}
                    />

                    <AuthError
                        error={props.error_message}
                        error_prefix={translate({
                            namespace: 'login',
                            key: 'login.error.prefix',
                            language: props.language,
                        })}
                    />
                </AuthCard>

                <AuthFooter>
                    {!is_loading && <ForgotPasswordLink />}

                    {!is_loading && is_nicejob && <GetStartedLink />}
                </AuthFooter>
            </AuthContent>
        </AuthContainer>
    );
}
