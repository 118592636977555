/**
 * This function takes a phone number as a string and returns a version of the phone number
 * where all but the last four digits are replaced with asterisks ('*').
 * The function also formats the censored phone number for readability.
 *
 * @param {string} phone - The phone number to censor.
 * This should be a string containing a sequence of digits,
 * potentially with non-digit characters as separators.
 * @returns {string} - The censored phone number.
 * This is a string where all but the last four digits of the input have been replaced with asterisks.
 * The digits are grouped into chunks of four (or fewer for the last group if the total number of digits is not a multiple of four), separated by ' - '.
 *
 * @example
 * // returns "*** - *** - 7890"
 * censorPhoneNumber("123-456-7890")
 */
export function censorPhoneNumber(phone: string): string {
    if (!phone) {
        return '';
    }

    // Remove non-digit characters for consistent processing
    const digits_only = phone.replace(/\D/g, '');
    const visible_part = digits_only.slice(-4);
    const censored_part = digits_only.slice(0, -4).replace(/\d/g, '*');

    // No need to format if there's nothing to censor
    if (!censored_part) {
        return visible_part;
    }

    // Break censored part into chunks of 4 for readability
    const censored_chunks = censored_part.match(/.{1,4}/g) || [];
    const formatted_censored = censored_chunks.join(' - ');

    return `${formatted_censored} - ${visible_part}`;
}
