import { NetworkName } from '@nicejob-library/data-platform-implementation/entities/Network/enums';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement } from 'react';
import { Testimonial } from '../../../core';
import {
    ApostropheContainer,
    BlueCircle,
    BlueCylinder,
    BlueStripedCylinder,
    GraphicsContainer,
    GreenCircle,
    GreenCylinder,
    TestimonialContainer,
    YellowCircle,
    YellowCylinder,
} from './BackgroundVisuals.styles';

/** Globals */
const TESTIMONIAL_TEXT = translate({
    namespace: 'login',
    key: 'onboarding.connect_gbp.testimonial',
});
const TESTIMONIAL_NAME = 'JR Bice';
const TESTIMONIAL_LOCATION = 'Scottsbluff, NE, US';
const TESTIMONIAL_RATING = 5;
const TESTIMONIAL_NETWORK = NetworkName.G2;
const TESTIMONIAL_PHOTO_URL =
    'https://storage.googleapis.com/cdn.nicejob.co/assets/stock-image-couple.png';
const TESTIMONIAL_HREF = 'https://www.g2.com/products/nicejob/reviews/nicejob-review-7477482';

export function BackgroundVisuals(): ReactElement {
    return (
        <GraphicsContainer>
            <YellowCircle />
            <YellowCylinder />
            <BlueCylinder />
            <BlueStripedCylinder />
            <GreenCircle />
            <GreenCylinder />
            <TestimonialContainer>
                <BlueCircle>
                    <ApostropheContainer>“</ApostropheContainer>
                </BlueCircle>
                <Testimonial
                    text={TESTIMONIAL_TEXT}
                    name={TESTIMONIAL_NAME}
                    location={TESTIMONIAL_LOCATION}
                    rating={TESTIMONIAL_RATING}
                    network={TESTIMONIAL_NETWORK}
                    photo_url={TESTIMONIAL_PHOTO_URL}
                    href={TESTIMONIAL_HREF}
                />
            </TestimonialContainer>
        </GraphicsContainer>
    );
}
