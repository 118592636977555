/**
 * Page to connect your Google Business Profile
 */

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@nicejob-library/internationalization';
import { useWindowSize } from '@nicejob-library/react-hooks';
import React, { ReactElement, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../../core';
import { BackgroundVisuals } from './ChildComponents/BackgroundVisuals';
import {
    ButtonGroup,
    ConnectGBPDivider,
    CreateGBPTipContainer,
    CreateGBPTipContainerText,
    DimmedOverlayBackground,
    ExitContainer,
    MainActionContainer,
    MainContainer,
    MainContainerFooter,
    MainModal,
    Typography,
    WelcomeText,
} from './ConnectGBP.styles';

interface ConnectGBPProps {
    onAbortClick: () => void;
    onGBPClick: () => void;
    onGBPCreateClick: () => void;
    connect_gbp_loading: boolean;
    show_welcome_text: boolean;
    user_name?: string;
    as_modal: boolean; // To display the component as modal and rendered using portal into "modal-root"
}

/**
 * ConnectGBP component used for the onboarding on login route and payments in frontend route
 * It displays a button to connect to GBP and link to create one
 * @returns as full modal with dimmed background if as_modal is true, return as plain content without any wrapper if as_modal is false
 */
export function ConnectGBP(props: ConnectGBPProps): ReactElement {
    const { height: window_height } = useWindowSize();
    const {
        onAbortClick,
        onGBPClick,
        onGBPCreateClick,
        connect_gbp_loading,
        show_welcome_text,
        user_name,
        as_modal,
    } = props;

    const [has_clicked_create_gbp, setHasClickedCreatedGbp] = useState(false);
    const content = (
        <>
            <ExitContainer onClick={onAbortClick}>
                <FontAwesomeIcon icon={faTimes} />
            </ExitContainer>

            {/* Main */}
            <MainContainer>
                <MainActionContainer>
                    {show_welcome_text && (
                        <WelcomeText>
                            {user_name
                                ? translate({
                                      namespace: 'login',
                                      key: 'onboarding.connect_gbp.welcome_name',
                                      params: {
                                          name: user_name
                                              ? (user_name?.split(' ')[0] as string)
                                              : '',
                                      },
                                  })
                                : translate({
                                      namespace: 'login',
                                      key: 'common.header.welcome',
                                  })}
                        </WelcomeText>
                    )}
                    <WelcomeText>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.connect_gbp.subheader',
                        })}
                    </WelcomeText>

                    <ButtonGroup>
                        <Button variant='action' onClick={onGBPClick} loading={connect_gbp_loading}>
                            {translate({
                                namespace: 'login',
                                key: 'onboarding.connect_gbp.button.connect_google_business',
                            })}
                        </Button>

                        <div>
                            <Typography font_weight={500} font_size={14}>
                                {translate({
                                    namespace: 'login',
                                    key: 'onboarding.connect_gbp.no_google_business',
                                })}
                            </Typography>
                            <Typography
                                is_link={true}
                                font_weight={500}
                                onClick={(): void => {
                                    onGBPCreateClick();
                                    setHasClickedCreatedGbp(true);
                                }}
                                font_size={14}
                            >
                                {translate({
                                    namespace: 'login',
                                    key: 'onboarding.connect_gbp.create_gbp',
                                })}
                            </Typography>
                        </div>

                        {has_clicked_create_gbp ? (
                            <CreateGBPTipContainer>
                                <FontAwesomeIcon icon={faSparkles} fontSize={12} color='#1874FD' />

                                <CreateGBPTipContainerText>
                                    {translate({
                                        namespace: 'login',
                                        key: 'onboarding.connect_gbp.remainder_connect_gbp',
                                    })}
                                </CreateGBPTipContainerText>

                                <FontAwesomeIcon
                                    icon={faTimes}
                                    fontSize={12}
                                    color='#828B98'
                                    onClick={(): void => setHasClickedCreatedGbp(false)}
                                    cursor='pointer'
                                />
                            </CreateGBPTipContainer>
                        ) : null}
                    </ButtonGroup>

                    {/* Main Footer */}
                    <MainContainerFooter>
                        <ConnectGBPDivider />
                        <Typography onClick={onAbortClick} font_size={14} font_weight={400}>
                            {translate({
                                namespace: 'login',
                                key: 'onboarding.connect_gbp.connect_later',
                            })}
                        </Typography>
                    </MainContainerFooter>
                </MainActionContainer>

                {/* Graphics */}
                <BackgroundVisuals />
            </MainContainer>
        </>
    );

    if (as_modal === false) {
        return content;
    }

    const modal_element = (
        <>
            <DimmedOverlayBackground onClick={onAbortClick}></DimmedOverlayBackground>
            <MainModal window_height={window_height}>{content}</MainModal>
        </>
    );

    return ReactDOM.createPortal(
        modal_element,
        document.getElementById('modal-root') as HTMLElement
    );
}
