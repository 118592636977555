import { Resource } from 'i18next';
import { ILanguageData } from './buildLanguageData';
import { ENGLISH_LANGUAGE } from './en';
import { FRENCH_LANGUAGE } from './fr';

/**  Export forwarding  */
export * from './types';

/**
 * @const LANGUAGES
 * @description List of all languages
 * @comment ADD NEW LANGUAGES HERE
 */
export const LANGUAGES = [ENGLISH_LANGUAGE.language, FRENCH_LANGUAGE.language] as const;

/**
 * @const RESOURCES
 * @description i18next-compatible Resource object containing all language data
 * @comment ADD NEW LANGUAGES HERE
 */
export const RESOURCES = buildResourceFromLanguageDataList(ENGLISH_LANGUAGE, FRENCH_LANGUAGE);

/**
 * @const NAMESPACES
 * @description i18next namespace list
 */
export const NAMESPACES = Object.keys(ENGLISH_LANGUAGE.namespaces);

/**
 * @function buildResourceFromLanguageDataList
 * @description Build an i18next-compatible Resource object from a list of ILanguageData objects
 */
function buildResourceFromLanguageDataList(...languages: Array<ILanguageData>): Resource {
    return languages.reduce((acc, { language, namespaces }) => {
        return {
            ...acc,
            [language]: namespaces,
        };
    }, {});
}
