import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthFooter,
    AuthLoading,
    RegistrationForm,
} from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IJoinCompanyPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function JoinCompanyPage(props: IJoinCompanyPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */
    return (
        <ThemeProvider theme={theme}>
            <JoinCompanyPageContent {...props} />
        </ThemeProvider>
    );
}

function JoinCompanyPageContent(props: IJoinCompanyPageProps): ReactElement {
    const { company, company_invitation, branding, error_message, language } = props;

    const [is_loading, setIsLoading] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onRegister(): void {
        setIsLoading(true);
    }

    return (
        <AuthContainer>
            <HeaderLogo branding={branding} />

            {is_loading && <AuthLoading />}

            <AuthContent>
                <AuthCard>
                    <RegistrationForm
                        brand_name={branding.brand_name}
                        welcome_text={translate({
                            namespace: 'login',
                            key: 'join_company.welcome',
                            params: {
                                company: company.company_name || '',
                                brand: branding.brand_name,
                            },
                            language,
                        })}
                        post_url={`/u/join/company/${company.id}/${company_invitation.id}`}
                        onSubmit={onRegister}
                        email={company_invitation.email}
                        name={`${company_invitation.first_name ||
                            ''} ${company_invitation.last_name || ''}`.trim()}
                        language={language}
                    />

                    <AuthError
                        error={error_message}
                        error_prefix={translate({
                            namespace: 'login',
                            key: 'common.error_prefix',
                            language,
                        })}
                    />
                </AuthCard>

                <AuthFooter>
                    <LoginLink branding={branding} language={language} />
                </AuthFooter>
            </AuthContent>
        </AuthContainer>
    );
}
