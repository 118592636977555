import { getCountries } from './globals';
import { ICountryData } from './types';

let country_options: Array<ICountryData>;

export function getCountryOptions(): Array<ICountryData> {
    if (!country_options) {
        country_options = getCountries().filter(
            country => country.alpha2 && country.alpha3 && country.status === 'assigned'
        );
    }

    return country_options;
}
