import { GLOBAL_METADATA_PARAM } from '../../../common/sdk/globals';
import { ISDKSendResponse } from '../../../types/sdk';
import { ISDKEvent } from '../../../types/sdk/events.types';
import { NJSource, TrackingData } from '../types';

export async function trackClick(tracking_data: TrackingData): Promise<void | ISDKSendResponse> {
    try {
        const { type, id, properties = {}, metadata, is_anonymous = false } = tracking_data;

        /**
         * Validate metadata
         */

        // check that type and id exists
        if (!type && !id) {
            console.error(`trackClick missing one of type or id`, tracking_data, new Error().stack);

            return;
        }

        // check that account_id, company_id, & user_id are set
        const { account_id, company_id, user_id } = window.NiceJob[GLOBAL_METADATA_PARAM] || {};

        if (!is_anonymous && (!account_id || !company_id || !user_id)) {
            console.error(
                `trackClick missing data`,
                {
                    account_id,
                    company_id,
                    user_id,
                },
                tracking_data
            );

            return;
        }

        const segment_data: TrackingData = {
            type,
            id,
            properties,
        };

        // account_id, company_id, user_id added with setGlobalMetadata
        // NOTE: clicks by admins will be saved to the database
        // (Tracking > Click) but will not be propagated to Segment!
        return window.NiceJob.send(ISDKEvent.click, {
            metadata: {
                ...metadata,
                nj_source: NJSource.APP,
                segment_data: JSON.stringify(segment_data),
            },
        }).catch(error => {
            console.error(`NiceJob SDK .send().catch() error [2]:`, error);
        });
    } catch (error) {
        console.error(`NiceJob SDK .send() error [1]:`, error);
    }
}
