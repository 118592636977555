import React, { ReactElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';

/** Interfaces */
export interface HelpArticleLinkProps {
    href: string;
    text: string;
    variant?: HELP_ARTICLE_VARIANTS;
    icon?: ReactElement;
    underline?: boolean;
    font_size?: HELP_ARTICLE_FONT_SIZE;
    onClick?: () => void;
    rel?: string;
    target?: string;
    inline?: boolean;
}

export enum HELP_ARTICLE_VARIANTS {
    BLUE = 'blue',
    GREY = 'grey',
}

export enum HELP_ARTICLE_FONT_SIZE {
    X_SMALL = '12px',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

function getVariantColor({
    variant,
    theme,
}: {
    variant: HELP_ARTICLE_VARIANTS;
    theme: DefaultTheme;
}): string {
    const variant_map: Record<HELP_ARTICLE_VARIANTS, string> = {
        [HELP_ARTICLE_VARIANTS.BLUE]: theme.colors.blue500,
        [HELP_ARTICLE_VARIANTS.GREY]: theme.colors.grey600,
    };

    return variant_map[variant];
}

export const StyledHelpArticleLink = styled.a<{
    variant: HELP_ARTICLE_VARIANTS;
    underline?: boolean;
    font_size: HELP_ARTICLE_FONT_SIZE;
}>`
    display: flex;
    text-decoration: ${(props): string => (props.underline ? 'underline' : 'none')};
    font-size: ${(props): HELP_ARTICLE_FONT_SIZE => props.font_size};
    color: ${(props): string => getVariantColor({ variant: props.variant, theme: props.theme })};
`;

export const StyledHelpArticleLinkInline = styled.a<{
    variant: HELP_ARTICLE_VARIANTS;
    underline?: boolean;
    font_size: HELP_ARTICLE_FONT_SIZE;
}>`
    text-decoration: ${(props): string => (props.underline ? 'underline' : 'none')};
    font-size: ${(props): HELP_ARTICLE_FONT_SIZE => props.font_size};
    color: ${(props): string => getVariantColor({ variant: props.variant, theme: props.theme })};
`;

export const StyledHelpArticleLinkIcon = styled.span`
    padding-top: 4px;
    padding-right: 5px;
    margin-right: 4px;
`;

export const StyledHelpArticleLinkContainer = styled.div<{
    variant: HELP_ARTICLE_VARIANTS;
}>`
    align-items: center;
    color: ${(props): string => getVariantColor({ variant: props.variant, theme: props.theme })};
    display: flex;
`;

export function HelpArticleLink(props: HelpArticleLinkProps): ReactElement | null {
    const {
        href,
        text,
        icon,
        variant = HELP_ARTICLE_VARIANTS.GREY,
        font_size = HELP_ARTICLE_FONT_SIZE.MEDIUM,
        underline = false,
        onClick,
        rel = 'noreferrer',
        target = '_blank',
        inline = false,
    } = props;

    const displayed_icon = icon ? (
        <StyledHelpArticleLinkIcon>{icon}</StyledHelpArticleLinkIcon>
    ) : null;

    if (inline) {
        return (
            <StyledHelpArticleLinkInline
                href={href}
                variant={variant}
                underline={underline}
                font_size={font_size}
                rel={rel}
                onClick={onClick}
                target={target}
            >
                {text}
            </StyledHelpArticleLinkInline>
        );
    }

    return (
        <StyledHelpArticleLinkContainer variant={variant}>
            {displayed_icon}
            <StyledHelpArticleLink
                href={href}
                variant={variant}
                underline={underline}
                font_size={font_size}
                rel={rel}
                onClick={onClick}
                target={target}
            >
                {text}
            </StyledHelpArticleLink>
        </StyledHelpArticleLinkContainer>
    );
}
