import { MutationHookOptions, useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { retryMutation } from './retryMutation';

/** Types */
interface IUseMutationWithRetryArgs {
    mutation: DocumentNode;
    options: Pick<MutationHookOptions, 'variables' | 'onCompleted' | 'onError'>;
    max_retry?: number;
}

type IUseMutationWithRetryResponse = [() => void, { loading: boolean }];

export function useMutationWithRetry(
    input: IUseMutationWithRetryArgs
): IUseMutationWithRetryResponse {
    const { mutation, options, max_retry = 0 } = input;

    const [mutationFunction, { loading }] = useMutation(mutation);

    return [(): void => retryMutation({ mutationFunction, options, max_retry }), { loading }];
}
