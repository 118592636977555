import React, { ReactElement } from 'react';
import styled from 'styled-components';

/* Types */
interface IWellProps {
    size: WellSize;
    children: ReactElement | Array<ReactElement>;
    className?: string;
}

export enum WellSize {
    SMALL,
    LARGE,
}

/** Styles */
const WellDiv = styled.div<Pick<IWellProps, 'size'>>`
    padding: ${(props): string => (props.size === WellSize.SMALL ? '16px 16px' : '24px 32px')};

    background-color: ${(props): string => props.theme.colors.grey50};
    border-radius: 8px;
`;

// Main component
export function Well(props: IWellProps): ReactElement {
    const { size, children, className } = props;

    return (
        <WellDiv size={size} className={className}>
            {children}
        </WellDiv>
    );
}
