import { css, FlattenSimpleInterpolation } from 'styled-components';

/**
 * Helper function to render styles is the Component has the `additional_styles`
 * prop.
 */
export function renderAdditionalStyles(additional_styles: string): FlattenSimpleInterpolation {
    return css`
        ${additional_styles}
    `;
}
