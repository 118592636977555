import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import gql from 'graphql-tag';

// eslint-disable-next-line @nicejob/snake-case
export const OnboardingStateFields = `
    onboarding_state_id
    user_id
    form_data {
        company_name
        company_address {
            address
            unit
            city
            state
            zip
            country
            googlePlaceId
            timezone
            geo {
                latitude
                longitude
            }
        }
        company_phone
    }
    authenticated_networks
    payment_required_products {
        convert
        reviews
    }
    is_data_complete
    is_company_signup_complete
    has_company_data_merged
    has_aborted_google_connect
    is_onboarding_complete
    has_backtracked
`;

export interface OnboardingStateVariables {
    onboarding_state_id: string;
}

export interface OnboardingStateData {
    onboardingState: IClientSafeOnboardingState;
}

// eslint-disable-next-line @nicejob/snake-case
const GraphQL = {
    Query: {
        onboardingState: gql`
             query onboardingState($onboarding_state_id: String!) {
                onboardingState(onboarding_state_id: $onboarding_state_id) {
                     ${OnboardingStateFields}
                 }
             }
         `,
    },
    Mutation: {
        updateOnboardingState: gql`
            mutation updateOnboardingState(
                $input: UpdateOnboardingStateInput!
            ) {
                updateOnboardingState(input: $input) {
                    ${OnboardingStateFields}
                }
            }
        `,
        deleteOnboardingState: gql`
            mutation deleteOnboardingState(
                $input: DeleteOnboardingStateInput!
            ) {
                deleteOnboardingState(input: $input) {
                    ${OnboardingStateFields}
                }
            }
    `,
    },
};

export default {
    ...GraphQL,
};
