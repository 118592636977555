import { hasFlag } from 'country-flag-icons';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { ICountryPhoneInfo } from '../types';

export const SUPPORTED_COUNTRIES = ['US', 'CA', 'AU', 'NZ', 'GB'];

export function getCountriesInfo(): Array<ICountryPhoneInfo> {
    const countries = getCountries();
    const region_names_in_english = new Intl.DisplayNames(['en'], { type: 'region' });
    const priority_map = new Map<string, number>(
        SUPPORTED_COUNTRIES.map((country, index) => [country, index])
    );

    const supported_countries: Array<ICountryPhoneInfo> = [];
    for (let i = 0; i < countries.length; i++) {
        const country_iso_code = countries[i];
        let calling_code = '';

        try {
            calling_code = getCountryCallingCode(country_iso_code);
        } catch (e) {
            continue;
        }

        if (calling_code === '') {
            continue;
        }

        if (!hasFlag(country_iso_code)) {
            continue;
        }

        const country_name = region_names_in_english
            .of(country_iso_code)
            ?.replace(/\([^()]*\)/g, '');

        supported_countries.push({
            label: country_name,
            country_iso_code: country_iso_code,
            calling_code,
            icon: getIconURL(country_iso_code),
            priority: priority_map.get(country_iso_code) ?? 1000,
        } as ICountryPhoneInfo);
    }

    supported_countries.sort((a, b) => a.priority - b.priority);

    return supported_countries;
}

function getIconURL(country: string): string {
    return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`;
}
