/** Imports */
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import {
    IAuthButtonParams,
    LoginWithAuthButton,
} from '@nicejob-library/design-system/authentication';
import { PasswordField } from '@nicejob-library/design-system/authentication/input/password-field/PasswordField';
import { TextField } from '@nicejob-library/design-system/core';
import { PhoneNumberInput } from '@nicejob-library/design-system/onboarding/input/PhoneNumberInput';
import { IPhoneNumberInfo } from '@nicejob-library/design-system/onboarding/input/PhoneNumberInput/PhoneNumberInput';
import { MetricKind } from '@nicejob-library/features/types';
import { translate } from '@nicejob-library/internationalization';
import { Paths } from '@nicejob-library/paths';
import {
    censorPhoneNumber,
    getCountriesInfo,
    removePhoneFormat,
    SUPPORTED_COUNTRIES,
} from '@nicejob-library/phone';
import {
    IValidationRules,
    validatePassword,
    Validation,
} from '@nicejob-library/react-form-validation';
import { TrackingData } from '@nicejob-library/tracking';
import axios from 'axios';
import { CountryCode } from 'libphonenumber-js';
import _debounce from 'lodash/debounce';
import React, {
    ChangeEvent,
    KeyboardEvent,
    MouseEvent,
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTheme } from 'styled-components';
import { FeatureFlag } from '../../../../../../../common';
import { OAuthOperationType } from '../../../../../../../types/entities.types';
import HttpStatusCode from '../../../../../../../types/httpstatuscode.enum';
import {
    GOOGLE_OAUTH_LOGIN_PATHS,
    XERO_OAUTH_LOGIN_PATHS,
} from '../../../../server/routes/common/globals';
import { VALIDATION_FIELD } from '../../../shared/types';
import { JumpingDots } from '../../components/loading/JumpingDots';
import { AuthContext } from '../../context/AuthContext';
import { LaunchDarklyContext } from '../../context/LaunchDarklyContext';
import { useOnboardingState } from '../../context/OnboardingStateContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

/** Components */
import { FullWidthButton, SignupButtonContainer } from '../../Onboarding.styles';
import { CometsBackgroundGraphic } from './ChildComponents/CometsBackgroundGraphic';
import { PhoneNumberInputWithVerification } from './ChildComponents/PhoneNumberInputWithVerification';

import {
    ArrowButtonContainer,
    BlueCylinder,
    BottomContainer,
    Header,
    InputContainer,
    LightBlueHalfCircle,
    LIWGInputContainer,
    LogInContainer,
    MainContainer,
    NicejobLogo,
    PhoneNumberInputContainer,
    RegisterAlert,
    SubHeader,
    TopContainer,
} from './ClientRegister.styles';
import { parseCoupon } from './common/parseCoupon';
import { trackRegistrationInDreamData } from './common/trackRegistrationInDreamData';
import { trackRegistrationInPartnerstack } from './common/trackRegistrationInPartnerstack';
import { IPostRegisterUserData, IRegisterBaseProps, IRegisterUser, ScreenStep } from './types';

type HeaderKey = 'onboarding.register.header' | 'onboarding.register.header_pending_verification';

const PHONE_VERIFICATION_CODE_LENGTH = 6;

/*
 * Google
 */
const GOOGLE_LOGIN_TRACKING_DATA: TrackingData = {
    id: 'signup_google',
    type: 'action',
    is_anonymous: true,
};

/*
 * Xero
 */
const XERO_LOGIN_TRACKING_DATA: TrackingData = {
    id: 'signup_xero',
    type: 'action',
    is_anonymous: true,
};

function getValidationRules(): IValidationRules {
    return {
        [VALIDATION_FIELD.user_name]: {
            label: translate({ namespace: 'common', key: 'textfield.full_name' }),
            required: true,
        },
        [VALIDATION_FIELD.email]: {
            type: 'email',
            label: translate({ namespace: 'common', key: 'textfield.email' }),
            required: true,
        },
        [VALIDATION_FIELD.phone]: {
            label: translate({ namespace: 'common', key: 'textfield.phone_number' }),
            required: true,
        },
        [VALIDATION_FIELD.password]: {
            label: translate({ namespace: 'common', key: 'textfield.password' }),
            required: true,
            type: 'password',
        },
    };
}

const NICEJOB_LOGO_URL = 'https://cdn.nicejob.co/assets/nicejob-white-v2.png';

export function ClientRegister(props: IRegisterBaseProps): ReactElement {
    const {
        // context
        language,

        // props
        query_params,

        // state
        alert_message,
        duplicate_email_validation_errors,
        setDuplicateEmailValidationErrors,
        is_valid,
        setIsValid,
        is_loading,
        validation_errors,
        clearValidationError,

        // handlers
        onSubmit,
        onValidation,
    } = props;

    // context
    const theme = useTheme();
    const { onOnboardingStateChange } = useOnboardingState();
    const { fetchCSRFToken } = useContext(AuthContext);
    const { client: ld_client } = useContext(LaunchDarklyContext);

    // refs
    const phone_number_input_ref = useRef<HTMLInputElement>(null);
    const user_name_input_ref = useRef<HTMLInputElement>(null);
    const is_phone_number_valid = useRef(false);

    // state
    const [screen_step_state, setScreenStep] = useState(ScreenStep.PHONE_NUMBER_INPUT);
    const [form_values, setFormValues] = useState<IRegisterUser>({
        email: '',
        user_name: '',
        password: '',
        phone: '',
        country_iso_code: '',
        verification_id: '',
    });
    const [verification_id, setVerificationId] = useState<string>('');

    // state: validation
    const [is_password_valid, setPasswordValid] = useState<boolean>(false);
    const [outbound_button_clicked, setOutboundButtonClicked] = useState(false);

    const validation = useMemo(
        () =>
            new Validation({
                rules: getValidationRules(),
                onValidation,
            }),
        []
    );

    // hooks
    const with_phone_verification = useFeatureFlag({
        key: FeatureFlag.ONBOARDING_WITH_PHONE_VERIFICATION,
    });

    const [onboarding_with_phone_verification, setOnboardingWithPhoneVerification] = useState<
        boolean
    >(with_phone_verification);

    // Universal params
    const universal_auth_button_params = useMemo((): IAuthButtonParams => {
        const products = JSON.stringify(query_params.parsed_products);

        const post_login_url = `${window.location.origin}${window.location.search}`;

        const path = window.location.pathname;
        const referrer = document.referrer;
        const search = window.location.search;
        const title = document.title;
        const url = window.location.href;
        const user_agent = navigator.userAgent;

        const params: IAuthButtonParams = {
            oauth_operation_type: OAuthOperationType.SIGN_UP,
            phone: form_values.phone,
            post_login_url,
            products,
            language,
            path,
            referrer,
            search,
            title,
            url,
            user_agent,
        };

        if (query_params.coupon) {
            params.coupon = query_params.coupon;
        }

        return params;
    }, [
        form_values.phone,
        query_params.products,
        query_params.parsed_products,
        query_params.coupon,
    ]);

    // derived state
    const phone_number_is_complete = form_values.phone.length > 0;

    // handlers
    function onLoginClick(): void {
        setOutboundButtonClicked(true);

        window.location.href = Paths.LOGIN_BASE;
    }

    /**
     * when the password changes, validate it
     */
    useEffect(() => {
        const password = form_values.password;

        const { valid } = validatePassword({ password });

        const password_valid = valid && !validation_errors?.password;

        setPasswordValid(password_valid);
    }, [form_values.password, validation_errors?.password]);

    /**
     * When screen step switches, handle input field auto focus
     */
    useEffect(() => {
        if (screen_step_state === ScreenStep.PHONE_NUMBER_INPUT) {
            setTimeout(() => {
                window.scrollTo(0, 0);

                phone_number_input_ref.current?.focus();
            }, 350);
        } else if (screen_step_state === ScreenStep.SIGNUP_INPUT) {
            setTimeout(() => {
                user_name_input_ref.current?.focus();
            }, 350);
        }
    }, [screen_step_state, phone_number_input_ref.current, user_name_input_ref.current]);

    /**
     * when for data changes, set is_valid
     */
    useEffect(() => {
        setIsValid(
            form_values.user_name.length > 0 &&
                form_values.email.length > 0 &&
                form_values.password.length >= 8 &&
                form_values.phone.length > 0 &&
                Object.keys(duplicate_email_validation_errors).length === 0 &&
                Object.keys(validation_errors).length === 0
        );
    }, [
        form_values.user_name,
        form_values.email,
        form_values.password,
        form_values.phone,
        duplicate_email_validation_errors,
        validation_errors,
    ]);

    /**
     * when state of onboarding_with_phone_verification changes, focus on phone input
     */
    useEffect(() => {
        phone_number_input_ref.current?.focus();
    }, [onboarding_with_phone_verification]);

    async function onClientRegisterSubmit(
        e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
    ): Promise<void> {
        onSendingMetricEvent(MetricKind.ONBOARDING_CLIENT_CLICK_BUTTON_REGISTER);

        onSubmit(e, async () => {
            const parsed_coupon = query_params?.coupon
                ? parseCoupon<string>(query_params.coupon)
                : undefined;

            const data: IPostRegisterUserData = {
                info: {
                    ...form_values,
                    skip_password_match: 1,
                    coupon: parsed_coupon,
                },
                products: query_params.parsed_products,
                utm: query_params.has_utm ? query_params.utm : undefined,
                language,
                should_resolve_synchronously: false,
            };

            trackRegistrationInDreamData(data);
            trackRegistrationInPartnerstack(data);

            const { data: response_data } = await axios.post<{
                onboarding_state: IClientSafeOnboardingState;
                success: boolean;
                products: {
                    convert: boolean;
                    reviews: boolean;
                };
            }>(Paths.REGISTER_BASE, data);

            await fetchCSRFToken();

            const { onboarding_state } = response_data;

            onOnboardingStateChange(onboarding_state);
        });
    }

    const [header_key, setHeaderKey] = useState<HeaderKey>('onboarding.register.header');
    const [shake_phone_input, setShakePhoneInput] = useState(false);

    const [phone_verification_in_progress, setPhoneVerificationInProgress] = useState<boolean>(
        false
    );
    const [phone_verification_is_loading, setPhoneVerificationIsLoading] = useState<boolean>(false);
    const [phone_number_input_error, setPhoneNumberInputError] = useState<string>('');
    const [country_iso_code, setCountryIsoCode] = useState<string>('');
    const [phone_verification_code, setPhoneVerificationCode] = useState<string>('');

    function onProceedToStepTwo(): void {
        if (!is_phone_number_valid.current) {
            setShakePhoneInput(true);
            setPhoneNumberInputError(
                translate({
                    namespace: 'login',
                    key: 'onboarding.register.error_valid_phone_number',
                })
            );

            return;
        }

        setTimeout(() => {
            setScreenStep(ScreenStep.SIGNUP_INPUT);
        }, 0);
    }

    function getSubHeaderText(): string {
        if (onboarding_with_phone_verification) {
            if (phone_verification_in_progress) {
                return translate({
                    namespace: 'login',
                    key: 'onboarding.register.subheader_pending_verification',
                    params: {
                        phone: censorPhoneNumber(form_values.phone),
                    },
                });
            }

            return translate({
                namespace: 'login',
                key: 'onboarding.register.subheader_with_verification',
            });
        }

        return translate({
            namespace: 'login',
            key: 'onboarding.register.subheader',
        });
    }

    function onPhoneNumberChange(phone_number_info: IPhoneNumberInfo): void {
        clearValidationError(VALIDATION_FIELD.phone);
        setPhoneNumberInputError('');
        setFormValues({
            ...form_values,
            phone: phone_number_info.raw,
            country_iso_code: phone_number_info.country_iso_code,
        });
        setCountryIsoCode(phone_number_info.country_iso_code);

        is_phone_number_valid.current = phone_number_info.is_valid;
    }

    function onPhoneVerificationCodeChange(e: ChangeEvent<HTMLInputElement>): void {
        const { value } = e.target;
        setPhoneVerificationCode(value);
        setPhoneNumberInputError('');
        if (value?.length === PHONE_VERIFICATION_CODE_LENGTH) {
            _debounce(onVerificationCodeEntryComplete, 1_000)(value);
        }
    }

    function onPhoneNumberInputKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        /**
         * Only proceed if the user presses Enter or Tab
         */
        if (!['Enter', 'Tab'].includes(e.key)) {
            return;
        }

        validation.input(e);

        if (onboarding_with_phone_verification) {
            onSendingVerificationCode();
        } else {
            onProceedToStepTwo();
        }
    }

    function onArrowButtonClick(): void {
        onSendingMetricEvent(MetricKind.ONBOARDING_CLIENT_SUBMIT_PHONE);

        if (onboarding_with_phone_verification) {
            onSendingVerificationCode();
        } else {
            onProceedToStepTwo();
        }
    }

    async function onSendingVerificationCode(): Promise<void> {
        setPhoneVerificationIsLoading(true);
        try {
            const response = await axios.post(Paths.PHONE_VERIFICATION_SEND_PHONE_CODE, {
                phone: form_values.phone,
                country_iso_code,
            });

            setVerificationId(response.data.verification_id);
            setFormValues({
                ...form_values,
                verification_id: response.data.verification_id,
            });

            setPhoneVerificationInProgress(true);
            setPhoneNumberInputError('');
            setHeaderKey('onboarding.register.header_pending_verification');
        } catch (error) {
            setPhoneNumberInputError(
                translate({
                    namespace: 'login',
                    key: 'onboarding.register.error_valid_phone_number',
                })
            );
        }
        setPhoneVerificationIsLoading(false);
    }

    async function onVerificationCodeEntryComplete(code: string): Promise<void> {
        if (!is_phone_number_valid.current) {
            setShakePhoneInput(true);
            setPhoneNumberInputError(
                translate({
                    namespace: 'login',
                    key: 'onboarding.register.error_valid_phone_number',
                })
            );
        }

        setPhoneVerificationIsLoading(true);
        try {
            await axios.post(Paths.PHONE_VERIFICATION_VERIFY_PHONE_CODE, {
                phone: form_values.phone,
                country_iso_code,
                code,
                verification_id,
            });

            setPhoneVerificationCode('');
            setPhoneNumberInputError('');
            setPhoneVerificationInProgress(false);
            onProceedToStepTwo();
        } catch (error) {
            if (error.response && error.response.status === HttpStatusCode.TOO_MANY_REQUESTS) {
                setPhoneNumberInputError(
                    translate({
                        namespace: 'login',
                        key: 'onboarding.register.error_max_attempts',
                    })
                );
            } else {
                setPhoneNumberInputError(
                    translate({
                        namespace: 'login',
                        key: 'onboarding.register.error_valid_verification_code',
                    })
                );
            }
        } finally {
            setPhoneVerificationIsLoading(false);
        }
    }

    function onSendingMetricEvent(metric_kind: MetricKind): void {
        if (ld_client) {
            ld_client.track(metric_kind);
        }
    }

    return (
        <MainContainer step={screen_step_state}>
            {/* Comets Graphics */}
            <CometsBackgroundGraphic
                screen_step_state={screen_step_state}
                setScreenStep={setScreenStep}
            />

            {/* Nicejob Logo */}
            <NicejobLogo src={NICEJOB_LOGO_URL} step={screen_step_state} />

            {/* Log in  */}
            <LogInContainer onMouseDown={onLoginClick} step={screen_step_state}>
                {translate({
                    namespace: 'login',
                    key: 'onboarding.register.button.login',
                })}
            </LogInContainer>

            {/* Top */}
            <TopContainer step={screen_step_state}>
                <Header step={screen_step_state}>
                    {translate({
                        namespace: 'login',
                        key: header_key,
                    })}
                </Header>
                <SubHeader step={screen_step_state}>
                    {phone_verification_is_loading ? <JumpingDots /> : getSubHeaderText()}
                </SubHeader>
                <PhoneNumberInputContainer
                    step={screen_step_state}
                    phone_verification_in_progress={phone_verification_in_progress}
                    onClick={(): void => {
                        if (screen_step_state === ScreenStep.SIGNUP_INPUT) {
                            setScreenStep(ScreenStep.PHONE_NUMBER_INPUT);
                        }
                    }}
                    shake={shake_phone_input}
                    onAnimationEnd={(): void => setShakePhoneInput(false)}
                >
                    <PhoneNumberInputWithVerification
                        with_verification={onboarding_with_phone_verification}
                        verification_in_progress={phone_verification_in_progress}
                        setVerificationInProgress={setPhoneVerificationInProgress}
                        placeholder={translate({
                            namespace: 'login',
                            key: 'onboarding.register.verification_code_placeholder',
                        })}
                        verification_code={phone_verification_code}
                        is_highlighted={screen_step_state === ScreenStep.PHONE_NUMBER_INPUT}
                        countdown_loading_message={translate({
                            namespace: 'login',
                            key: 'onboarding.register.resend_code',
                        })}
                        error={phone_number_input_error}
                        onError={setShakePhoneInput}
                        onBlur={validation.input}
                        onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => {
                            validation.input(e);
                            onVerificationCodeEntryComplete(phone_verification_code);
                        }}
                        onChange={onPhoneVerificationCodeChange}
                        onSendingVerificationCode={onSendingVerificationCode}
                    >
                        <PhoneNumberInput
                            input_ref={phone_number_input_ref}
                            placeholder={translate({
                                namespace: 'login',
                                key: 'onboarding.register.phone_placeholder',
                            })}
                            value={form_values.phone}
                            selected_country_iso_code={country_iso_code as CountryCode | undefined}
                            onChange={onPhoneNumberChange}
                            onKeyDown={onPhoneNumberInputKeyDown}
                            onRemovePhoneFormat={removePhoneFormat}
                            onGetCountriesInfo={getCountriesInfo}
                        />
                    </PhoneNumberInputWithVerification>

                    <ArrowButtonContainer
                        onboarding_with_phone_verification={onboarding_with_phone_verification}
                        is_visible={
                            !phone_verification_in_progress &&
                            screen_step_state === ScreenStep.PHONE_NUMBER_INPUT
                        }
                        phone_input_complete={phone_number_is_complete}
                        phone_verification_error={phone_number_input_error.length > 0}
                        onClick={onArrowButtonClick}
                    >
                        <FontAwesomeIcon
                            icon={faArrowDown}
                            fontSize='19px'
                            color={
                                phone_number_is_complete ? theme.colors.blue500 : theme.colors.white
                            }
                        />
                    </ArrowButtonContainer>
                </PhoneNumberInputContainer>
                <BlueCylinder step={screen_step_state} />
            </TopContainer>

            {/* Bottom */}
            <BottomContainer step={screen_step_state}>
                <LIWGInputContainer
                    onMouseDown={(): void => {
                        setOutboundButtonClicked(true);
                    }}
                >
                    <InputContainer step={screen_step_state}>
                        <LoginWithAuthButton
                            title={translate({
                                namespace: 'login',
                                key: 'onboarding.register.button.google',
                            })}
                            logo='https://cdn.nicejob.co/icons/google.png'
                            path={GOOGLE_OAUTH_LOGIN_PATHS.request}
                            with_separator={false}
                            params={universal_auth_button_params}
                            tracking_data={GOOGLE_LOGIN_TRACKING_DATA}
                            color={theme.colors.googleBlue}
                            onClick={(): void => {
                                onSendingMetricEvent(
                                    MetricKind.ONBOARDING_CLIENT_CLICK_BUTTON_REGISTER
                                );
                            }}
                        />
                    </InputContainer>
                    <InputContainer step={screen_step_state}>
                        <LoginWithAuthButton
                            title={translate({
                                namespace: 'login',
                                key: 'onboarding.register.button.xero',
                            })}
                            logo='https://cdn.nicejob.com/icons/xero.png'
                            path={XERO_OAUTH_LOGIN_PATHS.request}
                            with_separator={true}
                            params={universal_auth_button_params}
                            tracking_data={XERO_LOGIN_TRACKING_DATA}
                            color={theme.colors.xeroBlue}
                            onClick={(): void => {
                                onSendingMetricEvent(
                                    MetricKind.ONBOARDING_CLIENT_CLICK_BUTTON_REGISTER
                                );
                            }}
                        />
                        <LightBlueHalfCircle step={screen_step_state} variant='left' />
                        <LightBlueHalfCircle step={screen_step_state} variant='right' />
                    </InputContainer>
                </LIWGInputContainer>

                <InputContainer step={screen_step_state}>
                    <TextField
                        ref={user_name_input_ref}
                        label={translate({
                            namespace: 'common',
                            key: 'textfield.full_name',
                        })}
                        variant='stretch'
                        name='user_name'
                        invalid={!outbound_button_clicked && !!validation_errors?.user_name}
                        initial_value={form_values.user_name}
                        error={
                            outbound_button_clicked
                                ? undefined
                                : validation_errors?.user_name?.message
                        }
                        onBlur={validation.input}
                        onChange={(value: string): void => {
                            clearValidationError(VALIDATION_FIELD.user_name);
                            setFormValues({
                                ...form_values,
                                user_name: value,
                            });
                        }}
                    />
                </InputContainer>

                <InputContainer step={screen_step_state}>
                    <TextField
                        label={translate({
                            namespace: 'common',
                            key: 'textfield.email',
                        })}
                        name='email'
                        variant='stretch'
                        onBlur={validation.input}
                        invalid={
                            !outbound_button_clicked &&
                            (!!validation_errors?.email ||
                                !!duplicate_email_validation_errors?.email_duplication)
                        }
                        error={
                            outbound_button_clicked
                                ? undefined
                                : validation_errors?.email?.message ||
                                  duplicate_email_validation_errors?.email_duplication?.message
                        }
                        initial_value={form_values.email}
                        onChange={(value: string): void => {
                            clearValidationError(VALIDATION_FIELD.email);
                            setDuplicateEmailValidationErrors({});
                            setFormValues({
                                ...form_values,
                                email: value,
                            });
                        }}
                    />
                </InputContainer>

                <InputContainer step={screen_step_state}>
                    <PasswordField
                        label={translate({
                            namespace: 'common',
                            key: 'textfield.password',
                        })}
                        variant='stretch'
                        onKeyUp={(e): void => {
                            e.key !== 'Tab' &&
                                validation.input(e, {
                                    email: form_values.email,
                                });
                        }}
                        invalid={!outbound_button_clicked && !!validation_errors?.password}
                        valid={is_password_valid}
                        error={
                            outbound_button_clicked
                                ? undefined
                                : validation_errors?.password?.message
                        }
                        color={
                            outbound_button_clicked ? undefined : validation_errors?.password?.color
                        }
                        description={translate({
                            namespace: 'login',
                            key: 'onboarding.register.password_thank_you',
                        })}
                        onChange={(value: string): void => {
                            setFormValues({
                                ...form_values,
                                password: value,
                            });
                        }}
                        onKeyPress={(e): void => {
                            if (e.key === 'Enter' && is_valid && !is_loading) {
                                onClientRegisterSubmit(e);
                            }
                        }}
                    />
                </InputContainer>

                <SignupButtonContainer>
                    <FullWidthButton
                        variant='ghost'
                        loading={is_loading}
                        disabled={!is_valid || is_loading}
                        onClick={onClientRegisterSubmit}
                    >
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.register.button.email',
                        })}
                    </FullWidthButton>
                </SignupButtonContainer>
                <RegisterAlert
                    variant='serious'
                    message={alert_message}
                    show_alert={!!alert_message}
                />
            </BottomContainer>
        </MainContainer>
    );
}
