import { ILanguage, translate } from '@nicejob-library/internationalization';
import React, { FormEvent, ReactElement } from 'react';
import { TEST_ID } from '../../../../common/testing/constants';
import { AuthInput } from '../common/AuthInput';
import { PasswordField } from '../input/password-field/PasswordField';
import { Row, RowSubmit, SubmitInput } from './Form.styles';

export interface LoginFormProps {
    brand_name: string;
    post_url: string;
    language: ILanguage;
    email?: string;
    onSubmit?: (e: FormEvent) => void;
}

export function LoginForm(props: LoginFormProps): ReactElement {
    const { brand_name, post_url, email, onSubmit } = props;

    function onSubmitHandler(e: FormEvent): void {
        if (onSubmit) {
            onSubmit(e);
        }
    }

    return (
        <form onSubmit={onSubmitHandler} action={post_url} method='POST'>
            <Row>
                <AuthInput
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'common',
                        key: 'textfield.email_address',
                        language: props.language,
                    })}
                    type='email'
                    name='email'
                    test_id={TEST_ID.login.username_input}
                    initial_value={email}
                />
            </Row>
            <Row>
                <PasswordField
                    required
                    variant='stretch'
                    placeholder={translate({
                        namespace: 'common',
                        key: 'textfield.password',
                        language: props.language,
                    })}
                    test_id={TEST_ID.login.password_input}
                />
            </Row>
            <RowSubmit>
                <SubmitInput
                    name='login'
                    type='submit'
                    data-testid={TEST_ID.login.submit_button}
                    value={translate({
                        namespace: 'login',
                        key: 'login.button',
                        params: { brand: brand_name },
                        language: props.language,
                    })}
                />
            </RowSubmit>
        </form>
    );
}
