import { theme } from '@nicejob-library/design-system';
import {
    AuthCard,
    AuthContainer,
    AuthContent,
    AuthError,
    AuthFooter,
    AuthLoading,
    ResetPasswordForm,
} from '@nicejob-library/design-system/authentication';
import { translate } from '@nicejob-library/internationalization';
import React, { ReactElement, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IResetPasswordPageProps } from '../../types/login.types';
import { HeaderLogo } from './components/common/HeaderLogo';
import { LoginLink } from './components/common/LoginLink';

export function ResetPasswordPage(props: IResetPasswordPageProps): ReactElement {
    /**
     * NB: Cannot use hooks in top-level page component
     */
    return (
        <ThemeProvider theme={theme}>
            <ResetPasswordPageContent {...props} />
        </ThemeProvider>
    );
}

function ResetPasswordPageContent(props: IResetPasswordPageProps): ReactElement {
    const { branding, code, error_message, language } = props;

    const [is_loading, setIsLoading] = useState(false);

    /**
     * Set loading state when submitting the form
     */
    function onReset(): void {
        setIsLoading(true);
    }

    return (
        <AuthContainer>
            <HeaderLogo branding={branding} />

            {is_loading && <AuthLoading />}

            <AuthContent is_loading={is_loading}>
                <AuthCard>
                    <ResetPasswordForm
                        code={code}
                        post_url={`/u/reset_password?code=${code}`}
                        onSubmit={onReset}
                        language={language}
                    />

                    <AuthError
                        error={error_message}
                        error_prefix={translate({
                            namespace: 'common',
                            key: 'text.error',
                            language,
                        })}
                    />
                </AuthCard>

                <AuthFooter>
                    <LoginLink branding={branding} language={language} />
                </AuthFooter>
            </AuthContent>
        </AuthContainer>
    );
}
