import styled from 'styled-components';

export const InsightsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    width: 934px;
    height: 58px;
    left: 313px;
    top: 274px;
    background: #f9f6fe;
    border-radius: 16px;
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 934px;
    height: 58px;
`;

export const MessageText = styled.span`
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333;
`;

export const Sparkles = styled.span`
    width: 12px;
    height: 16px;
    font-family: 'Font Awesome 6 Pro', sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    color: #f9f6fe;
    display: flex;
    align-items: center;
`;
