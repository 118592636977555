import { getCountries } from './globals';
import { IProvinceData } from './types';

export interface IProvinceDataWithShort extends IProvinceData {
    short: string;
}

export function getProvinceOptions({
    country_code,
    countries_with_provinces, // countries for which we want to return province options
}: {
    country_code: string;
    countries_with_provinces?: Array<string>;
}): Array<IProvinceDataWithShort> {
    if (countries_with_provinces && !countries_with_provinces.includes(country_code)) {
        return [];
    }

    const country = getCountries().find(c => c.alpha2 === country_code);

    if (!country) {
        return [];
    }

    return country.provinces.map(p => ({ ...p, short: p.short || '' }));
}
