import { ApolloProvider } from '@apollo/client';
import { INiceJobApolloClient } from '@nicejob-library/apollo-client';
import { theme } from '@nicejob-library/design-system';
import { initNiceJobSDK, trackPageView } from '@nicejob-library/tracking';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AbsoluteCenteredLoadingBall } from '../../../../../library/design-system/core/loading-shapes/LoadingBall';
import { IOnboardingPageProps } from '../../../types/login.types';
import { OnboardingRouter } from './OnboardingRouter';
import { AuthContextConsumer, AuthContextProvider } from './context/AuthContext';
import { OnboardingStateContextProvider } from './context/OnboardingStateContext';
import { LaunchDarklyContextProvider } from './context/LaunchDarklyContext';
import { IPage } from './pages/interfaces';

export interface IAppProps {
    apollo_client: INiceJobApolloClient | null;
    pages: IPage[];
}

export function OnboardingApp(props: IOnboardingPageProps): ReactElement {
    const [sdk_initialized, setSDKInitialized] = useState<boolean>(false);

    // initialize GTM & nicejob sdk
    useEffect(() => {
        initNiceJobSDK(process.env.REACT_APP_SDK_COMPANY_PUBLIC_KEY as string);
        setSDKInitialized(true);
    }, []);

    // track pageview events on navigation
    const location = useLocation();

    useEffect(() => {
        /**
         * Prevent multiple pageview events on initNiceJobSDK
         * by waiting for sdk to be initialized. The init function
         * fires a pageview event so there is no need to fire one
         * immediately after initialization.
         */

        if (sdk_initialized) {
            trackPageView();
        }
    }, [location]);

    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <AuthContextConsumer>
                    {({ apollo_client, loading }): ReactElement => {
                        if (loading) {
                            return (
                                <>
                                    <AbsoluteCenteredLoadingBall />
                                </>
                            );
                        }

                        return (
                            <LaunchDarklyContextProvider>
                                <OnboardingStateContextProvider
                                    onboarding_state={props.onboarding_state}
                                >
                                    {apollo_client ? (
                                        <ApolloProvider client={apollo_client}>
                                            <OnboardingRouter />
                                        </ApolloProvider>
                                    ) : (
                                        <OnboardingRouter />
                                    )}
                                </OnboardingStateContextProvider>
                            </LaunchDarklyContextProvider>
                        );
                    }}
                </AuthContextConsumer>
            </AuthContextProvider>
        </ThemeProvider>
    );
}
