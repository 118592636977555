import styled from 'styled-components';

export const Root = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const StyledMenuWrapper = styled.div`
    background-color: ${({ theme }): string => theme.colors.white};
    border-radius: 5px;
    box-shadow: ${(props): string => props.theme.elevations.z16};
`;

export const StyledButton = styled.button<{ read_only: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 36px;
    padding: 11.5px;
    background-color: ${({ read_only, theme }): string =>
        read_only ? theme.colors.blue800 : theme.colors.white};
    border: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    outline: none;
    cursor: ${({ read_only }): string => (read_only ? 'default' : 'pointer')};

    & > *:first-child {
        margin-right: 8px;
    }

    & > svg {
        color: ${({ theme }): string => theme.colors.grey600};
    }
`;

export const FlagImage = styled.img`
    width: 21px;
    height: 16px;
    border-radius: 3px;
`;
export const FlagImageOnMenu = styled(FlagImage)`
    margin-right: 8px;
`;

export const PhoneInputDivider = styled.div`
    width: 1px;
    height: 36px;
    border-right: 1px solid ${({ theme }): string => theme.colors.grey300};
`;

export const InputWrapper = styled.div<{ read_only: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 234px;
    height: 36px;
    padding-left: 12px;
    background-color: ${({ read_only, theme }): string =>
        read_only ? theme.colors.blue800 : theme.colors.white};
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
`;

export const StyledInput = styled.input<{ read_only: boolean }>`
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: ${({ read_only, theme }): string =>
        read_only ? theme.colors.blue300 : theme.colors.grey900};

    &::placeholder {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: ${({ theme }): string => theme.colors.grey700};
    }
`;
